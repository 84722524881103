import React from 'react';
import { useParams } from 'react-router';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import UserPermissionsPanel from '~/panels/UserPermissionsPanel';
export const UserPermissionsPage = () => {
    const { userId } = useParams();
    const data = useLazyLoadQuery(graphql `
      query UserPermissionsPageQuery($userId: ID!) {
        user(id: $userId) {
          ...UserPermissionsPanel_user
          roles {
            nodes {
              id
              name
            }
          }
        }
      }
    `, {
        userId,
    });
    return <UserPermissionsPanel userRef={data.user}/>;
};
export default UserPermissionsPage;
