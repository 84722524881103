import { ApplicationInsights, PageView } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { SONIKA_AZURE_INSTRUMENTATION_KEY } from './environment';
const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: SONIKA_AZURE_INSTRUMENTATION_KEY,
        disableFetchTracking: false,
        enableCorsCorrelation: true,
        enableAutoRouteTracking: true,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
    },
});
appInsights.loadAppInsights();
appInsights.addTelemetryInitializer((item) => {
    var _a;
    if ((item === null || item === void 0 ? void 0 : item.name) === PageView.envelopeType) {
        if (((_a = item === null || item === void 0 ? void 0 : item.baseData) === null || _a === void 0 ? void 0 : _a.uri) === '/' && (window === null || window === void 0 ? void 0 : window.location)) {
            item.baseData.uri = `${window.location.protocol}//${window.location.host}`;
        }
    }
});
export { reactPlugin, appInsights };
