/**
 * @generated SignedSource<<972906e1ce5546339302b73607015a2d>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "concreteType": "UserHomeAddress",
        "kind": "LinkedField",
        "name": "homeAddress",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "careOf",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "city",
                "storageKey": null
            },
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postOfficeBox",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postalCode",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "street",
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserHomeAddressUpdateModal_unsetUserHomeAddressMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UnsetUserHomeAddressPayload",
                    "kind": "LinkedField",
                    "name": "unsetUserHomeAddress",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserHomeAddressUpdateModal_unsetUserHomeAddressMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UnsetUserHomeAddressPayload",
                    "kind": "LinkedField",
                    "name": "unsetUserHomeAddress",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "de35b00e06fd8bd7de251fe2c3bf5f89",
            "id": null,
            "metadata": {},
            "name": "UserHomeAddressUpdateModal_unsetUserHomeAddressMutation",
            "operationKind": "mutation",
            "text": "mutation UserHomeAddressUpdateModal_unsetUserHomeAddressMutation(\n  $model: UnsetUserHomeAddressInput!\n) {\n  unsetUserHomeAddress(model: $model) {\n    user {\n      homeAddress {\n        careOf\n        city\n        id\n        postOfficeBox\n        postalCode\n        street\n      }\n      id\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "0f6f28b194dfa455d909a809d1f8e1e6";
export default node;
