/**
 * @generated SignedSource<<8cdda7ec7ef484830d025d09491c12aa>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "kind": "InlineFragment",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Asset",
                "kind": "LinkedField",
                "name": "asset",
                "plural": false,
                "selections": [
                    (v2 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "SchedulableAsset",
                        "kind": "LinkedField",
                        "name": "schedulableEntity",
                        "plural": false,
                        "selections": [
                            (v2 /*: any*/)
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "SchedulableAsset",
        "abstractKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AssetAttributesUpdateModal_createSchedulableAssetMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateSchedulableEntityPayload",
                    "kind": "LinkedField",
                    "name": "createSchedulableAsset",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfISchedulableEntity",
                            "kind": "LinkedField",
                            "name": "entityEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AssetAttributesUpdateModal_createSchedulableAssetMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateSchedulableEntityPayload",
                    "kind": "LinkedField",
                    "name": "createSchedulableAsset",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfISchedulableEntity",
                            "kind": "LinkedField",
                            "name": "entityEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        },
                                        (v3 /*: any*/),
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "d39d053b95d76ed15c842f883db2956a",
            "id": null,
            "metadata": {},
            "name": "AssetAttributesUpdateModal_createSchedulableAssetMutation",
            "operationKind": "mutation",
            "text": "mutation AssetAttributesUpdateModal_createSchedulableAssetMutation(\n  $model: CreateSchedulableAssetRequestInput!\n) {\n  createSchedulableAsset(model: $model) {\n    entityEdge {\n      node {\n        __typename\n        ... on SchedulableAsset {\n          asset {\n            id\n            schedulableEntity {\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "ff3122722afb2250ede1a753adcaad15";
export default node;
