import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Flexbox, Text, Card, CardContent } from '@sonika-se/ui-kit/components';
import { setAccessTokens } from '@sonika-se/ui-kit/utils/auth';
import requestImpersonationTokens from '~/utils/requestImpersonationTokens';
export const ImpersonatePage = () => {
    const [urlSearchParams] = useSearchParams();
    const token = urlSearchParams.get('token');
    const redirectUri = urlSearchParams.get('redirectUri');
    const [state, setState] = useState('init');
    useEffect(() => {
        requestImpersonationTokens(token)
            .then((response) => {
            setState('success');
            const { token, refreshToken } = response;
            setAccessTokens(token, refreshToken);
            window.location.replace(redirectUri);
        })
            .catch((error) => {
            setState('error');
        });
    }, []);
    return (<Flexbox align="center" justify="center" style={{ width: '100%' }}>
      <Card>
        <CardContent>
          {state === 'init' && <Text>Loggar in användare...</Text>}
          {state === 'error' && <Text>Inloggning misslyckades. Prova att skapa ny inloggning i admin.</Text>}
        </CardContent>
      </Card>
    </Flexbox>);
};
export default ImpersonatePage;
