import { useState } from 'react';
import { graphql, useFragment, useMutation } from 'react-relay/hooks';
import useAttachments from './useAttachments';
export const useUserAttachments = (userRef, options) => {
    const data = useFragment(graphql `
      fragment useUserAttachments_user on User {
        id
        attachments(first: null) @connection(key: "useUserAttachments_attachments") {
          edges {
            node {
              contentType
              directory
              id
              name
              size
              storageId
              url
            }
          }
        }
      }
    `, userRef);
    const [id, setId] = useState(data === null || data === void 0 ? void 0 : data.id);
    const [createUserAttachment] = useMutation(graphql `
      mutation useUserAttachments_createFileMutation($model: CreateUserAttachmentRequestInput!) {
        createUserAttachment(model: $model) {
          userEdge {
            node {
              ...useUserAttachments_user
            }
          }
        }
      }
    `);
    const [deleteUserAttachment] = useMutation(graphql `
      mutation useUserAttachments_deleteFileMutation($model: DeleteUserAttachmentRequestInput!) {
        deleteUserAttachment(model: $model) {
          userAttachmentEdge {
            node {
              id
            }
          }
        }
      }
    `);
    const attachments = useAttachments(Object.assign(Object.assign({}, options), { createAttachment: (uploadedAttachment) => {
            const promise = new Promise((resolve, reject) => {
                createUserAttachment({
                    onCompleted: (response) => {
                        resolve(response);
                    },
                    onError: (error) => {
                        reject(error);
                    },
                    variables: {
                        model: Object.assign(Object.assign({}, uploadedAttachment), { userId: id }),
                    },
                });
            });
            return promise;
        }, deleteAttachment: ({ id }) => {
            const promise = new Promise((resolve, reject) => {
                deleteUserAttachment({
                    configs: [
                        {
                            connectionKeys: [
                                {
                                    key: 'useUserAttachments_attachments',
                                },
                            ],
                            parentID: data.id,
                            type: 'RANGE_DELETE',
                            deletedIDFieldName: ['userAttachmentEdge', 'node'],
                            pathToConnection: ['user', 'attachments'],
                        },
                    ],
                    onCompleted: (response) => {
                        resolve(response);
                    },
                    onError: (error) => {
                        reject(error);
                    },
                    variables: {
                        model: {
                            id,
                        },
                    },
                });
            });
            return promise;
        }, uploadEndpoint: 'tenants/v1/UserAttachment/upload' }));
    return Object.assign(Object.assign({}, attachments), { data, setId });
};
export default useUserAttachments;
