import React, { useEffect, useMemo, useRef } from 'react';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { DataTable, Flexbox, Placeholder, Text } from '@sonika-se/ui-kit/components';
import { Link } from 'react-router-dom';
import Avatar from '~/shared/Avatar';
export const ColleagueTable = (props) => {
    const { filter, queryRef, where } = props;
    const { data, hasNext, isLoadingNext, loadNext, refetch } = usePaginationFragment(graphql `
      fragment ColleagueTable_query on Query
      @argumentDefinitions(
        after: { type: "String" }
        filter: { type: "ColleaguesFilterInput" }
        first: { type: "Int", defaultValue: 50 }
        where: { type: "UserFilterInput" }
      )
      @refetchable(queryName: "ColleagueTablePaginationQuery") {
        currentUser {
          me {
            id
            isCompanyAdmin
          }
        }
        colleagues(after: $after, filter: $filter, first: $first, where: $where)
          @connection(key: "ColleagueTable_query_colleagues", filters: []) {
          edges {
            node {
              ...Avatar_iHaveAvatar
              assignmentTypes {
                nodes {
                  title
                }
              }
              firstname
              id
              lastname
            }
          }
        }
      }
    `, queryRef);
    const firstRender = useRef(true);
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        refetch({ filter, where }, { fetchPolicy: 'network-only' });
    }, [filter, where]);
    const users = useMemo(() => data.colleagues.edges.map(({ node: user }) => ({
        avatar: user,
        name: user,
    })), [data]);
    return users.length === 0 ? (<Placeholder text="Inga kollegor."/>) : (<DataTable columns={{
            avatar: {
                text: '',
                render: (user) => <Avatar iHaveAvatarRef={user} size="small"/>,
                width: 1,
            },
            name: {
                text: 'Namn',
                render: (user) => (<Flexbox direction="column" gap="none">
              <Link to={`/colleagues/${user.id}`}>
                <Text bold>
                  {user.firstname} {user.lastname}
                </Text>
              </Link>
              {user.assignmentTypes.nodes.length > 0 && (<Text appearance="light" size="xsmall">
                  {user.assignmentTypes.nodes.map(({ title }) => title).join(' • ')}
                </Text>)}
            </Flexbox>),
            },
        }} loading={isLoadingNext} onPaginate={() => {
            loadNext(50);
        }} pagination={{
            buttonText: 'Ladda fler',
            hasNextPage: hasNext,
        }} rows={users}/>);
};
export default ColleagueTable;
