import React, { Suspense, useState } from 'react';
import { Card, CardContent, Flexbox, Panel, Skeleton } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import AssignmentTypeTableQueryForm from '~/forms/AssignmentTypeTableQueryForm';
import AssignmentTypeTable from '~/tables/AssignmentTypeTable';
export const AssignmentTypeTablePanel = (props) => {
    const { actions, queryRef } = props;
    const data = useFragment(graphql `
      fragment AssignmentTypeTablePanel_query on Query {
        ...AssignmentTypeTable_query
      }
    `, queryRef);
    const [assignmentTypeTableProps, setAssignmentTypeTableProps] = useState();
    return (<Panel actions={actions}>
      <Flexbox direction="column" gap="small">
        <Card background="gray" shadow="none">
          <CardContent>
            <AssignmentTypeTableQueryForm onSubmit={(query) => {
            const { search, status } = query;
            const where = {
                and: [],
            };
            if (search !== undefined) {
                where.and = [
                    ...where.and,
                    {
                        title: {
                            contains: search,
                        },
                    },
                ];
            }
            if (status !== undefined) {
                where.and = [
                    ...where.and,
                    {
                        disabledDate: status === true
                            ? {
                                eq: null,
                            }
                            : {
                                neq: null,
                            },
                    },
                ];
            }
            setAssignmentTypeTableProps({
                where: where.and.length > 0 ? where : null,
            });
        }}/>
          </CardContent>
        </Card>
        <Suspense fallback={<Skeleton type="table"/>}>
          <AssignmentTypeTable queryRef={data} {...assignmentTypeTableProps}/>
        </Suspense>
      </Flexbox>
    </Panel>);
};
export default AssignmentTypeTablePanel;
