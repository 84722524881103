import React, { Suspense, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, DataTable, ModalSkeleton, Panel, PanelButton, Placeholder } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useMutation, useQueryLoader } from 'react-relay/hooks';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import enums from '~/enums.json';
const { PermissionEnum } = enums;
import AssignmentTypeSelectModal, { assignmentTypeSelectModalQuery } from '~/shared/modals/AssignmentTypeSelectModal';
export const AssetAssignmentTypesPanel = (props) => {
    const { assetRef } = props;
    const asset = useFragment(graphql `
      fragment AssetAssignmentTypesPanel_asset on Asset {
        assignmentTypes {
          nodes {
            id
            title
          }
        }
        id
      }
    `, assetRef);
    const assignmentTypes = useMemo(() => asset.assignmentTypes.nodes.map((assignmentType) => ({
        title: assignmentType,
        actions: assignmentType.id,
    })), [asset]);
    const [assetAssignmentTypeSelectModalRef, loadAssetAssignmentTypeSelectModal, disposeAssetAssignmentTypeSelectModal] = useQueryLoader(assignmentTypeSelectModalQuery);
    const [addAssignmentTypesToAsset, addAssignmentTypesToAssetLoading] = useMutation(graphql `
        mutation AssetAssignmentTypesPanel_addAssignmentTypesToAssetMutation($model: AddAssignmentTypesToAssetInput!) {
          addAssignmentTypesToAsset(model: $model) {
            asset {
              ...AssetAssignmentTypesPanel_asset
            }
          }
        }
      `);
    const [removeAssignmentTypesFromAsset, removeAssignmentTypesFromAssetLoading] = useMutation(graphql `
        mutation AssetAssignmentTypesPanel_removeAssignmentTypesFromAssetMutation(
          $model: RemoveAssignmentTypesFromAssetInput!
        ) {
          removeAssignmentTypesFromAsset(model: $model) {
            asset {
              ...AssetAssignmentTypesPanel_asset
            }
          }
        }
      `);
    const hasPermissions = usePermissions();
    const hasEditAssignmentTypesPermission = hasPermissions([PermissionEnum.EDIT_ASSIGNMENT_TYPES.key]);
    const where = useMemo(() => ({
        id: {
            nin: asset.assignmentTypes.nodes.map(({ id }) => id),
        },
    }), [asset]);
    return (<>
      <Panel title="Roller" actions={hasEditAssignmentTypesPermission && (<PanelButton disabled={addAssignmentTypesToAssetLoading} onClick={() => {
                loadAssetAssignmentTypeSelectModal({
                    where,
                });
            }}>
              Lägg till
            </PanelButton>)}>
        {assignmentTypes.length > 0 ? (<DataTable columns={{
                title: {
                    text: 'Namn',
                    render: ({ id, title }) => <Link to={`/assignment-types/${id}`}>{title}</Link>,
                },
                actions: {
                    align: 'right',
                    text: '',
                    render: (assignmentTypeId) => (<Button appearance="outlined" color="danger" disabled={removeAssignmentTypesFromAssetLoading} onClick={() => {
                            removeAssignmentTypesFromAsset({
                                variables: {
                                    model: {
                                        assignmentTypeIds: [assignmentTypeId],
                                        assetId: asset.id,
                                    },
                                },
                            });
                        }} size="xsmall">
                    Ta bort
                  </Button>),
                },
            }} rows={assignmentTypes}/>) : (<Placeholder text="Inga grupper."/>)}
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {assetAssignmentTypeSelectModalRef && (<AssignmentTypeSelectModal onClose={() => {
                disposeAssetAssignmentTypeSelectModal();
            }} onSelect={(assignmentTypeIds) => {
                disposeAssetAssignmentTypeSelectModal();
                addAssignmentTypesToAsset({
                    variables: {
                        model: {
                            assetId: asset.id,
                            assignmentTypeIds,
                        },
                    },
                });
            }} defaultWhere={where} preloadedQuery={assetAssignmentTypeSelectModalRef}/>)}
      </Suspense>
    </>);
};
export default AssetAssignmentTypesPanel;
