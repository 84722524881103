import React, { useEffect } from 'react';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { SelectDropdown } from '@sonika-se/ui-kit/components';
export const EventList = (props) => {
    var _a, _b;
    const { onSelected, selected, search, isLoading, eventsRef } = props;
    const { data, hasNext, isLoadingNext, loadNext, refetch } = usePaginationFragment(graphql `
      fragment EventList_query on Query
      @argumentDefinitions(
        after: { type: "String" }
        first: { type: "Int", defaultValue: 30 }
        where: { type: "EventFilterInput" }
      )
      @refetchable(queryName: "EventListPaginationQuery") {
        events(first: $first, after: $after, where: $where) @connection(key: "EventList_events") {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `, eventsRef);
    useEffect(() => {
        refetch({
            where: { name: { contains: search } },
        }, { fetchPolicy: 'network-only' });
    }, [search]);
    return (<SelectDropdown selectedItems={selected} isLoading={isLoading} searchString={search} setSelected={(value) => {
            onSelected([...selected === null || selected === void 0 ? void 0 : selected.map((item) => item.value), value === null || value === void 0 ? void 0 : value.value]);
        }} onLastItemVisible={hasNext && !isLoadingNext
            ? () => {
                loadNext(30);
            }
            : undefined} options={(_b = (_a = data === null || data === void 0 ? void 0 : data.events) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map(({ node }) => ({ label: node === null || node === void 0 ? void 0 : node.name, value: node === null || node === void 0 ? void 0 : node.id }))}/>);
};
export default EventList;
