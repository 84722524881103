import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Field, Form, Input } from '@sonika-se/ui-kit/components';
const validationSchema = yup.object().shape({
    username: yup.string().required('E-post är obligatorisk'),
});
const ResetPasswordForm = (props) => {
    var _a, _b;
    const { onSubmit, frontendUrl, disabled } = props;
    const defaultValues = {
        frontendClient: 'boat',
        frontendUrl,
        username: '',
    };
    const { control, register, handleSubmit, formState: { errors }, } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });
    const intl = useIntl();
    return (<Form onSubmit={handleSubmit(onSubmit)}>
      <input name="frontendUrl" {...register('frontendUrl')} type="hidden"/>
      <input name="frontendClient" {...register('frontendClient')} type="hidden"/>
      <Field label={intl.formatMessage({ defaultMessage: 'Din e-postadress', id: 'ResetPasswordForm.username.label' })} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.username) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()}>
        <Controller control={control} name="username" render={({ field }) => (<Input disabled={disabled} onBlur={field.onBlur} onChange={field.onChange} placeholder="exempel@sonika.se" type="email" value={field.value}/>)}/>
      </Field>
      <Button disabled={disabled} type="submit" size="large" style={{ marginTop: '.5rem' }}>
        <FormattedMessage defaultMessage="Återställ lösenord" id="ResetPasswordForm.submitButton.label"/>
      </Button>
    </Form>);
};
export default ResetPasswordForm;
