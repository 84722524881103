import React, { useRef } from 'react';
import { Button, Flexbox, ModalToSheet, ModalToSheetContent, ModalToSheetFooter, ModalToSheetHeader, } from '@sonika-se/ui-kit/components';
import { graphql, useMutation, usePreloadedQuery } from 'react-relay/hooks';
import UserBankAccountForm from '../forms/UserBankAccountForm';
export const editUserBankAccountModalQuery = graphql `
  query UserBankAccountUpdateModalQuery($userId: ID!) {
    ...UserBankAccountForm_query
    user(id: $userId) {
      bankAccount {
        accountNumber
        bankDetails {
          id
        }
        clearingNumber
      }
      id
    }
  }
`;
export const UserBankAccountUpdateModal = (props) => {
    var _a, _b, _c, _d;
    const { onClose, preloadedQuery } = props;
    const data = usePreloadedQuery(editUserBankAccountModalQuery, preloadedQuery);
    const [setUserBankAccount, setUserBankAccountLoading] = useMutation(graphql `
        mutation UserBankAccountUpdateModal_setUserBankAccountMutation($model: SetUserBankAccountInput!) {
          setUserBankAccount(model: $model) {
            user {
              bankAccount {
                accountNumber
                bankDetails {
                  id
                }
                bankName
                clearingNumber
                id
              }
            }
          }
        }
      `);
    const [unsetUserBankAccount, unsetUserBankAccountLoading] = useMutation(graphql `
        mutation UserBankAccountUpdateModal_unsetUserBankAccountMutation($model: UnsetUserBankAccountInput!) {
          unsetUserBankAccount(model: $model) {
            user {
              bankAccount {
                accountNumber
                clearingNumber
              }
            }
          }
        }
      `);
    const formRef = useRef(null);
    return (<ModalToSheet onClose={() => onClose()}>
      <ModalToSheetHeader onClose={() => onClose()}>Ändra bankkonto</ModalToSheetHeader>
      <ModalToSheetContent>
        <UserBankAccountForm defaultValues={{
            accountNumber: (_a = data.user.bankAccount) === null || _a === void 0 ? void 0 : _a.accountNumber,
            clearingNumber: (_b = data.user.bankAccount) === null || _b === void 0 ? void 0 : _b.clearingNumber,
            bankId: (_d = (_c = data.user.bankAccount) === null || _c === void 0 ? void 0 : _c.bankDetails) === null || _d === void 0 ? void 0 : _d.id,
        }} disabled={setUserBankAccountLoading} queryRef={data} ref={formRef}/>
      </ModalToSheetContent>
      <ModalToSheetFooter>
        <Flexbox gap="medium" justify="space" style={{ flex: 1 }}>
          {data.user.bankAccount ? (<Button color="danger" disabled={setUserBankAccountLoading || unsetUserBankAccountLoading} onClick={() => {
                unsetUserBankAccount({
                    onCompleted: () => {
                        onClose();
                    },
                    variables: {
                        model: {
                            userId: data.user.id,
                        },
                    },
                });
            }}>
              Ta bort
            </Button>) : (<div />)}
          <Button color="primary" disabled={setUserBankAccountLoading || unsetUserBankAccountLoading} onClick={() => {
            formRef.current.submit((form) => {
                setUserBankAccount({
                    onCompleted: () => {
                        onClose();
                    },
                    variables: {
                        model: {
                            accountNumber: form.accountNumber,
                            clearingNumber: form.clearingNumber,
                            userId: data.user.id,
                        },
                    },
                });
            });
        }}>
            Spara
          </Button>
        </Flexbox>
      </ModalToSheetFooter>
    </ModalToSheet>);
};
export default UserBankAccountUpdateModal;
