/**
 * @generated SignedSource<<1438140f5aa870bb79ad8902a73a6061>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountSidebar_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
};
node.hash = "57f7814e001e633e3b482d5e3b2f13e0";
export default node;
