import { useState } from 'react';
import { graphql, useFragment, useMutation } from 'react-relay/hooks';
export const useFaqPostTags = (assetRef) => {
    const data = useFragment(graphql `
      fragment useFaqPostTags_faqPost on FaqPost {
        id
        tags(first: null) @connection(key: "useFaqPostTags_tags") {
          edges {
            node {
              id
              tagId
              tag {
                name
              }
            }
          }
        }
      }
    `, assetRef);
    const [id, setId] = useState(data === null || data === void 0 ? void 0 : data.id);
    const [setTagsOnFaqPost, setTagsLoading] = useMutation(graphql `
      mutation useFaqPostTags_setTagMutation($model: SetTagsOnFaqPostRequestInput!) {
        setTagsOnFaqPost(model: $model) {
          faqPostEdge {
            node {
              ...useFaqPostTags_faqPost
            }
          }
        }
      }
    `);
    const setFaqPostTags = ({ tagIds }) => {
        const promise = new Promise((resolve, reject) => {
            setTagsOnFaqPost({
                onCompleted: (response) => {
                    resolve(response);
                },
                onError: (error) => {
                    reject(error);
                },
                variables: {
                    model: {
                        tagIds,
                        id,
                    },
                },
            });
        });
        return promise;
    };
    return { data, setId, setTagsLoading, setFaqPostTags };
};
export default useFaqPostTags;
