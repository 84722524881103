import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { Input } from '@sonika-se/ui-kit/components';
import { Button } from '@sonika-se/ui-kit/components';
import { Field, Form, Text } from '@sonika-se/ui-kit/components';
const validationSchema = Yup.object().shape({
    username: Yup.string().required('Du måste ange ett användarnamn'),
    password: Yup.string().required('Du måste ange ett lösenord'),
    repeatPassword: Yup.string()
        .required('Du måste ange ett lösenord')
        .oneOf([Yup.ref('password')], 'Lösenorden matchar inte'),
});
const UserInvitationAcceptForm = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const { error = '', onSubmit, disabled, defaultValues } = props;
    const intl = useIntl();
    const { control, register, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(validationSchema),
    });
    return (<Form onSubmit={handleSubmit(onSubmit)} style={{ marginTop: '2rem' }}>
      <Field label="Användarnamn" error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.username) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()}>
        <Controller control={control} name="username" render={({ field }) => {
            var _a, _b;
            return (<Input error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.username) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} onBlur={field.onBlur} onChange={field.onChange} type="text" disabled={disabled} value={field.value}/>);
        }}/>
      </Field>
      <Field label="Lösenord" error={(_d = (_c = errors === null || errors === void 0 ? void 0 : errors.password) === null || _c === void 0 ? void 0 : _c.message) === null || _d === void 0 ? void 0 : _d.toString()}>
        <Controller control={control} name="password" render={({ field }) => {
            var _a, _b;
            return (<Input error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.password) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} type="password" disabled={disabled} onBlur={field.onBlur} onChange={field.onChange} value={field.value}/>);
        }}/>
      </Field>
      <Field label="Upprepa lösenord" error={(_f = (_e = errors === null || errors === void 0 ? void 0 : errors.repeatPassword) === null || _e === void 0 ? void 0 : _e.message) === null || _f === void 0 ? void 0 : _f.toString()}>
        <Controller control={control} name="repeatPassword" render={({ field }) => {
            var _a, _b;
            return (<Input disabled={disabled} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.repeatPassword) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} onBlur={field.onBlur} onChange={field.onChange} type="password" value={field.value}/>);
        }}/>
      </Field>
      <Button type="submit" size="large" style={{ width: '100%', marginTop: 16 }} disabled={disabled}>
        Aktivera konto
      </Button>
      {error && <Text style={{ color: 'red' }}>{error}</Text>}
    </Form>);
};
export default UserInvitationAcceptForm;
