/**
 * @generated SignedSource<<22ece84deafdf21493ff1e82e2396664>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "userId"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "created",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accepted",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserLayoutQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "Avatar_iHaveAvatar"
                        },
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserInvitation",
                            "kind": "LinkedField",
                            "name": "invitation",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v4 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserLayoutQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserInvitation",
                            "kind": "LinkedField",
                            "name": "invitation",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v6 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Avatar",
                                    "kind": "LinkedField",
                                    "name": "avatar",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": [
                                                {
                                                    "kind": "Literal",
                                                    "name": "queryTransform",
                                                    "value": "width=300&height=300&mode=crop&scale=both"
                                                }
                                            ],
                                            "kind": "ScalarField",
                                            "name": "url",
                                            "storageKey": "url(queryTransform:\"width=300&height=300&mode=crop&scale=both\")"
                                        },
                                        (v6 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        (v7 /*: any*/),
                                        (v8 /*: any*/)
                                    ],
                                    "type": "Asset",
                                    "abstractKey": null
                                },
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        (v8 /*: any*/)
                                    ],
                                    "type": "Company",
                                    "abstractKey": null
                                },
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        (v7 /*: any*/)
                                    ],
                                    "type": "User",
                                    "abstractKey": null
                                }
                            ],
                            "type": "IHaveAvatar",
                            "abstractKey": "__isIHaveAvatar"
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "e7f1722bcac31427886082ffd291a2ba",
            "id": null,
            "metadata": {},
            "name": "UserLayoutQuery",
            "operationKind": "query",
            "text": "query UserLayoutQuery(\n  $userId: ID!\n) {\n  user(id: $userId) {\n    ...Avatar_iHaveAvatar\n    firstname\n    invitation {\n      created\n      accepted\n      id\n    }\n    lastname\n    id\n  }\n}\n\nfragment Avatar_iHaveAvatar on IHaveAvatar {\n  __isIHaveAvatar: __typename\n  avatar {\n    url(queryTransform: \"width=300&height=300&mode=crop&scale=both\")\n    id\n  }\n  ... on Asset {\n    color\n    name\n  }\n  ... on Company {\n    name\n  }\n  ... on User {\n    color\n    firstname\n    lastname\n  }\n}\n"
        }
    };
})();
node.hash = "8439f62430830cdf2478af62f48245a0";
export default node;
