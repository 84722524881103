import React, { useCallback, useRef } from 'react';
import { graphql, useMutation, usePreloadedQuery } from 'react-relay/hooks';
import { FormModal } from '@sonika-se/ui-kit/components';
import RoleGeneralInfoForm from '~/forms/RoleGeneralInfoForm';
export const roleRoleUpdateModalQuery = graphql `
  query RoleGeneralInfoUpdateModalQuery($roleId: ID!) {
    role(id: $roleId) {
      description
      id
      name
    }
  }
`;
export const RoleGeneralInfoUpdateModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const { role } = usePreloadedQuery(roleRoleUpdateModalQuery, preloadedQuery);
    const [updateRole, updateRoleLoading] = useMutation(graphql `
      mutation RoleGeneralInfoUpdateModal_updateRoleMutation($model: UpdateRoleInput!) {
        updateRole(model: $model) {
          roleEdge {
            node {
              description
              name
            }
          }
        }
      }
    `);
    const formRef = useRef(null);
    const handleSubmit = useCallback((form) => {
        updateRole({
            onCompleted: () => {
                onClose();
            },
            variables: {
                model: {
                    description: form.description,
                    id: role.id,
                    name: form.name,
                },
            },
        });
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Spara" disabled={updateRoleLoading} title="Ändra allmän info">
      <RoleGeneralInfoForm defaultValues={{
            description: role.description,
            name: role.name,
        }} disabled={updateRoleLoading} onSubmit={handleSubmit} ref={formRef}/>
    </FormModal>);
};
export default RoleGeneralInfoUpdateModal;
