import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { FileList as SonikaFileList, Panel } from '@sonika-se/ui-kit/components';
import { useApi } from '@sonika-se/ui-kit/hooks';
import useAssetAttachments from '~/hooks/attachments/useAssetAttachments';
export const AssetAttachmentsPanel = (props) => {
    const { assetRef } = props;
    const asset = useFragment(graphql `
      fragment AssetAttachmentsPanel_asset on Asset {
        ...useAssetAttachments_asset
      }
    `, assetRef);
    const { addAttachments, data, removeAttachment, uploading } = useAssetAttachments(asset);
    const { downloadFile } = useApi();
    return (<Panel title="Filer">
      <SonikaFileList defaultFiles={data.attachments.edges.map(({ node }) => node)} editable headerText="Släpp filer här eller klicka för att välja" onChange={(files) => {
            addAttachments(Array.from(files));
        }} onDelete={(file) => {
            removeAttachment(file);
        }} onDownload={(url, name) => {
            downloadFile(url, name)
                .then((ok) => console.log({ ok }))
                .catch((fail) => console.log({ fail }));
        }}/>
    </Panel>);
};
export default AssetAttachmentsPanel;
