import React from 'react';
import { graphql, usePreloadedQuery } from 'react-relay/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormSection, Label, Field, ModalToSheet, ModalToSheetHeader, ModalToSheetContent, } from '@sonika-se/ui-kit/components';
import EditEventName from '~/components/InlineEditFields/Event/EditEventName';
import EditEventColor from '~/components/InlineEditFields/Event/EditEventColor';
import EditEventStatus from '~/components/InlineEditFields/Event/EditEventStatus';
import EditEventAssignmentTypes from '~/components/InlineEditFields/Event/EditEventAssignmentTypes';
import EditEventIsAddition from '~/components/InlineEditFields/Event/EditEventIsAddition';
import EditEventIsWork from '~/components/InlineEditFields/Event/EditEventIsWork';
import EditEventIsAssigneeReplacementReason from '~/components/InlineEditFields/Event/EditEventIsAssigneeReplacementReason';
import EditEventIsAbsence from '~/components/InlineEditFields/Event/EditEventIsAbsence';
import EditEventIsUnsocial from '~/components/InlineEditFields/Event/EditEventIsUnsocial';
import EditEventAdditionUnit from '~/components/InlineEditFields/Event/EditEventAdditionUnit';
import EditEventIsCompensatoryTime from '~/components/InlineEditFields/Event/EditEventIsCompensatoryTime';
import EditEventAbsenceType from '~/components/InlineEditFields/Event/EditEventAbsenceType';
import EditIsAdditionAvailableInTimeReport from '~/components/InlineEditFields/Event/EditIsAdditionAvailableInTimeReport';
export const updateEventModalQuery = graphql `
  query UpdateEventModalQuery($id: ID!) {
    event(id: $id) {
      id
      isAddition
      isCompensatoryTime
      isAbsence
      isAdditionAvailableInTimeReport
      isUnsocial
      isCallHour
      name
      ...EditEventName
      ...EditEventColor
      ...EditEventStatus
      ...EditEventAssignmentTypes
      ...EditEventIsAddition_event
      ...EditEventIsWork_event
      ...EditEventIsUnsocial_event
      ...EditEventIsAbsence_event
      ...EditEventIsAssigneeReplacementReason_event
      ...EditEventAdditionUnit_event
      ...EditEventIsCompensatoryTime_event
      ...EditEventAbsenceType_event
      ...EditIsAdditionAvailableInTimeReport_event
    }
  }
`;
export const UpdateEventModal = (props) => {
    const { onCancel, preloadedQuery } = props;
    const { event } = usePreloadedQuery(updateEventModalQuery, preloadedQuery);
    const intl = useIntl();
    return (<ModalToSheet size="medium" onClose={onCancel}>
      <ModalToSheetHeader onClose={onCancel} subTitle={event === null || event === void 0 ? void 0 : event.name} closeButtonLabel={intl.formatMessage({ id: 'general.actions.close', defaultMessage: 'Stäng' })}>
        <FormattedMessage id="general.actions.edit" defaultMessage="Redigera"/>
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <FormSection columns="1fr 2fr">
          <Label text="Namn"/>
          <EditEventName event={event}/>
          <Label text="Färg"/>
          <EditEventColor event={event} placeholder="Välj färg"/>
          <Label text="Status"/>
          <EditEventStatus event={event}/>
          <Label text="Frånvaro"/>
          <EditEventIsAbsence eventRef={event}/>
          {event.isAbsence && (<>
              <Label text="Typ av frånvaro"/>
              <EditEventAbsenceType eventRef={event}/>
            </>)}
          <Label text="Arbete"/>
          <EditEventIsWork eventRef={event}/>
          <Label text="Bytesanledning"/>
          <EditEventIsAssigneeReplacementReason eventRef={event}/>
          <Label text="Obekväm arbetstid"/>
          <EditEventIsUnsocial eventRef={event}/>
          <Label text="Tillägg"/>
          <EditEventIsAddition eventRef={event}/>
          {event.isAddition && (<>
              <Label style={{ marginLeft: '10px' }} text="Kan registreras i tidrapport"/>
              <EditIsAdditionAvailableInTimeReport eventRef={event}/>
              {event.isAdditionAvailableInTimeReport && (<>
                  <Label style={{ marginLeft: '10px' }} text="Registreras i"/>
                  <EditEventAdditionUnit eventRef={event}/>
                </>)}
            </>)}
          <Label text="Timbank"/>
          <EditEventIsCompensatoryTime eventRef={event}/>
          <Field columnSpan={2}>
            <Label text="Roller"/>
            <EditEventAssignmentTypes event={event}/>
          </Field>
        </FormSection>
      </ModalToSheetContent>
    </ModalToSheet>);
};
export default UpdateEventModal;
