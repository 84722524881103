import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { useParams } from 'react-router';
import RoleSchedulePermissionsPanel from '~/panels/RoleSchedulePermissionsPanel';
export const RoleSchedulePermissionsPage = () => {
    const { roleId } = useParams();
    const data = useLazyLoadQuery(graphql `
      query RoleSchedulePermissionsPageQuery($roleId: ID!) {
        role(id: $roleId) {
          ...RoleSchedulePermissionsPanel_role
        }
      }
    `, {
        roleId,
    });
    return <RoleSchedulePermissionsPanel roleRef={data.role}/>;
};
export default RoleSchedulePermissionsPage;
