import { BasicPage, BasicPageHeader } from '@sonika-se/ui-kit/components';
import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import ColleagueTablePanel from '~/panels/ColleagueTablePanel';
export const ColleagueIndexPage = () => {
    const data = useLazyLoadQuery(graphql `
      query ColleagueIndexPageQuery {
        ...ColleagueTablePanel_query
      }
    `, {}, {
        fetchPolicy: 'network-only',
    });
    return (<BasicPage width="large">
      <BasicPageHeader title="Kollegor"/>
      <ColleagueTablePanel queryRef={data}/>
    </BasicPage>);
};
export default ColleagueIndexPage;
