import React from 'react';
const format = (value, unit) => `${value > 0 ? `${value}${unit} ` : ''}`;
export const formatDuration = ({ days = 0, hours = 0, minutes = 0, seconds = 0, }) => {
    const totalSeconds = days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 + seconds;
    const d = Math.floor(totalSeconds / (3600 * 24));
    const h = Math.floor(totalSeconds / 3600);
    const m = Math.floor((totalSeconds % 3600) / 60);
    const s = Math.floor((totalSeconds % 3600) % 60);
    return `${days ? format(d, 'd') : `${format(h, 't')}${format(m, 'm')}${format(s, 's')}`}`.trim();
};
export const Duration = (props) => {
    return <>{formatDuration(props)}</>;
};
export default Duration;
