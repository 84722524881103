import { Divider, Flexbox, Logo } from '@sonika-se/ui-kit/components';
import React from 'react';
import { createUseStyles } from 'react-jss';
import SonikaWave from '../../../static/sonika-wave.svg';
export const useStyles = createUseStyles((theme) => {
    const { pxToRem, shadow } = theme.helpers;
    return {
        accountPageContainer: {
            padding: '2rem',
            width: '100%',
            display: 'flex',
            background: theme.colors.primary,
            backgroundImage: `url(${SonikaWave})`,
            backgroundSize: '69px 33px',
            flexDirection: 'column',
            [`@media (max-width: ${theme.breakPoints.low})`]: {
                padding: 0,
            },
            boxSizing: 'border-box',
            height: '100vh',
            overflow: 'auto',
        },
        accountPage: {
            margin: 'auto',
            background: theme.colors.background.secondary,
            borderRadius: pxToRem(4),
            padding: '2rem',
            boxSizing: 'border-box',
            boxShadow: shadow(2),
            maxWidth: pxToRem(513),
            width: '100%',
            [`@media (max-width: ${theme.breakPoints.low})`]: {
                margin: 0,
                padding: '2rem 1rem ',
                height: '100% !important',
                boxShadow: 'none',
            },
        },
        logo: {
            height: pxToRem(24),
            alignSelf: 'flex-start',
        },
    };
});
export const AccountPage = (props) => {
    const classes = useStyles();
    return (<div className={classes.accountPageContainer}>
      <div className={classes.accountPage} style={props.style}>
        <Flexbox direction="column" gap="medium">
          <Logo className={classes.logo}/>
          <Divider />
          <div>{props.children}</div>
        </Flexbox>
      </div>
    </div>);
};
export default AccountPage;
