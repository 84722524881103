var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Flexbox, H3, Tabs } from '@sonika-se/ui-kit/components';
import LoginForm from './components/LoginForm';
import login from '~/utils/login';
import AccountPage from '../components/AccountPage';
import { FormattedMessage } from 'react-intl';
import LoginLoader from './components/LoginLoader';
import BankIdForm from './components/BankIdForm';
let abortController = null;
const Login = () => {
    var _a;
    const [error, setError] = useState('');
    const [formDisabled, setFormDisabled] = useState(false);
    const [searchParams] = useSearchParams();
    const [username, setUsername] = useState((_a = searchParams.get('username')) !== null && _a !== void 0 ? _a : '');
    const [selectedTab, setSelectedTab] = useState(username !== '' ? 'username' : 'bankId');
    const [password, setPassword] = useState('');
    const redirectUri = searchParams.get('redirectUri');
    const onSubmit = (data, e) => __awaiter(void 0, void 0, void 0, function* () {
        abortController = new AbortController();
        setUsername(data.username);
        setPassword(data.password);
        const { signal } = abortController;
        setFormDisabled(true);
        e.preventDefault();
        setError('');
        const status = yield login(data, signal);
        if (status === 'success') {
            if (redirectUri && redirectUri !== '') {
                window.location.replace(redirectUri);
            }
            else {
                window.location.replace('/');
            }
        }
        else {
            setFormDisabled(false);
            if (status === 'error-user') {
                setError('Felaktigt användarnamn eller lösenord');
            }
            else if (status === 'error') {
                setError('Tyvärr kan du inte logga in just nu, försök igen om en stund!');
            }
        }
    });
    return (<AccountPage style={{ height: '550px' }}>
      {!formDisabled && (<Flexbox direction="column" gap="large">
          <H3>
            <FormattedMessage id="Login.header" defaultMessage="Hur vill du logga in?"/>
          </H3>
          <Tabs initialValue={selectedTab} onChange={(value) => setSelectedTab(value)} divider style={{
                width: '100%',
            }} tabs={[
                {
                    label: 'BankID',
                    value: 'bankId',
                    key: 'bankId',
                    style: {
                        width: '100%',
                    },
                },
                {
                    label: 'Användarnamn',
                    value: 'username',
                    key: 'username',
                    style: {
                        width: '100%',
                    },
                },
            ]}/>
          {selectedTab === 'username' && (<LoginForm error={error} disabled={formDisabled} onSubmit={onSubmit} defaultPassword={password} defaultUsername={username}/>)}
          {selectedTab === 'bankId' && <BankIdForm disabled={formDisabled} redirectUri={redirectUri}/>}
        </Flexbox>)}
      {formDisabled && <LoginLoader abortController={abortController}/>}
    </AccountPage>);
};
export default Login;
