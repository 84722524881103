import React, { Suspense, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, DataTable, ModalSkeleton, Panel, PanelButton, Placeholder } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useMutation, useQueryLoader } from 'react-relay/hooks';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import enums from '~/enums.json';
const { PermissionEnum } = enums;
import RoleSelectModal, { roleSelectModalQuery } from '~/shared/modals/RoleSelectModal';
export const UserRolesPanel = (props) => {
    const { userRef } = props;
    const user = useFragment(graphql `
      fragment UserRolesPanel_user on User {
        roles {
          nodes {
            id
            name
          }
        }
        id
      }
    `, userRef);
    const roles = useMemo(() => user.roles.nodes.map((role) => ({
        name: role,
        actions: role.id,
    })), [user]);
    const [userRoleSelectModalRef, loadUserRoleSelectModal, disposeUserRoleSelectModal] = useQueryLoader(roleSelectModalQuery);
    const [addRolesToUser, addRolesToUserLoading] = useMutation(graphql `
      mutation UserRolesPanel_addRolesToUserMutation($model: AddRolesToUserInput!) {
        addRolesToUser(model: $model) {
          user {
            ...UserRolesPanel_user
          }
        }
      }
    `);
    const [removeRolesFromUser, removeRolesFromUserLoading] = useMutation(graphql `
      mutation UserRolesPanel_removeRolesFromUserMutation($model: RemoveRolesFromUserInput!) {
        removeRolesFromUser(model: $model) {
          user {
            ...UserRolesPanel_user
          }
        }
      }
    `);
    const hasPermissions = usePermissions();
    const hasEditRolesPermission = hasPermissions([PermissionEnum.EDIT_ASSIGNMENT_TYPES.key]);
    const where = useMemo(() => ({
        id: {
            nin: user.roles.nodes.map(({ id }) => id),
        },
    }), [user]);
    return (<>
      <Panel title="Grupper" actions={hasEditRolesPermission && (<PanelButton disabled={addRolesToUserLoading} onClick={() => {
                loadUserRoleSelectModal({
                    where,
                });
            }}>
              Lägg till
            </PanelButton>)}>
        {roles.length > 0 ? (<DataTable columns={{
                name: {
                    text: 'Namn',
                    render: ({ id, name }) => <Link to={`/roles/${id}`}>{name}</Link>,
                },
                actions: {
                    align: 'right',
                    text: '',
                    render: (roleId) => (<Button appearance="outlined" color="danger" disabled={removeRolesFromUserLoading} onClick={() => {
                            removeRolesFromUser({
                                variables: {
                                    model: {
                                        rolesIds: [roleId],
                                        userId: user.id,
                                    },
                                },
                            });
                        }} size="xsmall">
                    Ta bort
                  </Button>),
                },
            }} rows={roles}/>) : (<Placeholder text="Inga grupper."/>)}
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {userRoleSelectModalRef && (<RoleSelectModal onClose={() => {
                disposeUserRoleSelectModal();
            }} onSelect={(roleIds) => {
                disposeUserRoleSelectModal();
                addRolesToUser({
                    variables: {
                        model: {
                            userId: user.id,
                            roleIds,
                        },
                    },
                });
            }} defaultWhere={where} preloadedQuery={userRoleSelectModalRef}/>)}
      </Suspense>
    </>);
};
export default UserRolesPanel;
