/**
 * @generated SignedSource<<738d35b8e708c7e24bc6115268bf6567>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RoleGeneralInfoPanel_role",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        }
    ],
    "type": "Role",
    "abstractKey": null
};
node.hash = "9c0769ec5d4c2613e9d3d3cf2456b07d";
export default node;
