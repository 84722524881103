import React, { useMemo } from 'react';
import { Accordion, Button, Flexbox, Panel, Placeholder } from '@sonika-se/ui-kit/components';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { FormattedMessage } from 'react-intl';
import FaqPostListItem from './FaqPostListItem';
export const FaqPostList = (props) => {
    const { editable, queryRef, loadMore = true } = props;
    const { data, hasNext, isLoadingNext, loadNext } = usePaginationFragment(graphql `
      fragment FaqPostList_query on Query
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 30 }
        after: { type: "String" }
        order: { type: "[FaqPostSortInput!]" }
        where: { type: "FaqPostFilterInput" }
      )
      @refetchable(queryName: "FaqPostListPaginationQuery") {
        faqPosts(first: $first, after: $after, order: $order, where: $where) @connection(key: "FaqPostList_faqPosts") {
          edges {
            node {
              id
              ...FaqPostListItem_faqPost
            }
          }
        }
      }
    `, queryRef);
    const faqPosts = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.faqPosts) === null || _a === void 0 ? void 0 : _a.edges.map(({ node }) => node); }, [data]);
    return faqPosts.length === 0 ? (<Panel>
      <Placeholder text="Inga frågor och svar."/>
    </Panel>) : (<Flexbox direction="column" gap="large">
      <Accordion>
        {faqPosts.map((faqPost) => (<FaqPostListItem editable={editable} faqPostRef={faqPost} key={faqPost.id}/>))}
      </Accordion>
      {hasNext && loadMore && (<div>
          <Button onClick={() => loadNext(30)} size="large" disabled={isLoadingNext} color="secondary">
            <FormattedMessage id="general.actions.load_more" defaultMessage="Ladda fler"/>
          </Button>
        </div>)}
    </Flexbox>);
};
export default FaqPostList;
