/**
 * @generated SignedSource<<3dd0ff3f0c919e8ce85819a058e7f128>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "service"
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ServiceDetailsPageQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "service",
                            "variableName": "service"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "ServiceGeneralInfoPanel_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ServiceDetailsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": [
                        {
                            "fields": [
                                {
                                    "items": [
                                        {
                                            "kind": "Variable",
                                            "name": "servicesIn.0",
                                            "variableName": "service"
                                        }
                                    ],
                                    "kind": "ListValue",
                                    "name": "servicesIn"
                                }
                            ],
                            "kind": "ObjectValue",
                            "name": "filter"
                        }
                    ],
                    "concreteType": "UserConnection",
                    "kind": "LinkedField",
                    "name": "users",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "totalCount",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "de4ff23ad414b086b5d9e4c3e443cdd2",
            "id": null,
            "metadata": {},
            "name": "ServiceDetailsPageQuery",
            "operationKind": "query",
            "text": "query ServiceDetailsPageQuery(\n  $service: Service!\n) {\n  ...ServiceGeneralInfoPanel_query_4ESoAC\n}\n\nfragment ServiceGeneralInfoPanel_query_4ESoAC on Query {\n  users(filter: {servicesIn: [$service]}) {\n    totalCount\n  }\n}\n"
        }
    };
})();
node.hash = "6f02ab83b7b9f5b359f2e5728686f803";
export default node;
