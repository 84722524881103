import React from 'react';
import { Route, Routes } from 'react-router';
import UserAttachmentsPage from '~/pages/UserAttachmentsPage';
import UserDetailsPage from '~/pages/UserDetailsPage';
import UserLayout from '~/layouts/UserLayout';
import UserPermissionsPage from '~/pages/UserPermissionsPage';
import UserSystemPage from '~/pages/UserSystemPage';
import UserRolesPage from '~/pages/UserRolesPage';
import UserIndexPage from '~/pages/UserIndexPage';
import UserEconomyPage from '~/pages/UserEconomyPage';
export const UserRoutes = () => (<Routes>
    <Route path="" element={<UserIndexPage />}/>
    <Route element={<UserLayout />} path=":userId/*">
      <Route path="" element={<UserDetailsPage />}/>
      <Route path="attachments" element={<UserAttachmentsPage />}/>
      <Route path="groups" element={<UserRolesPage />}/>
      <Route path="permissions" element={<UserPermissionsPage />}/>
      <Route path="system" element={<UserSystemPage />}/>
      <Route path="economy" element={<UserEconomyPage />}/>
    </Route>
  </Routes>);
export default UserRoutes;
