import React from 'react';
import { isSameDay } from 'date-fns';
import { useFragment } from 'react-relay/hooks';
import { graphql } from 'react-relay';
import ScheduleShiftCard from '../../../cards/ScheduleShiftCard';
import Weekday from './Weekday';
export const ScheduleShiftList = (props) => {
    const { scheduleShiftConnectionRef } = props;
    const data = useFragment(graphql `
      fragment ScheduleShiftList_scheduleShiftConnection on ScheduleShiftConnection {
        edges {
          node {
            id
            start
            ...ScheduleShiftCard_scheduleShift
          }
        }
      }
    `, scheduleShiftConnectionRef);
    return (<div style={{
            display: 'grid',
            gap: '.5rem',
            gridTemplateColumns: 'auto 1fr',
        }}>
      {data.edges.map(({ node: scheduleShift }, index) => (<React.Fragment key={scheduleShift.id}>
          <div style={{ minWidth: '50px' }}>
            {(index === 0 ||
                !isSameDay(new Date(data.edges[index - 1].node.start.toString()), new Date(scheduleShift.start.toString()))) && <Weekday date={new Date(scheduleShift.start.toString())}/>}
          </div>
          <ScheduleShiftCard scheduleShiftRef={scheduleShift} style={{ gridColumn: 2 }}/>
        </React.Fragment>))}
    </div>);
};
export default ScheduleShiftList;
