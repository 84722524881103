/**
 * @generated SignedSource<<5d3d02a3240310c5e7b94d767bb84e16>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "filter"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "model"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": null,
                    "cursor": null,
                    "direction": "forward",
                    "path": [
                        "scheduleItems"
                    ]
                }
            ]
        },
        "name": "MySchedulePanel_schedulableUser",
        "selections": [
            {
                "alias": "scheduleItems",
                "args": null,
                "concreteType": "ICanBeUsedToCalculateScheduleTimesUnionTypeConnection",
                "kind": "LinkedField",
                "name": "__MySchedulePanel_scheduleItems_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ICanBeUsedToCalculateScheduleTimesUnionTypeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            (v0 /*: any*/)
                                        ],
                                        "type": "Node",
                                        "abstractKey": "__isNode"
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            (v1 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Event",
                                                "kind": "LinkedField",
                                                "name": "reasonEvent",
                                                "plural": false,
                                                "selections": [
                                                    (v2 /*: any*/),
                                                    (v3 /*: any*/),
                                                    (v0 /*: any*/)
                                                ],
                                                "storageKey": null
                                            },
                                            (v4 /*: any*/)
                                        ],
                                        "type": "PlannedAbsence",
                                        "abstractKey": null
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            (v4 /*: any*/)
                                        ],
                                        "type": "ScheduleShift",
                                        "abstractKey": null
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Event",
                                                "kind": "LinkedField",
                                                "name": "reasonEvent",
                                                "plural": false,
                                                "selections": [
                                                    (v3 /*: any*/),
                                                    (v0 /*: any*/),
                                                    (v2 /*: any*/)
                                                ],
                                                "storageKey": null
                                            },
                                            (v4 /*: any*/),
                                            (v1 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "ScheduleShift",
                                                "kind": "LinkedField",
                                                "name": "scheduleShift",
                                                "plural": false,
                                                "selections": [
                                                    (v0 /*: any*/),
                                                    (v4 /*: any*/)
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "type": "ScheduleShiftAssigneeReplacement",
                                        "abstractKey": null
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "ScheduleShiftCard_scheduleShift"
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "totalCount",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "SchedulableUser",
        "abstractKey": null
    };
})();
node.hash = "ad8c7e540b1c447e3121dc05774acca3";
export default node;
