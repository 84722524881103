import React, { useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Field, Form, Input, Select2 } from '@sonika-se/ui-kit/components';
import { Controller, useForm } from 'react-hook-form';
import { object, string } from 'yup';
export const userTableQueryFormValidationSchema = object({
    search: string().notRequired(),
    status: string().notRequired(),
});
export const UserTableQueryForm = (props) => {
    const { defaultValues, disabled, onSubmit } = props;
    const { control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues,
        resolver: yupResolver(userTableQueryFormValidationSchema),
    });
    const statusOptions = useMemo(() => [
        {
            label: 'Alla',
            value: undefined,
        },
        {
            label: 'Aktiv',
            value: 'ACTIVE',
        },
        {
            label: 'Inaktiv',
            value: 'DISABLED',
        },
    ], []);
    return (<Form columns="40% auto auto" gap="small" onSubmit={handleSubmit((data) => {
            onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit(data);
        })}>
      <Field>
        <Controller control={control} name="search" render={({ field }) => {
            var _a;
            return (<Input defaultValue={defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.search} disabled={disabled} display="block" error={(_a = errors === null || errors === void 0 ? void 0 : errors.search) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} placeholder="Fritextsök"/>);
        }}/>
      </Field>
      <Field>
        <Controller control={control} name="status" render={({ field }) => (<Select2 defaultValue={(defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.status) ? [defaultValues.status] : undefined} disabled={disabled} multiple={false} onBlur={() => field.onBlur()} onChange={({ single }) => field.onChange(single)} options={statusOptions} placeholder="Status"/>)}/>
      </Field>
      <Button type="submit">Filtrera</Button>
    </Form>);
};
export default UserTableQueryForm;
