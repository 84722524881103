import React, { useCallback, useRef } from 'react';
import { graphql, useMutation, usePreloadedQuery } from 'react-relay/hooks';
import { FormModal } from '@sonika-se/ui-kit/components';
import AssetGeneralInfoForm from '~/forms/AssetGeneralInfoForm';
export const assetAssetUpdateModalQuery = graphql `
  query AssetGeneralInfoUpdateModalQuery($assetId: ID!) {
    asset(id: $assetId) {
      assetNumber
      description
      id
      name
    }
  }
`;
export const AssetGeneralInfoUpdateModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const { asset } = usePreloadedQuery(assetAssetUpdateModalQuery, preloadedQuery);
    const [updateAsset, updateAssetLoading] = useMutation(graphql `
      mutation AssetGeneralInfoUpdateModal_updateAssetMutation($model: UpdateAssetRequestInput!) {
        updateAsset(model: $model) {
          assetEdge {
            node {
              assetNumber
              description
              name
            }
          }
        }
      }
    `);
    const formRef = useRef(null);
    const handleSubmit = useCallback((form) => {
        updateAsset({
            onCompleted: () => {
                onClose();
            },
            variables: {
                model: {
                    assetNumber: form.assetNumber,
                    description: form.description,
                    id: asset.id,
                    name: form.name,
                },
            },
        });
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Spara" disabled={updateAssetLoading} title="Ändra allmän info">
      <AssetGeneralInfoForm defaultValues={{
            assetNumber: asset.assetNumber,
            description: asset.description,
            name: asset.name,
        }} disabled={updateAssetLoading} onSubmit={handleSubmit} ref={formRef}/>
    </FormModal>);
};
export default AssetGeneralInfoUpdateModal;
