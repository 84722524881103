import React, { Suspense, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Button, DataTable, ModalSkeleton, Panel, PanelButton, Placeholder } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useMutation, useQueryLoader } from 'react-relay/hooks';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import enums from '~/enums.json';
const { PermissionEnum } = enums;
import AssignmentTypeSelectModal, { assignmentTypeSelectModalQuery } from '~/shared/modals/AssignmentTypeSelectModal';
export const UserAssignmentTypesPanel = (props) => {
    const { userRef } = props;
    const user = useFragment(graphql `
      fragment UserAssignmentTypesPanel_user on User {
        assignmentTypes {
          nodes {
            id
            title
          }
        }
        id
      }
    `, userRef);
    const assignmentTypes = useMemo(() => user.assignmentTypes.nodes.map((assignmentType) => ({
        title: assignmentType,
        actions: assignmentType.id,
    })), [user]);
    const [userAssignmentTypeSelectModalRef, loadUserAssignmentTypeSelectModal, disposeUserAssignmentTypeSelectModal] = useQueryLoader(assignmentTypeSelectModalQuery);
    const [addAssignmentTypesToUser, addAssignmentTypesToUserLoading] = useMutation(graphql `
        mutation UserAssignmentTypesPanel_addAssignmentTypesToUserMutation($model: AddAssignmentTypesToUserInput!) {
          addAssignmentTypesToUser(model: $model) {
            user {
              ...UserAssignmentTypesPanel_user
            }
          }
        }
      `);
    const [removeAssignmentTypesFromUser, removeAssignmentTypesFromUserLoading] = useMutation(graphql `
        mutation UserAssignmentTypesPanel_removeAssignmentTypesFromUserMutation(
          $model: RemoveAssignmentTypesFromUserInput!
        ) {
          removeAssignmentTypesFromUser(model: $model) {
            user {
              ...UserAssignmentTypesPanel_user
            }
          }
        }
      `);
    const hasPermissions = usePermissions();
    const hasEditAssignmentTypesPermission = hasPermissions([PermissionEnum.EDIT_ASSIGNMENT_TYPES.key]);
    const where = useMemo(() => ({
        id: {
            nin: user.assignmentTypes.nodes.map(({ id }) => id),
        },
    }), [user]);
    return (<>
      <Panel title="Roller" actions={hasEditAssignmentTypesPermission && (<PanelButton disabled={addAssignmentTypesToUserLoading} onClick={() => {
                loadUserAssignmentTypeSelectModal({
                    where,
                });
            }}>
              Lägg till
            </PanelButton>)}>
        {assignmentTypes.length > 0 ? (<DataTable columns={{
                title: {
                    text: 'Namn',
                    render: ({ id, title }) => <Link to={`/assignment-types/${id}`}>{title}</Link>,
                },
                actions: {
                    align: 'right',
                    text: '',
                    render: (assignmentTypeId) => (<Button appearance="outlined" color="danger" disabled={removeAssignmentTypesFromUserLoading} onClick={() => {
                            removeAssignmentTypesFromUser({
                                variables: {
                                    model: {
                                        assignmentTypeIds: [assignmentTypeId],
                                        userId: user.id,
                                    },
                                },
                            });
                        }} size="xsmall">
                    Ta bort
                  </Button>),
                },
            }} rows={assignmentTypes}/>) : (<Placeholder text="Inga grupper."/>)}
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {userAssignmentTypeSelectModalRef && (<AssignmentTypeSelectModal onClose={() => {
                disposeUserAssignmentTypeSelectModal();
            }} onSelect={(assignmentTypeIds) => {
                disposeUserAssignmentTypeSelectModal();
                addAssignmentTypesToUser({
                    variables: {
                        model: {
                            userId: user.id,
                            assignmentTypeIds,
                        },
                    },
                });
            }} defaultWhere={where} preloadedQuery={userAssignmentTypeSelectModalRef}/>)}
      </Suspense>
    </>);
};
export default UserAssignmentTypesPanel;
