import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, boolean, object, string } from 'yup';
import { Checkbox, Field, Flexbox, Form, FormSection, Switch, Text } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
export const permissionsFormSchemaValidationSchema = object({
    isCompanyAdmin: boolean().notRequired(),
    permissions: array().of(string()).required(),
});
export const PermissionsForm = forwardRef((props, forwardedRef) => {
    const { defaultValues, disabled, onSubmit } = props;
    const { control, formState: { isValid }, handleSubmit, } = useForm({
        defaultValues,
        resolver: yupResolver(permissionsFormSchemaValidationSchema),
    });
    useImperativeHandle(forwardedRef, () => ({
        isValid,
        submit: (onValid, onInvalid) => handleSubmit(onValid, onInvalid)(),
    }), [handleSubmit, isValid]);
    const { permissionGroups } = useLazyLoadQuery(graphql `
        query PermissionsFormQuery {
          permissionGroups {
            name
            permissions {
              name
              value
            }
          }
        }
      `, {});
    return (<Form onSubmit={handleSubmit((data) => {
            onSubmit(data);
        })}>
        {defaultValues.isCompanyAdmin !== undefined && (<Flexbox direction="column" gap="xsmall">
            <Field direction="row-reverse" label="Företagsadmin">
              <Controller control={control} name="isCompanyAdmin" render={({ field }) => (<Switch defaultChecked={defaultValues.isCompanyAdmin} disabled={disabled} onBlur={field.onBlur} onChange={() => {
                    field.onChange(!field.value);
                }}/>)}/>
            </Field>
            <Text appearance="light" size="small">
              Som företagsadmin får användaren alla rättigheter
            </Text>
          </Flexbox>)}
        <Controller control={control} name="permissions" render={({ field }) => (<>
              {permissionGroups.map((group) => (<FormSection columns="1fr 1fr" key={group.name} title={group.name}>
                  {group.permissions.map((permission) => (<Field direction="row-reverse" key={permission.value} label={permission.name}>
                      <Checkbox defaultChecked={defaultValues.permissions.includes(permission.value)} disabled={disabled} onBlur={field.onBlur} onChange={(event) => {
                        if (event.target.checked) {
                            field.onChange([...field.value, permission.value]);
                        }
                        else {
                            field.onChange([...field.value].filter((p) => p !== permission.value));
                        }
                    }}/>
                    </Field>))}
                </FormSection>))}
            </>)}/>
      </Form>);
});
export default PermissionsForm;
