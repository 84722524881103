import React, { Suspense } from 'react';
import { graphql } from 'react-relay';
import { usePaginationFragment, useQueryLoader } from 'react-relay/hooks';
import { useIntl } from 'react-intl';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { Skeleton, ColorItem, Chip, IconButton, ModalToSheetSkeleton, Flexbox, Text, Divider, Label, Placeholder, DataTable, } from '@sonika-se/ui-kit/components';
import { useViewport } from '@sonika-se/ui-kit/hooks';
import enums from '~/enums.json';
const { ValueUnit } = enums;
import UpdateEventModal, { updateEventModalQuery } from './UpdateEventModal';
export const EventTable = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const intl = useIntl();
    const [updateEventModalQueryRef, loadUpdateEventModalQuery, disposeUpdateEventModalQuery] = useQueryLoader(updateEventModalQuery);
    const { data: events, hasNext, loadNext, isLoadingNext, } = usePaginationFragment(graphql `
      fragment EventTable on Query
      @argumentDefinitions(first: { type: "Int", defaultValue: 30 }, after: { type: "String" })
      @refetchable(queryName: "EventTableRefetch") {
        events(first: $first, after: $after) @connection(key: "EventTableRefetch_events") {
          edges {
            node {
              id
              additionUnit
              assignmentTypes {
                id
                title
              }
              color
              disabled
              name
              isAbsence
              isAddition
              isAssigneeReplacementReason
              isWork
              isUnsocial
            }
          }
        }
      }
    `, props.queryRef);
    const { size: pageSize } = useViewport();
    if (!((_b = (_a = events === null || events === void 0 ? void 0 : events.events) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.length)) {
        return <Placeholder text="Det finns inga händelser"/>;
    }
    return (<Suspense fallback={<Skeleton type="table"/>}>
      {pageSize !== 'small' && (<DataTable columns={{
                event: {
                    text: intl.formatMessage({ id: 'general.misc.event.name.singular', defaultMessage: 'Händelse' }),
                },
                color: {
                    render: (color) => <ColorItem color={{ hex: color, name: '' }}/>,
                    text: intl.formatMessage({
                        id: 'general.misc.color.name.singular',
                        defaultMessage: 'Färg',
                    }),
                },
                assignmentTypes: {
                    text: intl.formatMessage({
                        id: 'general.objects.assignment_type.name.plural',
                        defaultMessage: 'Roller',
                    }),
                    render: (assignmentTypes) => {
                        var _a;
                        return (<Flexbox gap="xsmall">
                  {(_a = assignmentTypes === null || assignmentTypes === void 0 ? void 0 : assignmentTypes.slice(0, 4)) === null || _a === void 0 ? void 0 : _a.map((node) => (<Chip key={node === null || node === void 0 ? void 0 : node.id} size="xsmall" appearance="primary" text={node === null || node === void 0 ? void 0 : node.title}/>))}
                  {(assignmentTypes === null || assignmentTypes === void 0 ? void 0 : assignmentTypes.length) > 4 && (<Chip size="xsmall" appearance="secondary" text={`+ ${(assignmentTypes === null || assignmentTypes === void 0 ? void 0 : assignmentTypes.length) - 4}`}/>)}
                </Flexbox>);
                    },
                },
                status: {
                    text: intl.formatMessage({ id: 'general.misc.event.fields.status.label', defaultMessage: 'Status' }),
                    render: (disabled) => {
                        if (disabled) {
                            return (<div>
                      <Chip size="xsmall" appearance="danger" text={intl.formatMessage({
                                    id: 'general.misc.event.fields.status.disabled',
                                    defaultMessage: 'Inaktiv',
                                })}/>
                    </div>);
                        }
                        return (<div>
                    <Chip size="xsmall" appearance="success" text={intl.formatMessage({
                                id: 'general.misc.event.fields.status.active',
                                defaultMessage: 'Aktiv',
                            })}/>
                  </div>);
                    },
                },
                isAbsence: {
                    render: (isAbsence) => (isAbsence ? 'Ja' : ''),
                    text: 'Frånvaro',
                },
                isAssigneeReplacementReason: {
                    render: (isAssigneeReplacementReason) => (isAssigneeReplacementReason ? 'Ja' : ''),
                    text: 'Bytesanledning',
                },
                isWork: {
                    render: (isWork) => (isWork ? 'Ja' : ''),
                    text: 'Arbete',
                },
                isAddition: {
                    render: (isAddition) => (isAddition ? 'Ja' : ''),
                    text: 'Tillägg',
                },
                additionUnit: {
                    render: ({ additionUnit, isAddition }) => { var _a; return (isAddition ? (_a = ValueUnit[additionUnit]) === null || _a === void 0 ? void 0 : _a.description : ''); },
                    text: 'Tilläggsenhet',
                },
                actions: {
                    align: 'right',
                    render: (id) => (<IconButton size="small" icon={faPencil} onClick={() => loadUpdateEventModalQuery({ id })}/>),
                },
            }} rows={(_d = (_c = events === null || events === void 0 ? void 0 : events.events) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.map(({ node }) => ({
                event: node.name,
                color: node.color,
                status: node === null || node === void 0 ? void 0 : node.disabled,
                assignmentTypes: node === null || node === void 0 ? void 0 : node.assignmentTypes,
                actions: node === null || node === void 0 ? void 0 : node.id,
                isAbsence: node.isAbsence,
                isAddition: node.isAddition,
                isAssigneeReplacementReason: node.isAssigneeReplacementReason,
                isWork: node.isWork,
                additionUnit: {
                    isAddition: node.isAddition,
                    additionUnit: node.additionUnit,
                },
            }))} loading={isLoadingNext} onPaginate={() => loadNext(30)} pagination={{
                hasNextPage: hasNext,
                buttonText: intl.formatMessage({
                    id: 'general.actions.load_more',
                    defaultMessage: 'Ladda fler',
                }),
            }}/>)}
      {pageSize === 'small' && (<Flexbox direction="column" gap="small">
          {(_f = (_e = events === null || events === void 0 ? void 0 : events.events) === null || _e === void 0 ? void 0 : _e.edges) === null || _f === void 0 ? void 0 : _f.map(({ node }) => {
                var _a;
                return (<Flexbox direction="column" gap="small" key={node === null || node === void 0 ? void 0 : node.id}>
              <Divider />
              <Flexbox direction="column" style={{ padding: '.5rem' }}>
                <Flexbox justify="space">
                  <Flexbox align="center">
                    <ColorItem color={{ hex: node === null || node === void 0 ? void 0 : node.color, name: '' }}/>{' '}
                    <Text style={{ fontWeight: 'bold' }}>{node === null || node === void 0 ? void 0 : node.name}</Text>
                  </Flexbox>
                  <Flexbox gap="small" align="center">
                    {(node === null || node === void 0 ? void 0 : node.disabled) ? (<Chip size="xsmall" appearance="danger" text={intl.formatMessage({
                            id: 'general.misc.event.fields.status.disabled',
                            defaultMessage: 'Inaktiv',
                        })}/>) : (<Chip size="xsmall" appearance="success" text={intl.formatMessage({
                            id: 'general.misc.event.fields.status.active',
                            defaultMessage: 'Aktiv',
                        })}/>)}
                    <IconButton size="small" icon={faPencil} onClick={() => loadUpdateEventModalQuery({ id: node.id })}/>
                  </Flexbox>
                </Flexbox>
                <Label text={intl.formatMessage({
                        id: 'general.objects.assignment_types.name.plural',
                        defaultMessage: 'Roller',
                    })}>
                  <Flexbox gap="xxsmall" style={{ flexWrap: 'wrap' }}>
                    {(_a = node === null || node === void 0 ? void 0 : node.assignmentTypes) === null || _a === void 0 ? void 0 : _a.map((assignmentType) => (<Chip style={{
                            marginBottom: '.25rem',
                        }} key={assignmentType === null || assignmentType === void 0 ? void 0 : assignmentType.id} size="xsmall" appearance="primary" text={assignmentType === null || assignmentType === void 0 ? void 0 : assignmentType.title}/>))}
                  </Flexbox>
                </Label>
              </Flexbox>
            </Flexbox>);
            })}
        </Flexbox>)}
      <Suspense fallback={<ModalToSheetSkeleton type="form"/>}>
        {updateEventModalQueryRef && (<UpdateEventModal onCancel={() => disposeUpdateEventModalQuery()} preloadedQuery={updateEventModalQueryRef}/>)}
      </Suspense>
    </Suspense>);
};
export default EventTable;
