/**
 * @generated SignedSource<<53b160fc6c51512827154920dcb00d19>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "assignmentTypeId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "assignmentTypeId"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AssignmentTypeLayoutQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "AssignmentType",
                    "kind": "LinkedField",
                    "name": "assignmentType",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AssignmentTypeLayoutQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "AssignmentType",
                    "kind": "LinkedField",
                    "name": "assignmentType",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "85b051b00e7fc726a4210e4f3b63d23b",
            "id": null,
            "metadata": {},
            "name": "AssignmentTypeLayoutQuery",
            "operationKind": "query",
            "text": "query AssignmentTypeLayoutQuery(\n  $assignmentTypeId: ID!\n) {\n  assignmentType(id: $assignmentTypeId) {\n    title\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "3703083a5a475b9e34e8c5dddec6817d";
export default node;
