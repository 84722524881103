import React from 'react';
import { Route, Routes } from 'react-router';
import AssetAttachmentsPage from '~/pages/AssetAttachmentsPage';
import AssetDetailsPage from '~/pages/AssetDetailsPage';
import AssetIndexPage from '~/pages/AssetIndexPage';
import AssetLayout from '~/layouts/AssetLayout';
import AssetSystemPage from '~/pages/AssetSystemPage';
import AssetProficienciesPage from '~/pages/AssetProficienciesPage';
export const AssetRoutes = () => (<Routes>
    <Route path="" element={<AssetIndexPage />}/>
    <Route element={<AssetLayout />} path=":assetId/*">
      <Route path="" element={<AssetDetailsPage />}/>
      <Route path="attachments" element={<AssetAttachmentsPage />}/>
      <Route path="proficiencies" element={<AssetProficienciesPage />}/>
      <Route path="system" element={<AssetSystemPage />}/>
    </Route>
  </Routes>);
export default AssetRoutes;
