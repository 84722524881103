import React, { useCallback, useRef } from 'react';
import { useMutation, usePreloadedQuery } from 'react-relay/hooks';
import { graphql } from 'relay-runtime';
import { FormModal } from '@sonika-se/ui-kit/components';
import PermissionsForm from '~/forms/PermissionsForm';
export const rolePermissionsModalQuery = graphql `
  query RolePermissionsUpdateModalQuery($roleId: ID!) {
    role(id: $roleId) {
      id
      rolePermissions
    }
  }
`;
export const RolePermissionsUpdateModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const data = usePreloadedQuery(rolePermissionsModalQuery, preloadedQuery);
    const [setRolePermissions, setRolePermissionsLoading] = useMutation(graphql `
        mutation RolePermissionsUpdateModal_setRolePermissionsMutation($model: SetRolePermissionsRequestInput!) {
          setRolePermissions(model: $model) {
            role {
              id
              rolePermissions
            }
          }
        }
      `);
    const formRef = useRef(null);
    const handleSubmit = useCallback((form) => {
        setRolePermissions({
            onCompleted: () => {
                onClose();
            },
            variables: {
                model: {
                    permissions: form.permissions,
                    roleId: data.role.id,
                },
            },
        });
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Spara" disabled={setRolePermissionsLoading} title="Ändra rättigheter">
      <PermissionsForm defaultValues={{
            permissions: data.role.rolePermissions,
        }} disabled={setRolePermissionsLoading} onSubmit={handleSubmit} ref={formRef}/>
    </FormModal>);
};
export default RolePermissionsUpdateModal;
