/**
 * @generated SignedSource<<8dcc0b81cf159c9a08526e12db8995a2>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditIsAdditionAvailableInTimeReport_event",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAdditionAvailableInTimeReport",
            "storageKey": null
        }
    ],
    "type": "Event",
    "abstractKey": null
};
node.hash = "09dcfacd01f3358f1abb8a4c83813719";
export default node;
