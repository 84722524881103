import React from 'react';
import { useIntl } from 'react-intl';
import { useFragment, graphql, useMutation } from 'react-relay/hooks';
import { Flexbox, Switch } from '@sonika-se/ui-kit/components';
export const EditIsAdditionAvailableInTimeReport = (props) => {
    const { eventRef } = props;
    const intl = useIntl();
    const { id, isAdditionAvailableInTimeReport } = useFragment(graphql `
      fragment EditIsAdditionAvailableInTimeReport_event on Event {
        id
        isAdditionAvailableInTimeReport
      }
    `, eventRef);
    const [updateEvent, updateEventLoading] = useMutation(graphql `
    mutation EditIsAdditionAvailableInTimeReportMutation($model: UpdateEventRequestInput!) {
      updateEvent(model: $model) {
        eventEdge {
          node {
            id
            isAdditionAvailableInTimeReport
          }
        }
      }
    }
  `);
    return (<Flexbox align="center">
      <Switch disabled={updateEventLoading} checked={isAdditionAvailableInTimeReport} label="" onChange={() => {
            updateEvent({
                variables: {
                    model: {
                        id,
                        isAdditionAvailableInTimeReport: !isAdditionAvailableInTimeReport,
                    },
                },
            });
        }}/>
    </Flexbox>);
};
export default EditIsAdditionAvailableInTimeReport;
