import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { date, object, string } from 'yup';
import { Form, Input, Field, DateInput } from '@sonika-se/ui-kit/components';
export const userPersonalInfoFormValidationSchema = object().shape({
    employeeId: string().nullable().notRequired(),
    firstname: string(),
    lastname: string(),
    personnummer: string()
        .nullable()
        .matches(/^([0-9]{8}(\-?[0-9]{4})?)?$/, 'Felaktigt födelsedatum/personnummer')
        .notRequired(),
    employmentDate: date().nullable().notRequired(),
    employmentEndDate: date().nullable().notRequired(),
});
export const UserPersonalInfoForm = forwardRef((props, forwardedRef) => {
    const { defaultValues, disabled, onSubmit } = props;
    const { control, formState: { errors, isValid }, handleSubmit, } = useForm({
        defaultValues,
        resolver: yupResolver(userPersonalInfoFormValidationSchema),
    });
    useImperativeHandle(forwardedRef, () => ({
        isValid,
        submit: (onValid, onInvalid) => handleSubmit(onValid, onInvalid)(),
    }), [handleSubmit, isValid]);
    return (<Form columns="1fr 1fr" onSubmit={handleSubmit((data) => {
            onSubmit(data);
        })}>
        <Field label="Förnamn">
          <Controller control={control} name="firstname" render={({ field }) => {
            var _a;
            return (<Input disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.firstname) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} defaultValue={field.value}/>);
        }}/>
        </Field>
        <Field label="Efternamn">
          <Controller control={control} name="lastname" render={({ field }) => {
            var _a;
            return (<Input disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.lastname) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} defaultValue={field.value}/>);
        }}/>
        </Field>
        <Field columnSpan={2} label="Anställningsnr">
          <Controller control={control} name="employeeId" render={({ field }) => {
            var _a;
            return (<Input disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.employeeId) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} defaultValue={field.value}/>);
        }}/>
        </Field>
        <Field columnSpan={2} label="Födelsedatum/personnummer">
          <Controller control={control} name="personnummer" render={({ field }) => {
            var _a;
            return (<Input disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.personnummer) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} defaultValue={field.value}/>);
        }}/>
        </Field>
        <Field columnSpan={2} label="Anställd sedan">
          <Controller control={control} name="employmentDate" render={({ field }) => {
            var _a;
            return (<DateInput disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.employmentDate) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={(value) => {
                    field.onChange(value === undefined ? null : value);
                }} defaultValue={field.value}/>);
        }}/>
        </Field>
        <Field columnSpan={2} label="Anställning avslutad">
          <Controller control={control} name="employmentEndDate" render={({ field }) => {
            var _a;
            return (<DateInput disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.employmentEndDate) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={(value) => {
                    field.onChange(value === undefined ? null : value);
                }} defaultValue={field.value}/>);
        }}/>
        </Field>
      </Form>);
});
export default UserPersonalInfoForm;
