import React, { Suspense } from 'react';
import { ColorItem, Flexbox, getColor, KeyValue, ModalSkeleton, Panel, PanelButton } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import enums from '~/enums.json';
const { UserStatus } = enums;
import UserSystemInfoUpdateModal, { userSystemInfoUpdateModalQuery } from '~/modals/UserSystemInfoUpdateModal';
export const UserSystemInfoPanel = (props) => {
    var _a, _b;
    const { userRef } = props;
    const user = useFragment(graphql `
      fragment UserSystemInfoPanel_user on User {
        color
        id
        invitation {
          accepted
          created
        }
        status
      }
    `, userRef);
    const [userSystemInfoUpdateModalRef, loadUserSystemInfoUpdateModal, disposeUserSystemInfoUpdateModal] = useQueryLoader(userSystemInfoUpdateModalQuery);
    return (<>
      <Panel title="Systeminformation" actions={<PanelButton onClick={() => loadUserSystemInfoUpdateModal({ userId: user.id })}>Ändra</PanelButton>}>
        <Flexbox direction="row" gap="xlarge">
          <KeyValue label="Status" value={(_a = UserStatus[user.status]) === null || _a === void 0 ? void 0 : _a.description}/>
          <KeyValue label="Färg">
            <ColorItem color={{ hex: user.color, name: (_b = getColor(user.color)) === null || _b === void 0 ? void 0 : _b.name }}/>
          </KeyValue>
        </Flexbox>
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {userSystemInfoUpdateModalRef && (<UserSystemInfoUpdateModal onClose={() => {
                disposeUserSystemInfoUpdateModal();
            }} preloadedQuery={userSystemInfoUpdateModalRef}/>)}
      </Suspense>
    </>);
};
export default UserSystemInfoPanel;
