/**
 * @generated SignedSource<<53be211da35de7e685825115bf37dbcc>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "after"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "filter"
        },
        {
            "defaultValue": 50,
            "kind": "LocalArgument",
            "name": "first"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "where"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
        },
        {
            "kind": "Variable",
            "name": "filter",
            "variableName": "filter"
        },
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
        },
        {
            "kind": "Variable",
            "name": "where",
            "variableName": "where"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserSelectModal_queryQuery",
            "selections": [
                {
                    "args": (v1 /*: any*/),
                    "kind": "FragmentSpread",
                    "name": "UserSelectModal_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserSelectModal_queryQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UserConnection",
                    "kind": "LinkedField",
                    "name": "users",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "firstname",
                                            "storageKey": null
                                        },
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "lastname",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Avatar",
                                                    "kind": "LinkedField",
                                                    "name": "avatar",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": [
                                                                {
                                                                    "kind": "Literal",
                                                                    "name": "queryTransform",
                                                                    "value": "width=300&height=300&mode=crop&scale=both"
                                                                }
                                                            ],
                                                            "kind": "ScalarField",
                                                            "name": "url",
                                                            "storageKey": "url(queryTransform:\"width=300&height=300&mode=crop&scale=both\")"
                                                        },
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v4 /*: any*/)
                                                    ],
                                                    "type": "Asset",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v4 /*: any*/)
                                                    ],
                                                    "type": "Company",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v3 /*: any*/)
                                                    ],
                                                    "type": "User",
                                                    "abstractKey": null
                                                }
                                            ],
                                            "type": "IHaveAvatar",
                                            "abstractKey": "__isIHaveAvatar"
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "filters": [
                        "filter",
                        "where"
                    ],
                    "handle": "connection",
                    "key": "UserSelectModal_query_users",
                    "kind": "LinkedHandle",
                    "name": "users"
                }
            ]
        },
        "params": {
            "cacheID": "4f2c580266a5b3982862295d026d8d09",
            "id": null,
            "metadata": {},
            "name": "UserSelectModal_queryQuery",
            "operationKind": "query",
            "text": "query UserSelectModal_queryQuery(\n  $after: String\n  $filter: UsersFilterInput\n  $first: Int = 50\n  $where: UserFilterInput\n) {\n  ...UserSelectModal_query_27BTLk\n}\n\nfragment Avatar_iHaveAvatar on IHaveAvatar {\n  __isIHaveAvatar: __typename\n  avatar {\n    url(queryTransform: \"width=300&height=300&mode=crop&scale=both\")\n    id\n  }\n  ... on Asset {\n    color\n    name\n  }\n  ... on Company {\n    name\n  }\n  ... on User {\n    color\n    firstname\n    lastname\n  }\n}\n\nfragment UserSelectModal_query_27BTLk on Query {\n  users(after: $after, filter: $filter, first: $first, where: $where) {\n    edges {\n      node {\n        ...UserSnippet_user\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment UserSnippet_user on User {\n  ...Avatar_iHaveAvatar\n  firstname\n  id\n  lastname\n}\n"
        }
    };
})();
node.hash = "e7994b240ea23cd3d4aed4078bf815c0";
export default node;
