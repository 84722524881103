import React, { useEffect, useRef, useState, useTransition } from 'react';
import { graphql, usePaginationFragment, usePreloadedQuery } from 'react-relay/hooks';
import { SelectModal, Text } from '@sonika-se/ui-kit/components';
export const assetSelectModalQuery = graphql `
  query AssetSelectModalQuery($filter: AssetsFilterInput, $where: AssetFilterInput) {
    ...AssetSelectModal_query @arguments(filter: $filter, where: $where)
  }
`;
export const AssetSelectModal = (props) => {
    const { defaultFilter, defaultWhere, onClose, onSelect, preloadedQuery, selectButtonLabel, title = 'Välj enhet', } = props;
    const query = usePreloadedQuery(assetSelectModalQuery, preloadedQuery);
    const [filter, setFilter] = useState(defaultFilter);
    const [where, setWhere] = useState(defaultWhere);
    const { data: { assets }, hasNext, isLoadingNext, loadNext, refetch, } = usePaginationFragment(graphql `
      fragment AssetSelectModal_query on Query
      @argumentDefinitions(
        after: { type: "String" }
        filter: { type: "AssetsFilterInput" }
        first: { type: "Int", defaultValue: 50 }
        where: { type: "AssetFilterInput" }
      )
      @refetchable(queryName: "AssetSelectModal_queryQuery") {
        assets(after: $after, filter: $filter, first: $first, where: $where)
          @connection(key: "AssetSelectModal_query_assets") {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `, query);
    const [isTransitioning, startTransition] = useTransition();
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        startTransition(() => {
            refetch({
                filter,
                where,
            }, {
                fetchPolicy: 'network-only',
            });
        });
    }, [filter, where]);
    return (<SelectModal hasNext={hasNext} isLoadingNext={isLoadingNext} isSearching={isTransitioning} items={assets.edges.map(({ node }) => node)} loadNext={() => {
            loadNext(50);
        }} onClose={() => {
            onClose === null || onClose === void 0 ? void 0 : onClose();
        }} onSearch={(search) => {
            setFilter(Object.assign(Object.assign({}, defaultFilter), { search }));
        }} onSelect={(assets) => {
            onSelect === null || onSelect === void 0 ? void 0 : onSelect(assets.map(({ id }) => id));
        }} renderItem={(asset) => <Text>{asset.name}</Text>} selectButtonLabel={selectButtonLabel} title={title}/>);
};
export default AssetSelectModal;
