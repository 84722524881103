import React from 'react';
import { useParams } from 'react-router';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { Flexbox } from '@sonika-se/ui-kit/components';
import AssetAddressPanel from '~/panels/AssetAddressPanel';
import AssetSidebar from '~/sidebars/AssetSidebar';
import AssetAssignmentTypesPanel from '~/panels/AssetAssignmentTypesPanel';
import AssetGeneralInfoPanel from '~/panels/AssetGeneralInfoPanel';
export const AssetDetailsPage = () => {
    const { assetId } = useParams();
    const { asset } = useLazyLoadQuery(graphql `
      query AssetDetailsPageQuery($assetId: ID!) {
        asset(id: $assetId) {
          ...AssetAddressPanel_asset
          ...AssetAssignmentTypesPanel_asset
          ...AssetSidebar_asset
          ...AssetGeneralInfoPanel_asset
        }
      }
    `, {
        assetId,
    });
    return (<Flexbox direction="row" gap="large">
      <div style={{ flex: 3 }}>
        <Flexbox direction="column" gap="large">
          <AssetGeneralInfoPanel assetRef={asset}/>
          <AssetAddressPanel assetRef={asset}/>
          <AssetAssignmentTypesPanel assetRef={asset}/>
        </Flexbox>
      </div>
      <div style={{ flex: 1 }}>
        <AssetSidebar assetRef={asset}/>
      </div>
    </Flexbox>);
};
export default AssetDetailsPage;
