import React from 'react';
import { Chip, Divider, Flexbox, H5, Placeholder, Text } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import { isSameDay } from 'date-fns';
import ScheduleShiftCard from '../../../cards/ScheduleShiftCard';
import Weekday from './Weekday';
import { FormattedMessage, useIntl } from 'react-intl';
import dateRangeInWords from '~/utils/dateRangeInWords';
import { useEnvironment } from '@sonika-se/ui-kit/hooks';
export const MySchedulePanel = (props) => {
    const { schedulableUserRef } = props;
    const { frontUrls } = useEnvironment();
    const intl = useIntl();
    const data = useFragment(graphql `
      fragment MySchedulePanel_schedulableUser on SchedulableUser
      @argumentDefinitions(
        model: { type: "GetRunningScheduleRequestInput!" }
        filter: { type: "ScheduleItemsFilterInput" }
      ) {
        scheduleItems(first: 5, model: $model, where: null, filter: $filter)
          @connection(key: "MySchedulePanel_scheduleItems", filters: []) {
          edges {
            node {
              __typename
              ... on Node {
                id
              }
              ... on PlannedAbsence {
                end
                reasonEvent {
                  color
                  name
                  id
                }
                start
              }
              ... on ScheduleShift {
                start
              }
              ... on ScheduleShiftAssigneeReplacement {
                reasonEvent {
                  name
                  id
                  color
                }
                start
                end
                scheduleShift {
                  id
                  start
                }
              }
              __typename
              ...ScheduleShiftCard_scheduleShift
            }
          }
          totalCount
        }
      }
    `, schedulableUserRef);
    return (<>
      <Flexbox direction="column" gap="medium">
        <Flexbox direction="column" gap="xsmall">
          <Flexbox justify="space">
            <H5>
              <FormattedMessage id="DashboardPage.MySchedulePanel.header" defaultMessage="Kommande pass"/>
            </H5>
            <a href={`${frontUrls['SCHEDULE']}my-schedule`}>
              <Text size="medium" bold>
                Mitt schema
              </Text>
            </a>
          </Flexbox>
          <Divider />
        </Flexbox>
        {data.scheduleItems.totalCount === 0 && (<Placeholder title={intl.formatMessage({
                defaultMessage: 'Inga kommande pass',
                id: 'DashboardPage.MySchedulePanel.no_data',
            })}/>)}
        {data.scheduleItems.totalCount !== 0 && (<div style={{
                display: 'grid',
                gap: '.5rem',
                gridTemplateColumns: 'auto 1fr',
            }}>
            {data.scheduleItems.edges.map(({ node }, index) => {
                var _a, _b;
                return (<React.Fragment key={node.id}>
                {(index === 0 ||
                        !isSameDay(new Date((data.scheduleItems.edges[index - 1].node.start ||
                            data.scheduleItems.edges[index - 1].node.scheduleShift.start).toString()), new Date((node.start || node.scheduleShift.start).toString()))) && <Weekday date={new Date((node.start || node.scheduleShift.start).toString())}/>}
                <div style={{ gridColumn: 2 }}>
                  {node.__typename === 'PlannedAbsence' && (<Flexbox direction="column" gap="xsmall">
                      <Text size="small">
                        {dateRangeInWords(new Date(node.start.toString()), new Date(node.end.toString()))}
                      </Text>
                      <Chip type="view" appearance="danger" text={node.reasonEvent.name} color={(_a = node.reasonEvent) === null || _a === void 0 ? void 0 : _a.color}/>
                    </Flexbox>)}
                  {node.__typename === 'ScheduleShift' && <ScheduleShiftCard scheduleShiftRef={node}/>}
                  {node.__typename === 'ScheduleShiftAssigneeReplacement' && (<Flexbox direction="column" gap="xsmall">
                      <Text size="small">
                        {dateRangeInWords(new Date(node.start.toString()), new Date(node.end.toString()))}
                      </Text>
                      <Chip type="view" appearance="danger" text={node.reasonEvent.name} color={(_b = node.reasonEvent) === null || _b === void 0 ? void 0 : _b.color}/>
                    </Flexbox>)}
                </div>
              </React.Fragment>);
            })}
          </div>)}
      </Flexbox>
    </>);
};
export default MySchedulePanel;
