import React, { useEffect, useRef, useState } from 'react';
import { Avatar as UIAvatar } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import { useApi } from '@sonika-se/ui-kit/hooks';
import { blobToDataURL } from '@sonika-se/ui-kit/utils/misc';
export const Avatar = (props) => {
    const { iHaveAvatarRef, size } = props;
    const { avatar, color, name, firstname, lastname } = useFragment(graphql `
      fragment Avatar_iHaveAvatar on IHaveAvatar {
        avatar {
          url(queryTransform: "width=300&height=300&mode=crop&scale=both")
        }
        ... on Asset {
          color
          name
        }
        ... on Company {
          name
        }
        ... on User {
          color
          firstname
          lastname
        }
      }
    `, iHaveAvatarRef);
    const { fetch } = useApi();
    const isMounted = useRef(false);
    const [image, setImage] = useState();
    useEffect(() => {
        isMounted.current = true;
        if (avatar === null || avatar === void 0 ? void 0 : avatar.url) {
            fetch(avatar.url)
                .then((response) => response.blob())
                .then((blob) => blobToDataURL(blob))
                .then((dataUrl) => {
                if (isMounted.current) {
                    setImage(dataUrl);
                }
            });
        }
        else {
            setImage(undefined);
        }
    }, [avatar]);
    if ((avatar === null || avatar === void 0 ? void 0 : avatar.url) && !image) {
        return null;
    }
    return <UIAvatar color={color} image={image} size={size} text={name ? name : `${firstname} ${lastname}`}/>;
};
export default Avatar;
