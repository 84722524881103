import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { useFragment, graphql, useMutation } from 'react-relay/hooks';
import { InlineInputField } from '@sonika-se/ui-kit/components';
const validationSchema = object({
    name: string().required('Du måste Sätta ett namn'),
});
export const EditEventName = (props) => {
    var _a, _b;
    const { event, placeholder } = props;
    const { id, name } = useFragment(graphql `
      fragment EditEventName on Event {
        id
        name
      }
    `, event);
    const [updateEventName] = useMutation(graphql `
    mutation EditEventNameMutation($model: UpdateEventRequestInput!) {
      updateEvent(model: $model) {
        eventEdge {
          node {
            id
            name
          }
        }
      }
    }
  `);
    const { handleSubmit, register, reset, formState: { errors }, } = useForm({
        defaultValues: { name },
        resolver: yupResolver(validationSchema),
        mode: 'onBlur',
    });
    const field = useMemo(() => register('name'), []);
    return (<InlineInputField inputName={field.name} inputRef={field.ref} defaultValue={name} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.name) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} placeholder={placeholder} onChange={(event) => {
            field.onChange(event);
            handleSubmit((formData) => {
                reset(formData);
                updateEventName({
                    variables: {
                        model: Object.assign({ id }, formData),
                    },
                    optimisticResponse: {
                        updateEvent: {
                            eventEdge: {
                                node: Object.assign({ id }, formData),
                            },
                        },
                    },
                });
            })();
        }}/>);
};
export default EditEventName;
