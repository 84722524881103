/**
 * @generated SignedSource<<6f854df8c2e56580aa16b620ca9f2730>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 50
        }
    ], v1 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "AssignmentTypeIndexPageQuery",
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AssignmentTypeTablePanel_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "AssignmentTypeIndexPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v0 /*: any*/),
                    "concreteType": "AssignmentTypeConnection",
                    "kind": "LinkedField",
                    "name": "assignmentTypes",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssignmentTypeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssignmentType",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "AssetConnection",
                                            "kind": "LinkedField",
                                            "name": "assets",
                                            "plural": false,
                                            "selections": (v1 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "disabledDate",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "UserConnection",
                                            "kind": "LinkedField",
                                            "name": "users",
                                            "plural": false,
                                            "selections": (v1 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": "assignmentTypes(first:50)"
                },
                {
                    "alias": null,
                    "args": (v0 /*: any*/),
                    "filters": [],
                    "handle": "connection",
                    "key": "AssignmentTypeTable_query_assignmentTypes",
                    "kind": "LinkedHandle",
                    "name": "assignmentTypes"
                }
            ]
        },
        "params": {
            "cacheID": "f1e4441458e8a7f56219a1e08017fdf5",
            "id": null,
            "metadata": {},
            "name": "AssignmentTypeIndexPageQuery",
            "operationKind": "query",
            "text": "query AssignmentTypeIndexPageQuery {\n  ...AssignmentTypeTablePanel_query\n}\n\nfragment AssignmentTypeTablePanel_query on Query {\n  ...AssignmentTypeTable_query\n}\n\nfragment AssignmentTypeTable_query on Query {\n  assignmentTypes(first: 50) {\n    edges {\n      node {\n        assets {\n          totalCount\n        }\n        disabledDate\n        id\n        users {\n          totalCount\n        }\n        title\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "fcb86f564128ab5e4c4f4d1063086530";
export default node;
