import React from 'react';
import { useParams } from 'react-router';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import AssignmentTypeGeneralInfoPanel from '~/panels/AssignmentTypeGeneralInfoPanel';
import { Flexbox } from '@sonika-se/ui-kit/components';
import AssignmentTypeStatusPanel from '~/panels/AssignmentTypeStatusPanel';
export const AssignmentTypeDetailsPage = () => {
    const { assignmentTypeId } = useParams();
    const { assignmentType } = useLazyLoadQuery(graphql `
      query AssignmentTypeDetailsPageQuery($assignmentTypeId: ID!) {
        assignmentType(id: $assignmentTypeId) {
          ...AssignmentTypeGeneralInfoPanel_assignmentType
          ...AssignmentTypeStatusPanel_assignmentType
        }
      }
    `, {
        assignmentTypeId,
    });
    return (<Flexbox direction="row" gap="xlarge">
      <div style={{ flex: 3 }}>
        <Flexbox direction="column" gap="xlarge">
          <AssignmentTypeGeneralInfoPanel assignmentTypeRef={assignmentType}/>
        </Flexbox>
      </div>
      <div style={{ flex: 1 }}>
        <AssignmentTypeStatusPanel assignmentTypeRef={assignmentType}/>
      </div>
    </Flexbox>);
};
export default AssignmentTypeDetailsPage;
