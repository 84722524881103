/**
 * @generated SignedSource<<4aee837c12f1bb1f3e6771035061335d>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditEventIsAddition_event",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isAddition",
            "storageKey": null
        }
    ],
    "type": "Event",
    "abstractKey": null
};
node.hash = "dcc58f798f4d873d5163497b18f31bf7";
export default node;
