import { useEffect, useRef } from 'react';
import { Environment } from 'relay-runtime';
import { Network, Observable, RecordSource, Store } from 'relay-runtime';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { useApi, useEnvironment } from '@sonika-se/ui-kit/hooks';
import { getAccessTokens } from '@sonika-se/ui-kit/utils/auth';
import { TOKEN_REFRESH_EVENT } from '@sonika-se/ui-kit/utils/net';
const useProvideRelayEnvironment = () => {
    const { graphQL } = useApi();
    const { graphQLApiUrl } = useEnvironment();
    const subscriptionClient = useRef(new SubscriptionClient(graphQLApiUrl.replace('http://', 'ws://').replace('https://', 'wss://'), {
        lazy: true,
        reconnect: true,
        connectionParams: () => {
            const { accessToken } = getAccessTokens();
            return {
                Authorization: `Bearer ${accessToken}`,
            };
        },
    }));
    useEffect(() => {
        window.addEventListener(TOKEN_REFRESH_EVENT, () => {
            subscriptionClient.current.close(false, false);
        });
    }, [window]);
    const fetchFn = (request, variables) => {
        return graphQL(request.text, variables);
    };
    const subscribeFn = (request, variables) => {
        const subscribe = (request, variables) => {
            const subscribeObservable = subscriptionClient.current.request({
                query: request.text,
                operationName: request.name,
                variables,
            });
            return Observable.from(subscribeObservable);
        };
        return subscribe(request, variables);
    };
    const environment = useRef(new Environment({
        network: Network.create(fetchFn, subscribeFn),
        store: new Store(new RecordSource()),
    }));
    return environment.current;
};
export default useProvideRelayEnvironment;
