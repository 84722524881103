import React, { Suspense } from 'react';
import { createUseStyles } from 'react-jss';
import { useFragment, graphql, useQueryLoader } from 'react-relay/hooks';
import { ModalToSheetSkeleton, Tooltip } from '@sonika-se/ui-kit/components';
import IHaveAvatarUpdateModal, { changeAvatarModalQuery } from '../../../modals/IHaveAvatarUpdateModal';
import Avatar from '~/shared/Avatar';
const useStyles = createUseStyles((theme) => {
    return {
        logo: ({ editable }) => ({
            '& > button': {
                cursor: editable ? 'pointer !important' : 'default',
            },
            '&:hover': {
                boxShadow: editable ? theme.helpers.shadow(3) : 'none',
                opacity: '.5',
            },
        }),
    };
});
export const EditCompanyAvatar = (props) => {
    const { company, editable = false } = props;
    const data = useFragment(graphql `
      fragment EditCompanyAvatar on Company {
        id
        ...Avatar_iHaveAvatar
      }
    `, company);
    const [changeAvatarModalQueryRef, loadIHaveAvatarUpdateModalQuery, disposeIHaveAvatarUpdateModalQuery] = useQueryLoader(changeAvatarModalQuery);
    const classes = useStyles({ editable });
    return (<>
      {editable ? (<Tooltip content="Klicka för att ändra logga">
          <div className={classes.logo} onClick={() => {
                if (editable) {
                    loadIHaveAvatarUpdateModalQuery({
                        iHaveAvatarId: data.id,
                    });
                }
            }}>
            <Avatar size="xlarge" iHaveAvatarRef={data}/>
          </div>
        </Tooltip>) : (<div className={classes.logo}>
          <Avatar size="xlarge" iHaveAvatarRef={data}/>
        </div>)}
      <Suspense fallback={<ModalToSheetSkeleton />}>
        {changeAvatarModalQueryRef && (<IHaveAvatarUpdateModal onClose={() => {
                disposeIHaveAvatarUpdateModalQuery();
            }} preloadedQuery={changeAvatarModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default EditCompanyAvatar;
