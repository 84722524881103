/**
 * @generated SignedSource<<f7e7a172c072fe405ebd37ff60c8c5d8>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "assetId"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "assetId"
                }
            ],
            "concreteType": "Asset",
            "kind": "LinkedField",
            "name": "asset",
            "plural": false,
            "selections": [
                (v1 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "SchedulableAsset",
                    "kind": "LinkedField",
                    "name": "schedulableEntity",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "schedulable",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AssetAttributesUpdateModalQuery",
            "selections": (v2 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AssetAttributesUpdateModalQuery",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "14d79f9e6e2f163d7b82dc5dae952a5f",
            "id": null,
            "metadata": {},
            "name": "AssetAttributesUpdateModalQuery",
            "operationKind": "query",
            "text": "query AssetAttributesUpdateModalQuery(\n  $assetId: ID!\n) {\n  asset(id: $assetId) {\n    id\n    schedulableEntity {\n      id\n      schedulable\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "736710495b00d77d32f3513bb3f54f15";
export default node;
