/**
 * @generated SignedSource<<7a703a65e15f5b3341b6c61565a62cc2>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "externalContacts",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserExternalContactsUpdateModal_updateUserMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateUserPayload",
                    "kind": "LinkedField",
                    "name": "updateUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfUser",
                            "kind": "LinkedField",
                            "name": "userEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserExternalContactsUpdateModal_updateUserMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateUserPayload",
                    "kind": "LinkedField",
                    "name": "updateUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfUser",
                            "kind": "LinkedField",
                            "name": "userEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "e933280b640119bb849358646bd698a4",
            "id": null,
            "metadata": {},
            "name": "UserExternalContactsUpdateModal_updateUserMutation",
            "operationKind": "mutation",
            "text": "mutation UserExternalContactsUpdateModal_updateUserMutation(\n  $model: UpdateUserRequestInput!\n) {\n  updateUser(model: $model) {\n    userEdge {\n      node {\n        externalContacts\n        id\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "74400a30003572c58fa3e5d828663d83";
export default node;
