/**
 * @generated SignedSource<<b5a5026ef3aec0afca8a93c272f43460>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "assignmentTypeId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "assignmentTypeId"
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AssignmentTypeDetailsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "AssignmentType",
                    "kind": "LinkedField",
                    "name": "assignmentType",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "AssignmentTypeGeneralInfoPanel_assignmentType"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "AssignmentTypeStatusPanel_assignmentType"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AssignmentTypeDetailsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "AssignmentType",
                    "kind": "LinkedField",
                    "name": "assignmentType",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "disabledDate",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "b634a4548cc170106d3f2ff4f0f4d405",
            "id": null,
            "metadata": {},
            "name": "AssignmentTypeDetailsPageQuery",
            "operationKind": "query",
            "text": "query AssignmentTypeDetailsPageQuery(\n  $assignmentTypeId: ID!\n) {\n  assignmentType(id: $assignmentTypeId) {\n    ...AssignmentTypeGeneralInfoPanel_assignmentType\n    ...AssignmentTypeStatusPanel_assignmentType\n    id\n  }\n}\n\nfragment AssignmentTypeGeneralInfoPanel_assignmentType on AssignmentType {\n  id\n  title\n}\n\nfragment AssignmentTypeStatusPanel_assignmentType on AssignmentType {\n  disabledDate\n  id\n}\n"
        }
    };
})();
node.hash = "591818966761de35f6b16e7fd97bd052";
export default node;
