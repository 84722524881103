/**
 * @generated SignedSource<<05ae612098edcec479e46247c9e9a7c5>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "connectionsToPrependEdgeTo"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
        }
    ], v3 = {
        "alias": null,
        "args": null,
        "concreteType": "AssignmentTypeConnection",
        "kind": "LinkedField",
        "name": "assignmentTypes",
        "plural": false,
        "selections": (v2 /*: any*/),
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "created",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "concreteType": "RoleConnection",
        "kind": "LinkedField",
        "name": "roles",
        "plural": false,
        "selections": (v2 /*: any*/),
        "storageKey": null
    }, v9 = [
        (v5 /*: any*/)
    ], v10 = {
        "alias": null,
        "args": null,
        "concreteType": "SchedulableUser",
        "kind": "LinkedField",
        "name": "schedulableEntity",
        "plural": false,
        "selections": (v9 /*: any*/),
        "storageKey": null
    }, v11 = {
        "alias": null,
        "args": null,
        "concreteType": "TimeReportingUser",
        "kind": "LinkedField",
        "name": "timeReportingUser",
        "plural": false,
        "selections": (v9 /*: any*/),
        "storageKey": null
    }, v12 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "services",
        "storageKey": null
    }, v13 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    }, v14 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    }, v15 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RoleUsersPage_addUsersToRoleMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "AddUsersToRolePayload",
                    "kind": "LinkedField",
                    "name": "addUsersToRole",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfUser",
                            "kind": "LinkedField",
                            "name": "userEdges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "args": null,
                                            "kind": "FragmentSpread",
                                            "name": "Avatar_iHaveAvatar"
                                        },
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "UserInvitation",
                                            "kind": "LinkedField",
                                            "name": "invitation",
                                            "plural": false,
                                            "selections": [
                                                (v6 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v7 /*: any*/),
                                        (v8 /*: any*/),
                                        (v10 /*: any*/),
                                        (v11 /*: any*/),
                                        (v12 /*: any*/),
                                        (v13 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RoleUsersPage_addUsersToRoleMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "AddUsersToRolePayload",
                    "kind": "LinkedField",
                    "name": "addUsersToRole",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfUser",
                            "kind": "LinkedField",
                            "name": "userEdges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "UserInvitation",
                                            "kind": "LinkedField",
                                            "name": "invitation",
                                            "plural": false,
                                            "selections": [
                                                (v6 /*: any*/),
                                                (v5 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v7 /*: any*/),
                                        (v8 /*: any*/),
                                        (v10 /*: any*/),
                                        (v11 /*: any*/),
                                        (v12 /*: any*/),
                                        (v13 /*: any*/),
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Avatar",
                                                    "kind": "LinkedField",
                                                    "name": "avatar",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": [
                                                                {
                                                                    "kind": "Literal",
                                                                    "name": "queryTransform",
                                                                    "value": "width=300&height=300&mode=crop&scale=both"
                                                                }
                                                            ],
                                                            "kind": "ScalarField",
                                                            "name": "url",
                                                            "storageKey": "url(queryTransform:\"width=300&height=300&mode=crop&scale=both\")"
                                                        },
                                                        (v5 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v14 /*: any*/),
                                                        (v15 /*: any*/)
                                                    ],
                                                    "type": "Asset",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v15 /*: any*/)
                                                    ],
                                                    "type": "Company",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v14 /*: any*/)
                                                    ],
                                                    "type": "User",
                                                    "abstractKey": null
                                                }
                                            ],
                                            "type": "IHaveAvatar",
                                            "abstractKey": "__isIHaveAvatar"
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "filters": null,
                            "handle": "prependEdge",
                            "key": "",
                            "kind": "LinkedHandle",
                            "name": "userEdges",
                            "handleArgs": [
                                {
                                    "kind": "Variable",
                                    "name": "connections",
                                    "variableName": "connectionsToPrependEdgeTo"
                                }
                            ]
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "c1bd3794ada47d9a5ddff07162a6ac7d",
            "id": null,
            "metadata": {},
            "name": "RoleUsersPage_addUsersToRoleMutation",
            "operationKind": "mutation",
            "text": "mutation RoleUsersPage_addUsersToRoleMutation(\n  $model: AddUsersToRoleInput!\n) {\n  addUsersToRole(model: $model) {\n    userEdges {\n      node {\n        ...Avatar_iHaveAvatar\n        assignmentTypes {\n          totalCount\n        }\n        firstname\n        id\n        invitation {\n          created\n          id\n        }\n        lastname\n        roles {\n          totalCount\n        }\n        schedulableEntity {\n          id\n        }\n        timeReportingUser {\n          id\n        }\n        services\n        status\n      }\n    }\n  }\n}\n\nfragment Avatar_iHaveAvatar on IHaveAvatar {\n  __isIHaveAvatar: __typename\n  avatar {\n    url(queryTransform: \"width=300&height=300&mode=crop&scale=both\")\n    id\n  }\n  ... on Asset {\n    color\n    name\n  }\n  ... on Company {\n    name\n  }\n  ... on User {\n    color\n    firstname\n    lastname\n  }\n}\n"
        }
    };
})();
node.hash = "41dbdad000fa3430079c431eb0604f11";
export default node;
