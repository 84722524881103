import React from 'react';
import { useParams } from 'react-router';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import UserAttachmentsPanel from '~/panels/UserAttachmentsPanel';
export const UserAttachmentsPage = () => {
    const { userId } = useParams();
    const data = useLazyLoadQuery(graphql `
      query UserAttachmentsPageQuery($userId: ID!) {
        user(id: $userId) {
          ...UserAttachmentsPanel_user
        }
      }
    `, {
        userId,
    });
    return <UserAttachmentsPanel userRef={data.user}/>;
};
export default UserAttachmentsPage;
