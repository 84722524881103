/**
 * @generated SignedSource<<4160e2cb5978480b01214b3b7089170e>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "userId"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "accountNumber",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "concreteType": "BankDetails",
        "kind": "LinkedField",
        "name": "bankDetails",
        "plural": false,
        "selections": [
            (v3 /*: any*/)
        ],
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clearingNumber",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserBankAccountUpdateModalQuery",
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserBankAccountForm_query"
                },
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserBankAccount",
                            "kind": "LinkedField",
                            "name": "bankAccount",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserBankAccountUpdateModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "BankDetailsConnection",
                    "kind": "LinkedField",
                    "name": "bankDetails",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "BankDetails",
                            "kind": "LinkedField",
                            "name": "nodes",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "BankClearingNumber",
                                    "kind": "LinkedField",
                                    "name": "clearingNumbers",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "accountNumberLengthEnd",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "accountNumberLengthStart",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "clearingLength",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "rangeEnd",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "rangeStart",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserBankAccount",
                            "kind": "LinkedField",
                            "name": "bankAccount",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "6aca2d47f57075c3427441482385a273",
            "id": null,
            "metadata": {},
            "name": "UserBankAccountUpdateModalQuery",
            "operationKind": "query",
            "text": "query UserBankAccountUpdateModalQuery(\n  $userId: ID!\n) {\n  ...UserBankAccountForm_query\n  user(id: $userId) {\n    bankAccount {\n      accountNumber\n      bankDetails {\n        id\n      }\n      clearingNumber\n      id\n    }\n    id\n  }\n}\n\nfragment UserBankAccountForm_query on Query {\n  bankDetails {\n    nodes {\n      clearingNumbers {\n        accountNumberLengthEnd\n        accountNumberLengthStart\n        clearingLength\n        rangeEnd\n        rangeStart\n      }\n      id\n      name\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "a02aa81f4b055255c036f095e0268125";
export default node;
