import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Field, Form, Input } from '@sonika-se/ui-kit/components';
import { Controller, useForm } from 'react-hook-form';
import { object, string } from 'yup';
const validationSchema = object({
    name: string().required(),
});
export const SignupWizardCompanyForm = (props) => {
    const { defaultValues, formId, onSubmit } = props;
    const { control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
        mode: 'all',
    });
    return (<Form id={formId} onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Controller control={control} name="name" render={({ field }) => {
            var _a, _b;
            return (<Field error={(_a = errors === null || errors === void 0 ? void 0 : errors.name) === null || _a === void 0 ? void 0 : _a.message} label="Företagsnamn">
            <Input error={(_b = errors === null || errors === void 0 ? void 0 : errors.name) === null || _b === void 0 ? void 0 : _b.message} onChange={field.onChange} onBlur={field.onBlur} defaultValue={defaultValues.name}/>
          </Field>);
        }}/>
    </Form>);
};
export default SignupWizardCompanyForm;
