/**
 * @generated SignedSource<<ec51d9a95cade2ab96b87c36511acd28>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ServiceCard_serviceInfo",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "value",
            "storageKey": null
        }
    ],
    "type": "ServiceInfo",
    "abstractKey": null
};
node.hash = "de46544240f44f55e82d635c3a86cef6";
export default node;
