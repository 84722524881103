/**
 * @generated SignedSource<<e0528677c663b92c2f0d8220618d4d09>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Literal",
                    "name": "filter",
                    "value": {
                        "includeDeprecated": true
                    }
                }
            ],
            "concreteType": "SchedulePermissionGroup",
            "kind": "LinkedField",
            "name": "schedulePermissionGroups",
            "plural": true,
            "selections": [
                (v0 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "SchedulePermissionItem",
                    "kind": "LinkedField",
                    "name": "permissions",
                    "plural": true,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": "schedulePermissionGroups(filter:{\"includeDeprecated\":true})"
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "RoleSchedulePermissionsPanelQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "RoleSchedulePermissionsPanelQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "c05d062d005237bd3612caeccec3fadb",
            "id": null,
            "metadata": {},
            "name": "RoleSchedulePermissionsPanelQuery",
            "operationKind": "query",
            "text": "query RoleSchedulePermissionsPanelQuery {\n  schedulePermissionGroups(filter: {includeDeprecated: true}) {\n    name\n    permissions {\n      name\n      value\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "e794f2a5ba6f8874979136983d09d055";
export default node;
