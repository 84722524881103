/**
 * @generated SignedSource<<b6f7e3a1d39431477e8096645a643537>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "event",
        "plural": false,
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
    }, v3 = [
        (v0 /*: any*/)
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "MyTimeReportsPanel_timeReportingUser",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Literal",
                        "name": "first",
                        "value": 4
                    },
                    {
                        "kind": "Literal",
                        "name": "order",
                        "value": {
                            "month": "DESC"
                        }
                    }
                ],
                "concreteType": "TimeReportConnection",
                "kind": "LinkedField",
                "name": "timeReports",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "TimeReportEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "TimeReport",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "status",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "month",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": [
                                            {
                                                "kind": "Literal",
                                                "name": "order",
                                                "value": [
                                                    {
                                                        "timestampId": "DESC"
                                                    }
                                                ]
                                            }
                                        ],
                                        "concreteType": "TimeReportHistory",
                                        "kind": "LinkedField",
                                        "name": "history",
                                        "plural": true,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "message",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": "history(order:[{\"timestampId\":\"DESC\"}])"
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "TimeReportSummaryByEmployment",
                                        "kind": "LinkedField",
                                        "name": "employmentSummaries",
                                        "plural": true,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Employment",
                                                "kind": "LinkedField",
                                                "name": "employment",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "EmploymentSetting",
                                                        "kind": "LinkedField",
                                                        "name": "employmentSetting",
                                                        "plural": false,
                                                        "selections": [
                                                            {
                                                                "alias": null,
                                                                "args": null,
                                                                "kind": "ScalarField",
                                                                "name": "title",
                                                                "storageKey": null
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "TimeReportSummaryByEmploymentAndEvent",
                                                "kind": "LinkedField",
                                                "name": "eventSummaries",
                                                "plural": true,
                                                "selections": [
                                                    (v1 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "isWork",
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "TimeReportSummaryByEmploymentAndEventPartsSummary",
                                                        "kind": "LinkedField",
                                                        "name": "nonRegularPartsSummary",
                                                        "plural": true,
                                                        "selections": [
                                                            (v1 /*: any*/),
                                                            {
                                                                "alias": null,
                                                                "args": [
                                                                    {
                                                                        "kind": "Literal",
                                                                        "name": "unit",
                                                                        "value": "SECOND"
                                                                    }
                                                                ],
                                                                "concreteType": "TimeSpanUnitValue",
                                                                "kind": "LinkedField",
                                                                "name": "duration",
                                                                "plural": false,
                                                                "selections": [
                                                                    (v2 /*: any*/)
                                                                ],
                                                                "storageKey": "duration(unit:\"SECOND\")"
                                                            }
                                                        ],
                                                        "storageKey": null
                                                    },
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "unit",
                                                        "storageKey": null
                                                    },
                                                    (v2 /*: any*/)
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "TimeTrack",
                                        "kind": "LinkedField",
                                        "name": "timeTracks",
                                        "plural": true,
                                        "selections": (v3 /*: any*/),
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "TimeReportAddition",
                                        "kind": "LinkedField",
                                        "name": "additions",
                                        "plural": true,
                                        "selections": (v3 /*: any*/),
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": "timeReports(first:4,order:{\"month\":\"DESC\"})"
            }
        ],
        "type": "TimeReportingUser",
        "abstractKey": null
    };
})();
node.hash = "c605821fa86081978c75179c605ccf84";
export default node;
