import React from 'react';
import { useFragment, graphql, useMutation } from 'react-relay/hooks';
import { InlineColorSelect, getColorFromHex } from '@sonika-se/ui-kit/components';
export const EditEventColor = (props) => {
    const { event, placeholder, disabled } = props;
    const { id, color } = useFragment(graphql `
      fragment EditEventColor on Event {
        id
        color
      }
    `, event);
    const [updateEventColor] = useMutation(graphql `
    mutation EditEventColorMutation($model: UpdateEventRequestInput!) {
      updateEvent(model: $model) {
        eventEdge {
          node {
            id
            color
          }
        }
      }
    }
  `);
    return (<InlineColorSelect defaultValue={getColorFromHex(color)} placeholder={placeholder} disabled={disabled} onChange={(color) => {
            updateEventColor({
                variables: {
                    model: {
                        id,
                        color: color === null || color === void 0 ? void 0 : color.hex,
                    },
                },
                optimisticResponse: {
                    updateEvent: {
                        eventEdge: {
                            node: {
                                id,
                                color: color === null || color === void 0 ? void 0 : color.hex,
                            },
                        },
                    },
                },
            });
        }}/>);
};
export default EditEventColor;
