/**
 * @generated SignedSource<<454978716ea12fc03b37bc17a18a12dc>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserUpcomingScheduleShiftsPanel_schedulableEntity",
    "selections": [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Literal",
                    "name": "first",
                    "value": 5
                },
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "ICanBeUsedToCalculateScheduleTimesUnionTypeConnection",
            "kind": "LinkedField",
            "name": "scheduleItems",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "type": "Node",
                            "abstractKey": "__isNode"
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        },
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "start",
                                    "storageKey": null
                                }
                            ],
                            "type": "ICanBeUsedToCalculateScheduleTimes",
                            "abstractKey": "__isICanBeUsedToCalculateScheduleTimes"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ScheduleShiftCard_scheduleShift"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "SchedulableUser",
    "abstractKey": null
};
node.hash = "54ebe16a1a43971f218699dab14799b6";
export default node;
