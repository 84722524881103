import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { useParams } from 'react-router';
import RolePermissionsPanel from '~/panels/RolePermissionsPanel';
export const RolePermissionsPage = () => {
    const { roleId } = useParams();
    const data = useLazyLoadQuery(graphql `
      query RolePermissionsPageQuery($roleId: ID!) {
        role(id: $roleId) {
          ...RolePermissionsPanel_role
        }
      }
    `, {
        roleId,
    });
    return <RolePermissionsPanel roleRef={data.role}/>;
};
export default RolePermissionsPage;
