import React from 'react';
import { useIntl } from 'react-intl';
import { useFragment, graphql, useMutation } from 'react-relay/hooks';
import { Flexbox, Switch } from '@sonika-se/ui-kit/components';
export const EditEventIsAbsence = (props) => {
    const { eventRef } = props;
    const intl = useIntl();
    const { id, isAbsence } = useFragment(graphql `
      fragment EditEventIsAbsence_event on Event {
        id
        isAbsence
      }
    `, eventRef);
    const [updateEvent, updateEventLoading] = useMutation(graphql `
    mutation EditEventIsAbsenceMutation($model: UpdateEventRequestInput!) {
      updateEvent(model: $model) {
        eventEdge {
          node {
            id
            isAbsence
          }
        }
      }
    }
  `);
    return (<Flexbox align="center">
      <Switch disabled={updateEventLoading} checked={isAbsence} label="Är frånvaro" onChange={() => {
            updateEvent({
                variables: {
                    model: {
                        id,
                        isAbsence: !isAbsence,
                    },
                },
            });
        }}/>
    </Flexbox>);
};
export default EditEventIsAbsence;
