/**
 * @generated SignedSource<<750a73548406708d4c42db11f4fbeb70>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "assetId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "assetId"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AssetProficienciesPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Asset",
                    "kind": "LinkedField",
                    "name": "asset",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "AssetProficienciesPanel_asset"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AssetProficienciesPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Asset",
                    "kind": "LinkedField",
                    "name": "asset",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssetUserProficiencyConnection",
                            "kind": "LinkedField",
                            "name": "proficientUsers",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssetUserProficiency",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "AssignmentType",
                                            "kind": "LinkedField",
                                            "name": "assignmentType",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "title",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "user",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "fullName",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "validateProficiency",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "4d09ca2f968fa800b495cb531047e5f5",
            "id": null,
            "metadata": {},
            "name": "AssetProficienciesPageQuery",
            "operationKind": "query",
            "text": "query AssetProficienciesPageQuery(\n  $assetId: ID!\n) {\n  asset(id: $assetId) {\n    ...AssetProficienciesPanel_asset\n    id\n  }\n}\n\nfragment AssetProficienciesPanel_asset on Asset {\n  proficientUsers {\n    nodes {\n      id\n      assignmentType {\n        id\n        title\n      }\n      user {\n        id\n        fullName\n      }\n    }\n  }\n  id\n  validateProficiency\n}\n"
        }
    };
})();
node.hash = "14cd517ab28fedc26c4989a95f9759ae";
export default node;
