import React, { Suspense, useState } from 'react';
import { graphql } from 'react-relay';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { Skeleton, Button, ModalToSheetSkeleton, ToggleButtonGroup, Flexbox, Text, BasicPage, BasicPageHeader, } from '@sonika-se/ui-kit/components';
import { useViewport } from '@sonika-se/ui-kit/hooks';
import CreateEventModal from './components/CreateEventModal';
import EventTable from './components/EventTable';
import EventAssignmentTypeTable from './components/EventAssignmentTypeTable';
export const systemTextsPageQuery = graphql `
  query EventSettingsPageQuery {
    ...EventTable
    ...EventAssignmentTypeTable
  }
`;
export const EventSettingsPage = () => {
    const data = useLazyLoadQuery(systemTextsPageQuery, {});
    const [createEvent, setCreateEvent] = useState(false);
    const [selectedTable, setSelectedTable] = useState('events');
    const intl = useIntl();
    const { size: pageSize } = useViewport();
    return (<Suspense fallback={<Skeleton type="table" height="100%"/>}>
      <BasicPage width="large">
        <BasicPageHeader title={intl.formatMessage({ defaultMessage: 'Händelser', id: 'general.misc.event.name.plural' })} action={<Button size={pageSize === 'small' ? 'small' : 'medium'} onClick={() => {
                setCreateEvent(true);
            }}>
              <FormattedMessage id="general.actions.create" defaultMessage="Lägg till"/>
            </Button>}/>
        <Flexbox direction="column" gap="xsmall" align="start">
          <Text size="small">Gruppera utifrån</Text>
          <ToggleButtonGroup defaultValue={'events'} type="radio" width="auto" inputName="table_type" onChange={(event) => {
            var _a;
            setSelectedTable((_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value);
        }} options={[
            { label: 'Händelse', value: 'events' },
            { label: 'Roll', value: 'assignmentTypes' },
        ]}/>
        </Flexbox>
        {selectedTable === 'events' && <EventTable queryRef={data}/>}
        {selectedTable === 'assignmentTypes' && <EventAssignmentTypeTable queryRef={data}/>}
      </BasicPage>
      <Suspense fallback={<ModalToSheetSkeleton type="form"/>}>
        <CreateEventModal open={createEvent} onCancel={() => setCreateEvent(false)}/>
      </Suspense>
    </Suspense>);
};
export default EventSettingsPage;
