/**
 * @generated SignedSource<<e3f5187398605c6d4320b70055a9aef4>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "PrivateRoutes_queryQuery",
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "PrivateRoutes_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "PrivateRoutes_queryQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CurrentUser",
                    "kind": "LinkedField",
                    "name": "currentUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "me",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Avatar",
                                    "kind": "LinkedField",
                                    "name": "avatar",
                                    "plural": false,
                                    "selections": [
                                        (v0 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": [
                                                {
                                                    "kind": "Literal",
                                                    "name": "queryTransform",
                                                    "value": "width=68&height=68&mode=crop&scale=both"
                                                }
                                            ],
                                            "kind": "ScalarField",
                                            "name": "url",
                                            "storageKey": "url(queryTransform:\"width=68&height=68&mode=crop&scale=both\")"
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "color",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "firstname",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "lastname",
                                    "storageKey": null
                                },
                                (v0 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "permissions",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "services",
                            "storageKey": null
                        },
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "ec15add6ed81ef279f492fa2ed74db56",
            "id": null,
            "metadata": {},
            "name": "PrivateRoutes_queryQuery",
            "operationKind": "query",
            "text": "query PrivateRoutes_queryQuery {\n  ...PrivateRoutes_query\n}\n\nfragment PrivateRoutes_query on Query {\n  currentUser {\n    me {\n      avatar {\n        id\n        url(queryTransform: \"width=68&height=68&mode=crop&scale=both\")\n      }\n      color\n      firstname\n      lastname\n      id\n    }\n    permissions\n    services\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "014975e3ef18d6b8ab2d52e8f7bbd145";
export default node;
