/**
 * @generated SignedSource<<cadc2f809a8c7db8e6da8b30ed79792e>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "userId"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserDetailsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserActionsPanel_user"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserSidebar_user"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserAssignmentTypesPanel_user"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserContactPanel_user"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserExternalContactsPanel_user"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserHomeAddressPanel_user"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserPersonalInfoPanel_user"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserServicesPanel_user"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserDetailsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hidden",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserInvitation",
                            "kind": "LinkedField",
                            "name": "invitation",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "created",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "accepted",
                                    "storageKey": null
                                },
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isCompanyAdmin",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SchedulableUser",
                            "kind": "LinkedField",
                            "name": "schedulableEntity",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "schedulable",
                                    "storageKey": null
                                },
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "services",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "TimeReportingUser",
                            "kind": "LinkedField",
                            "name": "timeReportingUser",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "active",
                                    "storageKey": null
                                },
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssignmentTypeConnection",
                            "kind": "LinkedField",
                            "name": "assignmentTypes",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssignmentType",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "phone",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "externalContacts",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserHomeAddress",
                            "kind": "LinkedField",
                            "name": "homeAddress",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "careOf",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "city",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "postOfficeBox",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "postalCode",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "street",
                                    "storageKey": null
                                },
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "employeeId",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstname",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastname",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "personnummer",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "employmentDate",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "employmentEndDate",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Company",
                            "kind": "LinkedField",
                            "name": "company",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "activeServices",
                                    "storageKey": null
                                },
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "5260cb36be1cd4e8b92ebb6e40143d20",
            "id": null,
            "metadata": {},
            "name": "UserDetailsPageQuery",
            "operationKind": "query",
            "text": "query UserDetailsPageQuery(\n  $userId: ID!\n) {\n  user(id: $userId) {\n    ...UserActionsPanel_user\n    ...UserSidebar_user\n    ...UserAssignmentTypesPanel_user\n    ...UserContactPanel_user\n    ...UserExternalContactsPanel_user\n    ...UserHomeAddressPanel_user\n    ...UserPersonalInfoPanel_user\n    ...UserServicesPanel_user\n    id\n  }\n}\n\nfragment UserActionsPanel_user on User {\n  id\n  status\n}\n\nfragment UserAssignmentTypesPanel_user on User {\n  assignmentTypes {\n    nodes {\n      id\n      title\n    }\n  }\n  id\n}\n\nfragment UserContactPanel_user on User {\n  email\n  id\n  phone\n}\n\nfragment UserExternalContactsPanel_user on User {\n  externalContacts\n  id\n}\n\nfragment UserHomeAddressPanel_user on User {\n  id\n  homeAddress {\n    careOf\n    city\n    postOfficeBox\n    postalCode\n    street\n    id\n  }\n}\n\nfragment UserPersonalInfoPanel_user on User {\n  employeeId\n  firstname\n  id\n  lastname\n  personnummer\n  employmentDate\n  employmentEndDate\n}\n\nfragment UserServicesPanel_user on User {\n  services\n  company {\n    activeServices\n    id\n  }\n}\n\nfragment UserSidebar_user on User {\n  hidden\n  id\n  invitation {\n    created\n    accepted\n    id\n  }\n  isCompanyAdmin\n  schedulableEntity {\n    schedulable\n    id\n  }\n  services\n  timeReportingUser {\n    active\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "d47e7a3ced45cd72d45ecc9d3a90bd81";
export default node;
