import React, { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { serviceIcons } from '@sonika-se/ui-kit';
import { Flexbox } from '@sonika-se/ui-kit/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, object, string } from 'yup';
import ServiceToggleButton from './ServiceToggleButton';
import { graphql, useFragment } from 'react-relay/hooks';
const validationSchema = object({
    services: array().of(string()).min(1),
});
export const SignupWizardServicesForm = (props) => {
    const { defaultValues, formId, onSubmit, queryRef } = props;
    const data = useFragment(graphql `
      fragment SignupWizardServicesFormServices_query on Query {
        services {
          value
          name
          description
        }
      }
    `, queryRef);
    const services = useMemo(() => data.services.map((x) => ({
        icon: serviceIcons[x.value],
        id: x.value,
        name: x.name,
        description: x.description,
    })), [data]);
    const { control, handleSubmit } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });
    return (<form id={formId} onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Controller control={control} name="services" render={({ field }) => (<Flexbox direction="column" gap="small">
            {services.map((service) => (<ServiceToggleButton disabled defaultValue={field.value.includes(service.id)} key={service.id} icon={service.icon} onChange={(value) => {
                    if (value) {
                        field.onChange([...field.value, service.id]);
                    }
                    else {
                        field.onChange([...field.value.filter((value) => value !== service.id)]);
                    }
                }} text={service.description} title={service.name}/>))}
          </Flexbox>)}/>
    </form>);
};
export default SignupWizardServicesForm;
