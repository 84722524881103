import React, { useCallback, useRef } from 'react';
import { graphql, useMutation, usePreloadedQuery } from 'react-relay/hooks';
import { FormModal } from '@sonika-se/ui-kit/components';
import AssignmentTypeStatusForm from '~/forms/AssignmentTypeStatusForm';
export const assignmentTypeStatusUpdateModalQuery = graphql `
  query AssignmentTypeStatusUpdateModalQuery($assignmentTypeId: ID!) {
    assignmentType(id: $assignmentTypeId) {
      disabledDate
      id
    }
  }
`;
export const AssignmentTypeStatusUpdateModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const { assignmentType } = usePreloadedQuery(assignmentTypeStatusUpdateModalQuery, preloadedQuery);
    const [updateAssignmentType, updateAssignmentTypeLoading] = useMutation(graphql `
        mutation AssignmentTypeStatusUpdateModal_updateAssignmentTypeMutation(
          $model: UpdateAssignmentTypeRequestInput!
        ) {
          updateAssignmentType(model: $model) {
            assignmentTypeEdge {
              node {
                disabledDate
              }
            }
          }
        }
      `);
    const formRef = useRef(null);
    const handleSubmit = useCallback((form) => {
        updateAssignmentType({
            onCompleted: () => {
                onClose();
            },
            variables: {
                model: {
                    assignmentTypeId: assignmentType.id,
                    disabled: !form.active,
                },
            },
        });
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Spara" disabled={updateAssignmentTypeLoading} title="Ändra status">
      <AssignmentTypeStatusForm defaultValues={{
            active: assignmentType.disabledDate === null,
        }} disabled={updateAssignmentTypeLoading} onSubmit={handleSubmit} ref={formRef}/>
    </FormModal>);
};
export default AssignmentTypeStatusUpdateModal;
