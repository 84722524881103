import React, { useEffect, useRef, useState, useTransition } from 'react';
import { graphql, usePaginationFragment, usePreloadedQuery } from 'react-relay/hooks';
import { SelectModal, Text } from '@sonika-se/ui-kit/components';
export const roleSelectModalQuery = graphql `
  query RoleSelectModalQuery($where: RoleFilterInput) {
    ...RoleSelectModal_query @arguments(where: $where)
  }
`;
export const RoleSelectModal = (props) => {
    const { defaultWhere, onClose, onSelect, preloadedQuery, selectButtonLabel, title = 'Välj grupp' } = props;
    const query = usePreloadedQuery(roleSelectModalQuery, preloadedQuery);
    const [where, setWhere] = useState(defaultWhere);
    const { data: { roles }, hasNext, isLoadingNext, loadNext, refetch, } = usePaginationFragment(graphql `
      fragment RoleSelectModal_query on Query
      @argumentDefinitions(
        after: { type: "String" }
        first: { type: "Int", defaultValue: 50 }
        where: { type: "RoleFilterInput" }
      )
      @refetchable(queryName: "RoleSelectModal_queryQuery") {
        roles(after: $after, first: $first, where: $where) @connection(key: "RoleSelectModal_query_roles") {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `, query);
    const [isTransitioning, startTransition] = useTransition();
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        startTransition(() => {
            refetch({
                where,
            }, {
                fetchPolicy: 'network-only',
            });
        });
    }, [where]);
    return (<SelectModal hasNext={hasNext} isLoadingNext={isLoadingNext} isSearching={isTransitioning} items={roles.edges.map(({ node }) => node)} loadNext={() => {
            loadNext(50);
        }} onClose={() => {
            onClose === null || onClose === void 0 ? void 0 : onClose();
        }} onSelect={(roles) => {
            onSelect === null || onSelect === void 0 ? void 0 : onSelect(roles.map(({ id }) => id));
        }} renderItem={(role) => <Text>{role.name}</Text>} selectButtonLabel={selectButtonLabel} title={title}/>);
};
export default RoleSelectModal;
