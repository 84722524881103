import React, { Suspense, useState } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { Card, CardContent, Flexbox, Panel, Skeleton } from '@sonika-se/ui-kit/components';
import ColleagueTable from '~/tables/ColleagueTable';
import ColleagueTableQueryForm from '~/forms/ColleagueTableQueryForm';
export const ColleagueTablePanel = (props) => {
    const { queryRef } = props;
    const data = useFragment(graphql `
      fragment ColleagueTablePanel_query on Query @argumentDefinitions(filter: { type: "ColleaguesFilterInput" }) {
        ...ColleagueTable_query @arguments(filter: $filter)
      }
    `, queryRef);
    const [colleagueTableProps, setColleagueTableProps] = useState();
    return (<Panel>
      <Flexbox direction="column" gap="small">
        <Card background="gray" shadow="none">
          <CardContent>
            <ColleagueTableQueryForm onSubmit={(query) => {
            const { search } = query;
            setColleagueTableProps({
                filter: {
                    search,
                },
            });
        }}/>
          </CardContent>
        </Card>
        <Suspense fallback={<Skeleton type="table"/>}>
          <ColleagueTable queryRef={data} {...colleagueTableProps}/>
        </Suspense>
      </Flexbox>
    </Panel>);
};
export default ColleagueTablePanel;
