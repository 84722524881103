import React, { Suspense } from 'react';
import { MarkdownContent, ModalSkeleton, Panel, PanelButton, Placeholder } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import UserExternalContactsUpdateModal, { userExternalContactsUpdateModalQuery, } from '~/modals/UserExternalContactsUpdateModal';
export const UserExternalContactsPanel = (props) => {
    const { userRef } = props;
    const user = useFragment(graphql `
      fragment UserExternalContactsPanel_user on User {
        externalContacts
        id
      }
    `, userRef);
    const [userExternalContactsUpdateModalRef, loadUserExternalContactsUpdateModal, disposeUserExternalContactsUpdateModal,] = useQueryLoader(userExternalContactsUpdateModalQuery);
    return (<>
      <Panel title="Externa kontakter" actions={<PanelButton onClick={() => loadUserExternalContactsUpdateModal({ userId: user.id })}>Ändra</PanelButton>}>
        {user.externalContacts ? (<MarkdownContent>{user.externalContacts}</MarkdownContent>) : (<Placeholder height="full" text="Inga externa kontakter."/>)}
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {userExternalContactsUpdateModalRef && (<UserExternalContactsUpdateModal onClose={() => {
                disposeUserExternalContactsUpdateModal();
            }} preloadedQuery={userExternalContactsUpdateModalRef}/>)}
      </Suspense>
    </>);
};
export default UserExternalContactsPanel;
