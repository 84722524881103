import React, { useCallback, useEffect, useRef, useState } from 'react';
import { graphql, usePaginationFragment, usePreloadedQuery } from 'react-relay/hooks';
import { Flexbox, FormModal, Notification } from '@sonika-se/ui-kit/components';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import downloadCsv from '~/utils/downloadCsv';
import ExportForm from '~/forms/ExportForm';
import generateCsv from '~/utils/generateCsv';
import ProgressBar from '~/components/ProgressBar';
export const userExportModalQuery = graphql `
  query UserExportModalQuery($filter: UsersFilterInput, $where: UserFilterInput) {
    ...UserExportModal_query @arguments(filter: $filter, where: $where)
  }
`;
const selectableFields = [
    {
        title: 'Allmänt',
        fields: [
            {
                label: 'Förnamn',
                name: 'firstname',
                value: (user) => user.firstname,
            },
            {
                label: 'Efternamn',
                name: 'lastname',
                value: (user) => user.lastname,
            },
            {
                label: 'Personnummer',
                name: 'personnummer',
                value: (user) => user.personnummer,
            },
            {
                label: 'Anställningsnr.',
                name: 'employeeId',
                value: (user) => user.employeeId,
            },
            {
                label: 'Telefon',
                name: 'phone',
                value: (user) => user.phone,
            },
            {
                label: 'E-post',
                name: 'email',
                value: (user) => user.email,
            },
            {
                label: 'Roller',
                name: 'assignmentTypes',
                value: (user) => user.assignmentTypes.nodes.map((assignmentType) => assignmentType.title).join(', '),
            },
        ],
    },
    {
        title: 'Hemadress',
        fields: [
            {
                label: 'c/o',
                name: 'careOf',
                value: (user) => { var _a; return (_a = user.homeAddress) === null || _a === void 0 ? void 0 : _a.careOf; },
            },
            {
                label: 'Postbox',
                name: 'postOfficeBox',
                value: (user) => { var _a; return (_a = user.homeAddress) === null || _a === void 0 ? void 0 : _a.postOfficeBox; },
            },
            {
                label: 'Gatuadress',
                name: 'street',
                value: (user) => { var _a; return (_a = user.homeAddress) === null || _a === void 0 ? void 0 : _a.street; },
            },
            {
                label: 'Postnummer',
                name: 'postalCode',
                value: (user) => { var _a; return (_a = user.homeAddress) === null || _a === void 0 ? void 0 : _a.postalCode; },
            },
            {
                label: 'Stad',
                name: 'city',
                value: (user) => { var _a; return (_a = user.homeAddress) === null || _a === void 0 ? void 0 : _a.city; },
            },
        ],
    },
    {
        title: 'Bankuppgifter',
        fields: [
            {
                label: 'Bank',
                name: 'bankName',
                value: (user) => { var _a; return (_a = user.bankAccount) === null || _a === void 0 ? void 0 : _a.bankName; },
            },
            {
                label: 'Clearingnummer',
                name: 'clearingNumber',
                value: (user) => { var _a; return (_a = user.bankAccount) === null || _a === void 0 ? void 0 : _a.clearingNumber; },
            },
            {
                label: 'Kontonummer',
                name: 'accountNumber',
                value: (user) => { var _a; return (_a = user.bankAccount) === null || _a === void 0 ? void 0 : _a.accountNumber; },
            },
        ],
    },
];
export const UserExportModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const query = usePreloadedQuery(userExportModalQuery, preloadedQuery);
    const { data, hasNext, loadNext } = usePaginationFragment(graphql `
      fragment UserExportModal_query on Query
      @argumentDefinitions(
        after: { type: "String" }
        filter: { type: "UsersFilterInput" }
        first: { type: "Int", defaultValue: 50 }
        where: { type: "UserFilterInput" }
      )
      @refetchable(queryName: "UserExportModalPaginationQuery") {
        users(after: $after, filter: $filter, first: $first, where: $where)
          @connection(key: "UserExportModal_query_users", filters: []) {
          edges {
            node {
              assignmentTypes {
                nodes {
                  title
                }
              }
              bankAccount {
                accountNumber
                bankName
                clearingNumber
              }
              email
              employeeId
              firstname
              homeAddress {
                careOf
                city
                postOfficeBox
                postalCode
                street
              }
              lastname
              personnummer
              phone
            }
          }
          totalCount
        }
      }
    `, query);
    const formRef = useRef(null);
    const [exportUsers, setExportUsers] = useState();
    const [progress, setProgress] = useState(0);
    useEffect(() => {
        if (exportUsers) {
            setProgress(data.users.edges.length / data.users.totalCount);
            if (hasNext) {
                loadNext(50);
            }
            else {
                const csv = generateCsv(exportUsers.map((field) => field.label), data.users.edges.map(({ node }) => exportUsers.map((field) => { var _a; return (_a = field.value) === null || _a === void 0 ? void 0 : _a.call(field, node); })));
                downloadCsv(csv, 'users.csv');
                setExportUsers(undefined);
            }
        }
    }, [data, exportUsers, hasNext]);
    const handleSubmit = useCallback((form) => {
        const selectableFieldsFlattened = selectableFields.map((group) => group.fields).flat();
        setExportUsers(selectableFieldsFlattened.filter((field) => form.fields.includes(field.name)));
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Exportera" disabled={!!exportUsers} title="Exportera användare">
      <Flexbox direction="column" gap="large">
        <Notification color={sonikaTheme.colors.system.wait} shadow={false} title="Exporterar lista med användare till fil i CSV-format"/>
        {!!exportUsers && <ProgressBar progress={progress}/>}
        <ExportForm selectableFields={selectableFields} defaultValues={{
            fields: [],
        }} onSubmit={handleSubmit} ref={formRef}/>
      </Flexbox>
    </FormModal>);
};
export default UserExportModal;
