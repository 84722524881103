import React, { Suspense, useState } from 'react';
import { graphql } from 'react-relay';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { Flexbox, FormSection, Divider, H6, Skeleton, BasicPage, KeyValue, H2, IconButton, Tooltip, ModalSkeleton, } from '@sonika-se/ui-kit/components';
import EditCompanyAvatar from '~/components/InlineEditFields/Company/EditCompanyAvatar';
import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import EditCompanySettingsModal from '~/modals/EditCompanySettingsModal';
export const generalSettingsPageQuery = graphql `
  query SystemSettingsPageQuery {
    currentCompany {
      id
      name
      administrationEmail
      organizationNumber
      ...EditCompanyAvatar
    }
  }
`;
export const SystemSettingsPage = () => {
    const data = useLazyLoadQuery(generalSettingsPageQuery, {});
    const [showEditCompanySettingsModal, setShowEditCompanySettingsModal] = useState(false);
    return (<>
      <Suspense fallback={<Skeleton type="table" height="100%"/>}>
        <BasicPage width="large">
          <Flexbox direction="column" gap="xlarge" style={{ marginTop: '3rem' }}>
            <Flexbox direction="row" justify="space">
              <H6>Företagsinställningar</H6>
              <Tooltip content="Redigera">
                <IconButton icon={faEdit} color="primary" appearance="text" onClick={() => setShowEditCompanySettingsModal(true)}/>
              </Tooltip>
            </Flexbox>
            <Flexbox gap="medium" align="center">
              <EditCompanyAvatar company={data === null || data === void 0 ? void 0 : data.currentCompany} editable size="xlarge"/>
              <H2>{data === null || data === void 0 ? void 0 : data.currentCompany.name}</H2>
            </Flexbox>
            <Flexbox direction="column" gap="medium">
              <Divider />
              <FormSection columns="1fr 3fr">
                <KeyValue label="Administratör e-post">{data === null || data === void 0 ? void 0 : data.currentCompany.administrationEmail}</KeyValue>
                <KeyValue label="Organisationsnummer">{data === null || data === void 0 ? void 0 : data.currentCompany.organizationNumber}</KeyValue>
              </FormSection>
              <Divider />
            </Flexbox>
          </Flexbox>
        </BasicPage>
      </Suspense>
      <Suspense fallback={<ModalSkeleton type="loader"/>}>
        {showEditCompanySettingsModal && (<EditCompanySettingsModal onClose={() => {
                setShowEditCompanySettingsModal(false);
            }} onDismiss={() => {
                setShowEditCompanySettingsModal(false);
            }}/>)}
      </Suspense>
    </>);
};
export default SystemSettingsPage;
