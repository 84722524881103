import React from 'react';
import { Flexbox, Text } from '@sonika-se/ui-kit/components';
import { useEnvironment } from '@sonika-se/ui-kit/hooks';
import { graphql, useFragment } from 'react-relay/hooks';
import Avatar from '../Avatar';
export const UserAssignmentTypeSnippet = (props) => {
    const { avatarSize = 'small', avatarVisible = true, className, linkDisabled = false, nameVisible = true, style, userRef, assignmentType, } = props;
    const user = useFragment(graphql `
      fragment UserAssignmentTypeSnippet_user on User {
        id
        fullName
        ...Avatar_iHaveAvatar
      }
    `, userRef);
    const { frontUrls } = useEnvironment();
    return (<Flexbox align="center" className={className} gap="small" style={style}>
      {avatarVisible && <Avatar iHaveAvatarRef={user} size={avatarSize}/>}
      {nameVisible && (<Flexbox direction="row" justify="space" style={{ flex: '1' }}>
          {linkDisabled ? (<Text bold lineHeight="normal">{`${user.fullName}`}</Text>) : (<a href={`${frontUrls['CORE']}users/${user.id}`}>
              <Text bold lineHeight="normal">{`${user.fullName} - ${assignmentType.title}`}</Text>
            </a>)}
          <Text lineHeight="normal">{`${assignmentType.title}`}</Text>
        </Flexbox>)}
    </Flexbox>);
};
export default UserAssignmentTypeSnippet;
