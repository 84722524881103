import React from 'react';
import { graphql, useMutation, usePreloadedQuery } from 'react-relay/hooks';
import { Field, Form, ModalToSheet, ModalToSheetContent, ModalToSheetHeader, Switch, } from '@sonika-se/ui-kit/components';
export const userServicesUpdateModalQuery = graphql `
  query UserServicesUpdateModalQuery($userId: ID!) {
    user(id: $userId) {
      id
      services
    }
    currentCompany {
      activeServices
    }
    services {
      value
      name
    }
  }
`;
export const UserServicesUpdateModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const { user, currentCompany, services: allServices, } = usePreloadedQuery(userServicesUpdateModalQuery, preloadedQuery);
    const [setUserServices, setUserServicesLoading] = useMutation(graphql `
      mutation UserServicesUpdateModal_setUserServicesMutation($model: SetUserServicesInput!) {
        setUserServices(model: $model) {
          user {
            ...UserServicesPanel_user
          }
        }
      }
    `);
    return (<ModalToSheet onClose={() => onClose()} size="small">
      <ModalToSheetHeader onClose={() => onClose()}>Ändra tjänster</ModalToSheetHeader>
      <ModalToSheetContent>
        <Form>
          {currentCompany.activeServices
            .map((service) => service)
            .sort()
            .map((service) => {
            var _a, _b;
            return (<Field direction="row-reverse" key={service} label={(_b = (_a = allServices.find((x) => x.value == service)) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : service.toString()}>
                <Switch defaultChecked={Boolean(user.services.find((s) => s === service))} disabled={setUserServicesLoading} onChange={(event) => {
                    setUserServices({
                        variables: {
                            model: {
                                id: user.id,
                                services: event.target.checked
                                    ? [...user.services, service]
                                    : user.services.filter((s) => s !== service),
                            },
                        },
                    });
                }}/>
              </Field>);
        })}
        </Form>
      </ModalToSheetContent>
    </ModalToSheet>);
};
export default UserServicesUpdateModal;
