import React, { useState } from 'react';
import { graphql } from 'react-relay';
import { useLazyLoadQuery } from 'react-relay/hooks';
import { BasicPage, BasicPageHeader, Button, Card, CardContent, Flexbox, Icon, Text, } from '@sonika-se/ui-kit/components';
import { faComments } from '@fortawesome/pro-regular-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import FaqPostList from './components/FaqPostList';
import NewFaqPostModal from './components/NewFaqPostModal';
export const faqPageQuery = graphql `
  query FaqPageQuery {
    ...FaqPostList_query
    currentCompany {
      administrationEmail
    }
  }
`;
export const FaqPage = () => {
    var _a, _b, _c;
    const intl = useIntl();
    const data = useLazyLoadQuery(faqPageQuery, {});
    const [newFaqPostModalVisible, setNewFaqPostModalVisible] = useState(false);
    const hasPermissions = usePermissions();
    return (<>
      <BasicPage width="large">
        <BasicPageHeader action={hasPermissions(['EDIT_FAQ']) && (<Button color="secondary" onClick={() => {
                setNewFaqPostModalVisible(true);
            }}>
                <FormattedMessage id="general.misc.faq.actions.create" defaultMessage="Ny fråga"/>
              </Button>)} title={intl.formatMessage({ defaultMessage: 'Kunskapsbank', id: 'general.misc.faq.name.singular' })}/>
        <Flexbox direction="column" gap="large">
          {((_a = data.currentCompany) === null || _a === void 0 ? void 0 : _a.administrationEmail) && (<Card>
              <CardContent padding="large">
                <Flexbox align="center" gap="medium">
                  <Icon color="#0C2AF3" icon={faComments} style={{ fontSize: '2rem' }}/>
                  <Text lineHeight="onehalf" style={{ flex: 1 }}>
                    <FormattedMessage id="FaqPage.description" defaultMessage="Ställ frågor om systemet till {emailTo}" values={{
                emailTo: (<a href={`mailto:${(_b = data.currentCompany) === null || _b === void 0 ? void 0 : _b.administrationEmail}`} style={{ color: '#0C2AF3', textDecoration: 'none' }}>
                            {(_c = data.currentCompany) === null || _c === void 0 ? void 0 : _c.administrationEmail}
                          </a>),
            }}/>
                  </Text>
                </Flexbox>
              </CardContent>
            </Card>)}
          <FaqPostList editable={true} queryRef={data}/>
        </Flexbox>
      </BasicPage>
      {newFaqPostModalVisible && (<NewFaqPostModal onClose={() => {
                setNewFaqPostModalVisible(false);
            }}/>)}
    </>);
};
export default FaqPage;
