import React, { useCallback, useEffect, useRef, useState } from 'react';
import { graphql, usePaginationFragment, usePreloadedQuery } from 'react-relay/hooks';
import { Flexbox, FormModal, Notification } from '@sonika-se/ui-kit/components';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import downloadCsv from '~/utils/downloadCsv';
import ExportForm from '~/forms/ExportForm';
import generateCsv from '~/utils/generateCsv';
import ProgressBar from '~/components/ProgressBar';
export const assetExportModalQuery = graphql `
  query AssetExportModalQuery($filter: AssetsFilterInput, $where: AssetFilterInput) {
    ...AssetExportModal_query @arguments(filter: $filter, where: $where)
  }
`;
const selectableFields = [
    {
        title: 'Allmänt',
        fields: [
            {
                label: 'Namn',
                name: 'name',
                value: (asset) => asset.name,
            },
            {
                label: 'Nummer',
                name: 'assetNumber',
                value: (asset) => asset.assetNumber,
            },
            {
                label: 'Roller',
                name: 'assignmentTypes',
                value: (asset) => asset.assignmentTypes.nodes.map((assignmentType) => assignmentType.title).join(', '),
            },
        ],
    },
    {
        title: 'Adress',
        fields: [
            {
                label: 'Gatuadress',
                name: 'street',
                value: (asset) => asset.street,
            },
            {
                label: 'Postnummer',
                name: 'postalCode',
                value: (asset) => asset.postalCode,
            },
            {
                label: 'Stad',
                name: 'city',
                value: (asset) => asset.city,
            },
        ],
    },
];
export const AssetExportModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const query = usePreloadedQuery(assetExportModalQuery, preloadedQuery);
    const { data, hasNext, loadNext } = usePaginationFragment(graphql `
      fragment AssetExportModal_query on Query
      @argumentDefinitions(
        after: { type: "String" }
        filter: { type: "AssetsFilterInput" }
        first: { type: "Int", defaultValue: 50 }
        where: { type: "AssetFilterInput" }
      )
      @refetchable(queryName: "AssetExportModalPaginationQuery") {
        assets(after: $after, filter: $filter, first: $first, where: $where)
          @connection(key: "AssetExportModal_query_assets", filters: []) {
          edges {
            node {
              assetNumber
              assignmentTypes {
                nodes {
                  title
                }
              }
              city
              postalCode
              street
              name
            }
          }
          totalCount
        }
      }
    `, query);
    const formRef = useRef(null);
    const [exportAssets, setExportAssets] = useState();
    const [progress, setProgress] = useState(0);
    useEffect(() => {
        if (exportAssets) {
            setProgress(data.assets.edges.length / data.assets.totalCount);
            if (hasNext) {
                loadNext(50);
            }
            else {
                const csv = generateCsv(exportAssets.map((field) => field.label), data.assets.edges.map(({ node }) => exportAssets.map((field) => { var _a; return (_a = field.value) === null || _a === void 0 ? void 0 : _a.call(field, node); })));
                downloadCsv(csv, 'assets.csv');
                setExportAssets(undefined);
            }
        }
    }, [data, exportAssets, hasNext]);
    const handleSubmit = useCallback((form) => {
        const selectableFieldsFlattened = selectableFields.map((group) => group.fields).flat();
        setExportAssets(selectableFieldsFlattened.filter((field) => form.fields.includes(field.name)));
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Exportera" disabled={!!exportAssets} title="Exportera enheter">
      <Flexbox direction="column" gap="large">
        <Notification color={sonikaTheme.colors.system.wait} shadow={false} title="Exporterar lista med enheter till fil i CSV-format"/>
        {!!exportAssets && <ProgressBar progress={progress}/>}
        <ExportForm selectableFields={selectableFields} defaultValues={{
            fields: [],
        }} onSubmit={handleSubmit} ref={formRef}/>
      </Flexbox>
    </FormModal>);
};
export default AssetExportModal;
