/**
 * @generated SignedSource<<d97dcacf2b3714ce07c65cb31545a34e>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "activeServices",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "ServiceIndexPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "currentCompany",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ServiceInfo",
                    "kind": "LinkedField",
                    "name": "services",
                    "plural": true,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ServiceCard_serviceInfo"
                        },
                        (v1 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "ServiceIndexPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "currentCompany",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ServiceInfo",
                    "kind": "LinkedField",
                    "name": "services",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                        },
                        (v1 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "1787659dc6dcd985518f4d6d9fab7285",
            "id": null,
            "metadata": {},
            "name": "ServiceIndexPageQuery",
            "operationKind": "query",
            "text": "query ServiceIndexPageQuery {\n  currentCompany {\n    activeServices\n    id\n  }\n  services {\n    ...ServiceCard_serviceInfo\n    value\n  }\n}\n\nfragment ServiceCard_serviceInfo on ServiceInfo {\n  description\n  name\n  value\n}\n"
        }
    };
})();
node.hash = "47fdea2dd1cbde6887d835f414ff4bb1";
export default node;
