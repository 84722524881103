import React, { useEffect, useRef, useState, useTransition } from 'react';
import { graphql, usePaginationFragment, usePreloadedQuery } from 'react-relay/hooks';
import { SelectModal } from '@sonika-se/ui-kit/components';
import UserSnippet from '../snippets/UserSnippet';
export const userSelectModalQuery = graphql `
  query UserSelectModalQuery($filter: UsersFilterInput, $where: UserFilterInput) {
    ...UserSelectModal_query @arguments(filter: $filter, where: $where)
  }
`;
export const UserSelectModal = (props) => {
    const { defaultFilter, defaultWhere, onClose, onSelect, preloadedQuery, selectButtonLabel, title = 'Välj användare', } = props;
    const query = usePreloadedQuery(userSelectModalQuery, preloadedQuery);
    const [filter, setFilter] = useState(defaultFilter);
    const [where, setWhere] = useState(defaultWhere);
    const { data: { users }, hasNext, isLoadingNext, loadNext, refetch, } = usePaginationFragment(graphql `
      fragment UserSelectModal_query on Query
      @argumentDefinitions(
        after: { type: "String" }
        filter: { type: "UsersFilterInput" }
        first: { type: "Int", defaultValue: 50 }
        where: { type: "UserFilterInput" }
      )
      @refetchable(queryName: "UserSelectModal_queryQuery") {
        users(after: $after, filter: $filter, first: $first, where: $where)
          @connection(key: "UserSelectModal_query_users") {
          edges {
            node {
              ...UserSnippet_user
              id
            }
          }
        }
      }
    `, query);
    const [isTransitioning, startTransition] = useTransition();
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        startTransition(() => {
            refetch({
                filter,
                where,
            }, {
                fetchPolicy: 'network-only',
            });
        });
    }, [filter, where]);
    return (<SelectModal hasNext={hasNext} isLoadingNext={isLoadingNext} isSearching={isTransitioning} items={users.edges.map(({ node }) => node)} loadNext={() => {
            loadNext(50);
        }} onClose={() => {
            onClose === null || onClose === void 0 ? void 0 : onClose();
        }} onSearch={(search) => {
            setFilter(Object.assign(Object.assign({}, defaultFilter), { search }));
        }} onSelect={(users) => {
            onSelect === null || onSelect === void 0 ? void 0 : onSelect(users.map(({ id }) => id));
        }} renderItem={(user) => <UserSnippet linkDisabled userRef={user}/>} selectButtonLabel={selectButtonLabel} title={title}/>);
};
export default UserSelectModal;
