/**
 * @generated SignedSource<<66a25994761e60b38cdba95f8251d131>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isAddition",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isCompensatoryTime",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isAbsence",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isAdditionAvailableInTimeReport",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isUnsocial",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isCallHour",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdateEventModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Event",
                    "kind": "LinkedField",
                    "name": "event",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditEventName"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditEventColor"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditEventStatus"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditEventAssignmentTypes"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditEventIsAddition_event"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditEventIsWork_event"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditEventIsUnsocial_event"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditEventIsAbsence_event"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditEventIsAssigneeReplacementReason_event"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditEventAdditionUnit_event"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditEventIsCompensatoryTime_event"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditEventAbsenceType_event"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "EditIsAdditionAvailableInTimeReport_event"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpdateEventModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Event",
                    "kind": "LinkedField",
                    "name": "event",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "color",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "disabled",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssignmentType",
                            "kind": "LinkedField",
                            "name": "assignmentTypes",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "title",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isWork",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isAssigneeReplacementReason",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "additionUnit",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "absenceType",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "6b7509494def66a33d11807792b1bc70",
            "id": null,
            "metadata": {},
            "name": "UpdateEventModalQuery",
            "operationKind": "query",
            "text": "query UpdateEventModalQuery(\n  $id: ID!\n) {\n  event(id: $id) {\n    id\n    isAddition\n    isCompensatoryTime\n    isAbsence\n    isAdditionAvailableInTimeReport\n    isUnsocial\n    isCallHour\n    name\n    ...EditEventName\n    ...EditEventColor\n    ...EditEventStatus\n    ...EditEventAssignmentTypes\n    ...EditEventIsAddition_event\n    ...EditEventIsWork_event\n    ...EditEventIsUnsocial_event\n    ...EditEventIsAbsence_event\n    ...EditEventIsAssigneeReplacementReason_event\n    ...EditEventAdditionUnit_event\n    ...EditEventIsCompensatoryTime_event\n    ...EditEventAbsenceType_event\n    ...EditIsAdditionAvailableInTimeReport_event\n  }\n}\n\nfragment EditEventAbsenceType_event on Event {\n  id\n  absenceType\n}\n\nfragment EditEventAdditionUnit_event on Event {\n  id\n  additionUnit\n}\n\nfragment EditEventAssignmentTypes on Event {\n  id\n  assignmentTypes {\n    id\n    title\n  }\n}\n\nfragment EditEventColor on Event {\n  id\n  color\n}\n\nfragment EditEventIsAbsence_event on Event {\n  id\n  isAbsence\n}\n\nfragment EditEventIsAddition_event on Event {\n  id\n  isAddition\n}\n\nfragment EditEventIsAssigneeReplacementReason_event on Event {\n  id\n  isAssigneeReplacementReason\n}\n\nfragment EditEventIsCompensatoryTime_event on Event {\n  id\n  isCompensatoryTime\n}\n\nfragment EditEventIsUnsocial_event on Event {\n  id\n  isUnsocial\n}\n\nfragment EditEventIsWork_event on Event {\n  id\n  isWork\n}\n\nfragment EditEventName on Event {\n  id\n  name\n}\n\nfragment EditEventStatus on Event {\n  id\n  disabled\n}\n\nfragment EditIsAdditionAvailableInTimeReport_event on Event {\n  id\n  isAdditionAvailableInTimeReport\n}\n"
        }
    };
})();
node.hash = "f903a40a47e97238b4e31a5f39697acd";
export default node;
