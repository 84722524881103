/**
 * @generated SignedSource<<d621211697372448048b5e90737af6dc>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "extended"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "filter"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserTablePanel_query",
    "selections": [
        {
            "args": [
                {
                    "kind": "Variable",
                    "name": "extended",
                    "variableName": "extended"
                },
                {
                    "kind": "Variable",
                    "name": "filter",
                    "variableName": "filter"
                }
            ],
            "kind": "FragmentSpread",
            "name": "UserTable_query"
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "7ca6ef9ec02698274937b918f9298269";
export default node;
