/**
 * @generated SignedSource<<e198883c0f7d8c2d0301a99458534ce1>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "DeleteAssetAttachmentPayload",
            "kind": "LinkedField",
            "name": "deleteAssetAttachment",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "EdgeOfAssetAttachment",
                    "kind": "LinkedField",
                    "name": "assetAttachmentEdge",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssetAttachment",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "useAssetAttachments_deleteFileMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "useAssetAttachments_deleteFileMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "d3b5945130f7e1ebc0aaf51293545364",
            "id": null,
            "metadata": {},
            "name": "useAssetAttachments_deleteFileMutation",
            "operationKind": "mutation",
            "text": "mutation useAssetAttachments_deleteFileMutation(\n  $model: DeleteAssetAttachmentRequestInput!\n) {\n  deleteAssetAttachment(model: $model) {\n    assetAttachmentEdge {\n      node {\n        id\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "7f80429bf92429761da79417d332db0e";
export default node;
