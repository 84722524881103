import React, { Suspense, useState } from 'react';
import { AccordionItem, Chip, Confirm, FileList as SonikaFileList, Flexbox, IconButton, MarkdownContent, ModalToSheetSkeleton, } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useMutation, useQueryLoader } from 'react-relay/hooks';
import { faPencil, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { useApi } from '@sonika-se/ui-kit/hooks';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import EditFaqPostModal, { editFaqPostModalQuery } from './EditFaqPostModal';
import enums from '~/enums.json';
import useFaqPostTags from '~/hooks/tags/useFaqPostTags';
export const useStyles = createUseStyles((theme) => {
    return {
        listItem: {
            padding: '.5rem 3rem',
            [`@media (max-width: ${theme.breakPoints.low})`]: {
                paddingRight: 0,
            },
        },
    };
});
export const FaqPostListItem = (props) => {
    var _a;
    const { editable = false, faqPostRef } = props;
    const classes = useStyles();
    const data = useFragment(graphql `
      fragment FaqPostListItem_faqPost on FaqPost {
        id
        answer
        question
        status
        ...useFaqPostTags_faqPost
        ...useFaqPostAttachments_faqPost @relay(mask: false)
      }
    `, faqPostRef);
    const [deleteFaqPostMutation, deleteFaqPostMutationLoading] = useMutation(graphql `
      mutation FaqPostListItem_deleteFaqPostMutation($model: DeleteFaqPostRequestInput!) {
        deleteFaqPost(model: $model) {
          faqPostEdge {
            node {
              id
            }
          }
        }
      }
    `);
    const { data: tagData } = useFaqPostTags(data);
    const hasPermissions = usePermissions();
    const intl = useIntl();
    const faqPostStates = enums.FaqPostState;
    const [deleteConfirmVisible, setDeleteConfirmVisible] = useState(false);
    const { downloadFile } = useApi();
    const [editFaqPostModalQueryRef, loadEditFaqPostModalQuery, disposeEditFaqPostModalQuery] = useQueryLoader(editFaqPostModalQuery);
    return (<>
      <AccordionItem actions={editable &&
            hasPermissions(['EDIT_FAQ']) && (<Flexbox gap="xsmall">
              <IconButton appearance="text" disabled={deleteFaqPostMutationLoading} icon={faPencil} onClick={() => {
                loadEditFaqPostModalQuery({
                    id: data.id,
                });
            }}/>
              <IconButton appearance="text" color="danger" disabled={deleteFaqPostMutationLoading} icon={faTrashAlt} onClick={() => {
                setDeleteConfirmVisible(true);
            }}/>
            </Flexbox>)} size="medium" title={<Flexbox gap="xsmall" align="center">
            <span>{data.question}</span>
            {editable && hasPermissions(['EDIT_FAQ']) && (<Chip color={data.status === 'PUBLISHED' ? '#57be56' : null} size="xsmall" text={intl.formatMessage({
                    defaultMessage: (_a = faqPostStates[data.status]) === null || _a === void 0 ? void 0 : _a.description,
                    id: `FaqPostState_${data.status}`,
                })}/>)}
          </Flexbox>}>
        <div className={classes.listItem}>
          <MarkdownContent>{data.answer}</MarkdownContent>
          {tagData.tags.edges.length > 0 && (<div style={{ marginTop: '1rem' }}>
              {tagData.tags.edges.map(({ node }) => (<Chip key={node.id} size="xsmall" style={{ marginRight: '0.5rem' }} text={node.tag.name}/>))}
            </div>)}
        </div>
        <div className={classes.listItem}>
          <SonikaFileList headerText={intl.formatMessage({
            defaultMessage: 'Släpp filer här eller klicka för att välja',
            id: 'ScheduleShiftModal.FileList.header_text',
        })} defaultFiles={data.attachments.edges.map(({ node }) => node)} onDownload={(url, name) => {
            downloadFile(url, name);
        }}/>
        </div>
      </AccordionItem>
      {deleteConfirmVisible && (<Confirm appearance="danger" headerText={intl.formatMessage({
                id: 'FaqPage.FaqPostListItem.delete.header_text',
                defaultMessage: 'Är du säker på att du vill ta bort frågan?',
            })} onCancel={() => setDeleteConfirmVisible(false)} confirmButton={{
                onConfirm: () => {
                    deleteFaqPostMutation({
                        configs: [
                            {
                                connectionKeys: [
                                    {
                                        key: 'FaqPostList_faqPosts',
                                    },
                                ],
                                parentID: 'client:root',
                                type: 'RANGE_DELETE',
                                deletedIDFieldName: ['faqPostEdge', 'node'],
                                pathToConnection: ['client:root', 'faqPosts'],
                            },
                        ],
                        onCompleted: (response) => {
                            console.log({ response });
                        },
                        onError: (error) => {
                            console.log({ error });
                        },
                        variables: {
                            model: {
                                id: data.id,
                            },
                        },
                    });
                },
                text: 'Ta bort',
            }}/>)}
      <Suspense fallback={<ModalToSheetSkeleton height="20vh"/>}>
        {editFaqPostModalQueryRef && (<EditFaqPostModal onClose={() => disposeEditFaqPostModalQuery()} preloadedQuery={editFaqPostModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default FaqPostListItem;
