import React, { Suspense } from 'react';
import { DataTable, Flexbox, H4, Icon, ModalSkeleton, Panel, PanelButton } from '@sonika-se/ui-kit/components';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import { graphql, useFragment, useLazyLoadQuery, useQueryLoader } from 'react-relay/hooks';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import RolePermissionsUpdateModal, { rolePermissionsModalQuery } from '~/modals/RolePermissionsUpdateModal';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
export const RolePermissionsPanel = (props) => {
    const { roleRef } = props;
    const role = useFragment(graphql `
      fragment RolePermissionsPanel_role on Role {
        id
        rolePermissions
      }
    `, roleRef);
    const [rolePermissionsModalQueryRef, loadRolePermissionsUpdateModalQuery, disposeRolePermissionsUpdateModalQuery] = useQueryLoader(rolePermissionsModalQuery);
    const { permissionGroups } = useLazyLoadQuery(graphql `
      query RolePermissionsPanelQuery {
        permissionGroups {
          name
          permissions {
            name
            value
          }
        }
      }
    `, {});
    const hasPermissions = usePermissions();
    return (<>
      <Panel title={'Rättigheter'} actions={hasPermissions(['EDIT_USER_PERMISSIONS']) && (<PanelButton onClick={() => {
                loadRolePermissionsUpdateModalQuery({
                    roleId: role.id,
                });
            }}>
              Ändra
            </PanelButton>)}>
        <Flexbox direction="column" gap="large">
          {permissionGroups.map((group) => (<Flexbox key={group.name} direction="column" gap="small">
              <H4>{group.name}</H4>
              <DataTable columns={{
                permission: {
                    text: 'Rättighet',
                    render: (permission) => permission.name,
                    width: '33%',
                },
                status: {
                    text: 'Status',
                    width: 1,
                    render: (status) => status ? (<Icon color={sonikaTheme.colors.system.go} icon={faCheckCircle}/>) : (<Icon color={sonikaTheme.colors.system.stop} icon={faTimesCircle}/>),
                },
            }} rows={group.permissions.map((permission) => ({
                permission: permission,
                status: role.rolePermissions.findIndex((rolePermission) => rolePermission === permission.value) > -1,
            }))}/>
            </Flexbox>))}
        </Flexbox>
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {rolePermissionsModalQueryRef && (<RolePermissionsUpdateModal onClose={() => disposeRolePermissionsUpdateModalQuery()} preloadedQuery={rolePermissionsModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default RolePermissionsPanel;
