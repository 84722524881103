import React, { Suspense, useMemo } from 'react';
import { graphql, useLazyLoadQuery, useMutation, useQueryLoader } from 'react-relay/hooks';
import { useParams } from 'react-router';
import { Button, ModalSkeleton, PanelButton } from '@sonika-se/ui-kit/components';
import { ConnectionHandler } from 'relay-runtime';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import UserTablePanel from '~/panels/UserTablePanel';
import UserSelectModal, { userSelectModalQuery } from '~/shared/modals/UserSelectModal';
export const AssignmentTypeUsersPage = () => {
    const { assignmentTypeId } = useParams();
    const userTableFilter = useMemo(() => ({
        assignmentTypeIdsIn: [assignmentTypeId],
    }), []);
    const hasPermissions = usePermissions();
    const userTableExtended = hasPermissions(['EDIT_USERS']);
    const data = useLazyLoadQuery(graphql `
      query AssignmentTypeUsersPageQuery($userTableExtended: Boolean!, $userTableFilter: UsersFilterInput!) {
        ...UserTablePanel_query @arguments(extended: $userTableExtended, filter: $userTableFilter)
      }
    `, {
        userTableExtended,
        userTableFilter,
    }, {
        fetchPolicy: 'network-only',
    });
    const [addUsersToAssignmentType, addUsersToAssignmentTypeLoading] = useMutation(graphql `
        mutation AssignmentTypeUsersPage_addUsersToAssignmentTypeMutation(
          $connectionsToPrependEdgeTo: [ID!]!
          $model: AddUsersToAssignmentTypeInput!
        ) {
          addUsersToAssignmentType(model: $model) {
            userEdges @prependEdge(connections: $connectionsToPrependEdgeTo) {
              node {
                ...Avatar_iHaveAvatar
                assignmentTypes {
                  totalCount
                }
                firstname
                id
                invitation {
                  created
                }
                lastname
                roles {
                  totalCount
                }
                schedulableEntity {
                  id
                }
                timeReportingUser {
                  id
                }
                services
                status
              }
            }
          }
        }
      `);
    const [removeUsersFromAssignmentType, removeUsersFromAssignmentTypeLoading] = useMutation(graphql `
        mutation AssignmentTypeUsersPage_removeUsersToAssignmentTypeMutation(
          $connectionsToDeleteEdgeFrom: [ID!]!
          $model: RemoveUsersFromAssignmentTypeInput!
        ) {
          removeUsersFromAssignmentType(model: $model) {
            userIds @deleteEdge(connections: $connectionsToDeleteEdgeFrom)
          }
        }
      `);
    const [userSelectModalQueryRef, loadUserSelectModalQuery, disposeUserSelectModalQuery] = useQueryLoader(userSelectModalQuery);
    const userTableConnectionId = useMemo(() => ConnectionHandler.getConnectionID('root', 'UserTable_query_users'), []);
    return (<>
      <UserTablePanel actions={<PanelButton disabled={addUsersToAssignmentTypeLoading} onClick={() => {
                loadUserSelectModalQuery({
                    filter: {
                        assignmentTypeIdsNotIn: [assignmentTypeId],
                    },
                    where: {
                        status: {
                            eq: 'ACTIVE',
                        },
                    },
                }, {
                    fetchPolicy: 'network-only',
                });
            }}>
            Lägg till
          </PanelButton>} extended={userTableExtended} filter={userTableFilter} queryRef={data} tableProps={{
            actions: (user) => (<Button appearance="outlined" color="danger" disabled={removeUsersFromAssignmentTypeLoading} onClick={() => {
                    removeUsersFromAssignmentType({
                        variables: {
                            connectionsToDeleteEdgeFrom: [userTableConnectionId],
                            model: {
                                assignmentTypeId,
                                userIds: [user.id],
                            },
                        },
                    });
                }} size="xsmall">
              Ta bort
            </Button>),
        }} title="Användare"/>
      <Suspense fallback={<ModalSkeleton />}>
        {userSelectModalQueryRef && (<UserSelectModal defaultFilter={{
                assignmentTypeIdsNotIn: [assignmentTypeId],
            }} defaultWhere={{
                status: {
                    eq: 'ACTIVE',
                },
            }} onClose={() => {
                disposeUserSelectModalQuery();
            }} onSelect={(userIds) => {
                addUsersToAssignmentType({
                    variables: {
                        connectionsToPrependEdgeTo: [userTableConnectionId],
                        model: {
                            assignmentTypeId,
                            userIds,
                        },
                    },
                });
                disposeUserSelectModalQuery();
            }} preloadedQuery={userSelectModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default AssignmentTypeUsersPage;
