/**
 * @generated SignedSource<<37e6d173b200a04fcd799b269f8c1b4d>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditEventIsCompensatoryTime_event",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isCompensatoryTime",
            "storageKey": null
        }
    ],
    "type": "Event",
    "abstractKey": null
};
node.hash = "4138d2a283135875b2a9e1337804661c";
export default node;
