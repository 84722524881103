import React, { useMemo } from 'react';
import { DataTable, Flexbox, H4, Icon, Panel, Placeholder, Text } from '@sonika-se/ui-kit/components';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay/hooks';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons';
import { useEnvironment } from '@sonika-se/ui-kit/hooks';
export const RoleSchedulePermissionsPanel = (props) => {
    const { roleRef } = props;
    const role = useFragment(graphql `
      fragment RoleSchedulePermissionsPanel_role on Role {
        id
        schedulePermissions {
          schedule {
            id
            name
          }
          permissions
        }
      }
    `, roleRef);
    const { schedulePermissionGroups } = useLazyLoadQuery(graphql `
      query RoleSchedulePermissionsPanelQuery {
        schedulePermissionGroups(filter: { includeDeprecated: true }) {
          name
          permissions {
            name
            value
          }
        }
      }
    `, {});
    const schedulePermissionsList = useMemo(() => {
        return schedulePermissionGroups.flatMap((x) => x.permissions);
    }, [schedulePermissionGroups]);
    const getSchedulePermissionDetails = (permission) => {
        return schedulePermissionsList.find((x) => x.value == permission);
    };
    const { frontUrls } = useEnvironment();
    return (<>
      <Panel title={'Schemarättigheter'} actions={<a href={`${frontUrls['SCHEDULE']}schedules/`}>
            <Text size="medium" bold>
              Till alla scheman
            </Text>
          </a>}>
        <Flexbox direction="column" gap="large">
          {role.schedulePermissions.map((schedulePermission) => (<Flexbox key={schedulePermission.schedule.name} direction="column" gap="small">
              <Flexbox align="center" justify="space">
                <H4>{schedulePermission.schedule.name}</H4>
                <a href={`${frontUrls['SCHEDULE']}schedules/${schedulePermission.schedule.id}/permissions`}>
                  <Text size="medium" bold>
                    Till schema
                  </Text>
                </a>
              </Flexbox>
              {schedulePermission.permissions.length === 0 ? (<Placeholder text="Inga rättigheter."/>) : (<DataTable columns={{
                    permission: {
                        text: 'Rättighet',
                        render: (permission) => { var _a; return (_a = getSchedulePermissionDetails(permission)) === null || _a === void 0 ? void 0 : _a.name; },
                        width: '33%',
                    },
                    status: {
                        text: 'Status',
                        width: 1,
                        render: (status) => <Icon color={sonikaTheme.colors.system.go} icon={faCheckCircle}/>,
                    },
                }} rows={schedulePermission.permissions.map((permission) => ({
                    permission: permission,
                    status: null,
                }))}/>)}
            </Flexbox>))}
        </Flexbox>
      </Panel>
    </>);
};
export default RoleSchedulePermissionsPanel;
