/**
 * @generated SignedSource<<c1d72975bca3414dc058a11eb08caf11>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v1 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "PermissionGroup",
            "kind": "LinkedField",
            "name": "permissionGroups",
            "plural": true,
            "selections": [
                (v0 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Permission",
                    "kind": "LinkedField",
                    "name": "permissions",
                    "plural": true,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "UserPermissionsPanelQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "UserPermissionsPanelQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "21d2057acd8ea1de73b5ff0337407281",
            "id": null,
            "metadata": {},
            "name": "UserPermissionsPanelQuery",
            "operationKind": "query",
            "text": "query UserPermissionsPanelQuery {\n  permissionGroups {\n    name\n    permissions {\n      name\n      value\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "374f1fd19d6d4e86f32815730e736c9a";
export default node;
