import React from 'react';
import { useIntl } from 'react-intl';
import { graphql, useFragment, useLazyLoadQuery, useMutation } from 'react-relay/hooks';
import { NewInlineSelect } from '@sonika-se/ui-kit/components';
export const editEventAssignmentTypesQuery = graphql `
  query EditEventAssignmentTypesQuery {
    assignmentTypes(where: { disabledDate: { eq: null } }) {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;
export const EditEventAssignmentTypes = (props) => {
    var _a, _b;
    const { event } = props;
    const data = useLazyLoadQuery(editEventAssignmentTypesQuery, {});
    const intl = useIntl();
    const { id, assignmentTypes } = useFragment(graphql `
      fragment EditEventAssignmentTypes on Event {
        id
        assignmentTypes {
          id
          title
        }
      }
    `, event);
    const [updateEventAssignmentType, updateEventAssignmentTypeLoading] = useMutation(graphql `
      mutation EditEventAssignmentTypesMutation($model: SetEventAssignmentTypesInput!) {
        setEventAssignmentTypes(model: $model) {
          eventEdge {
            node {
              id
              assignmentTypes {
                id
                title
              }
            }
          }
        }
      }
    `);
    return (<NewInlineSelect disabled={updateEventAssignmentTypeLoading} multiple stayOpenOnSelect isLoading={updateEventAssignmentTypeLoading} onChange={(values) => {
            updateEventAssignmentType({
                variables: {
                    model: {
                        id,
                        assignmentTypeIds: values === null || values === void 0 ? void 0 : values.map(({ value }) => value),
                    },
                },
            });
        }} selectedItems={assignmentTypes === null || assignmentTypes === void 0 ? void 0 : assignmentTypes.map((node) => ({ value: node === null || node === void 0 ? void 0 : node.id, label: node === null || node === void 0 ? void 0 : node.title }))} placeholder={intl.formatMessage({
            defaultMessage: 'Välj eller sök efter roller',
            id: 'EditEventAssignmentTypes.tags.placeholder',
        })} options={(_b = (_a = data === null || data === void 0 ? void 0 : data.assignmentTypes) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map(({ node }) => ({ label: node === null || node === void 0 ? void 0 : node.title, value: node === null || node === void 0 ? void 0 : node.id }))}/>);
};
export default EditEventAssignmentTypes;
