import React, { Suspense } from 'react';
import { Outlet, useParams } from 'react-router';
import { BasicPage, Flexbox, Heading, Skeleton } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import Avatar from '~/shared/Avatar';
import { useViewport } from '@sonika-se/ui-kit/hooks';
export const ColleagueLayout = () => {
    const { userId } = useParams();
    const { user } = useLazyLoadQuery(graphql `
      query ColleagueLayoutQuery($userId: ID!) {
        user(id: $userId) {
          ...Avatar_iHaveAvatar
          firstname
          lastname
        }
      }
    `, {
        userId,
    });
    const { size } = useViewport();
    return (<BasicPage width="large">
      <Flexbox align={size === 'small' ? 'center' : 'start'} direction={size === 'small' ? 'column' : 'row'} gap={size === 'small' ? 'xsmall' : 'medium'} style={{ paddingTop: '3rem' }}>
        <Avatar iHaveAvatarRef={user} size={size === 'small' ? 'xlarge' : 'large'}/>
        <Heading appearance={size === 'small' ? 'h4' : 'h1'}>{`${user.firstname} ${user.lastname}`}</Heading>
      </Flexbox>
      <Flexbox direction="column" gap="xlarge">
        <Suspense fallback={<Skeleton height="40vh" type="table"/>}>
          <Outlet />
        </Suspense>
      </Flexbox>
    </BasicPage>);
};
export default ColleagueLayout;
