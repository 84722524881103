import React, { useCallback, useRef } from 'react';
import { graphql, useMutation, usePreloadedQuery } from 'react-relay/hooks';
import { FormModal } from '@sonika-se/ui-kit/components';
import AssetSystemInfoForm from '~/forms/AssetSystemInfoForm';
export const assetSystemInfoUpdateModalQuery = graphql `
  query AssetSystemInfoUpdateModalQuery($assetId: ID!) {
    asset(id: $assetId) {
      color
      disabledDate
      id
    }
  }
`;
export const AssetSystemInfoUpdateModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const { asset } = usePreloadedQuery(assetSystemInfoUpdateModalQuery, preloadedQuery);
    const [updateAsset, updateAssetLoading] = useMutation(graphql `
      mutation AssetSystemInfoUpdateModal_updateAssetMutation($model: UpdateAssetRequestInput!) {
        updateAsset(model: $model) {
          assetEdge {
            node {
              color
              disabledDate
            }
          }
        }
      }
    `);
    const formRef = useRef(null);
    const handleSubmit = useCallback((form) => {
        updateAsset({
            onCompleted: () => {
                onClose();
            },
            variables: {
                model: {
                    color: form.color,
                    disabled: !form.status,
                    id: asset.id,
                },
            },
        });
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Spara" disabled={updateAssetLoading} title="Ändra systeminformation">
      <AssetSystemInfoForm defaultValues={{
            color: asset.color,
            status: asset.disabledDate === null,
        }} disabled={updateAssetLoading} onSubmit={handleSubmit} ref={formRef}/>
    </FormModal>);
};
export default AssetSystemInfoUpdateModal;
