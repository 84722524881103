import React, { Suspense } from 'react';
import { Flexbox, KeyValue, ModalSkeleton, Panel, PanelButton, Placeholder } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import UserBankAccountUpdateModal, { editUserBankAccountModalQuery } from '~/modals/UserBankAccountUpdateModal';
export const UserBankAccountPanel = (props) => {
    const { userRef } = props;
    const user = useFragment(graphql `
      fragment UserBankAccountPanel_user on User {
        id
        bankAccount {
          accountNumber
          bankName
          clearingNumber
        }
      }
    `, userRef);
    const [editUserBankAccountModalQueryRef, loadUserBankAccountUpdateModalQuery, disposeUserBankAccountUpdateModalQuery,] = useQueryLoader(editUserBankAccountModalQuery);
    return (<>
      <Panel title="Bankuppgifter" actions={<PanelButton onClick={() => {
                loadUserBankAccountUpdateModalQuery({
                    userId: user.id,
                });
            }}>
            Ändra
          </PanelButton>}>
        {user.bankAccount ? (<Flexbox direction="column" gap="medium">
            <KeyValue label="Bank" value={user.bankAccount.bankName}/>
            <KeyValue label="Clearingnummer" value={user.bankAccount.clearingNumber}/>
            <KeyValue label="Kontonummer" value={user.bankAccount.accountNumber}/>
          </Flexbox>) : (<Placeholder text="Inga bankuppgifter."/>)}
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {editUserBankAccountModalQueryRef && (<UserBankAccountUpdateModal preloadedQuery={editUserBankAccountModalQueryRef} onClose={() => {
                disposeUserBankAccountUpdateModalQuery();
            }}/>)}
      </Suspense>
    </>);
};
export default UserBankAccountPanel;
