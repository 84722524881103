/**
 * @generated SignedSource<<36b966f8b3eaf9284ca2684812434dbf>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userId"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "userId"
                }
            ],
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hidden",
                    "storageKey": null
                },
                (v1 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCompanyAdmin",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "SchedulableUser",
                    "kind": "LinkedField",
                    "name": "schedulableEntity",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "schedulable",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "TimeReportingUser",
                    "kind": "LinkedField",
                    "name": "timeReportingUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "active",
                            "storageKey": null
                        },
                        (v1 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserAttributesUpdateModalQuery",
            "selections": (v2 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserAttributesUpdateModalQuery",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "7e140eb9368b88b950e5f66b3beef347",
            "id": null,
            "metadata": {},
            "name": "UserAttributesUpdateModalQuery",
            "operationKind": "query",
            "text": "query UserAttributesUpdateModalQuery(\n  $userId: ID!\n) {\n  user(id: $userId) {\n    hidden\n    id\n    isCompanyAdmin\n    schedulableEntity {\n      id\n      schedulable\n    }\n    timeReportingUser {\n      active\n      id\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "5a2368d25e62803a0f9ca658fba68b30";
export default node;
