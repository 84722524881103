/**
 * @generated SignedSource<<6d7d5c68c9ae2539e72c3deb6b2f6278>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditEventAdditionUnit_event",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "additionUnit",
            "storageKey": null
        }
    ],
    "type": "Event",
    "abstractKey": null
};
node.hash = "72beccd14d8a61c0dde8197ae40232f4";
export default node;
