/**
 * @generated SignedSource<<f445a928c612d38efb5110bf0fa2c1a1>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "connectionsToPrependEdge"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "concreteType": "EdgeOfRole",
        "kind": "LinkedField",
        "name": "roleEdge",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Role",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "rolePermissions",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserConnection",
                        "kind": "LinkedField",
                        "name": "usersInRole",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "totalCount",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RoleCreateModal_createRoleMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateRolePayload",
                    "kind": "LinkedField",
                    "name": "createRole",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RoleCreateModal_createRoleMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateRolePayload",
                    "kind": "LinkedField",
                    "name": "createRole",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "filters": null,
                            "handle": "prependEdge",
                            "key": "",
                            "kind": "LinkedHandle",
                            "name": "roleEdge",
                            "handleArgs": [
                                {
                                    "kind": "Variable",
                                    "name": "connections",
                                    "variableName": "connectionsToPrependEdge"
                                }
                            ]
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "bb57a75a0fe1e5e9a1b178adf8d685f3",
            "id": null,
            "metadata": {},
            "name": "RoleCreateModal_createRoleMutation",
            "operationKind": "mutation",
            "text": "mutation RoleCreateModal_createRoleMutation(\n  $model: CreateRoleInput!\n) {\n  createRole(model: $model) {\n    roleEdge {\n      node {\n        id\n        name\n        rolePermissions\n        usersInRole {\n          totalCount\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "05043a21d768759f9ef7c236ea892feb";
export default node;
