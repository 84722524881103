import React from 'react';
import { useRoutes } from 'react-router-dom';
import { graphql, useFragment } from 'react-relay/hooks';
import AccountPages from './pages/AccountPages/AccountPages';
import PrivateRoutes from './PrivateRoutes';
import SignupPage from './pages/SignupPage/SignupPage';
const AppRoutes = (props) => {
    const { queryRef } = props;
    const data = useFragment(graphql `
      fragment Routes_query on Query {
        ...PrivateRoutes_query
      }
    `, queryRef);
    const routes = useRoutes([
        {
            path: 'account/*',
            element: <AccountPages />,
        },
        {
            path: 'signup/*',
            element: <SignupPage />,
        },
        {
            path: '*',
            element: <PrivateRoutes queryRef={data}/>,
        },
    ]);
    return routes;
};
export default AppRoutes;
