import React, { Suspense } from 'react';
import { Outlet, useParams } from 'react-router';
import { BasicPage, BasicPageHeader, Flexbox, Menu, MenuItem, Skeleton } from '@sonika-se/ui-kit/components';
import { serviceNames } from '@sonika-se/ui-kit';
export const ServiceLayout = () => {
    const { service } = useParams();
    return (<BasicPage width="large">
      <BasicPageHeader title={serviceNames[service]}/>
      <Flexbox direction="column" gap="xlarge">
        <Menu>
          <MenuItem index path="." text="Allmänt"/>
          <MenuItem path="users" text="Användare"/>
        </Menu>
        <Suspense fallback={<Skeleton height="40vh"/>}>
          <Outlet />
        </Suspense>
      </Flexbox>
    </BasicPage>);
};
export default ServiceLayout;
