/**
 * @generated SignedSource<<e33549b091faa5dab04e4aba6f72fca5>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "connectionsToPrependEdge"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "concreteType": "EdgeOfAssignmentType",
        "kind": "LinkedField",
        "name": "assignmentTypeEdge",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentType",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "disabledDate",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AssignmentTypeCreateModalCreateMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateAssignmentTypePayload",
                    "kind": "LinkedField",
                    "name": "createAssignmentType",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AssignmentTypeCreateModalCreateMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateAssignmentTypePayload",
                    "kind": "LinkedField",
                    "name": "createAssignmentType",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "filters": null,
                            "handle": "prependEdge",
                            "key": "",
                            "kind": "LinkedHandle",
                            "name": "assignmentTypeEdge",
                            "handleArgs": [
                                {
                                    "kind": "Variable",
                                    "name": "connections",
                                    "variableName": "connectionsToPrependEdge"
                                }
                            ]
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "d2fa9a773cb2f971119ca316065ff386",
            "id": null,
            "metadata": {},
            "name": "AssignmentTypeCreateModalCreateMutation",
            "operationKind": "mutation",
            "text": "mutation AssignmentTypeCreateModalCreateMutation(\n  $model: CreateAssignmentTypeRequestInput!\n) {\n  createAssignmentType(model: $model) {\n    assignmentTypeEdge {\n      node {\n        id\n        title\n        disabledDate\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "7ad1d816616c03e4b0a1fb1255e4103c";
export default node;
