/**
 * @generated SignedSource<<80e093b0c7e1d2c3bb3f495b2a7f5f3e>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "currentCompany",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "administrationEmail",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "organizationNumber",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "EditCompanySettingsModalQuery",
            "selections": (v0 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "EditCompanySettingsModalQuery",
            "selections": (v0 /*: any*/)
        },
        "params": {
            "cacheID": "e71f9b7563f2bc26774ab154a17e4015",
            "id": null,
            "metadata": {},
            "name": "EditCompanySettingsModalQuery",
            "operationKind": "query",
            "text": "query EditCompanySettingsModalQuery {\n  currentCompany {\n    id\n    name\n    administrationEmail\n    organizationNumber\n  }\n}\n"
        }
    };
})();
node.hash = "f74a61c8719bd9ba9b605760da6f0935";
export default node;
