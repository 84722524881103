/**
 * @generated SignedSource<<1e5b998109793b72658b46fee1014c1a>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserAssignmentTypesPanel_addAssignmentTypesToUserMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "AddAssignmentTypesToUserPayload",
                    "kind": "LinkedField",
                    "name": "addAssignmentTypesToUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "UserAssignmentTypesPanel_user"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserAssignmentTypesPanel_addAssignmentTypesToUserMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "AddAssignmentTypesToUserPayload",
                    "kind": "LinkedField",
                    "name": "addAssignmentTypesToUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssignmentTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "assignmentTypes",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "AssignmentType",
                                            "kind": "LinkedField",
                                            "name": "nodes",
                                            "plural": true,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "title",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "8b5b99cd7d08c70e2e98fc013956971f",
            "id": null,
            "metadata": {},
            "name": "UserAssignmentTypesPanel_addAssignmentTypesToUserMutation",
            "operationKind": "mutation",
            "text": "mutation UserAssignmentTypesPanel_addAssignmentTypesToUserMutation(\n  $model: AddAssignmentTypesToUserInput!\n) {\n  addAssignmentTypesToUser(model: $model) {\n    user {\n      ...UserAssignmentTypesPanel_user\n      id\n    }\n  }\n}\n\nfragment UserAssignmentTypesPanel_user on User {\n  assignmentTypes {\n    nodes {\n      id\n      title\n    }\n  }\n  id\n}\n"
        }
    };
})();
node.hash = "7c1fc620bc4f52db833fc62f8ad1955d";
export default node;
