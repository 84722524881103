import React, { useCallback, useRef } from 'react';
import { useMutation, graphql } from 'react-relay/hooks';
import { useEnvironment } from '@sonika-se/ui-kit/hooks';
import { FormModal } from '@sonika-se/ui-kit/components';
import UserCreateForm from '../forms/UserCreateForm';
export const UserCreateModal = (props) => {
    const { connectionsToPrependEdge, onClose } = props;
    const [createUser, createUserLoading] = useMutation(graphql `
    mutation UserCreateModalCreateMutation($connectionsToPrependEdge: [ID!]!, $model: CreateUserRequestInput!) {
      createUser(model: $model) {
        userEdge @prependEdge(connections: $connectionsToPrependEdge) {
          node {
            firstname
            id
            invitation {
              created
            }
            lastname
            status
          }
        }
      }
    }
  `);
    const { frontUrls } = useEnvironment();
    const formRef = useRef(null);
    const handleSubmit = useCallback((form) => {
        createUser({
            variables: {
                connectionsToPrependEdge,
                model: {
                    email: form.email,
                    firstname: form.firstname,
                    lastname: form.lastname,
                    personnummer: form.personnummer,
                    invitation: form.invitation
                        ? { frontendClient: 'BOAT', frontendUrl: `${frontUrls['CORE']}account/accept-invite` }
                        : null,
                },
            },
            onCompleted: (data) => {
                onClose(data.createUser.userEdge.node);
            },
        });
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Spara" disabled={createUserLoading} title="Lägg till användare">
      <UserCreateForm defaultValues={{
            email: '',
            firstname: '',
            lastname: '',
            personnummer: '',
            invitation: false,
        }} disabled={createUserLoading} onSubmit={handleSubmit} ref={formRef}/>
    </FormModal>);
};
export default UserCreateModal;
