import React, { Suspense, useMemo } from 'react';
import { Chip, DataTable, Flexbox, H4, Icon, ModalSkeleton, Notification, Panel, PanelButton, } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useLazyLoadQuery, useQueryLoader } from 'react-relay/hooks';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import UserPermissionsUpdateModal, { userPermissionsModalQuery } from '~/modals/UserPermissionsUpdateModal';
import { Link } from 'react-router-dom';
export const UserPermissionsPanel = (props) => {
    const { userRef } = props;
    const user = useFragment(graphql `
      fragment UserPermissionsPanel_user on User {
        id
        isCompanyAdmin
        roles {
          nodes {
            id
            name
            rolePermissions
          }
        }
        userPermissions
      }
    `, userRef);
    const { permissionGroups } = useLazyLoadQuery(graphql `
      query UserPermissionsPanelQuery {
        permissionGroups {
          name
          permissions {
            name
            value
          }
        }
      }
    `, {});
    const userPermissions = useMemo(() => {
        const allPermissions = permissionGroups.flatMap((x) => x.permissions).flatMap((x) => x.value);
        return allPermissions
            .map((permission) => ({
            permission,
            onUser: user.userPermissions.includes(permission),
            onRoles: user.roles.nodes.filter((role) => role.rolePermissions.includes(permission)),
        }))
            .filter(({ onUser, onRoles }) => onUser || onRoles.length > 0);
    }, [user, permissionGroups]);
    const [userPermissionsModalQueryRef, loadUserPermissionsUpdateModalQuery, disposeUserPermissionsUpdateModalQuery] = useQueryLoader(userPermissionsModalQuery);
    return (<>
      <Panel title="Rättigheter" actions={<PanelButton onClick={() => {
                loadUserPermissionsUpdateModalQuery({
                    userId: user.id,
                });
            }}>
            Ändra
          </PanelButton>}>
        <Flexbox direction="column" gap="large">
          {user.isCompanyAdmin && (<Notification color={sonikaTheme.colors.system.wait} shadow={false} text="Systemets alla rättigheter är automatiskt tillgängliga för en företagsadmin" title="Användaren är företagsadmin"/>)}
          {permissionGroups.map((group) => (<Flexbox key={group.name} direction="column" gap="small">
              <H4>{group.name}</H4>
              <DataTable columns={{
                permission: {
                    text: 'Rättighet',
                    render: (permission) => permission.name,
                    width: '33%',
                },
                status: {
                    text: 'Status',
                    width: 1,
                    render: (status) => status ? (<Icon color={sonikaTheme.colors.system.go} icon={faCheckCircle}/>) : (<Icon color={sonikaTheme.colors.system.stop} icon={faTimesCircle}/>),
                },
                origin: {
                    text: 'Från',
                    render: (origin) => origin ? (<Flexbox direction="row" gap="xsmall" wrap="wrap">
                          {user.isCompanyAdmin && (<Chip color={sonikaTheme.colors.primary} size="xsmall" text="Företagsadmin"/>)}
                          {origin.onUser && <Chip color={sonikaTheme.colors.primary} size="xsmall" text="Användare"/>}
                          {origin.onRoles.map((role) => (<Link key={role.id} to={`/groups/${role.id}`}>
                              <Chip color={sonikaTheme.colors.primary} size="xsmall" text={role.name} type="view"/>
                            </Link>))}
                        </Flexbox>) : null,
                },
            }} rows={group.permissions.map((permission) => ({
                permission: permission,
                status: user.isCompanyAdmin ||
                    userPermissions.findIndex((userPermission) => userPermission.permission === permission.value) > -1,
                origin: userPermissions.find((userPermission) => userPermission.permission === permission.value),
            }))}/>
            </Flexbox>))}
        </Flexbox>
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {userPermissionsModalQueryRef && (<UserPermissionsUpdateModal onClose={() => disposeUserPermissionsUpdateModalQuery()} preloadedQuery={userPermissionsModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default UserPermissionsPanel;
