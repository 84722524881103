import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Field, Form, Input } from '@sonika-se/ui-kit/components';
import { Controller, useForm } from 'react-hook-form';
import { object, string } from 'yup';
export const roleTableQueryFormValidationSchema = object({
    search: string().notRequired(),
});
export const RoleTableQueryForm = (props) => {
    const { defaultValues, disabled, onSubmit } = props;
    const { control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues,
        resolver: yupResolver(roleTableQueryFormValidationSchema),
    });
    return (<Form columns="40% auto 1fr" gap="small" onSubmit={handleSubmit((data) => {
            onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit(data);
        })}>
      <Field>
        <Controller control={control} name="search" render={({ field }) => {
            var _a;
            return (<Input defaultValue={defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.search} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.search) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} placeholder="Fritextsök"/>);
        }}/>
      </Field>
      <Button type="submit">Filtrera</Button>
    </Form>);
};
export default RoleTableQueryForm;
