import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, boolean } from 'yup';
import { Form, Input, Field, Checkbox } from '@sonika-se/ui-kit/components';
export const userCreateFormValidationSchema = object({
    firstname: string().required(),
    lastname: string().required(),
    email: string().required(),
    invitation: boolean().notRequired(),
    personnummer: string()
        .nullable()
        .matches(/^([0-9]{8}(\-?[0-9]{4})?)?$/, 'Felaktigt födelsedatum/personnummer')
        .notRequired(),
});
export const UserCreateForm = forwardRef((props, forwardedRef) => {
    const { disabled, defaultValues, onSubmit } = props;
    const { control, formState: { errors, isValid }, handleSubmit, } = useForm({
        defaultValues,
        resolver: yupResolver(userCreateFormValidationSchema),
    });
    useImperativeHandle(forwardedRef, () => ({
        isValid,
        submit: (onValid, onInvalid) => handleSubmit(onValid, onInvalid)(),
    }), [handleSubmit, isValid]);
    return (<Form columns="1fr 1fr" onSubmit={handleSubmit((data) => {
            onSubmit(data);
        })}>
        <Field label="Förnamn">
          <Controller control={control} name="firstname" render={({ field }) => {
            var _a;
            return (<Input disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.firstname) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} defaultValue={field.value}/>);
        }}/>
        </Field>
        <Field label="Efternamn">
          <Controller control={control} name="lastname" render={({ field }) => {
            var _a;
            return (<Input disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.lastname) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} defaultValue={field.value}/>);
        }}/>
        </Field>
        <Field columnSpan={2} label="E-post">
          <Controller control={control} name="email" render={({ field }) => {
            var _a;
            return (<Input disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.email) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} defaultValue={field.value}/>);
        }}/>
        </Field>
        <Field columnSpan={2} label={'Födelsedatum/personnummer'}>
          <Controller control={control} name="personnummer" render={({ field }) => {
            var _a;
            return (<Input disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.personnummer) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} defaultValue={field.value}/>);
        }}/>
        </Field>
        <Field label="Skicka inbjudan med e-post">
          <Controller control={control} name="invitation" render={({ field }) => {
            var _a;
            return (<Checkbox checked={field.value} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.invitation) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={(event) => field.onChange(event.target.checked)}/>);
        }}/>
        </Field>
      </Form>);
});
export default UserCreateForm;
