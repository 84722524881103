import React, { useEffect, useMemo, useRef } from 'react';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { Chip, DataTable, Placeholder, Text } from '@sonika-se/ui-kit/components';
import { Link } from 'react-router-dom';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
const AssignmentTypeTable = (props) => {
    const { where, queryRef } = props;
    const { data, hasNext, isLoadingNext, loadNext, refetch } = usePaginationFragment(graphql `
      fragment AssignmentTypeTable_query on Query
      @argumentDefinitions(
        after: { type: "String" }
        first: { type: "Int", defaultValue: 50 }
        where: { type: "AssignmentTypeFilterInput" }
      )
      @refetchable(queryName: "AssignmentTypeTablePaginationQuery") {
        assignmentTypes(after: $after, first: $first, where: $where)
          @connection(key: "AssignmentTypeTable_query_assignmentTypes", filters: []) {
          edges {
            node {
              assets {
                totalCount
              }
              disabledDate
              id
              users {
                totalCount
              }
              title
            }
          }
        }
      }
    `, queryRef);
    const firstRender = useRef(true);
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        refetch({ where }, { fetchPolicy: 'network-only' });
    }, [where]);
    const assignmentTypes = useMemo(() => data.assignmentTypes.edges.map(({ node: assignmentType }) => {
        var _a, _b;
        return ({
            name: assignmentType,
            userCount: ((_a = assignmentType.users) === null || _a === void 0 ? void 0 : _a.totalCount) || 0,
            assetCount: ((_b = assignmentType.assets) === null || _b === void 0 ? void 0 : _b.totalCount) || 0,
            status: assignmentType.disabledDate === null,
        });
    }), [data]);
    return assignmentTypes.length === 0 ? (<Placeholder text="Inga roller."/>) : (<DataTable columns={{
            name: {
                text: 'Titel',
                render: ({ id, title }) => (<Text bold>
              <Link to={`/assignment-types/${id}`}>{title}</Link>
            </Text>),
            },
            userCount: {
                text: 'Användare',
            },
            assetCount: {
                text: 'Enheter',
            },
            status: {
                text: 'Status',
                render: (status) => (<div>
              {status ? (<Chip color={sonikaTheme.colors.system.go} size="xsmall" text="Aktiv" type="view"/>) : (<Chip color={sonikaTheme.colors.system.stop} size="xsmall" text="Inaktiv" type="view"/>)}
            </div>),
                width: 1,
            },
        }} loading={isLoadingNext} onPaginate={() => {
            loadNext(50);
        }} pagination={{
            buttonText: 'Ladda fler',
            hasNextPage: hasNext,
        }} rows={assignmentTypes}/>);
};
export default AssignmentTypeTable;
