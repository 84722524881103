var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useLazyLoadQuery, graphql, useMutation } from 'react-relay/hooks';
import { Text, Confirm, Flexbox, H3 } from '@sonika-se/ui-kit/components';
import { removeAccessTokens } from '@sonika-se/ui-kit/utils/auth';
import AccountPage from '../components/AccountPage';
import { FormattedMessage } from 'react-intl';
import UserInvitationAcceptForm from '~/components/Forms/UserInvitationAcceptForm/UserInvitationAcceptForm';
const AcceptInvite = () => {
    const [urlSearchParams] = useSearchParams();
    const ticket = urlSearchParams.get('ticket');
    // Clear any previous login
    removeAccessTokens();
    const [invitationCompleted, setInvitationCompleted] = useState();
    const data = useLazyLoadQuery(graphql `
      query AcceptInviteQuery($ticket: String!) {
        waitingUserInvitation(ticket: $ticket) {
          email
          ticket
        }
      }
    `, {
        ticket,
    });
    const [acceptInvitation, isInFlight] = useMutation(graphql `
      mutation AcceptInviteMutation($model: AcceptUserInvitationRequestInput!) {
        acceptInvitation(model: $model)
      }
    `);
    const navigate = useNavigate();
    const onSubmit = (data, e) => __awaiter(void 0, void 0, void 0, function* () {
        acceptInvitation({
            onCompleted: () => {
                setInvitationCompleted({ username: data.username });
            },
            onError: (error) => {
                console.log({ error });
            },
            variables: {
                model: {
                    username: data.username,
                    password: data.password,
                    ticket,
                },
            },
        });
    });
    return (<AccountPage>
      <Flexbox direction="column" gap="large">
        <Flexbox direction="column" gap="medium">
          <H3>
            <FormattedMessage id="AcceptInvite.header" defaultMessage="Aktivera konto"/>
          </H3>
          <Text>
            <FormattedMessage id="AcceptInvite.description" defaultMessage="Skapa ett lösenord för att aktivera kontot"/>
          </Text>
        </Flexbox>
        {data && (<>
            <UserInvitationAcceptForm disabled={isInFlight} onSubmit={onSubmit} defaultValues={{ username: data.waitingUserInvitation.email, password: null, repeatPassword: null }}/>
          </>)}
        {/* {ticketError && (
          <Card>
            <CardContent>
              <Text size="small">Länken du har använt är inte giltig längre.</Text>
            </CardContent>
          </Card>
        )} */}
        {invitationCompleted && (<Confirm headerText="Inbjudan slutförd - fortsätt med att logga in med ditt konto." confirmButton={{
                text: 'Gå till inloggning',
                onConfirm: () => navigate(`/account/login?username=${encodeURIComponent(invitationCompleted.username)}`),
            }}/>)}
      </Flexbox>
    </AccountPage>);
};
export default AcceptInvite;
