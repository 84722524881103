/**
 * @generated SignedSource<<5112130d3dab6d2426ab6e029e030eb4>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ],
    "kind": "Fragment",
    "metadata": {
        "connection": [
            {
                "count": null,
                "cursor": null,
                "direction": "forward",
                "path": [
                    "availableVacantShifts"
                ]
            }
        ]
    },
    "name": "AvailableVacanciesPanel_schedulableUser",
    "selections": [
        {
            "alias": "availableVacantShifts",
            "args": null,
            "concreteType": "ScheduleShiftConnection",
            "kind": "LinkedField",
            "name": "__AvailableVacanciesPanel_availableVacantShifts_connection",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ScheduleShiftEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "ScheduleShift",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ScheduleShiftList_scheduleShiftConnection"
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "SchedulableUser",
    "abstractKey": null
};
node.hash = "5aab80e12878d72f15aa2c5e98f2bf13";
export default node;
