/**
 * @generated SignedSource<<72508952fdb0a91bbffba160aab5b726>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "connectionsToDeleteEdgeFrom"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userIds",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AssignmentTypeUsersPage_removeUsersToAssignmentTypeMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "RemoveUsersFromAssignmentTypePayload",
                    "kind": "LinkedField",
                    "name": "removeUsersFromAssignmentType",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AssignmentTypeUsersPage_removeUsersToAssignmentTypeMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "RemoveUsersFromAssignmentTypePayload",
                    "kind": "LinkedField",
                    "name": "removeUsersFromAssignmentType",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "filters": null,
                            "handle": "deleteEdge",
                            "key": "",
                            "kind": "ScalarHandle",
                            "name": "userIds",
                            "handleArgs": [
                                {
                                    "kind": "Variable",
                                    "name": "connections",
                                    "variableName": "connectionsToDeleteEdgeFrom"
                                }
                            ]
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "59a5b1eed14f3fae992ec4ff0e11be19",
            "id": null,
            "metadata": {},
            "name": "AssignmentTypeUsersPage_removeUsersToAssignmentTypeMutation",
            "operationKind": "mutation",
            "text": "mutation AssignmentTypeUsersPage_removeUsersToAssignmentTypeMutation(\n  $model: RemoveUsersFromAssignmentTypeInput!\n) {\n  removeUsersFromAssignmentType(model: $model) {\n    userIds\n  }\n}\n"
        }
    };
})();
node.hash = "55fb627e7327b2ab292490c85f3279e1";
export default node;
