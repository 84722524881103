import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { Form, Label, Flexbox, MarkdownEditor } from '@sonika-se/ui-kit/components';
export const userExternalContactsFormValidationSchema = object().shape({
    externalContacts: string().notRequired(),
});
export const UserExternalContactsForm = forwardRef((props, forwardedRef) => {
    const { defaultValues, disabled, onSubmit } = props;
    const { control, formState: { isValid }, handleSubmit, } = useForm({
        defaultValues,
        resolver: yupResolver(userExternalContactsFormValidationSchema),
    });
    useImperativeHandle(forwardedRef, () => ({
        isValid,
        submit: (onValid, onInvalid) => handleSubmit(onValid, onInvalid)(),
    }), [handleSubmit, isValid]);
    return (<Form onSubmit={handleSubmit((data) => {
            onSubmit(data);
        })}>
      <Flexbox direction="column" gap="xsmall">
        <Label text="Externa kontakter"/>
        <Controller control={control} name="externalContacts" render={({ field }) => (<MarkdownEditor height="200px" initialValue={field.value} onBlur={field.onBlur} onChange={field.onChange} toolbarItems={[['link', 'ul']]}/>)}/>
      </Flexbox>
    </Form>);
});
export default UserExternalContactsForm;
