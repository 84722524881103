import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { Form, Input, Field } from '@sonika-se/ui-kit/components';
export const assetCreateFormValidationSchema = object({
    name: string().required('Du måste ange ett namn'),
});
export const AssetCreateForm = forwardRef((props, forwardedRef) => {
    const { defaultValues, disabled, onSubmit } = props;
    const { control, formState: { errors, isValid }, handleSubmit, } = useForm({
        defaultValues,
        resolver: yupResolver(assetCreateFormValidationSchema),
    });
    useImperativeHandle(forwardedRef, () => ({
        isValid,
        submit: (onValid, onInvalid) => handleSubmit(onValid, onInvalid)(),
    }), [handleSubmit, isValid]);
    return (<Form onSubmit={handleSubmit((data) => {
            onSubmit(data);
        })}>
        <Field label="Namn">
          <Controller control={control} name="name" render={({ field }) => {
            var _a;
            return (<Input disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.name) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} defaultValue={field.value}/>);
        }}/>
        </Field>
      </Form>);
});
export default AssetCreateForm;
