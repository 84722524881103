import React from 'react';
import { createUseStyles } from 'react-jss';
const useStyles = createUseStyles((theme) => {
    const { pxToRem } = theme.helpers;
    return {
        progress: ({ progress }) => ({
            backgroundColor: theme.colors.primary,
            height: pxToRem(16),
            width: `${progress * 100}%`,
        }),
        root: {
            backgroundColor: theme.colors.primaryLight,
            borderRadius: pxToRem(4),
            overflow: 'hidden',
        },
    };
});
export const ProgressBar = (props) => {
    const { progress } = props;
    const classes = useStyles({ progress });
    return (<div className={classes.root}>
      <div className={classes.progress}/>
    </div>);
};
export default ProgressBar;
