import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { Chip, DataTable, Placeholder, Text } from '@sonika-se/ui-kit/components';
import { Link } from 'react-router-dom';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import Avatar from '~/shared/Avatar';
export const AssetTable = forwardRef((props, forwardedRef) => {
    const { actions, extended, filter, queryRef, where } = props;
    const { data, hasNext, isLoadingNext, loadNext, refetch } = usePaginationFragment(graphql `
      fragment AssetTable_query on Query
      @argumentDefinitions(
        after: { type: "String" }
        extended: { type: "Boolean!" }
        filter: { type: "AssetsFilterInput" }
        first: { type: "Int", defaultValue: 50 }
        where: { type: "AssetFilterInput" }
      )
      @refetchable(queryName: "AssetTablePaginationQuery") {
        assets(after: $after, filter: $filter, first: $first, where: $where)
          @connection(key: "AssetTable_query_assets", filters: []) {
          edges {
            node {
              ...Avatar_iHaveAvatar
              assignmentTypes @include(if: $extended) {
                totalCount
              }
              disabledDate
              id
              name
            }
          }
        }
      }
    `, queryRef);
    useImperativeHandle(forwardedRef, () => ({
        refetch: () => refetch({ extended, filter }, { fetchPolicy: 'network-only' }),
    }), [extended, filter, refetch]);
    const firstRender = useRef(true);
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        refetch({ extended, filter, where }, { fetchPolicy: 'network-only' });
    }, [extended, filter, where]);
    const assets = useMemo(() => data.assets.edges.map(({ node: asset }) => {
        var _a;
        return ({
            avatar: asset,
            name: asset,
            assignmentTypeCount: ((_a = asset.assignmentTypes) === null || _a === void 0 ? void 0 : _a.totalCount) || 0,
            status: asset.disabledDate === null,
            actions: actions ? asset : undefined,
        });
    }), [data]);
    return assets.length === 0 ? (<Placeholder text="Inga enheter."/>) : (<DataTable columns={{
            avatar: {
                text: '',
                render: (user) => <Avatar iHaveAvatarRef={user} size="small"/>,
                width: 1,
            },
            name: {
                text: 'Namn',
                render: ({ id, name }) => (<Text bold>{extended ? <Link to={`/assets/${id}`}>{name}</Link> : <>{name}</>}</Text>),
            },
            assignmentTypeCount: extended
                ? {
                    text: 'Roller',
                }
                : undefined,
            status: {
                text: 'Status',
                render: (status) => (<div>
              {status ? (<Chip color={sonikaTheme.colors.system.go} size="xsmall" text="Aktiv" type="view"/>) : (<Chip color={sonikaTheme.colors.system.stop} size="xsmall" text="Inaktiv" type="view"/>)}
            </div>),
                width: 1,
            },
            actions: actions
                ? {
                    align: 'right',
                    render: (user) => {
                        return actions(user);
                    },
                    text: '',
                }
                : undefined,
        }} loading={isLoadingNext} onPaginate={() => {
            loadNext(50);
        }} pagination={{
            buttonText: 'Ladda fler',
            hasNextPage: hasNext,
        }} rows={assets}/>);
});
export default AssetTable;
