import React, { Suspense } from 'react';
import { Flexbox, KeyValue, ModalSkeleton, Panel, PanelButton } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import AssetGeneralInfoUpdateModal, { assetAssetUpdateModalQuery } from '~/modals/AssetGeneralInfoUpdateModal';
export const AssetGeneralInfoPanel = (props) => {
    const { assetRef } = props;
    const asset = useFragment(graphql `
      fragment AssetGeneralInfoPanel_asset on Asset {
        assetNumber
        description
        id
        name
      }
    `, assetRef);
    const [assetGeneralInfoUpdateModalRef, loadAssetGeneralInfoUpdateModal, disposeAssetGeneralInfoUpdateModal] = useQueryLoader(assetAssetUpdateModalQuery);
    return (<>
      <Panel title="Allmänt" actions={<PanelButton onClick={() => loadAssetGeneralInfoUpdateModal({ assetId: asset.id })}>Ändra</PanelButton>}>
        <Flexbox direction="column" gap="medium">
          <KeyValue label="Namn" value={asset.name}/>
          <KeyValue label="Nummer" value={asset.assetNumber}/>
          <KeyValue label="Beskrivning" value={asset.description}/>
        </Flexbox>
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {assetGeneralInfoUpdateModalRef && (<AssetGeneralInfoUpdateModal onClose={() => {
                disposeAssetGeneralInfoUpdateModal();
            }} preloadedQuery={assetGeneralInfoUpdateModalRef}/>)}
      </Suspense>
    </>);
};
export default AssetGeneralInfoPanel;
