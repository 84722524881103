/**
 * @generated SignedSource<<aad8efcef9d7e4ec38d577d8902e010f>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserAssignmentTypeSnippet_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Avatar_iHaveAvatar"
        }
    ],
    "type": "User",
    "abstractKey": null
};
node.hash = "f1ec01b718d354275c9656667c15bccf";
export default node;
