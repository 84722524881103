import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { boolean, object, string } from 'yup';
import { Form, Field, ColorInput, Switch } from '@sonika-se/ui-kit/components';
export const userSystemInfoFormValidationSchema = object().shape({
    color: string().nullable().notRequired(),
    status: boolean().notRequired(),
});
export const UserSystemInfoForm = forwardRef((props, forwardedRef) => {
    const { defaultValues, disabled, onSubmit } = props;
    const { control, formState: { errors, isValid }, handleSubmit, } = useForm({
        defaultValues,
        resolver: yupResolver(userSystemInfoFormValidationSchema),
    });
    useImperativeHandle(forwardedRef, () => ({
        isValid,
        submit: (onValid, onInvalid) => handleSubmit(onValid, onInvalid)(),
    }), [handleSubmit, isValid]);
    return (<Form onSubmit={handleSubmit((data) => {
            onSubmit(data);
        })}>
        <Field label="Aktiv">
          <Controller control={control} name="status" render={({ field }) => {
            var _a;
            return (<Switch defaultChecked={defaultValues.status} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.status) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={() => {
                    field.onChange(!field.value);
                }}/>);
        }}/>
        </Field>
        <Field label="Färg">
          <Controller control={control} name="color" render={({ field }) => {
            var _a;
            return (<ColorInput defaultValue={field.value} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.color) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange}/>);
        }}/>
        </Field>
      </Form>);
});
export default UserSystemInfoForm;
