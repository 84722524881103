/**
 * @generated SignedSource<<5c9d3fb4e75b064eb085d0b305187a5b>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "AssetProficienciesPanel_asset",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "AssetUserProficiencyConnection",
                "kind": "LinkedField",
                "name": "proficientUsers",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssetUserProficiency",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "AssignmentType",
                                "kind": "LinkedField",
                                "name": "assignmentType",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "title",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "fullName",
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "validateProficiency",
                "storageKey": null
            }
        ],
        "type": "Asset",
        "abstractKey": null
    };
})();
node.hash = "9e65a1e8d4422c28e18124d8a73c893a";
export default node;
