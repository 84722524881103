import React from 'react';
import { Divider, Flexbox, H5, Placeholder, Text } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import ScheduleShiftList from './ScheduleShiftList';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEnvironment } from '@sonika-se/ui-kit/hooks';
export const AvailableVacanciesPanel = (props) => {
    const { schedulableUserRef } = props;
    const { frontUrls } = useEnvironment();
    const intl = useIntl();
    const data = useFragment(graphql `
      fragment AvailableVacanciesPanel_schedulableUser on SchedulableUser
      @argumentDefinitions(model: { type: "GetRunningScheduleRequestInput!" }) {
        availableVacantShifts(first: 5, model: $model)
          @connection(key: "AvailableVacanciesPanel_availableVacantShifts", filters: []) {
          totalCount
          edges {
            node {
              id
            }
          }
          ...ScheduleShiftList_scheduleShiftConnection
        }
      }
    `, schedulableUserRef);
    return (<Flexbox direction="column" gap="medium">
      <Flexbox direction="column" gap="xsmall">
        <Flexbox justify="space">
          <H5>
            <FormattedMessage defaultMessage="Vakanta pass" id="DashboardPage.AvailableVacancies.header"/>
          </H5>
          <a href={`${frontUrls['SCHEDULE']}running-schedules?onlyVacant=true&hideEmptySchedules=true`}>
            <Text size="medium" bold>
              Alla vakanta pass
            </Text>
          </a>
        </Flexbox>
        <Divider />
      </Flexbox>
      {data.availableVacantShifts.totalCount === 0 ? (<Placeholder title={intl.formatMessage({
                defaultMessage: 'Inga vakanta pass',
                id: 'DashboardPage.AvailableVacancies.no_data',
            })}/>) : (<ScheduleShiftList scheduleShiftConnectionRef={data.availableVacantShifts}/>)}
    </Flexbox>);
};
export default AvailableVacanciesPanel;
