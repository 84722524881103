/**
 * @generated SignedSource<<77bc6d15ca6ca725fe2a3d70665c7007>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "assetId"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "assetId"
                }
            ],
            "concreteType": "Asset",
            "kind": "LinkedField",
            "name": "asset",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assetNumber",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AssetGeneralInfoUpdateModalQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AssetGeneralInfoUpdateModalQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "702771f34c3e62aefd1babf12f7f0d43",
            "id": null,
            "metadata": {},
            "name": "AssetGeneralInfoUpdateModalQuery",
            "operationKind": "query",
            "text": "query AssetGeneralInfoUpdateModalQuery(\n  $assetId: ID!\n) {\n  asset(id: $assetId) {\n    assetNumber\n    description\n    id\n    name\n  }\n}\n"
        }
    };
})();
node.hash = "10de529fdb7ce0d350e0e5ec155883b9";
export default node;
