import { Button, Card, CardContent, Flexbox, ModalSkeleton, Panel, Skeleton } from '@sonika-se/ui-kit/components';
import React, { Suspense, useState } from 'react';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import AssetTableQueryForm from '~/forms/AssetTableQueryForm';
import AssetExportModal, { assetExportModalQuery } from '~/modals/AssetExportModal';
import AssetTable from '~/tables/AssetTable';
export const AssetTablePanel = (props) => {
    const { actions, extended, filter, queryRef, tableProps, tableRef, title } = props;
    const data = useFragment(graphql `
      fragment AssetTablePanel_query on Query
      @argumentDefinitions(extended: { type: "Boolean!" }, filter: { type: "AssetsFilterInput" }) {
        ...AssetTable_query @arguments(extended: $extended, filter: $filter)
      }
    `, queryRef);
    const [assetTableProps, setAssetTableProps] = useState(Object.assign(Object.assign({}, tableProps), { extended,
        filter }));
    const [assetExportModalQueryRef, loadAssetExportModalQuery, disposeAssetExportModalQuery] = useQueryLoader(assetExportModalQuery);
    return (<>
      <Panel actions={actions} title={title}>
        <Flexbox direction="column" gap="small">
          <Card background="gray" shadow="none">
            <CardContent>
              <Flexbox direction="row" gap="small" justify="space">
                <AssetTableQueryForm onSubmit={(query) => {
            const { search, status } = query;
            setAssetTableProps({
                extended,
                filter: search !== undefined
                    ? Object.assign(Object.assign({}, filter), { search }) : filter,
                where: status === true
                    ? {
                        disabledDate: {
                            eq: null,
                        },
                    }
                    : status === false
                        ? {
                            disabledDate: {
                                neq: null,
                            },
                        }
                        : undefined,
            });
        }}/>
                <Button appearance="outlined" onClick={() => {
            loadAssetExportModalQuery({
                filter: assetTableProps.filter,
                where: assetTableProps.where,
            }, {
                fetchPolicy: 'network-only',
            });
        }}>
                  Exportera
                </Button>
              </Flexbox>
            </CardContent>
          </Card>
          <Suspense fallback={<Skeleton type="table"/>}>
            <AssetTable extended={extended} queryRef={data} ref={tableRef} {...assetTableProps}/>
          </Suspense>
        </Flexbox>
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {assetExportModalQueryRef && (<AssetExportModal onClose={() => {
                disposeAssetExportModalQuery();
            }} preloadedQuery={assetExportModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default AssetTablePanel;
