import React, { Suspense, useMemo } from 'react';
import { graphql, useLazyLoadQuery, useMutation, useQueryLoader } from 'react-relay/hooks';
import { useParams } from 'react-router';
import { Button, ModalSkeleton, PanelButton } from '@sonika-se/ui-kit/components';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import { ConnectionHandler } from 'relay-runtime';
import AssetTablePanel from '~/panels/AssetTablePanel';
import AssetSelectModal, { assetSelectModalQuery } from '~/shared/modals/AssetSelectModal';
export const AssignmentTypeAssetsPage = () => {
    const { assignmentTypeId } = useParams();
    const assetTableFilter = useMemo(() => ({
        assignmentTypeIdsIn: [assignmentTypeId],
    }), []);
    const hasPermissions = usePermissions();
    const assetTableExtended = hasPermissions(['EDIT_ASSETS']);
    const data = useLazyLoadQuery(graphql `
      query AssignmentTypeAssetsPageQuery($assetTableExtended: Boolean!, $assetTableFilter: AssetsFilterInput!) {
        ...AssetTablePanel_query @arguments(extended: $assetTableExtended, filter: $assetTableFilter)
      }
    `, {
        assetTableExtended,
        assetTableFilter,
    }, {
        fetchPolicy: 'network-only',
    });
    const [assetSelectModalQueryRef, loadAssetSelectModalQuery, disposeAssetSelectModalQuery] = useQueryLoader(assetSelectModalQuery);
    const [addAssetsToAssignmentType, addAssetsToAssignmentTypeLoading] = useMutation(graphql `
        mutation AssignmentTypeAssetsPage_addAssetsToAssignmentTypeMutation(
          $connectionsToPrependEdgeTo: [ID!]!
          $model: AddAssetsToAssignmentTypeInput!
        ) {
          addAssetsToAssignmentType(model: $model) {
            assetEdges @prependEdge(connections: $connectionsToPrependEdgeTo) {
              node {
                id
                name
              }
            }
          }
        }
      `);
    const [removeAssetsFromAssignmentType, removeAssetsFromAssignmentTypeLoading] = useMutation(graphql `
        mutation AssignmentTypeAssetsPage_removeAssetsToAssignmentTypeMutation(
          $connectionsToDeleteEdgeFrom: [ID!]!
          $model: RemoveAssetsFromAssignmentTypeInput!
        ) {
          removeAssetsFromAssignmentType(model: $model) {
            assetIds @deleteEdge(connections: $connectionsToDeleteEdgeFrom)
          }
        }
      `);
    const assetTableConnectionId = useMemo(() => ConnectionHandler.getConnectionID('root', 'AssetTable_query_assets'), []);
    return (<>
      <AssetTablePanel actions={<PanelButton disabled={addAssetsToAssignmentTypeLoading} onClick={() => {
                loadAssetSelectModalQuery({
                    filter: {
                        assignmentTypeIdsNotIn: [assignmentTypeId],
                    },
                }, {
                    fetchPolicy: 'network-only',
                });
            }}>
            Lägg till
          </PanelButton>} extended={assetTableExtended} filter={assetTableFilter} queryRef={data} tableProps={{
            actions: (asset) => (<Button appearance="outlined" color="danger" disabled={removeAssetsFromAssignmentTypeLoading} onClick={() => {
                    removeAssetsFromAssignmentType({
                        variables: {
                            connectionsToDeleteEdgeFrom: [assetTableConnectionId],
                            model: {
                                assignmentTypeId,
                                assetIds: [asset.id],
                            },
                        },
                    });
                }} size="xsmall">
              Ta bort
            </Button>),
        }} title="Enheter"/>
      <Suspense fallback={<ModalSkeleton />}>
        {assetSelectModalQueryRef && (<AssetSelectModal defaultFilter={{
                assignmentTypeIdsNotIn: [assignmentTypeId],
            }} onClose={() => {
                disposeAssetSelectModalQuery();
            }} onSelect={(assetIds) => {
                addAssetsToAssignmentType({
                    variables: {
                        connectionsToPrependEdgeTo: [assetTableConnectionId],
                        model: {
                            assignmentTypeId,
                            assetIds,
                        },
                    },
                });
                disposeAssetSelectModalQuery();
            }} preloadedQuery={assetSelectModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default AssignmentTypeAssetsPage;
