/**
 * @generated SignedSource<<c4def035a31ea2f4f7a0a8f629819f61>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "UpdateEventPayload",
            "kind": "LinkedField",
            "name": "updateEvent",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "EdgeOfEvent",
                    "kind": "LinkedField",
                    "name": "eventEdge",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Event",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isWork",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "EditEventIsWorkMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "EditEventIsWorkMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "50e69e4aceb330f68fe56b14de6972b8",
            "id": null,
            "metadata": {},
            "name": "EditEventIsWorkMutation",
            "operationKind": "mutation",
            "text": "mutation EditEventIsWorkMutation(\n  $model: UpdateEventRequestInput!\n) {\n  updateEvent(model: $model) {\n    eventEdge {\n      node {\n        id\n        isWork\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "3073f4d139ec8f2716a3c81b2655ed7d";
export default node;
