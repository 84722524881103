import React from 'react';
import { Route, Routes } from 'react-router';
import AssignmentTypeAssetsPage from '~/pages/AssignmentTypeAssetsPage';
import AssignmentTypeDetailsPage from '~/pages/AssignmentTypeDetailsPage';
import AssignmentTypeIndexPage from '~/pages/AssignmentTypeIndexPage';
import AssignmentTypeLayout from '~/layouts/AssignmentTypeLayout';
import AssignmentTypeUsersPage from '~/pages/AssignmentTypeUsersPage';
export const AssignmentTypeRoutes = () => (<Routes>
    <Route path="" element={<AssignmentTypeIndexPage />}/>
    <Route element={<AssignmentTypeLayout />} path=":assignmentTypeId/*">
      <Route path="" element={<AssignmentTypeDetailsPage />}/>
      <Route path="assets" element={<AssignmentTypeAssetsPage />}/>
      <Route path="users" element={<AssignmentTypeUsersPage />}/>
    </Route>
  </Routes>);
export default AssignmentTypeRoutes;
