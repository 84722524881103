import React, { useEffect, useMemo, useRef } from 'react';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { DataTable, Placeholder, Text } from '@sonika-se/ui-kit/components';
import { Link } from 'react-router-dom';
const RoleTable = (props) => {
    const { queryRef, where } = props;
    const { data, hasNext, isLoadingNext, loadNext, refetch } = usePaginationFragment(graphql `
      fragment RoleTable_query on Query
      @argumentDefinitions(
        after: { type: "String" }
        first: { type: "Int", defaultValue: 50 }
        where: { type: "RoleFilterInput" }
      )
      @refetchable(queryName: "RoleTablePaginationQuery") {
        roles(after: $after, first: $first, where: $where) @connection(key: "RoleTable_query_roles", filters: []) {
          edges {
            node {
              id
              name
              rolePermissions
              usersInRole {
                totalCount
              }
            }
          }
        }
      }
    `, queryRef);
    const firstRender = useRef(true);
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        refetch({ where }, { fetchPolicy: 'network-only' });
    }, [where]);
    const roles = useMemo(() => data.roles.edges.map(({ node: role }) => ({
        name: role,
        userCount: role.usersInRole.totalCount,
        permissionCount: role.rolePermissions.length,
    })), [data]);
    return roles.length === 0 ? (<Placeholder text="Inga grupper."/>) : (<DataTable columns={{
            name: {
                text: 'Namn',
                render: ({ id, name }) => (<Text bold>
              <Link to={`/groups/${id}`}>{name}</Link>
            </Text>),
            },
            userCount: {
                text: 'Användare',
            },
            permissionCount: {
                text: 'Rättigheter',
                width: 1,
            },
        }} loading={isLoadingNext} onPaginate={() => {
            loadNext(50);
        }} pagination={{
            buttonText: 'Ladda fler',
            hasNextPage: hasNext,
        }} rows={roles}/>);
};
export default RoleTable;
