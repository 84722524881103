var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from 'react';
import { graphql, useMutation, usePreloadedQuery } from 'react-relay/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { faCamera, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { DropZone, Flexbox, Button, Icon, Confirm, ModalToSheet, ModalToSheetHeader, ModalToSheetContent, } from '@sonika-se/ui-kit/components';
import { useApi, useToasts } from '@sonika-se/ui-kit/hooks';
import { blobToDataURL } from '@sonika-se/ui-kit/utils/misc';
export const changeAvatarModalQuery = graphql `
  query IHaveAvatarUpdateModalQuery($iHaveAvatarId: ID!) {
    node(id: $iHaveAvatarId) {
      ... on IHaveAvatar {
        avatarImage: avatar {
          id
          storageId
          avatarUrl: url(queryTransform: "width=400&height=400&mode=crop&scale=both")
        }
      }
      ... on Node {
        id
      }
    }
  }
`;
const useStyles = createUseStyles((theme) => {
    return {
        avatarImage: {
            height: '22rem',
            width: '22rem',
            borderRadius: '.25rem',
        },
        loadingContainer: {
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            fontSize: '3rem',
            color: theme.colors.foreground.placeholder,
            justifyContent: 'center',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            marginTop: '0px !important',
            backgroundColor: `${theme.colors.background.primary}90`,
        },
    };
});
export const IHaveAvatarUpdateModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const [image, setImage] = useState('');
    const [loading, setLoading] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const classes = useStyles();
    const inputFileRef = useRef();
    const intl = useIntl();
    const { node: { avatarImage, id }, } = usePreloadedQuery(changeAvatarModalQuery, preloadedQuery);
    const { fetch, uploadFile } = useApi();
    const { addToast } = useToasts();
    const [setAvatar] = useMutation(graphql `
      mutation IHaveAvatarUpdateModal_setAvatarMutation($model: SetAvatarRequestInput!) {
        setAvatar(model: $model) {
          ... on IHaveAvatar {
            ...Avatar_iHaveAvatar
            avatarImage: avatar {
              avatarUrl: url(queryTransform: "width=400&height=400&mode=crop&scale=both")
              id
              storageId
            }
          }
        }
      }
    `);
    const onUpload = (file) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        const response = yield uploadFile(file, 'tenants/v1/Avatar/upload');
        if ((response === null || response === void 0 ? void 0 : response.error) || (response === null || response === void 0 ? void 0 : response.file)) {
            setLoading(false);
            addToast((response === null || response === void 0 ? void 0 : response.file) ||
                intl.formatMessage({
                    defaultMessage: 'Bilden kunde inte laddas upp',
                    id: 'IHaveAvatarUpdateModal.upload.error',
                }), {
                color: 'error',
            });
        }
        else {
            yield setAvatar({
                onCompleted: () => {
                    setLoading(false);
                },
                onError: () => {
                    setLoading(false);
                    addToast(intl.formatMessage({
                        defaultMessage: 'Bilden kunde inte laddas upp',
                        id: 'IHaveAvatarUpdateModal.upload.error',
                    }), {
                        color: 'error',
                    });
                },
                variables: {
                    model: {
                        storageId: response === null || response === void 0 ? void 0 : response.storageId,
                        iHaveAvatarId: id,
                    },
                },
            });
        }
    });
    useEffect(() => {
        if (avatarImage === null || avatarImage === void 0 ? void 0 : avatarImage.avatarUrl) {
            fetch(avatarImage === null || avatarImage === void 0 ? void 0 : avatarImage.avatarUrl)
                .then((response) => response.blob())
                .then((blob) => blobToDataURL(blob))
                .then((dataUrl) => {
                setImage(dataUrl);
                setLoading(false);
            });
        }
        else {
            setImage('');
        }
    }, [avatarImage]);
    return (<ModalToSheet onClose={onClose} size="small">
      <ModalToSheetHeader onClose={onClose} closeButtonLabel={intl.formatMessage({ id: 'general.actions.close', defaultMessage: 'Stäng' })} actions={!!avatarImage
            ? [
                {
                    label: intl.formatMessage({
                        defaultMessage: 'Ta bort bild',
                        id: 'IHaveAvatarUpdateModal.actions.delete_image',
                    }),
                    onClick: () => {
                        setConfirmDelete(true);
                    },
                },
            ]
            : undefined}>
        <FormattedMessage defaultMessage="Uppdatera bild" id="IHaveAvatarUpdateModal.title"/>
      </ModalToSheetHeader>
      <ModalToSheetContent>
        {!avatarImage &&
            (!loading ? (<DropZone onChange={(files) => {
                    onUpload(files[0]);
                }} multiple={false} accept="image/*,image/heif,image/heic" style={{ height: '18rem', display: 'flex' }} headerText={intl.formatMessage({
                    id: 'IHaveAvatarUpdateModal.select_image',
                    defaultMessage: 'Släpp bild här eller klicka för att välja',
                })} description={intl.formatMessage({
                    id: 'IHaveAvatarUpdateModal.image_restrictions',
                    defaultMessage: 'Bilden får max vara 2MB',
                })}/>) : (<div style={{ position: 'relative' }}>
              <object data={image} type="image/png" className={classes.avatarImage}/>
              {loading && (<div className={classes.loadingContainer}>
                  <Icon icon={faSpinner} spin/>
                </div>)}
            </div>))}
        {!!avatarImage && (<Flexbox direction="column" gap="medium">
            <div style={{ position: 'relative' }}>
              <object data={image} type="image/png" className={classes.avatarImage}/>
              {loading && (<div className={classes.loadingContainer}>
                  <Icon icon={faSpinner} spin/>
                </div>)}
            </div>
            <Button disabled={loading} onClick={() => {
                var _a;
                ;
                (_a = ((inputFileRef === null || inputFileRef === void 0 ? void 0 : inputFileRef.current) || {
                    click: () => {
                        console.log('no reference');
                    },
                })) === null || _a === void 0 ? void 0 : _a.click();
            }}>
              <FormattedMessage defaultMessage="Ladda upp ny bild" id="IHaveAvatarUpdateModal.upload_new_image"/>
            </Button>
            <input type="file" accept="image/*,image/heif,image/heic" autoComplete="off" tabIndex={-1} style={{ display: 'none' }} ref={inputFileRef} onChange={(event) => {
                var _a, _b;
                if ((_b = (_a = event.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b.length) {
                    onUpload(event.target.files[0]);
                }
            }}/>
          </Flexbox>)}
      </ModalToSheetContent>
      {confirmDelete && (<Confirm appearance="danger" icon={faCamera} headerText={intl.formatMessage({
                defaultMessage: 'Är du säker på att du vill ta bort bilden?',
                id: 'IHaveAvatarUpdateModal.delete_image.header_text',
            })} onCancel={() => setConfirmDelete(false)} confirmButton={{
                text: intl.formatMessage({
                    defaultMessage: 'Ta bort bild',
                    id: 'IHaveAvatarUpdateModal.delete_image.button_label',
                }),
                onConfirm: () => {
                    setLoading(true);
                    setConfirmDelete(false);
                    setAvatar({
                        onCompleted: () => setLoading(false),
                        onError: (error) => {
                            setLoading(false);
                            setLoading(false);
                            addToast(intl.formatMessage({
                                defaultMessage: 'Bilden kunde inte tas bort',
                                id: 'IHaveAvatarUpdateModal.delete_image.error',
                            }), {
                                color: 'error',
                            });
                        },
                        variables: {
                            model: {
                                storageId: null,
                                iHaveAvatarId: id,
                            },
                        },
                    });
                },
            }}/>)}
    </ModalToSheet>);
};
export default IHaveAvatarUpdateModal;
