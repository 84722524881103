/**
 * @generated SignedSource<<30ce8e105b378aed1d5d5b0448ea50fd>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userId"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": [
            {
                "kind": "Variable",
                "name": "id",
                "variableName": "userId"
            }
        ],
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
            (v1 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "services",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "activeServices",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "concreteType": "ServiceInfo",
        "kind": "LinkedField",
        "name": "services",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserServicesUpdateModalQuery",
            "selections": [
                (v2 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "currentCompany",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                },
                (v4 /*: any*/)
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserServicesUpdateModalQuery",
            "selections": [
                (v2 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Company",
                    "kind": "LinkedField",
                    "name": "currentCompany",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        (v1 /*: any*/)
                    ],
                    "storageKey": null
                },
                (v4 /*: any*/)
            ]
        },
        "params": {
            "cacheID": "8e2b890f0e29ccc26ffa03362dc2adf7",
            "id": null,
            "metadata": {},
            "name": "UserServicesUpdateModalQuery",
            "operationKind": "query",
            "text": "query UserServicesUpdateModalQuery(\n  $userId: ID!\n) {\n  user(id: $userId) {\n    id\n    services\n  }\n  currentCompany {\n    activeServices\n    id\n  }\n  services {\n    value\n    name\n  }\n}\n"
        }
    };
})();
node.hash = "a44d5885122ecadfee37f3c409d88762";
export default node;
