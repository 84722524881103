import { NewInlineSelect, SelectDropdown } from '@sonika-se/ui-kit/components';
import { useDebounce } from '@sonika-se/ui-kit/hooks';
import React, { Suspense, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import useTags from '~/hooks/tags/useTags';
const TagsList = (props) => {
    var _a;
    const { onSelected, selected, search, onLoading, isLoading } = props;
    const { tags, setTags, createTagLoading, createTag, hasNext, loadNext, isLoadingNext, refetch } = useTags({
        setTags: onSelected,
        defaultTags: selected === null || selected === void 0 ? void 0 : selected.map(({ value }) => value),
    });
    useEffect(() => {
        onLoading(createTagLoading);
    }, [createTagLoading]);
    useEffect(() => {
        refetch({
            where: {
                name: { contains: search },
            },
        }, { fetchPolicy: 'network-only' });
    }, [search]);
    return (<SelectDropdown selectedItems={selected} isCreatable isLoading={isLoading} searchString={search} onCreate={(name) => {
            createTag({ name });
        }} setSelected={(value) => {
            setTags({
                tagIds: [...selected === null || selected === void 0 ? void 0 : selected.map((item) => item.value), value === null || value === void 0 ? void 0 : value.value],
            });
        }} onLastItemVisible={hasNext && !isLoadingNext
            ? () => {
                loadNext(10);
            }
            : undefined} options={(_a = tags === null || tags === void 0 ? void 0 : tags.edges) === null || _a === void 0 ? void 0 : _a.map(({ node }) => ({ label: node === null || node === void 0 ? void 0 : node.name, value: node === null || node === void 0 ? void 0 : node.id }))}/>);
};
const Tags = (props) => {
    const { tags, setTagsLoading, setTags } = props;
    const [selected, setSelected] = useState([]);
    const [search, setSearch] = useState('');
    const delayedSearch = useDebounce(search, 300);
    const [isLoading, setIsLoading] = useState(setTagsLoading);
    useEffect(() => {
        var _a, _b;
        setSelected((_b = (_a = tags === null || tags === void 0 ? void 0 : tags.tags) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map(({ node }) => { var _a; return ({ value: node === null || node === void 0 ? void 0 : node.tagId, label: (_a = node === null || node === void 0 ? void 0 : node.tag) === null || _a === void 0 ? void 0 : _a.name }); }));
        setSearch('');
    }, [tags]);
    useEffect(() => {
        setIsLoading(setTagsLoading);
    }, [setTagsLoading]);
    const intl = useIntl();
    return (<NewInlineSelect isLoading={isLoading} searchString={search} selectedItems={selected} disabled={false} onSearch={(value) => {
            setSearch(value);
        }} onChange={(values) => {
            setTags({ tagIds: values === null || values === void 0 ? void 0 : values.map((item) => item === null || item === void 0 ? void 0 : item.value) });
        }} multiple placeholder={intl.formatMessage({
            defaultMessage: 'Välj taggar',
            id: 'general.misc.tags.placeholder',
        })} optionsList={<Suspense fallback={<SelectDropdown searchString="" isLoading setSelected={() => console.log('Skeleton')} selectedItems={[]}/>}>
          <TagsList selected={selected} onSelected={(tagIds) => setTags(tagIds)} onLoading={(loading) => setIsLoading(loading)} isLoading={isLoading} search={delayedSearch}/>
        </Suspense>}/>);
};
export default Tags;
