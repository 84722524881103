/**
 * @generated SignedSource<<762d5abb98b858669cb5078f5f63a093>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "BankIdLoginPayload",
            "kind": "LinkedField",
            "name": "bankIdLogin",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "token",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "refreshToken",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasMultipleUsers",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "BankIdForm_BankIdLoginMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "BankIdForm_BankIdLoginMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "4c13b75b77b593f0e94d58b930c06994",
            "id": null,
            "metadata": {},
            "name": "BankIdForm_BankIdLoginMutation",
            "operationKind": "mutation",
            "text": "mutation BankIdForm_BankIdLoginMutation(\n  $model: BankIdLoginInput!\n) {\n  bankIdLogin(model: $model) {\n    token\n    refreshToken\n    hasMultipleUsers\n  }\n}\n"
        }
    };
})();
node.hash = "850582dda3e518b2b5420a003f1a277a";
export default node;
