/**
 * @generated SignedSource<<8cebfb4a274893bb4a1ba1d034c01a93>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "assetId"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "assetId"
                }
            ],
            "concreteType": "Asset",
            "kind": "LinkedField",
            "name": "asset",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "city",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "postalCode",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "street",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AssetAddressUpdateModalQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AssetAddressUpdateModalQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "426499e8c243886c5965024fb1f883fd",
            "id": null,
            "metadata": {},
            "name": "AssetAddressUpdateModalQuery",
            "operationKind": "query",
            "text": "query AssetAddressUpdateModalQuery(\n  $assetId: ID!\n) {\n  asset(id: $assetId) {\n    city\n    id\n    postalCode\n    street\n  }\n}\n"
        }
    };
})();
node.hash = "98ee34fd8c3339be7136431d02177ee3";
export default node;
