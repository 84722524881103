import React, { Suspense } from 'react';
import { Flexbox, KeyValue, ModalSkeleton, Panel, PanelButton } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import UserUsernameUpdateModal, { userSystemInfoUpdateModalQuery } from '~/modals/UserUsernameUpdateModal';
export const UserUsernamePanel = (props) => {
    const { editable = true, userRef } = props;
    const user = useFragment(graphql `
      fragment UserUsernamePanel_user on User {
        color
        id
        username
      }
    `, userRef);
    const [userSystemInfoUpdateModalRef, loadUserUsernameUpdateModal, disposeUserUsernameUpdateModal] = useQueryLoader(userSystemInfoUpdateModalQuery);
    return (<>
      <Panel title="Användarnamn" actions={editable ? (<PanelButton onClick={() => loadUserUsernameUpdateModal({ userId: user.id })}>Ändra</PanelButton>) : undefined}>
        <Flexbox direction="row" gap="xlarge">
          <KeyValue label="Användarnamn" value={user.username}/>
        </Flexbox>
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {userSystemInfoUpdateModalRef && (<UserUsernameUpdateModal onClose={() => {
                disposeUserUsernameUpdateModal();
            }} preloadedQuery={userSystemInfoUpdateModalRef}/>)}
      </Suspense>
    </>);
};
export default UserUsernamePanel;
