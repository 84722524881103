/**
 * @generated SignedSource<<5ca6b0b8fb9c210783d6665829219bbc>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "DeleteFaqPostAttachmentPayload",
            "kind": "LinkedField",
            "name": "deleteFaqPostAttachment",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "EdgeOfFaqPostAttachment",
                    "kind": "LinkedField",
                    "name": "faqPostAttachmentEdge",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "FaqPostAttachment",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "useFaqPostAttachments_deleteFileMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "useFaqPostAttachments_deleteFileMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "26fa69a6e9f9e5a5f579b43b3fac0cee",
            "id": null,
            "metadata": {},
            "name": "useFaqPostAttachments_deleteFileMutation",
            "operationKind": "mutation",
            "text": "mutation useFaqPostAttachments_deleteFileMutation(\n  $model: DeleteFaqPostAttachmentRequestInput!\n) {\n  deleteFaqPostAttachment(model: $model) {\n    faqPostAttachmentEdge {\n      node {\n        id\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "03cfb0bd41ef2bf7f758f378c157c02b";
export default node;
