import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Field, Form, Input, Select2 } from '@sonika-se/ui-kit/components';
import { Controller, useForm } from 'react-hook-form';
import { boolean, object, string } from 'yup';
export const assignmentTypeQueryFormSchemaValidationSchema = object({
    search: string().notRequired(),
    status: boolean().notRequired(),
});
export const AssignmentTypeTableQueryForm = (props) => {
    const { defaultValues, disabled, onSubmit } = props;
    const { control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues,
        resolver: yupResolver(assignmentTypeQueryFormSchemaValidationSchema),
    });
    return (<Form columns="40% auto auto 1fr" gap="small" onSubmit={handleSubmit((data) => {
            onSubmit === null || onSubmit === void 0 ? void 0 : onSubmit(data);
        })}>
      <Field>
        <Controller control={control} name="search" render={({ field }) => {
            var _a;
            return (<Input defaultValue={defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.search} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.search) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} placeholder="Fritextsök"/>);
        }}/>
      </Field>
      <Field>
        <Controller control={control} name="status" render={({ field }) => (<Select2 defaultValue={(defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.status) ? [defaultValues.status] : undefined} disabled={disabled} multiple={false} onBlur={() => field.onBlur()} onChange={({ single }) => field.onChange(single)} options={[
                {
                    label: 'Alla',
                    value: undefined,
                },
                {
                    label: 'Aktiv',
                    value: true,
                },
                {
                    label: 'Inaktiv',
                    value: false,
                },
            ]} placeholder="Status"/>)}/>
      </Field>
      <Button type="submit">Filtrera</Button>
    </Form>);
};
export default AssignmentTypeTableQueryForm;
