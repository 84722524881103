import React from 'react';
import { Flexbox, H4, H2, Text } from '@sonika-se/ui-kit/components';
import { useNavigate } from 'react-router';
import { graphql, useFragment } from 'react-relay/hooks';
import FaqPostList from '~/pages/FaqPage/components/FaqPostList';
import { FormattedMessage } from 'react-intl';
import { useViewport } from '@sonika-se/ui-kit/hooks';
import { Link } from 'react-router-dom';
export const FaqPanel = (props) => {
    const { queryRef } = props;
    const data = useFragment(graphql `
      fragment FaqPanel_query on Query {
        ...FaqPostList_query @arguments(first: 5, order: { createdDate: DESC }, where: { status: { eq: PUBLISHED } })
      }
    `, queryRef);
    const navigate = useNavigate();
    const { size } = useViewport();
    return (<Flexbox direction="column" gap="medium">
      <Flexbox justify="space" align="center">
        {size !== 'small' ? (<H2>
            <FormattedMessage defaultMessage="Kunskapsbank" id="FaqPanel.header"/>
          </H2>) : (<H4>
            <FormattedMessage defaultMessage="Kunskapsbank" id="FaqPanel.header"/>
          </H4>)}
        <Link to="/faq">
          <Text size="medium" bold>
            Visa alla
          </Text>
        </Link>
      </Flexbox>
      <FaqPostList editable={false} queryRef={data} loadMore={false}/>
    </Flexbox>);
};
export default FaqPanel;
