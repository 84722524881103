/**
 * @generated SignedSource<<ee945aa98dcebe39bb42459c890f104e>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignmentTypeStatusPanel_assignmentType",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "disabledDate",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        }
    ],
    "type": "AssignmentType",
    "abstractKey": null
};
node.hash = "3a1faa36c4dc001de4e6c180308059e8";
export default node;
