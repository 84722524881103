import React, { useState } from 'react';
import { BasicPage, BasicPageHeader, Button, Flexbox } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { ConnectionHandler } from 'relay-runtime';
import UserCreateModal from '~/modals/UserCreateModal';
import UserTablePanel from '~/panels/UserTablePanel';
export const UserIndexPage = () => {
    const data = useLazyLoadQuery(graphql `
      query UserIndexPageQuery {
        ...UserTablePanel_query @arguments(extended: true)
      }
    `, {}, {
        fetchPolicy: 'network-only',
    });
    const [userCreateModalVisible, setUserCreateModalVisible] = useState(false);
    return (<>
      <BasicPage width="large">
        <BasicPageHeader title="Användare" action={<Flexbox direction="row" gap="xsmall">
              <Button onClick={() => {
                setUserCreateModalVisible(true);
            }}>
                Lägg till användare
              </Button>
            </Flexbox>}/>
        <UserTablePanel extended queryRef={data}/>
      </BasicPage>
      {userCreateModalVisible && (<UserCreateModal connectionsToPrependEdge={[ConnectionHandler.getConnectionID('root', 'UserTable_query_users')]} onClose={() => {
                setUserCreateModalVisible(false);
            }}/>)}
    </>);
};
export default UserIndexPage;
