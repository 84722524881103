import React from 'react';
import { useIntl } from 'react-intl';
import { useFragment, graphql, useMutation } from 'react-relay/hooks';
import { Flexbox, Switch } from '@sonika-se/ui-kit/components';
export const EditEventIsUnsocial = (props) => {
    const { eventRef } = props;
    const intl = useIntl();
    const { id, isUnsocial } = useFragment(graphql `
      fragment EditEventIsUnsocial_event on Event {
        id
        isUnsocial
      }
    `, eventRef);
    const [updateEvent, updateEventLoading] = useMutation(graphql `
    mutation EditEventIsUnsocialMutation($model: UpdateEventRequestInput!) {
      updateEvent(model: $model) {
        eventEdge {
          node {
            id
            isUnsocial
          }
        }
      }
    }
  `);
    return (<Flexbox align="center">
      <Switch disabled={updateEventLoading} checked={isUnsocial} label={intl.formatMessage({
            id: 'EventSettings.IsUnsocial',
            defaultMessage: 'Är obekväm arbetstid',
        })} onChange={() => {
            updateEvent({
                variables: {
                    model: {
                        id,
                        isUnsocial: !isUnsocial,
                    },
                },
            });
        }}/>
    </Flexbox>);
};
export default EditEventIsUnsocial;
