import React, { Suspense } from 'react';
import { ColorItem, Flexbox, KeyValue, getColor, ModalSkeleton, Panel, PanelButton } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import AssetSystemInfoUpdateModal, { assetSystemInfoUpdateModalQuery } from '~/modals/AssetSystemInfoUpdateModal';
export const AssetSystemInfoPanel = (props) => {
    var _a;
    const { assetRef } = props;
    const asset = useFragment(graphql `
      fragment AssetSystemInfoPanel_asset on Asset {
        color
        id
        disabledDate
      }
    `, assetRef);
    const [assetSystemInfoUpdateModalRef, loadAssetSystemInfoUpdateModal, disposeAssetSystemInfoUpdateModal] = useQueryLoader(assetSystemInfoUpdateModalQuery);
    return (<>
      <Panel title="Systeminformation" actions={<PanelButton onClick={() => loadAssetSystemInfoUpdateModal({ assetId: asset.id })}>Ändra</PanelButton>}>
        <Flexbox direction="row" gap="xlarge">
          <KeyValue label="Status">{asset.disabledDate !== null ? 'Inaktiv' : 'Aktiv'}</KeyValue>
          <KeyValue label="Färg">
            <ColorItem color={{ hex: asset.color, name: (_a = getColor(asset.color)) === null || _a === void 0 ? void 0 : _a.name }}/>
          </KeyValue>
        </Flexbox>
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {assetSystemInfoUpdateModalRef && (<AssetSystemInfoUpdateModal onClose={() => {
                disposeAssetSystemInfoUpdateModal();
            }} preloadedQuery={assetSystemInfoUpdateModalRef}/>)}
      </Suspense>
    </>);
};
export default AssetSystemInfoPanel;
