import { Flexbox } from '@sonika-se/ui-kit/components';
import { addYears, formatISO } from 'date-fns';
import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { useParams } from 'react-router';
import UserContactPanel from '~/panels/UserContactPanel';
import UserUpcomingScheduleShiftsPanel from '~/panels/UserUpcomingScheduleShiftsPanel';
export const ColleagueDetailsPage = () => {
    var _a;
    const { userId } = useParams();
    const { user } = useLazyLoadQuery(graphql `
      query ColleagueDetailsPageQuery($userId: ID!, $scheduleItemsModel: GetRunningScheduleRequestInput!) {
        user(id: $userId) {
          ...UserContactPanel_user
          id
          schedulableEntity {
            schedulable
            ...UserUpcomingScheduleShiftsPanel_schedulableEntity @arguments(model: $scheduleItemsModel)
          }
        }
      }
    `, {
        userId,
        scheduleItemsModel: {
            fromDate: formatISO(Date.now()),
            toDate: formatISO(addYears(Date.now(), 1)),
        },
    }, {
        fetchPolicy: 'network-only',
    });
    return (<Flexbox direction="column" gap="large">
      <UserContactPanel editable={false} userRef={user}/>
      {((_a = user.schedulableEntity) === null || _a === void 0 ? void 0 : _a.schedulable) && (<UserUpcomingScheduleShiftsPanel schedulableEntityRef={user.schedulableEntity}/>)}
    </Flexbox>);
};
export default ColleagueDetailsPage;
