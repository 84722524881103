import React from 'react';
import { Route, Routes } from 'react-router';
import ServiceLayout from '~/layouts/ServiceLayouts';
import ServiceDetailsPage from '~/pages/ServiceDetailsPage';
import ServiceIndexPage from '~/pages/ServiceIndexPage';
import ServiceUsersPage from '~/ServiceUsersPage';
export const ServiceRoutes = () => (<Routes>
    <Route path="" element={<ServiceIndexPage />}/>
    <Route element={<ServiceLayout />} path=":service/*">
      <Route path="" element={<ServiceDetailsPage />}/>
      <Route path="users" element={<ServiceUsersPage />}/>
    </Route>
  </Routes>);
export default ServiceRoutes;
