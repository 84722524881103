/**
 * @generated SignedSource<<c3b03327a334a5d45bea1a45e2d13315>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "roleId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "roleId"
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RolePermissionsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Role",
                    "kind": "LinkedField",
                    "name": "role",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "RolePermissionsPanel_role"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RolePermissionsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Role",
                    "kind": "LinkedField",
                    "name": "role",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rolePermissions",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "796d236d83a8d60de6301c5d4e58e479",
            "id": null,
            "metadata": {},
            "name": "RolePermissionsPageQuery",
            "operationKind": "query",
            "text": "query RolePermissionsPageQuery(\n  $roleId: ID!\n) {\n  role(id: $roleId) {\n    ...RolePermissionsPanel_role\n    id\n  }\n}\n\nfragment RolePermissionsPanel_role on Role {\n  id\n  rolePermissions\n}\n"
        }
    };
})();
node.hash = "6af3041d0841dd236051937a2f55ac5f";
export default node;
