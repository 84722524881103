import * as React from 'react';
const SystemTexts = (props) => {
    // const { onChange } = props
    // const data = useLazyLoadQuery<any>(
    //   graphql`
    //     query SystemTextsGetTextsQuery($locale: Locale!) {
    //       ...SystemTexts_texts @arguments(locale: $locale)
    //     }
    //   `,
    //   {
    //     locale: 'SV_SE',
    //   },
    // )
    // const [texts, refetch] = useRefetchableFragment<any, any>(
    //   graphql`
    //     fragment SystemTexts_texts on Query
    //     @argumentDefinitions(locale: { type: "Locale!" })
    //     @refetchable(queryName: "SystemTexts_textsQuery") {
    //       texts(locale: $locale) {
    //         edges {
    //           node {
    //             key
    //             value
    //             locale
    //             scope
    //             id
    //           }
    //         }
    //       }
    //     }
    //   `,
    //   data,
    // )
    /*
    const configUpdate = React.useMemo(() => {
      return {
        onCompleted: () => {
          console.log('completed')
        },
        onError: (error) => {
          console.log({ error })
        },
        onNext: (next) => {
          console.log({ next })
          refetch(
            {
              locale: 'SV_SE',
            },
            { fetchPolicy: 'network-only' },
          )
        },
        subscription: subscriptionUpdate,
      } as GraphQLSubscriptionConfig<SystemTextsUpdateSubscription>
    }, [])
  
    const configDelete = React.useMemo(() => {
      return {
        onCompleted: () => {
          console.log('completed')
        },
        onError: (error) => {
          console.log({ error })
        },
        onNext: (next) => {
          console.log({ next })
          refetch(
            {
              locale: 'SV_SE',
            },
            { fetchPolicy: 'network-only' },
          )
        },
        subscription: subscriptionDelete,
      } as GraphQLSubscriptionConfig<SystemTextsDeleteSubscription>
    }, [])
  
    const configCreate = React.useMemo(() => {
      return {
        onCompleted: () => {
          console.log('completed')
        },
        onError: (error) => {
          console.log({ error })
        },
        onNext: (next) => {
          console.log({ next })
          refetch(
            {
              locale: 'SV_SE',
            },
            { fetchPolicy: 'network-only' },
          )
        },
        subscription: subscriptionCreate,
      } as GraphQLSubscriptionConfig<SystemTextsCreateSubscription>
    }, [])
  
    useSubscription(configUpdate)
    useSubscription(configDelete)
    useSubscription(configCreate)
    */
    // React.useEffect(() => {
    //   onChange(texts?.texts?.edges)
    // }, [texts])
    return <div />;
};
export default SystemTexts;
