import React from 'react';
import { useMutation, graphql } from 'react-relay/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { Form, FormSection, Field, Input, FormFooter, ColorInput, Flexbox, Button, Divider, ModalToSheet, ModalToSheetContent, ModalToSheetHeader, } from '@sonika-se/ui-kit/components';
import { useViewport } from '@sonika-se/ui-kit/hooks';
const validationSchema = object({
    name: string().required('Du måste ange ett namn'),
    color: string(),
});
export const CreateEventModal = (props) => {
    const { onCancel, open = false } = props;
    const [createEvent, isInFlight] = useMutation(graphql `
    mutation CreateEventModalMutation($model: CreateEventRequestInput!) {
      createEvent(model: $model) {
        eventEdge {
          node {
            id
            additionUnit
            assignmentTypes {
              id
              title
            }
            color
            disabled
            name
            isAbsence
            isAddition
            isAssigneeReplacementReason
            isWork
            isUnsocial
          }
        }
      }
    }
  `);
    const { size: pageSize } = useViewport();
    const intl = useIntl();
    const { control, formState: { errors }, handleSubmit, register, } = useForm({
        resolver: yupResolver(validationSchema),
    });
    if (!open)
        return null;
    const content = (<Form onSubmit={handleSubmit((data) => {
            createEvent({
                variables: {
                    model: data,
                },
                configs: [
                    {
                        connectionInfo: [
                            {
                                key: 'EventTableRefetch_events',
                                rangeBehavior: 'append',
                            },
                        ],
                        parentID: 'client:root',
                        edgeName: 'eventEdge',
                        type: 'RANGE_ADD',
                    },
                ],
                onCompleted: () => {
                    onCancel();
                },
            });
        })}>
      <FormSection>
        <Field label={intl.formatMessage({
            defaultMessage: 'Namn',
            id: 'general.misc.event.fields.name.label',
        })}>
          <Controller control={control} name="name" render={({ field }) => {
            var _a, _b;
            return (<Input disabled={isInFlight} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.name) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} onBlur={field.onBlur} onChange={field.onChange} placeholder={intl.formatMessage({
                    defaultMessage: 'Händelsens namn',
                    id: 'general.misc.event.fields.name.placeholder',
                })} value={field.value}/>);
        }}/>
        </Field>
        <Field label={intl.formatMessage({
            defaultMessage: 'Färg',
            id: 'general.misc.color.name.singular',
        })}>
          <Controller control={control} name="color" render={({ field }) => (<ColorInput disabled={isInFlight} onBlur={field.onBlur} onChange={field.onChange} placeholder={intl.formatMessage({
                defaultMessage: 'Välj färg',
                id: 'general.misc.color.actions.select',
            })} value={field.value}/>)}/>
        </Field>
      </FormSection>
      {pageSize !== 'small' && (<FormFooter disabled={isInFlight} submitText={intl.formatMessage({ id: 'general.actions.save', defaultMessage: 'Spara' })} onCancel={() => onCancel()}/>)}
      {pageSize === 'small' && (<Flexbox direction="column" gap="medium">
          <Divider />
          <Button type="submit">
            <FormattedMessage id="general.actions.save" defaultMessage="Spara"/>
          </Button>
          <Button onClick={onCancel} appearance="outlined" style={{ marginBottom: '1rem' }}>
            <FormattedMessage id="general.actions.cancel" defaultMessage="Avbryt"/>
          </Button>
        </Flexbox>)}
    </Form>);
    return (<ModalToSheet size="small" onClose={onCancel}>
      <ModalToSheetHeader onClose={onCancel} closeButtonLabel={intl.formatMessage({ id: 'general.actions.cancel', defaultMessage: 'Avbryt' })}>
        <FormattedMessage id="general.actions.create_event" defaultMessage="Lägg till händelse"/>
      </ModalToSheetHeader>
      <ModalToSheetContent>{content}</ModalToSheetContent>
    </ModalToSheet>);
};
export default CreateEventModal;
