import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { array, object, string } from 'yup';
import { Checkbox, Field, Form, FormSection } from '@sonika-se/ui-kit/components';
export const rolesSelectFormValidationSchema = object({
    fields: array().of(string()).required().min(1),
});
export const ExportForm = forwardRef((props, forwardedRef) => {
    const { defaultValues, disabled, onSubmit, selectableFields } = props;
    const { control, handleSubmit, formState: { isValid }, } = useForm({
        defaultValues,
        resolver: yupResolver(rolesSelectFormValidationSchema),
    });
    useImperativeHandle(forwardedRef, () => ({
        isValid,
        submit: (onValid, onInvalid) => handleSubmit(onValid, onInvalid)(),
    }), [handleSubmit, isValid]);
    return (<Form onSubmit={handleSubmit((data) => {
            onSubmit(data);
        })}>
      <Controller control={control} name="fields" render={({ field }) => (<>
            {selectableFields.map((group) => (<FormSection columns="1fr 1fr 1fr" key={group.title} title={group.title}>
                {group.fields.map((column) => (<Field direction="row-reverse" key={column.name} label={column.label}>
                    <Checkbox defaultChecked={defaultValues.fields.includes(column.name)} disabled={disabled} onBlur={field.onBlur} onChange={(event) => {
                        if (event.target.checked) {
                            field.onChange([...field.value, column.name]);
                        }
                        else {
                            field.onChange([...field.value].filter((id) => id !== column.name));
                        }
                    }}/>
                  </Field>))}
              </FormSection>))}
          </>)}/>
    </Form>);
});
export default ExportForm;
