import React, { Suspense } from 'react';
import { Flexbox, H2, Skeleton, H4 } from '@sonika-se/ui-kit/components';
import { usePermissions, useViewport } from '@sonika-se/ui-kit/hooks';
import { graphql, useFragment } from 'react-relay/hooks';
import { FormattedMessage } from 'react-intl';
import MySchedulePanel from './MySchedulePanel';
import AvailableVacanciesPanel from './AvailableVacanciesPanel';
import AbsencePanel from './AbsencePanel';
const SchedulePanel = (props) => {
    var _a, _b;
    const { size } = useViewport();
    const hasPermissions = usePermissions();
    const data = useFragment(graphql `
      fragment SchedulePanel_user on User
      @argumentDefinitions(
        availableVacanciesModel: { type: "GetRunningScheduleRequestInput!" }
        myScheduleModel: { type: "GetRunningScheduleRequestInput!" }
        myScheduleFilterModel: { type: "ScheduleItemsFilterInput" }
      ) {
        schedulableEntity {
          id
          schedulable
          ...MySchedulePanel_schedulableUser @arguments(model: $myScheduleModel, filter: $myScheduleFilterModel)
          ...AvailableVacanciesPanel_schedulableUser @arguments(model: $availableVacanciesModel)
        }
      }
    `, props.userRef);
    if (!((_a = data === null || data === void 0 ? void 0 : data.schedulableEntity) === null || _a === void 0 ? void 0 : _a.schedulable) && !hasPermissions(['EDIT_SCHEDULES']))
        return null;
    return (<Flexbox direction="column" gap="medium">
      {size !== 'small' ? (<H2>
          <FormattedMessage defaultMessage="Schema" id="general.services.SCHEDULE.name"/>
        </H2>) : (<H4>
          <FormattedMessage defaultMessage="Schema" id="general.services.SCHEDULE.name"/>
        </H4>)}
      <Flexbox direction={size === 'large' ? 'row' : 'column'} gap="xlarge">
        {((_b = data === null || data === void 0 ? void 0 : data.schedulableEntity) === null || _b === void 0 ? void 0 : _b.schedulable) && (<>
            <div style={{ flex: 1 }}>
              <Suspense fallback={<Skeleton height="20rem"/>}>
                <MySchedulePanel schedulableUserRef={data.schedulableEntity}/>
              </Suspense>
            </div>
            <div style={{ flex: 1 }}>
              <Suspense fallback={<Skeleton height="20rem"/>}>
                <AvailableVacanciesPanel schedulableUserRef={data.schedulableEntity}/>
              </Suspense>
            </div>
          </>)}
        {hasPermissions(['EDIT_SCHEDULES']) && (<div style={{ flex: 1 }}>
            <Suspense fallback={<Skeleton height="20rem"/>}>
              <AbsencePanel />
            </Suspense>
          </div>)}
      </Flexbox>
    </Flexbox>);
};
export default SchedulePanel;
