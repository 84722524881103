/**
 * @generated SignedSource<<4bd31b08260cde40c61fc1f24f9c33ac>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditCompanyAvatar",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Avatar_iHaveAvatar"
        }
    ],
    "type": "Company",
    "abstractKey": null
};
node.hash = "687b5eb51d1741a5c251491185843d6f";
export default node;
