import React from 'react';
import { useIntl } from 'react-intl';
import { useFragment, graphql, useMutation } from 'react-relay/hooks';
import { Flexbox, InlineSelect, ReadViewContainer } from '@sonika-se/ui-kit/components';
import enums from '~/enums.json';
const { ValueUnit } = enums;
export const EditEventAdditionUnit = (props) => {
    const { eventRef } = props;
    const { id, additionUnit } = useFragment(graphql `
      fragment EditEventAdditionUnit_event on Event {
        id
        additionUnit
      }
    `, eventRef);
    const [updateEvent, updateEventLoading] = useMutation(graphql `
    mutation EditEventAdditionUnitMutation($model: UpdateEventRequestInput!) {
      updateEvent(model: $model) {
        eventEdge {
          node {
            id
            additionUnit
          }
        }
      }
    }
  `);
    const intl = useIntl();
    return (<Flexbox align="center">
      <InlineSelect disabled={updateEventLoading} isLoading={updateEventLoading} isCreatable={false} onChange={(value) => {
            updateEvent({
                variables: {
                    model: {
                        id,
                        additionUnit: value,
                    },
                },
            });
        }} defaultValue={additionUnit} placeholder={intl.formatMessage({
            defaultMessage: 'Välj enhet',
            id: 'EditEventAdditionUnit.selectPlaceholder',
        })} options={Object.keys(ValueUnit).map((value) => {
            var _a;
            return ({
                label: (_a = ValueUnit[value]) === null || _a === void 0 ? void 0 : _a.description,
                value,
            });
        })} view={() => { var _a; return additionUnit && <ReadViewContainer>{(_a = ValueUnit[additionUnit]) === null || _a === void 0 ? void 0 : _a.description}</ReadViewContainer>; }}/>
    </Flexbox>);
};
export default EditEventAdditionUnit;
