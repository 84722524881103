/**
 * @generated SignedSource<<c60765d246e6c5e99643f68f21160d1c>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "userId"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserEconomyPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserBankAccountPanel_user"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserEconomyPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserBankAccount",
                            "kind": "LinkedField",
                            "name": "bankAccount",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "accountNumber",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "bankName",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "clearingNumber",
                                    "storageKey": null
                                },
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "eaf72621a03d9bbbaa853fab6148bb82",
            "id": null,
            "metadata": {},
            "name": "UserEconomyPageQuery",
            "operationKind": "query",
            "text": "query UserEconomyPageQuery(\n  $userId: ID!\n) {\n  user(id: $userId) {\n    ...UserBankAccountPanel_user\n    id\n  }\n}\n\nfragment UserBankAccountPanel_user on User {\n  id\n  bankAccount {\n    accountNumber\n    bankName\n    clearingNumber\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "bb81900cbc3e0a4e67f68590a00d3f81";
export default node;
