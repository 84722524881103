/**
 * @generated SignedSource<<0649fdec405bd56fa44cb7ac62cb8b5a>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "connectionsToDeleteEdgeFrom"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "assetIds",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AssignmentTypeAssetsPage_removeAssetsToAssignmentTypeMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "RemoveAssetsFromAssignmentTypePayload",
                    "kind": "LinkedField",
                    "name": "removeAssetsFromAssignmentType",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AssignmentTypeAssetsPage_removeAssetsToAssignmentTypeMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "RemoveAssetsFromAssignmentTypePayload",
                    "kind": "LinkedField",
                    "name": "removeAssetsFromAssignmentType",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "filters": null,
                            "handle": "deleteEdge",
                            "key": "",
                            "kind": "ScalarHandle",
                            "name": "assetIds",
                            "handleArgs": [
                                {
                                    "kind": "Variable",
                                    "name": "connections",
                                    "variableName": "connectionsToDeleteEdgeFrom"
                                }
                            ]
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "36ebc567f2b8faf63a407b2632065c4a",
            "id": null,
            "metadata": {},
            "name": "AssignmentTypeAssetsPage_removeAssetsToAssignmentTypeMutation",
            "operationKind": "mutation",
            "text": "mutation AssignmentTypeAssetsPage_removeAssetsToAssignmentTypeMutation(\n  $model: RemoveAssetsFromAssignmentTypeInput!\n) {\n  removeAssetsFromAssignmentType(model: $model) {\n    assetIds\n  }\n}\n"
        }
    };
})();
node.hash = "a50b956e3b1d7b3c01365844d5fbe036";
export default node;
