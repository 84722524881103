import { useApi } from '@sonika-se/ui-kit/hooks';
import { useEffect, useState } from 'react';
export const useAttachments = (options) => {
    const { createAttachment, deleteAttachment, uploadEndpoint, onQueueComplete } = options;
    const { uploadFile } = useApi();
    const [attachments, setAttachments] = useState();
    const [uploading, setUploading] = useState(false);
    const addAttachments = (newAttachments) => {
        setAttachments([...(attachments || []), ...newAttachments]);
    };
    const removeAttachment = (attachment) => {
        return deleteAttachment(attachment);
    };
    useEffect(() => {
        if (attachments == null) {
            return;
        }
        if (attachments.length === 0) {
            if (onQueueComplete) {
                onQueueComplete();
            }
            setAttachments(null);
            return;
        }
        const attachment = attachments[0];
        setUploading(true);
        const completeUpload = () => {
            setAttachments(attachments.slice(1));
            setUploading(false);
        };
        if (!attachment.type) {
            return completeUpload();
        }
        uploadFile(attachment, uploadEndpoint)
            .then((uploadResponse) => {
            console.log('upload complete', uploadResponse);
            createAttachment(uploadResponse)
                .then((createResponse) => {
                console.log({ createResponse });
            })
                .catch((createError) => {
                console.log({ createError });
            })
                .finally(() => {
                completeUpload();
            });
        })
            .catch((uploadError) => {
            console.log({ uploadError });
            completeUpload();
        });
    }, [attachments]);
    return { addAttachments, attachments, removeAttachment, uploading };
};
export default useAttachments;
