/**
 * @generated SignedSource<<bed469afb3ceafedb10d024c4723d099>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userId"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "userId"
                }
            ],
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "employeeId",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstname",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastname",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "personnummer",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "personnummerLastFour",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "employmentDate",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "employmentEndDate",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserPersonalInfoUpdateModalQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserPersonalInfoUpdateModalQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "fb7d7e76d41718c47c2bab1180e28c49",
            "id": null,
            "metadata": {},
            "name": "UserPersonalInfoUpdateModalQuery",
            "operationKind": "query",
            "text": "query UserPersonalInfoUpdateModalQuery(\n  $userId: ID!\n) {\n  user(id: $userId) {\n    employeeId\n    firstname\n    id\n    lastname\n    personnummer\n    personnummerLastFour\n    employmentDate\n    employmentEndDate\n  }\n}\n"
        }
    };
})();
node.hash = "2ace2e76d20f706c7245028ce00f3e3a";
export default node;
