/**
 * @generated SignedSource<<030974fb88420b0a62e049db402b1d87>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "SignupWizardServicesQuery",
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SignupWizardServicesFormServices_query"
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ServiceInfo",
                    "kind": "LinkedField",
                    "name": "services",
                    "plural": true,
                    "selections": [
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "SignupWizardServicesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ServiceInfo",
                    "kind": "LinkedField",
                    "name": "services",
                    "plural": true,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "da27d8f8d27e88805189198f2d02524e",
            "id": null,
            "metadata": {},
            "name": "SignupWizardServicesQuery",
            "operationKind": "query",
            "text": "query SignupWizardServicesQuery {\n  ...SignupWizardServicesFormServices_query\n  services {\n    value\n  }\n}\n\nfragment SignupWizardServicesFormServices_query on Query {\n  services {\n    value\n    name\n    description\n  }\n}\n"
        }
    };
})();
node.hash = "f29a281028a3846d6167bf7c35a4584a";
export default node;
