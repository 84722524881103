import React from 'react';
import { useParams } from 'react-router';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import AssetAttachmentsPanel from '~/panels/AssetAttachmentsPanel';
export const AssetAttachmentsPage = () => {
    const { assetId } = useParams();
    const data = useLazyLoadQuery(graphql `
      query AssetAttachmentsPageQuery($assetId: ID!) {
        asset(id: $assetId) {
          ...AssetAttachmentsPanel_asset
        }
      }
    `, {
        assetId,
    });
    return <AssetAttachmentsPanel assetRef={data.asset}/>;
};
export default AssetAttachmentsPage;
