import React from 'react';
import { BasicPage, Flexbox, H1 } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import AccountSidebar from '~/sidebars/AccountSidebar';
import Avatar from '~/shared/Avatar';
import UserEmploymentPanel from '~/panels/UserEmploymentPanel';
import UserContactPanel from '~/panels/UserContactPanel';
import UserPersonalInfoPanel from '~/panels/UserPersonalInfoPanel';
import UserUsernamePanel from '~/panels/UserUsernamePanel';
export const AccountIndexPage = () => {
    const { currentUser: { me }, } = useLazyLoadQuery(graphql `
      query AccountIndexPageQuery {
        currentUser {
          me {
            ...AccountSidebar_user
            ...Avatar_iHaveAvatar
            ...UserContactPanel_user
            ...UserEmploymentPanel_user
            ...UserPersonalInfoPanel_user
            ...UserUsernamePanel_user
          }
        }
      }
    `, {}, {
        fetchPolicy: 'network-only',
    });
    return (<>
      <BasicPage width="large">
        <Flexbox direction="row" gap="medium" style={{ paddingTop: '3rem' }}>
          <Avatar iHaveAvatarRef={me} size="large"/>
          <H1>Mitt konto</H1>
        </Flexbox>
        <Flexbox direction="row" gap="large">
          <div style={{ flex: 3 }}>
            <Flexbox direction="column" gap="large">
              <UserPersonalInfoPanel editable={false} userRef={me}/>
              <Flexbox direction="row" gap="large" justify="equal">
                <UserContactPanel editable={false} userRef={me}/>
                <UserUsernamePanel editable={false} userRef={me}/>
              </Flexbox>
              <UserEmploymentPanel userRef={me}/>
            </Flexbox>
          </div>
          <div style={{ flex: 1 }}>
            <AccountSidebar userRef={me}/>
          </div>
        </Flexbox>
      </BasicPage>
    </>);
};
export default AccountIndexPage;
