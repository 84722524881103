import React, { useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { graphql, usePaginationFragment, usePreloadedQuery } from 'react-relay/hooks';
import { SelectModal } from '@sonika-se/ui-kit/components';
import UserAssignmentTypeSnippet from '../snippets/UserAssignmentTypeSnippet';
export const userAssignmentTypeSelectModalQuery = graphql `
  query UserAssignmentTypeSelectModalQuery($filter: UsersFilterInput, $where: UserFilterInput) {
    ...UserAssignmentTypeSelectModal_query @arguments(filter: $filter, where: $where)
  }
`;
export const UserAssignmentTypeSelectModal = (props) => {
    const { defaultFilter, defaultWhere = { status: { eq: 'ACTIVE' } }, onClose, onSelect, preloadedQuery, selectButtonLabel, title = 'Välj användarroller', } = props;
    const query = usePreloadedQuery(userAssignmentTypeSelectModalQuery, preloadedQuery);
    const [filter, setFilter] = useState(defaultFilter);
    const [where, setWhere] = useState(defaultWhere);
    const { data: { users }, hasNext, isLoadingNext, loadNext, refetch, } = usePaginationFragment(graphql `
      fragment UserAssignmentTypeSelectModal_query on Query
      @argumentDefinitions(
        after: { type: "String" }
        filter: { type: "UsersFilterInput" }
        first: { type: "Int", defaultValue: 50 }
        where: { type: "UserFilterInput", defaultValue: { status: { eq: ACTIVE } } }
      )
      @refetchable(queryName: "UserAssignmentTypeSelectModal_queryQuery") {
        users(after: $after, filter: $filter, first: $first, where: $where)
          @connection(key: "UserAssignmentTypeSelectModal_query_users") {
          edges {
            node {
              ...UserAssignmentTypeSnippet_user
              id
              assignmentTypes {
                nodes {
                  id
                  title
                }
              }
            }
          }
        }
      }
    `, query);
    const [isTransitioning, startTransition] = useTransition();
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        startTransition(() => {
            refetch({
                filter,
                where,
            }, {
                fetchPolicy: 'network-only',
            });
        });
    }, [filter, where]);
    const items = useMemo(() => {
        const result = [];
        users.edges.forEach((user) => {
            user.node.assignmentTypes.nodes.forEach((assignmentType) => {
                result.push({ user: user.node, assignmentType: assignmentType });
            });
        });
        return result;
    }, [users]);
    return (<SelectModal hasNext={hasNext} isLoadingNext={isLoadingNext} isSearching={isTransitioning} items={items} loadNext={() => {
            loadNext(50);
        }} onClose={() => {
            onClose === null || onClose === void 0 ? void 0 : onClose();
        }} onSearch={(search) => {
            setFilter(Object.assign(Object.assign({}, defaultFilter), { search }));
        }} onSelect={(selection) => {
            onSelect === null || onSelect === void 0 ? void 0 : onSelect(selection.map((x) => ({ userId: x.user.id, assignmentTypeId: x.assignmentType.id })));
        }} renderItem={(item) => (<UserAssignmentTypeSnippet linkDisabled userRef={item.user} assignmentType={item.assignmentType}/>)} selectButtonLabel={selectButtonLabel} title={title}/>);
};
export default UserAssignmentTypeSelectModal;
