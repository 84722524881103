import React, { Suspense } from 'react';
import { Flexbox, KeyValue, ModalSkeleton, Panel, PanelButton, Placeholder } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import UserHomeAddressUpdateModal, { editUserHomeAddressModalQuery } from '~/modals/UserHomeAddressUpdateModal';
export const UserHomeAddressPanel = (props) => {
    const { userRef } = props;
    const user = useFragment(graphql `
      fragment UserHomeAddressPanel_user on User {
        id
        homeAddress {
          careOf
          city
          postOfficeBox
          postalCode
          street
        }
      }
    `, userRef);
    const [editUserHomeAddressModalQueryRef, loadUserHomeAddressUpdateModalQuery, disposeUserHomeAddressUpdateModalQuery,] = useQueryLoader(editUserHomeAddressModalQuery);
    return (<>
      <Panel title="Hemadress" actions={<PanelButton onClick={() => {
                loadUserHomeAddressUpdateModalQuery({
                    userId: user.id,
                });
            }}>
            Ändra
          </PanelButton>}>
        {user.homeAddress ? (<Flexbox direction="column" gap="medium">
            {user.homeAddress.careOf && <KeyValue label="c/o" value={user.homeAddress.careOf}/>}
            {user.homeAddress.postOfficeBox && <KeyValue label="Postbox" value={user.homeAddress.postOfficeBox}/>}
            {user.homeAddress.street && <KeyValue label="Gata" value={user.homeAddress.street}/>}
            {(user.homeAddress.postalCode || user.homeAddress.city) && (<Flexbox direction="row" gap="xlarge">
                {user.homeAddress.postalCode && <KeyValue label="Postnummer" value={user.homeAddress.postalCode}/>}
                {user.homeAddress.city && <KeyValue label="Stad" value={user.homeAddress.city}/>}
              </Flexbox>)}
          </Flexbox>) : (<Placeholder text="Ingen hemadress."/>)}
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {editUserHomeAddressModalQueryRef && (<UserHomeAddressUpdateModal preloadedQuery={editUserHomeAddressModalQueryRef} onClose={() => {
                disposeUserHomeAddressUpdateModalQuery();
            }}/>)}
      </Suspense>
    </>);
};
export default UserHomeAddressPanel;
