import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Field, Flexbox, H3, MultiSelect } from '@sonika-se/ui-kit/components';
import AccountPage from '../components/AccountPage';
import { FormattedMessage } from 'react-intl';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay/hooks';
import { setAccessTokens } from '@sonika-se/ui-kit/utils/auth';
export const selectUserQuery = graphql `
  query SelectUserQuery {
    currentUser {
      me {
        id
      }
      availableUsers {
        company {
          name
        }
        id
        firstname
        lastname
      }
    }
  }
`;
const SelectUser = () => {
    var _a, _b, _c, _d, _e, _f;
    const [searchParams] = useSearchParams();
    const redirectUri = searchParams.get('redirectUri');
    const data = useLazyLoadQuery(selectUserQuery, {});
    const [selectedUser, setSelectedUser] = useState((_b = (_a = data === null || data === void 0 ? void 0 : data.currentUser) === null || _a === void 0 ? void 0 : _a.me) === null || _b === void 0 ? void 0 : _b.id);
    const [selectUserLogin, selectUserLoginLoading] = useMutation(graphql `
      mutation SelectUser_selectUserLoginMutation($model: SelectUserLoginInput!) {
        selectUserLogin(model: $model) {
          token
          refreshToken
        }
      }
    `);
    return (<AccountPage>
      <Flexbox direction="column" gap="large">
        <H3>
          <FormattedMessage id="SelectUser.header" defaultMessage="Välj konto"/>
        </H3>
        <Field label="Välj konto att fortsätta med">
          <MultiSelect defaultValue={(_d = (_c = data === null || data === void 0 ? void 0 : data.currentUser) === null || _c === void 0 ? void 0 : _c.me) === null || _d === void 0 ? void 0 : _d.id} placeholder="Välj konto" options={(_f = (_e = data === null || data === void 0 ? void 0 : data.currentUser) === null || _e === void 0 ? void 0 : _e.availableUsers) === null || _f === void 0 ? void 0 : _f.map((user) => {
            var _a;
            return ({
                value: user === null || user === void 0 ? void 0 : user.id,
                label: `${user === null || user === void 0 ? void 0 : user.firstname} ${user === null || user === void 0 ? void 0 : user.lastname} (${(_a = user === null || user === void 0 ? void 0 : user.company) === null || _a === void 0 ? void 0 : _a.name})`,
            });
        })} onChange={(value) => {
            setSelectedUser(value[0]);
        }}/>
        </Field>
        <Button display="block" onClick={() => {
            selectUserLogin({
                variables: {
                    model: {
                        userId: selectedUser,
                    },
                },
                onCompleted: ({ selectUserLogin }) => {
                    setAccessTokens(selectUserLogin === null || selectUserLogin === void 0 ? void 0 : selectUserLogin.token, selectUserLogin === null || selectUserLogin === void 0 ? void 0 : selectUserLogin.refreshToken);
                    if (redirectUri && redirectUri !== '') {
                        window.location.replace(redirectUri);
                    }
                    else {
                        window.location.replace('/');
                    }
                },
            });
        }} disabled={!selectedUser}>
          Fortsätt
        </Button>
      </Flexbox>
    </AccountPage>);
};
export default SelectUser;
