import React, { Suspense, useMemo } from 'react';
import { graphql, useLazyLoadQuery, useMutation, useQueryLoader } from 'react-relay/hooks';
import { useParams } from 'react-router';
import { Button, ModalSkeleton, PanelButton } from '@sonika-se/ui-kit/components';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import { ConnectionHandler } from 'relay-runtime';
import UserTablePanel from '~/panels/UserTablePanel';
import UserSelectModal, { userSelectModalQuery } from '~/shared/modals/UserSelectModal';
export const RoleUsersPage = () => {
    const { roleId } = useParams();
    const userTableFilter = useMemo(() => ({
        roleIdsIn: [roleId],
    }), []);
    const hasPermissions = usePermissions();
    const userTableExtended = hasPermissions(['EDIT_USERS']);
    const data = useLazyLoadQuery(graphql `
      query RoleUsersPageQuery($userTableExtended: Boolean!, $userTableFilter: UsersFilterInput!) {
        ...UserTablePanel_query @arguments(extended: $userTableExtended, filter: $userTableFilter)
      }
    `, {
        userTableExtended,
        userTableFilter,
    }, {
        fetchPolicy: 'network-only',
    });
    const [userSelectModalQueryRef, loadUserSelectModalQuery, disposeUserSelectModalQuery] = useQueryLoader(userSelectModalQuery);
    const [addUsersToRole, addUsersToRoleLoading] = useMutation(graphql `
      mutation RoleUsersPage_addUsersToRoleMutation($connectionsToPrependEdgeTo: [ID!]!, $model: AddUsersToRoleInput!) {
        addUsersToRole(model: $model) {
          userEdges @prependEdge(connections: $connectionsToPrependEdgeTo) {
            node {
              ...Avatar_iHaveAvatar
              assignmentTypes {
                totalCount
              }
              firstname
              id
              invitation {
                created
              }
              lastname
              roles {
                totalCount
              }
              schedulableEntity {
                id
              }
              timeReportingUser {
                id
              }
              services
              status
            }
          }
        }
      }
    `);
    const [removeUsersFromRole, removeUsersFromRoleLoading] = useMutation(graphql `
      mutation RoleUsersPage_removeUsersToRoleMutation(
        $connectionsToDeleteEdgeFrom: [ID!]!
        $model: RemoveUsersFromRoleInput!
      ) {
        removeUsersFromRole(model: $model) {
          userIds @deleteEdge(connections: $connectionsToDeleteEdgeFrom)
        }
      }
    `);
    const userTableConnectionId = useMemo(() => ConnectionHandler.getConnectionID('root', 'UserTable_query_users'), []);
    return (<>
      <UserTablePanel actions={hasPermissions(['EDIT_USER_PERMISSIONS']) && (<PanelButton disabled={addUsersToRoleLoading} onClick={() => {
                loadUserSelectModalQuery({
                    filter: {
                        roleIdsNotIn: [roleId],
                    },
                    where: {
                        status: {
                            eq: 'ACTIVE',
                        },
                    },
                }, {
                    fetchPolicy: 'network-only',
                });
            }}>
              Lägg till
            </PanelButton>)} extended={userTableExtended} filter={userTableFilter} queryRef={data} tableProps={{
            actions: (user) => (<Button appearance="outlined" color="danger" disabled={removeUsersFromRoleLoading} onClick={() => {
                    removeUsersFromRole({
                        variables: {
                            connectionsToDeleteEdgeFrom: [userTableConnectionId],
                            model: {
                                id: roleId,
                                userIds: [user.id],
                            },
                        },
                    });
                }} size="xsmall">
              Ta bort
            </Button>),
        }} title="Användare"/>
      <Suspense fallback={<ModalSkeleton />}>
        {userSelectModalQueryRef && (<UserSelectModal defaultFilter={{
                roleIdsNotIn: [roleId],
            }} defaultWhere={{
                status: {
                    eq: 'ACTIVE',
                },
            }} onClose={() => {
                disposeUserSelectModalQuery();
            }} onSelect={(userIds) => {
                addUsersToRole({
                    variables: {
                        connectionsToPrependEdgeTo: [userTableConnectionId],
                        model: {
                            id: roleId,
                            userIds,
                        },
                    },
                });
                disposeUserSelectModalQuery();
            }} preloadedQuery={userSelectModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default RoleUsersPage;
