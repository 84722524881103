import React, { useMemo } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { addMonths } from 'date-fns';
import { createUseStyles } from 'react-jss';
import { BasicPage } from '@sonika-se/ui-kit/components';
import FaqPanel from '~/components/Widgets/FaqPanel';
import TimePanel from '~/components/Widgets/TimePanel/TimePanel';
import SchedulePanel from '~/components/Widgets/SchedulePanel/SchedulePanel';
const useStyles = createUseStyles((theme) => ({
    dashboardPageContainer: {
        '& > *:not(:first-child)': {
            marginTop: '4rem',
            [`@media (max-width: ${theme.breakPoints.low})`]: {
                marginTop: '2rem',
            },
        },
        '& > *:first-child': {
            marginTop: '2rem',
        },
    },
}));
export const DashboardPage = () => {
    const classes = useStyles();
    const fromDate = new Date();
    const data = useLazyLoadQuery(graphql `
      query DashboardPageQuery(
        $availableVacanciesModel: GetRunningScheduleRequestInput!
        $myScheduleModel: GetRunningScheduleRequestInput!
        $myScheduleFilterModel: ScheduleItemsFilterInput
      ) {
        currentUser {
          activeServices
          me {
            ...SchedulePanel_user
              @arguments(
                availableVacanciesModel: $availableVacanciesModel
                myScheduleModel: $myScheduleModel
                myScheduleFilterModel: $myScheduleFilterModel
              )
            ...TimePanel_user
          }
        }
        ...FaqPanel_query
      }
    `, {
        availableVacanciesModel: {
            fromDate: fromDate,
            toDate: addMonths(fromDate, 1),
        },
        myScheduleModel: {
            fromDate: fromDate,
            toDate: addMonths(fromDate, 1),
        },
        myScheduleFilterModel: {
            includePlannedAbsences: false,
            includeReplacements: false,
            includeScheduleShifts: true,
            shiftStates: ['ACTIVE'],
        },
    });
    const hasService = useMemo(() => {
        return (service) => { var _a; return !!((_a = data === null || data === void 0 ? void 0 : data.currentUser) === null || _a === void 0 ? void 0 : _a.activeServices.find((activeService) => activeService === service)); };
    }, [data]);
    return (<BasicPage width="large">
      <div className={classes.dashboardPageContainer}>
        {hasService('SCHEDULE') && <SchedulePanel userRef={data.currentUser.me}/>}
        {hasService('TIME') && <TimePanel userRef={data.currentUser.me}/>}
        <FaqPanel queryRef={data}/>
      </div>
    </BasicPage>);
};
export default DashboardPage;
