/**
 * @generated SignedSource<<c9dedcb8d2a85905a3c63903033e8ca6>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignmentTypeTablePanel_query",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AssignmentTypeTable_query"
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "aaf6a2c5ef4cf4e5e0783b10b1a056ea";
export default node;
