/**
 * @generated SignedSource<<87adf8d579cd9010d626316f0986f3c8>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "UpdateUserPasswordPayload",
            "kind": "LinkedField",
            "name": "updateUserPassword",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "success",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserPasswordUpdateModal_updateUserPasswordMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserPasswordUpdateModal_updateUserPasswordMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "5a7aadd9e4dfc58c703136942b2d8420",
            "id": null,
            "metadata": {},
            "name": "UserPasswordUpdateModal_updateUserPasswordMutation",
            "operationKind": "mutation",
            "text": "mutation UserPasswordUpdateModal_updateUserPasswordMutation(\n  $model: UpdateUserPasswordInput!\n) {\n  updateUserPassword(model: $model) {\n    success\n  }\n}\n"
        }
    };
})();
node.hash = "2821b04e69ed3100b985c3d56c5956e2";
export default node;
