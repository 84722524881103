import React, { useState } from 'react';
import { BasicPage, BasicPageHeader, Button } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { ConnectionHandler } from 'relay-runtime';
import AssetCreateModal from '~/modals/AssetCreateModal';
import AssetTablePanel from '~/panels/AssetTablePanel';
export const AssetIndexPage = () => {
    const data = useLazyLoadQuery(graphql `
      query AssetIndexPageQuery {
        ...AssetTablePanel_query @arguments(extended: true)
      }
    `, {}, {
        fetchPolicy: 'network-only',
    });
    const [assetCreateModalVisible, setAssetCreateModalVisible] = useState(false);
    return (<>
      <BasicPage width="large">
        <BasicPageHeader title="Enheter" action={<Button onClick={() => {
                setAssetCreateModalVisible(true);
            }}>
              Lägg till enhet
            </Button>}/>
        <AssetTablePanel extended queryRef={data}/>
      </BasicPage>
      {assetCreateModalVisible && (<AssetCreateModal connectionsToPrependEdge={[ConnectionHandler.getConnectionID('root', 'AssetTable_query_assets')]} onClose={() => {
                setAssetCreateModalVisible(false);
            }}/>)}
    </>);
};
export default AssetIndexPage;
