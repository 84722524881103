import React, { useState } from 'react';
import { useFragment, graphql, useMutation } from 'react-relay/hooks';
import { useIntl } from 'react-intl';
import { Confirm, Flexbox, Switch } from '@sonika-se/ui-kit/components';
export const EditEventStatus = (props) => {
    const { event } = props;
    const intl = useIntl();
    const [showConfirm, setShowConfirm] = useState(false);
    const { id, disabled } = useFragment(graphql `
      fragment EditEventStatus on Event {
        id
        disabled
      }
    `, event);
    const [updateEventStatus, updateEventStatusLoading] = useMutation(graphql `
    mutation EditEventStatusMutation($model: UpdateEventRequestInput!) {
      updateEvent(model: $model) {
        eventEdge {
          node {
            id
            disabled
          }
        }
      }
    }
  `);
    return (<Flexbox align="center">
      <Switch disabled={updateEventStatusLoading} checked={!disabled} label={disabled
            ? intl.formatMessage({
                id: 'general.misc.event.fields.status.disabled',
                defaultMessage: 'Inaktiv',
            })
            : intl.formatMessage({
                id: 'general.misc.event.fields.status.active',
                defaultMessage: 'Aktiv',
            })} onChange={() => {
            setShowConfirm(true);
        }}/>
      {showConfirm && (<Confirm appearance="danger" disabled={updateEventStatusLoading} headerText={disabled ? 'Aktivera händelse' : 'Inaktivera händelse'} text={`Är du säker på att du vill ${disabled ? 'aktivera' : 'inaktivera'} den här händelsen?`} confirmButton={{
                text: disabled ? 'Aktivera' : 'Inaktivera',
                onConfirm: () => {
                    updateEventStatus({
                        variables: {
                            model: {
                                id,
                                disabled: !disabled,
                            },
                        },
                        onCompleted: () => {
                            setShowConfirm(false);
                        },
                    });
                },
            }} onCancel={() => setShowConfirm(false)}/>)}
    </Flexbox>);
};
export default EditEventStatus;
