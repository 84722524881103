import React from 'react';
import { Field, Form, ModalToSheet, ModalToSheetContent, ModalToSheetHeader, Switch, } from '@sonika-se/ui-kit/components';
import { graphql, useMutation, usePreloadedQuery } from 'react-relay/hooks';
export const userAttributesUpdateModalQuery = graphql `
  query UserAttributesUpdateModalQuery($userId: ID!) {
    user(id: $userId) {
      hidden
      id
      isCompanyAdmin
      schedulableEntity {
        id
        schedulable
      }
      timeReportingUser {
        active
        id
      }
    }
  }
`;
export const UserAttributesUpdateModal = (props) => {
    var _a, _b;
    const { onClose, preloadedQuery } = props;
    const { user } = usePreloadedQuery(userAttributesUpdateModalQuery, preloadedQuery);
    const [createSchedulableUser, createSchedulableUserLoading] = useMutation(graphql `
        mutation UserAttributesUpdateModal_createSchedulableUserMutation($model: CreateSchedulableUserRequestInput!) {
          createSchedulableUser(model: $model) {
            entityEdge {
              node {
                ... on SchedulableUser {
                  user {
                    id
                    schedulableEntity {
                      schedulable
                    }
                  }
                }
              }
            }
          }
        }
      `);
    const [updateSchedulableEntity, updateSchedulableEntityLoading] = useMutation(graphql `
        mutation UserAttributesUpdateModal_updateSchedulableEntityMutation(
          $model: UpdateSchedulableEntityRequestInput!
        ) {
          updateSchedulableEntity(model: $model) {
            entityEdge {
              node {
                schedulable
              }
            }
          }
        }
      `);
    const [createTimeReportingUser, createTimeReportingUserLoading] = useMutation(graphql `
        mutation UserAttributesUpdateModal_createTimeReportingUserMutation($model: CreateTimeReportingUserInput!) {
          createTimeReportingUser(model: $model) {
            timeReportingUserEdge {
              node {
                user {
                  id
                  timeReportingUser {
                    active
                  }
                }
              }
            }
          }
        }
      `);
    const [updateTimeReportingUser, updateTimeReportingUserLoading] = useMutation(graphql `
        mutation UserAttributesUpdateModal_updateTimeReportingUserMutation($model: UpdateTimeReportingUserInput!) {
          updateTimeReportingUser(model: $model) {
            timeReportingUserEdge {
              node {
                active
              }
            }
          }
        }
      `);
    const [updateUser, updateUserLoading] = useMutation(graphql `
      mutation UserAttributesUpdateModal_updateUserMutation($model: UpdateUserRequestInput!) {
        updateUser(model: $model) {
          userEdge {
            node {
              hidden
            }
          }
        }
      }
    `);
    return (<ModalToSheet onClose={() => onClose()} size="small">
      <ModalToSheetHeader onClose={() => onClose()}>Ändra egenskaper</ModalToSheetHeader>
      <ModalToSheetContent>
        <Form>
          <Field direction="row-reverse" label="Schemaläggningsbar">
            <Switch defaultChecked={(_a = user.schedulableEntity) === null || _a === void 0 ? void 0 : _a.schedulable} disabled={createSchedulableUserLoading || updateSchedulableEntityLoading} onChange={(event) => {
            if (!user.schedulableEntity && event.target.checked) {
                createSchedulableUser({
                    variables: {
                        model: {
                            userId: user.id,
                        },
                    },
                });
            }
            else {
                updateSchedulableEntity({
                    variables: {
                        model: {
                            iSchedulableEntityId: user.schedulableEntity.id,
                            schedulable: event.target.checked,
                        },
                    },
                });
            }
        }}/>
          </Field>
          <Field direction="row-reverse" label="Tidrapporterande">
            <Switch defaultChecked={(_b = user.timeReportingUser) === null || _b === void 0 ? void 0 : _b.active} disabled={createTimeReportingUserLoading || updateTimeReportingUserLoading} onChange={(event) => {
            if (!user.timeReportingUser && event.target.checked) {
                createTimeReportingUser({
                    variables: {
                        model: {
                            active: true,
                            userId: user.id,
                        },
                    },
                });
            }
            else {
                updateTimeReportingUser({
                    variables: {
                        model: {
                            active: event.target.checked,
                            id: user.timeReportingUser.id,
                        },
                    },
                });
            }
        }}/>
          </Field>
          <Field direction="row-reverse" label="Visa i kollegor">
            <Switch defaultChecked={!user.hidden} disabled={updateUserLoading} onChange={(event) => {
            updateUser({
                variables: {
                    model: {
                        id: user.id,
                        hidden: !event.target.checked,
                    },
                },
            });
        }}/>
          </Field>
        </Form>
      </ModalToSheetContent>
    </ModalToSheet>);
};
export default UserAttributesUpdateModal;
