import React, { Suspense } from 'react';
import { Flexbox, KeyValue, ModalSkeleton, Panel, PanelButton, Text } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import UserContactUpdateModal, { userContactUpdateModalQuery } from '~/modals/UserContactUpdateModal';
import { useViewport } from '@sonika-se/ui-kit/hooks';
export const UserContactPanel = (props) => {
    const { editable = true, userRef } = props;
    const user = useFragment(graphql `
      fragment UserContactPanel_user on User {
        email
        id
        phone
      }
    `, userRef);
    const [userContactUpdateModalRef, loadUserContactUpdateModal, disposeUserContactUpdateModal] = useQueryLoader(userContactUpdateModalQuery);
    const { size } = useViewport();
    return (<>
      <Panel title="Kontakt" actions={editable && <PanelButton onClick={() => loadUserContactUpdateModal({ userId: user.id })}>Ändra</PanelButton>}>
        <Flexbox direction={size === 'small' ? 'column' : 'row'} gap={size === 'small' ? 'medium' : 'xlarge'}>
          <KeyValue label="Telefon">
            <Text>{user.phone ? <a href="tel:{user.phone}">{user.phone}</a> : '-'}</Text>
          </KeyValue>
          <KeyValue label="E-post">
            <Text>{user.email ? <a href="mailto:data.user.email">{user.email}</a> : '-'}</Text>
          </KeyValue>
        </Flexbox>
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {userContactUpdateModalRef && (<UserContactUpdateModal onClose={() => {
                disposeUserContactUpdateModal();
            }} preloadedQuery={userContactUpdateModalRef}/>)}
      </Suspense>
    </>);
};
export default UserContactPanel;
