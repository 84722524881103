import React from 'react';
import { Field, ModalToSheet, ModalToSheetContent, ModalToSheetHeader } from '@sonika-se/ui-kit/components';
import FaqPostForm from './FaqPostForm';
import { graphql, useMutation, usePreloadedQuery } from 'react-relay/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { useApi } from '@sonika-se/ui-kit/hooks';
import useFaqPostAttachments from '~/hooks/attachments/useFaqPostAttachments';
import FaqPostTags from './FaqPostTags';
export const editFaqPostModalQuery = graphql `
  query EditFaqPostModalQuery($id: ID!) {
    faqPost(id: $id) {
      id
      answer
      question
      status
      ...useFaqPostAttachments_faqPost
      ...useFaqPostTags_faqPost
    }
  }
`;
export const EditFaqPostModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const { faqPost } = usePreloadedQuery(editFaqPostModalQuery, preloadedQuery);
    const intl = useIntl();
    const [updateFaqPostMutation, updateFaqPostMutationLoading] = useMutation(graphql `
      mutation EditFaqPostModal_updateFaqPostMutation($model: UpdateFaqPostRequestInput!) {
        updateFaqPost(model: $model) {
          faqPostEdge {
            node {
              id
              answer
              question
              status
              ...useFaqPostAttachments_faqPost
              ...useFaqPostTags_faqPost
            }
          }
        }
      }
    `);
    const { addAttachments, data, removeAttachment, uploading } = useFaqPostAttachments(faqPost);
    const { downloadFile } = useApi();
    return (<ModalToSheet onClose={onClose} size="large">
      <ModalToSheetHeader onClose={onClose} closeButtonLabel="Avbryt">
        <FormattedMessage id="general.misc.faq.actions.update" defaultMessage="Ändra fråga"/>
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <FaqPostForm defaultValues={{
            answer: faqPost.answer,
            id: faqPost.id,
            question: faqPost.question,
            status: faqPost.status,
        }} fileListProps={{
            defaultFiles: data.attachments.edges.map(({ node }) => node),
            onChange: (files) => {
                addAttachments(Array.from(files));
            },
            onDelete: (file) => {
                removeAttachment(file);
            },
            onDownload: (url, name) => {
                downloadFile(url, name);
            },
        }} disabled={updateFaqPostMutationLoading || uploading} onCancel={() => onClose()} onSubmit={(formData) => {
            updateFaqPostMutation({
                onCompleted: () => {
                    onClose();
                },
                onError: (error) => {
                    console.log({ error });
                },
                variables: {
                    model: {
                        answer: formData.answer,
                        id: faqPost.id,
                        question: formData.question,
                        status: formData.status,
                    },
                },
            });
        }}>
          <Field label="Etiketter">
            <FaqPostTags faqPostRef={faqPost}/>
          </Field>
        </FaqPostForm>
      </ModalToSheetContent>
    </ModalToSheet>);
};
export default EditFaqPostModal;
