/**
 * @generated SignedSource<<a8cd67a81ffdea57dce3db41a56b2e25>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "DeleteUserAttachmentPayload",
            "kind": "LinkedField",
            "name": "deleteUserAttachment",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "EdgeOfUserAttachment",
                    "kind": "LinkedField",
                    "name": "userAttachmentEdge",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserAttachment",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "useUserAttachments_deleteFileMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "useUserAttachments_deleteFileMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "e33c5122508464a6f49defeea710b30b",
            "id": null,
            "metadata": {},
            "name": "useUserAttachments_deleteFileMutation",
            "operationKind": "mutation",
            "text": "mutation useUserAttachments_deleteFileMutation(\n  $model: DeleteUserAttachmentRequestInput!\n) {\n  deleteUserAttachment(model: $model) {\n    userAttachmentEdge {\n      node {\n        id\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "1773b1e90f28e6df0253f9ed20390192";
export default node;
