/**
 * @generated SignedSource<<e1dadbb2da593f2b4308981f8b1e6eba>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AssetProficienciesPanel_removeProficienciesFromAssetMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DeleteAssetUserProficienciesPayload",
                    "kind": "LinkedField",
                    "name": "deleteAssetUserProficiencies",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Asset",
                            "kind": "LinkedField",
                            "name": "asset",
                            "plural": false,
                            "selections": [
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "AssetProficienciesPanel_asset"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AssetProficienciesPanel_removeProficienciesFromAssetMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DeleteAssetUserProficienciesPayload",
                    "kind": "LinkedField",
                    "name": "deleteAssetUserProficiencies",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Asset",
                            "kind": "LinkedField",
                            "name": "asset",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssetUserProficiencyConnection",
                                    "kind": "LinkedField",
                                    "name": "proficientUsers",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "AssetUserProficiency",
                                            "kind": "LinkedField",
                                            "name": "nodes",
                                            "plural": true,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "AssignmentType",
                                                    "kind": "LinkedField",
                                                    "name": "assignmentType",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "title",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "User",
                                                    "kind": "LinkedField",
                                                    "name": "user",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "fullName",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "validateProficiency",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "beddbb38206b1609bdc406acba3bfdd0",
            "id": null,
            "metadata": {},
            "name": "AssetProficienciesPanel_removeProficienciesFromAssetMutation",
            "operationKind": "mutation",
            "text": "mutation AssetProficienciesPanel_removeProficienciesFromAssetMutation(\n  $model: DeleteAssetUserProficienciesInput!\n) {\n  deleteAssetUserProficiencies(model: $model) {\n    asset {\n      ...AssetProficienciesPanel_asset\n      id\n    }\n  }\n}\n\nfragment AssetProficienciesPanel_asset on Asset {\n  proficientUsers {\n    nodes {\n      id\n      assignmentType {\n        id\n        title\n      }\n      user {\n        id\n        fullName\n      }\n    }\n  }\n  id\n  validateProficiency\n}\n"
        }
    };
})();
node.hash = "98b5d1bf02f638f7147cd22093ae5403";
export default node;
