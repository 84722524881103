/**
 * @generated SignedSource<<5e190bf79c337c3efd98f1c7f29942ae>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "assetId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "assetId"
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AssetSystemPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Asset",
                    "kind": "LinkedField",
                    "name": "asset",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "AssetSystemInfoPanel_asset"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AssetSystemPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Asset",
                    "kind": "LinkedField",
                    "name": "asset",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "color",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "disabledDate",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "e1ba3a123348e547db7d9351be47d747",
            "id": null,
            "metadata": {},
            "name": "AssetSystemPageQuery",
            "operationKind": "query",
            "text": "query AssetSystemPageQuery(\n  $assetId: ID!\n) {\n  asset(id: $assetId) {\n    ...AssetSystemInfoPanel_asset\n    id\n  }\n}\n\nfragment AssetSystemInfoPanel_asset on Asset {\n  color\n  id\n  disabledDate\n}\n"
        }
    };
})();
node.hash = "8839b5ffc61bef7b4467e26b5e056c95";
export default node;
