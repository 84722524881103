import React, { Suspense, useMemo } from 'react';
import { useLocation, useMatch, useNavigate, useRoutes } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Box, NoAccessPanel, PrivateRoute, SidebarLayout, SidebarNavigation, SidebarNavigationLink, Skeleton, } from '@sonika-se/ui-kit/components';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import SystemSettingsPage from './pages/SystemSettingsPage/SystemSettingsPage';
import EventSettingsPage from './pages/EventSettingsPage/EventSettingsPage';
import ErrorBoundary from '~/shared/components/ErrorBoundary';
export const SettingsPages = () => {
    const hasPermissions = usePermissions();
    const intl = useIntl();
    const homeUrl = '/settings';
    const navigate = useNavigate();
    const loc = useLocation();
    const links = [];
    if (hasPermissions(['EDIT_COMPANY'])) {
        links.push({
            label: intl.formatMessage({ defaultMessage: 'Företagsinställningar', id: 'general.objects.company.name.plural' }),
            route: '/settings/system',
        });
    }
    if (hasPermissions(['EDIT_EVENTS'])) {
        links.push({
            label: intl.formatMessage({ defaultMessage: 'Händelser', id: 'general.misc.event.name.plural' }),
            route: '/settings/events',
        });
    }
    const noAccessContent = useMemo(() => (<Box display="flex" height="100%" padding="large">
        <NoAccessPanel />
      </Box>), []);
    const routes = useRoutes([
        {
            path: '*',
            children: [
                {
                    path: 'system',
                    element: (<PrivateRoute fallback={noAccessContent} permissions={['EDIT_COMPANY']}>
              <SystemSettingsPage />
            </PrivateRoute>),
                },
                {
                    path: 'events',
                    element: (<PrivateRoute fallback={noAccessContent} permissions={['EDIT_EVENTS']}>
              <EventSettingsPage />
            </PrivateRoute>),
                },
            ],
        },
    ]);
    return (<SidebarLayout routes={<ErrorBoundary>
          <Suspense fallback={<Skeleton height="100%" type="page"/>}>{routes}</Suspense>
        </ErrorBoundary>}>
      <SidebarNavigation appearance="gray" onClick={() => navigate(homeUrl)} navigationHeaderText={intl.formatMessage({
            id: 'general.objects.settings.name.plural',
            defaultMessage: 'Inställningar',
        })} homePage={homeUrl === loc.pathname}>
        {links === null || links === void 0 ? void 0 : links.map((link) => {
            const match = useMatch(link === null || link === void 0 ? void 0 : link.route);
            return (<SidebarNavigationLink onClick={() => navigate(link === null || link === void 0 ? void 0 : link.route)} label={link === null || link === void 0 ? void 0 : link.label} key={link === null || link === void 0 ? void 0 : link.label} active={!!match}/>);
        })}
      </SidebarNavigation>
    </SidebarLayout>);
};
export default SettingsPages;
