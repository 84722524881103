/**
 * @generated SignedSource<<89a8cdc995559eeb174a21bd4bd92219>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RoleTablePanel_query",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RoleTable_query"
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "751ef3747073f68986dbac35317346b4";
export default node;
