import React from 'react';
import { useParams } from 'react-router';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import AssetSystemInfoPanel from '~/panels/AssetSystemInfoPanel';
export const AssetSystemPage = () => {
    const { assetId } = useParams();
    const { asset } = useLazyLoadQuery(graphql `
      query AssetSystemPageQuery($assetId: ID!) {
        asset(id: $assetId) {
          ...AssetSystemInfoPanel_asset
        }
      }
    `, {
        assetId,
    });
    return <AssetSystemInfoPanel assetRef={asset}/>;
};
export default AssetSystemPage;
