/**
 * @generated SignedSource<<c2d08f10b3ff98b27b1df466a3cac27d>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "extended"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "filter"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssetTablePanel_query",
    "selections": [
        {
            "args": [
                {
                    "kind": "Variable",
                    "name": "extended",
                    "variableName": "extended"
                },
                {
                    "kind": "Variable",
                    "name": "filter",
                    "variableName": "filter"
                }
            ],
            "kind": "FragmentSpread",
            "name": "AssetTable_query"
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "d2b1879e7d12988a3558e625222cdae4";
export default node;
