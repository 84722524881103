/**
 * @generated SignedSource<<6c9e327ca80e013e9afe0eb8755692a0>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "scheduleItemsModel"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "userId"
    }, v2 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "userId"
        }
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "schedulable",
        "storageKey": null
    }, v5 = {
        "kind": "Variable",
        "name": "model",
        "variableName": "scheduleItemsModel"
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v7 = {
        "kind": "InlineFragment",
        "selections": [
            (v3 /*: any*/)
        ],
        "type": "Node",
        "abstractKey": "__isNode"
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    }, v10 = [
        (v9 /*: any*/),
        (v3 /*: any*/)
    ], v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "ColleagueDetailsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserContactPanel_user"
                        },
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SchedulableUser",
                            "kind": "LinkedField",
                            "name": "schedulableEntity",
                            "plural": false,
                            "selections": [
                                (v4 /*: any*/),
                                {
                                    "args": [
                                        (v5 /*: any*/)
                                    ],
                                    "kind": "FragmentSpread",
                                    "name": "UserUpcomingScheduleShiftsPanel_schedulableEntity"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "ColleagueDetailsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                        },
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "phone",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SchedulableUser",
                            "kind": "LinkedField",
                            "name": "schedulableEntity",
                            "plural": false,
                            "selections": [
                                (v4 /*: any*/),
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Literal",
                                            "name": "first",
                                            "value": 5
                                        },
                                        (v5 /*: any*/)
                                    ],
                                    "concreteType": "ICanBeUsedToCalculateScheduleTimesUnionTypeConnection",
                                    "kind": "LinkedField",
                                    "name": "scheduleItems",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "nodes",
                                            "plural": true,
                                            "selections": [
                                                (v6 /*: any*/),
                                                (v7 /*: any*/),
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v8 /*: any*/)
                                                    ],
                                                    "type": "ICanBeUsedToCalculateScheduleTimes",
                                                    "abstractKey": "__isICanBeUsedToCalculateScheduleTimes"
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": null,
                                                            "kind": "LinkedField",
                                                            "name": "assignedEntity",
                                                            "plural": false,
                                                            "selections": [
                                                                (v6 /*: any*/),
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "Asset",
                                                                            "kind": "LinkedField",
                                                                            "name": "asset",
                                                                            "plural": false,
                                                                            "selections": (v10 /*: any*/),
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "type": "SchedulableAsset",
                                                                    "abstractKey": null
                                                                },
                                                                {
                                                                    "kind": "InlineFragment",
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "User",
                                                                            "kind": "LinkedField",
                                                                            "name": "user",
                                                                            "plural": false,
                                                                            "selections": (v10 /*: any*/),
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "type": "SchedulableUser",
                                                                    "abstractKey": null
                                                                },
                                                                (v7 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "AssignmentType",
                                                            "kind": "LinkedField",
                                                            "name": "assignmentType",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "title",
                                                                    "storageKey": null
                                                                },
                                                                (v3 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Schedule",
                                                            "kind": "LinkedField",
                                                            "name": "schedule",
                                                            "plural": false,
                                                            "selections": [
                                                                (v11 /*: any*/),
                                                                (v3 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "end",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "ScheduleShiftConnection",
                                                            "kind": "LinkedField",
                                                            "name": "simultaneousScheduleShift",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "ScheduleShiftEdge",
                                                                    "kind": "LinkedField",
                                                                    "name": "edges",
                                                                    "plural": true,
                                                                    "selections": [
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": "ScheduleShift",
                                                                            "kind": "LinkedField",
                                                                            "name": "node",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v3 /*: any*/),
                                                                                {
                                                                                    "alias": null,
                                                                                    "args": null,
                                                                                    "concreteType": null,
                                                                                    "kind": "LinkedField",
                                                                                    "name": "assignedEntity",
                                                                                    "plural": false,
                                                                                    "selections": [
                                                                                        (v6 /*: any*/),
                                                                                        {
                                                                                            "kind": "InlineFragment",
                                                                                            "selections": [
                                                                                                (v3 /*: any*/),
                                                                                                {
                                                                                                    "alias": null,
                                                                                                    "args": null,
                                                                                                    "concreteType": "Asset",
                                                                                                    "kind": "LinkedField",
                                                                                                    "name": "asset",
                                                                                                    "plural": false,
                                                                                                    "selections": [
                                                                                                        (v11 /*: any*/),
                                                                                                        (v9 /*: any*/),
                                                                                                        (v3 /*: any*/)
                                                                                                    ],
                                                                                                    "storageKey": null
                                                                                                }
                                                                                            ],
                                                                                            "type": "SchedulableAsset",
                                                                                            "abstractKey": null
                                                                                        },
                                                                                        (v7 /*: any*/)
                                                                                    ],
                                                                                    "storageKey": null
                                                                                }
                                                                            ],
                                                                            "storageKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v8 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "isVacant",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "type": "ScheduleShift",
                                                    "abstractKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "e9bbc3f5d5d751ba5340d71394004511",
            "id": null,
            "metadata": {},
            "name": "ColleagueDetailsPageQuery",
            "operationKind": "query",
            "text": "query ColleagueDetailsPageQuery(\n  $userId: ID!\n  $scheduleItemsModel: GetRunningScheduleRequestInput!\n) {\n  user(id: $userId) {\n    ...UserContactPanel_user\n    id\n    schedulableEntity {\n      schedulable\n      ...UserUpcomingScheduleShiftsPanel_schedulableEntity_102zXz\n      id\n    }\n  }\n}\n\nfragment ScheduleShiftCard_scheduleShift on ScheduleShift {\n  __typename\n  id\n  assignedEntity {\n    __typename\n    ... on SchedulableAsset {\n      asset {\n        color\n        id\n      }\n    }\n    ... on SchedulableUser {\n      user {\n        color\n        id\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  assignmentType {\n    title\n    id\n  }\n  schedule {\n    name\n    id\n  }\n  end\n  simultaneousScheduleShift {\n    edges {\n      node {\n        id\n        assignedEntity {\n          __typename\n          ... on SchedulableAsset {\n            id\n            asset {\n              name\n              color\n              id\n            }\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n      }\n    }\n  }\n  start\n  isVacant\n}\n\nfragment UserContactPanel_user on User {\n  email\n  id\n  phone\n}\n\nfragment UserUpcomingScheduleShiftsPanel_schedulableEntity_102zXz on SchedulableUser {\n  scheduleItems(first: 5, model: $scheduleItemsModel) {\n    nodes {\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n      __typename\n      ... on ICanBeUsedToCalculateScheduleTimes {\n        __isICanBeUsedToCalculateScheduleTimes: __typename\n        start\n      }\n      ...ScheduleShiftCard_scheduleShift\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "f051e410a6caa9eac02ef190fab8315c";
export default node;
