/**
 * @generated SignedSource<<de9c51d99a4a5dfe19b5243d5e19e70c>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "connectionsToPrependEdge"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "concreteType": "EdgeOfAsset",
        "kind": "LinkedField",
        "name": "assetEdge",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Asset",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "disabledDate",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AssetCreateModalCreateMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateAssetPayload",
                    "kind": "LinkedField",
                    "name": "createAsset",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AssetCreateModalCreateMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateAssetPayload",
                    "kind": "LinkedField",
                    "name": "createAsset",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "filters": null,
                            "handle": "prependEdge",
                            "key": "",
                            "kind": "LinkedHandle",
                            "name": "assetEdge",
                            "handleArgs": [
                                {
                                    "kind": "Variable",
                                    "name": "connections",
                                    "variableName": "connectionsToPrependEdge"
                                }
                            ]
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "e86ea8ee320c68fc4ba69c6e8ec7c491",
            "id": null,
            "metadata": {},
            "name": "AssetCreateModalCreateMutation",
            "operationKind": "mutation",
            "text": "mutation AssetCreateModalCreateMutation(\n  $model: CreateAssetRequestInput!\n) {\n  createAsset(model: $model) {\n    assetEdge {\n      node {\n        disabledDate\n        id\n        name\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "49475b376517c763a144d6e625ee7324";
export default node;
