import React, { Suspense } from 'react';
import { graphql } from 'react-relay';
import { usePaginationFragment, useQueryLoader } from 'react-relay/hooks';
import { useIntl } from 'react-intl';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { Skeleton, Chip, IconButton, Flexbox, Divider, Text, Label, ModalToSheetSkeleton, Placeholder, DataTable, } from '@sonika-se/ui-kit/components';
import { useViewport } from '@sonika-se/ui-kit/hooks';
import UpdateEventByAssignmentTypeModal, { updateEventByAssignmentTypeModalQuery, } from './UpdateEventByAssignmentTypeModal';
export const EventAssignmentTypeTable = (props) => {
    var _a, _b, _c, _d, _e, _f;
    const intl = useIntl();
    const [updateEventModalQueryRef, loadUpdateEventModalQuery, disposeUpdateEventModalQuery] = useQueryLoader(updateEventByAssignmentTypeModalQuery);
    const { data: assignmentTypes, hasNext, loadNext, isLoadingNext, } = usePaginationFragment(graphql `
      fragment EventAssignmentTypeTable on Query
      @argumentDefinitions(first: { type: "Int", defaultValue: 30 }, after: { type: "String" })
      @refetchable(queryName: "EventAssignmentTypeTableRefetch") {
        assignmentTypes(first: $first, after: $after)
          @connection(key: "EventAssignmentTypeTableRefetch_assignmentTypes") {
          edges {
            node {
              id
              title
              events {
                edges {
                  node {
                    id
                    name
                    color
                  }
                }
              }
            }
          }
        }
      }
    `, props.queryRef);
    const { size: pageSize } = useViewport();
    if (!((_b = (_a = assignmentTypes === null || assignmentTypes === void 0 ? void 0 : assignmentTypes.assignmentTypes) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.length)) {
        return <Placeholder text="Det finns inga roller"/>;
    }
    return (<Suspense fallback={<Skeleton type="table"/>}>
      {pageSize !== 'small' && (<DataTable columns={{
                assignmentType: {
                    text: intl.formatMessage({ id: 'general.objects.assignment_type.name.singular', defaultMessage: 'Roll' }),
                },
                events: {
                    text: intl.formatMessage({ id: 'general.misc.event.name.plural', defaultMessage: 'Händelser' }),
                    render: (events) => {
                        var _a;
                        return (<Flexbox gap="xsmall">
                  {(_a = events === null || events === void 0 ? void 0 : events.slice(0, 4)) === null || _a === void 0 ? void 0 : _a.map(({ node }) => (<Chip key={node === null || node === void 0 ? void 0 : node.id} size="xsmall" color={node === null || node === void 0 ? void 0 : node.color} text={node === null || node === void 0 ? void 0 : node.name}/>))}
                  {(events === null || events === void 0 ? void 0 : events.length) > 4 && <Chip size="xsmall" appearance="secondary" text={`+ ${(events === null || events === void 0 ? void 0 : events.length) - 4}`}/>}
                </Flexbox>);
                    },
                },
                actions: {
                    align: 'right',
                    render: (id) => (<IconButton size="small" icon={faPencil} onClick={() => loadUpdateEventModalQuery({ id })}/>),
                },
            }} rows={(_d = (_c = assignmentTypes === null || assignmentTypes === void 0 ? void 0 : assignmentTypes.assignmentTypes) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.map(({ node }) => {
                var _a;
                return ({
                    assignmentType: node === null || node === void 0 ? void 0 : node.title,
                    events: (_a = node === null || node === void 0 ? void 0 : node.events) === null || _a === void 0 ? void 0 : _a.edges,
                    actions: node === null || node === void 0 ? void 0 : node.id,
                });
            })} loading={isLoadingNext} onPaginate={() => loadNext(30)} pagination={{
                hasNextPage: hasNext,
                buttonText: intl.formatMessage({
                    id: 'general.actions.load_more',
                    defaultMessage: 'Ladda fler',
                }),
            }}/>)}
      {pageSize === 'small' && (<Flexbox direction="column" gap="small">
          {(_f = (_e = assignmentTypes === null || assignmentTypes === void 0 ? void 0 : assignmentTypes.assignmentTypes) === null || _e === void 0 ? void 0 : _e.edges) === null || _f === void 0 ? void 0 : _f.map(({ node }) => {
                var _a, _b;
                return (<Flexbox direction="column" gap="small" key={node === null || node === void 0 ? void 0 : node.id}>
              <Divider />
              <Flexbox direction="column" style={{ padding: '.5rem' }}>
                <Flexbox justify="space">
                  <Text style={{ fontWeight: 'bold' }}>{node === null || node === void 0 ? void 0 : node.title}</Text>
                  <Flexbox gap="small" align="center">
                    <IconButton size="small" icon={faPencil} onClick={() => loadUpdateEventModalQuery({ id: node.id })}/>
                  </Flexbox>
                </Flexbox>
                <Label text={intl.formatMessage({
                        id: 'general.objects.event.name.plural',
                        defaultMessage: 'Händelser',
                    })}>
                  <Flexbox gap="xxsmall" style={{ flexWrap: 'wrap' }}>
                    {(_b = (_a = node === null || node === void 0 ? void 0 : node.events) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.map(({ node: event }) => (<Chip style={{
                            marginBottom: '.25rem',
                        }} key={event === null || event === void 0 ? void 0 : event.id} size="xsmall" color={event === null || event === void 0 ? void 0 : event.color} text={event === null || event === void 0 ? void 0 : event.name}/>))}
                  </Flexbox>
                </Label>
              </Flexbox>
            </Flexbox>);
            })}
        </Flexbox>)}
      <Suspense fallback={<ModalToSheetSkeleton type="form"/>}>
        {updateEventModalQueryRef && (<UpdateEventByAssignmentTypeModal onCancel={() => disposeUpdateEventModalQuery()} preloadedQuery={updateEventModalQueryRef}/>)}
      </Suspense>
    </Suspense>);
};
export default EventAssignmentTypeTable;
