import { isSameDay } from 'date-fns';
import { format } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
export const dateRangeInWords = (start, end) => {
    const timeStart = format(start, 'HH:mm', { locale });
    const timeEnd = format(end, 'HH:mm', { locale });
    const duration = end.getTime() - start.getTime();
    if (isSameDay(start, end) || duration < 24 * 60 * 60 * 1000) {
        return `${timeStart} - ${timeEnd}`;
    }
    return `${timeStart} - ${timeEnd} ${format(end, 'dd/MM', { locale })}`;
};
export default dateRangeInWords;
