import React, { Suspense, useEffect, useMemo } from 'react';
import { graphql, useRefetchableFragment } from 'react-relay/hooks';
import { matchPath, useLocation, useNavigate, Route, Routes } from 'react-router-dom';
import { faHome } from '@fortawesome/pro-regular-svg-icons';
import { serviceIcons, serviceNames } from '@sonika-se/ui-kit';
import { App, Box, NoAccessPanel, Skeleton } from '@sonika-se/ui-kit/components';
import { useApi, useEnvironment, usePermissions, useViewport } from '@sonika-se/ui-kit/hooks';
import AssetRoutes from './routes/AssetRoutes';
import AssignmentTypeRoutes from './routes/AssignmentTypeRoutes';
import ColleagueRoutes from './routes/ColleagueRoutes';
import DashboardPage from '~/pages/DashboardPage';
import ErrorBoundary from './shared/components/ErrorBoundary';
import FaqPage from './pages/FaqPage/FaqPage';
import AccountIndexPage from './pages/AccountIndexPage';
import RoleRoutes from './routes/RoleRoutes';
import ServiceRoutes from './routes/ServiceRoutes';
import SettingsPages from '~/pages/SettingsPages/SettingsPages';
import UserRoutes from './routes/UserRoutes';
export const PrivateRoutes = (props) => {
    var _a;
    const { queryRef } = props;
    const [data, refetch] = useRefetchableFragment(graphql `
      fragment PrivateRoutes_query on Query @refetchable(queryName: "PrivateRoutes_queryQuery") {
        currentUser {
          me {
            avatar {
              id
              url(queryTransform: "width=68&height=68&mode=crop&scale=both")
            }
            color
            firstname
            lastname
          }
          permissions
          services
        }
      }
    `, queryRef);
    const { currentUser } = data;
    const { size: pageSize } = useViewport();
    const location = useLocation();
    const navigate = useNavigate();
    const { refreshAccessToken } = useApi();
    const { frontUrls } = useEnvironment();
    const hasPermissions = usePermissions();
    useEffect(() => {
        if (!currentUser) {
            refreshAccessToken().then(() => {
                refetch(null, {
                    fetchPolicy: 'network-only',
                });
            });
        }
    }, [currentUser]);
    const noAccessContent = useMemo(() => (<Box display="flex" height="100%" padding="large">
        <NoAccessPanel />
      </Box>), []);
    if (!currentUser) {
        return null;
    }
    return (<Route element={<App apps={currentUser
                ? ['CORE', ...data.currentUser.services].map((service) => ({
                    icon: serviceIcons[service],
                    name: serviceNames[service],
                    url: frontUrls[service],
                }))
                : null} homeUrl="/" icon={faHome} menu={{
                items: [
                    {
                        path: '/',
                        text: 'Översikt',
                    },
                    {
                        path: '/colleagues',
                        text: 'Kollegor',
                    },
                    {
                        path: '/users',
                        permission: {
                            list: ['EDIT_USERS'],
                            condition: 'all',
                        },
                        text: 'Användare',
                    },
                    {
                        path: '/assets',
                        permission: {
                            list: ['EDIT_ASSETS'],
                            condition: 'all',
                        },
                        text: 'Enheter',
                    },
                    {
                        path: '/assignment-types',
                        permission: {
                            list: ['EDIT_ASSIGNMENT_TYPES'],
                            condition: 'all',
                        },
                        text: 'Roller',
                    },
                    {
                        path: '/groups',
                        permission: {
                            list: ['EDIT_ROLES'],
                            condition: 'all',
                        },
                        text: 'Grupper',
                    },
                    {
                        permission: {
                            list: ['EDIT_SERVICES'],
                            condition: 'all',
                        },
                        path: '/services',
                        text: 'Tjänster',
                    },
                    {
                        path: '/faq',
                        text: 'Kunskapsbank',
                    },
                    {
                        path: '/settings',
                        permission: {
                            list: ['EDIT_COMPANY', 'EDIT_EVENTS'],
                            condition: 'any',
                        },
                        text: 'Inställningar',
                    },
                ]
                    .filter((route) => route.permission === undefined ||
                    hasPermissions(route.permission.list, route.permission.condition))
                    .map((route) => ({
                    selected: matchPath({
                        path: route.path,
                        end: route.path === '/',
                    }, location.pathname) !== null,
                    text: route.text,
                    url: route.path,
                })),
                onNavigate: (url) => {
                    navigate(url);
                },
            }} name="Hem" user={currentUser
                ? {
                    color: currentUser.me.color,
                    firstname: currentUser.me.firstname,
                    lastname: currentUser.me.lastname,
                    imageUrl: (_a = currentUser.me.avatar) === null || _a === void 0 ? void 0 : _a.url,
                }
                : undefined}>
          <ErrorBoundary>
            <Suspense fallback={<Skeleton height="100%" type="page"/>}>
              <Routes>
                <Route path="" element={<DashboardPage />}/>
                <Route path="assets/*" element={<AssetRoutes />}/>
                <Route path="assignment-types/*" element={<AssignmentTypeRoutes />}/>
                <Route path="colleagues/*" element={<ColleagueRoutes />}/>
                <Route path="faq" element={<FaqPage />}/>
                <Route path="groups/*" element={<RoleRoutes />}/>
                <Route path="my-account" element={<AccountIndexPage />}/>
                <Route path="services/*" element={<ServiceRoutes />}/>
                <Route path="settings/*" element={<SettingsPages />}/>
                <Route path="users/*" element={<UserRoutes />}/>
              </Routes>
            </Suspense>
          </ErrorBoundary>
        </App>}/>);
};
export default PrivateRoutes;
