/**
 * @generated SignedSource<<c9bdc12822974629c2667588a3ba96b2>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "CheckPasswordPolicyPayload",
            "kind": "LinkedField",
            "name": "checkPasswordPolicy",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "success",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "failureReason",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UpdatePassword_checkPasswordPolicyMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UpdatePassword_checkPasswordPolicyMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "38d21770b2cb42d199f49ff1cba0c8fc",
            "id": null,
            "metadata": {},
            "name": "UpdatePassword_checkPasswordPolicyMutation",
            "operationKind": "mutation",
            "text": "mutation UpdatePassword_checkPasswordPolicyMutation(\n  $model: CheckPasswordPolicyInput!\n) {\n  checkPasswordPolicy(model: $model) {\n    success\n    failureReason\n  }\n}\n"
        }
    };
})();
node.hash = "bcdc9f22ccb8f6591f6e8b9bff1b7f65";
export default node;
