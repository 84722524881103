import React, { useCallback, useMemo, useRef } from 'react';
import { useMutation, usePreloadedQuery } from 'react-relay/hooks';
import { graphql } from 'relay-runtime';
import { FormModal } from '@sonika-se/ui-kit/components';
import PermissionsForm from '~/forms/PermissionsForm';
export const userPermissionsModalQuery = graphql `
  query UserPermissionsUpdateModalQuery($userId: ID!) {
    user(id: $userId) {
      id
      isCompanyAdmin
      userPermissions
    }
    currentUser {
      me {
        isCompanyAdmin
      }
    }
  }
`;
export const UserPermissionsUpdateModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const { user, currentUser } = usePreloadedQuery(userPermissionsModalQuery, preloadedQuery);
    const currentUserCanSetCompanyAdmin = useMemo(() => {
        return currentUser.me.isCompanyAdmin;
    }, [currentUser]);
    const [setUserPermissions, setUserPermissionsLoading] = useMutation(graphql `
        mutation UserPermissionsUpdateModal_setUserPermissionsMutation($model: SetUserPermissionsRequestInput!) {
          setUserPermissions(model: $model) {
            user {
              id
              userPermissions
            }
          }
        }
      `);
    const [setCompanyAdmin, setCompanyAdminLoading] = useMutation(graphql `
      mutation UserPermissionsUpdateModal_setCompanyAdminMutation($model: SetCompanyAdminInput!) {
        setCompanyAdmin(model: $model) {
          user {
            id
            isCompanyAdmin
          }
        }
      }
    `);
    const formRef = useRef(null);
    const handleSubmit = useCallback((form) => {
        setUserPermissions({
            onCompleted: () => {
                if (currentUserCanSetCompanyAdmin) {
                    setCompanyAdmin({
                        onCompleted: () => {
                            onClose();
                        },
                        variables: {
                            model: {
                                isCompanyAdmin: form.isCompanyAdmin,
                                userId: user.id,
                            },
                        },
                    });
                }
                else {
                    onClose();
                }
            },
            variables: {
                model: {
                    permissions: form.permissions,
                    userId: user.id,
                },
            },
        });
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Spara" disabled={setUserPermissionsLoading} title="Ändra rättigheter">
      <PermissionsForm defaultValues={{
            isCompanyAdmin: currentUserCanSetCompanyAdmin ? user.isCompanyAdmin : undefined,
            permissions: user.userPermissions,
        }} disabled={setUserPermissionsLoading || setCompanyAdminLoading} onSubmit={handleSubmit} ref={formRef}/>
    </FormModal>);
};
export default UserPermissionsUpdateModal;
