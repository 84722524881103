import React, { useState } from 'react';
import { serviceIcons } from '@sonika-se/ui-kit';
import { Button, Card, CardContent, CardFooter, CardHeader, Confirm, Flexbox, H4, Icon, KeyValue, StatusIndicator, } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useMutation } from 'react-relay/hooks';
import { Link } from 'react-router-dom';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
export const ServiceCard = (props) => {
    const { active, serviceRef } = props;
    const service = useFragment(graphql `
      fragment ServiceCard_serviceInfo on ServiceInfo {
        description
        name
        value
      }
    `, serviceRef);
    const [setCurrentCompanyService, setCurrentCompanyServiceLoading] = useMutation(graphql `
        mutation ServiceCard_setCurrentCompanyServiceMutation($model: SetCurrentCompanyServiceInput!) {
          setCurrentCompanyService(model: $model) {
            companyEdge {
              node {
                activeServices
              }
            }
          }
        }
      `);
    const hasPermissions = usePermissions();
    const [activateConfirmVisible, setActivateConfirmVisible] = useState(false);
    return (<>
      <Card style={{ display: 'flex', flexDirection: 'column' }}>
        <CardHeader>
          <Flexbox align="center" gap="xsmall">
            <Icon color={sonikaTheme.colors.primary} icon={serviceIcons[service.value]} style={{ fontSize: '1.2rem' }}/>
            <H4>{active ? <Link to={`/services/${service.value}`}>{service.name}</Link> : service.name}</H4>
          </Flexbox>
        </CardHeader>
        <CardContent padding="large" style={{ flex: 1 }}>
          <Flexbox direction="column" gap="medium">
            <KeyValue label="Status">
              <StatusIndicator color={active ? sonikaTheme.colors.system.go : sonikaTheme.colors.system.stop} label={active ? 'Aktiv' : 'Inaktiv'}/>
            </KeyValue>
            <KeyValue label="Beskrivning" value={service.description}/>
          </Flexbox>
        </CardContent>
        {hasPermissions(['EDIT_SERVICES']) && (<CardFooter>
            <Button color="success" disabled={active || setCurrentCompanyServiceLoading} display="block" onClick={() => {
                setActivateConfirmVisible(true);
            }}>
              Aktivera
            </Button>
          </CardFooter>)}
      </Card>
      {activateConfirmVisible && (<Confirm confirmButton={{
                onConfirm: () => {
                    setCurrentCompanyService({
                        variables: {
                            model: {
                                active: true,
                                service: service.value,
                            },
                        },
                    });
                },
                text: 'Aktivera',
            }} headerText="Aktivera tjänst" icon={serviceIcons[service.value]} onCancel={() => {
                setActivateConfirmVisible(false);
            }} text="Är du säker på att du vill aktivera tjänsten?"/>)}
    </>);
};
export default ServiceCard;
