/**
 * @generated SignedSource<<1a61969bb23b3fcf225c158045bcd5c9>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleShiftList_scheduleShiftConnection",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleShiftEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ScheduleShift",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "start",
                            "storageKey": null
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ScheduleShiftCard_scheduleShift"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "ScheduleShiftConnection",
    "abstractKey": null
};
node.hash = "2a5380bad12d5d2e98b72110ad6b86f9";
export default node;
