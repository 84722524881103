/**
 * @generated SignedSource<<821b70735f409ac61068bd6678171cb5>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserAttributesUpdateModal_createTimeReportingUserMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateTimeReportingUserPayload",
                    "kind": "LinkedField",
                    "name": "createTimeReportingUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfTimeReportingUser",
                            "kind": "LinkedField",
                            "name": "timeReportingUserEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TimeReportingUser",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "user",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "TimeReportingUser",
                                                    "kind": "LinkedField",
                                                    "name": "timeReportingUser",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserAttributesUpdateModal_createTimeReportingUserMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateTimeReportingUserPayload",
                    "kind": "LinkedField",
                    "name": "createTimeReportingUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfTimeReportingUser",
                            "kind": "LinkedField",
                            "name": "timeReportingUserEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TimeReportingUser",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "user",
                                            "plural": false,
                                            "selections": [
                                                (v2 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "TimeReportingUser",
                                                    "kind": "LinkedField",
                                                    "name": "timeReportingUser",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "aae2fcdbe79bd900abf6bff09178edfb",
            "id": null,
            "metadata": {},
            "name": "UserAttributesUpdateModal_createTimeReportingUserMutation",
            "operationKind": "mutation",
            "text": "mutation UserAttributesUpdateModal_createTimeReportingUserMutation(\n  $model: CreateTimeReportingUserInput!\n) {\n  createTimeReportingUser(model: $model) {\n    timeReportingUserEdge {\n      node {\n        user {\n          id\n          timeReportingUser {\n            active\n            id\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "3d3b0786dc752a513441918692921eee";
export default node;
