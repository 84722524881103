/**
 * @generated SignedSource<<adef7c04e5ff6a2388e0c3cfb7b10561>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userId"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "userId"
                }
            ],
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserHomeAddress",
                    "kind": "LinkedField",
                    "name": "homeAddress",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "careOf",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "city",
                            "storageKey": null
                        },
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "postOfficeBox",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "postalCode",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "street",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                (v1 /*: any*/)
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserHomeAddressUpdateModalQuery",
            "selections": (v2 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserHomeAddressUpdateModalQuery",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "ace08505b7fa3ea78f6a6a1f29ad5986",
            "id": null,
            "metadata": {},
            "name": "UserHomeAddressUpdateModalQuery",
            "operationKind": "query",
            "text": "query UserHomeAddressUpdateModalQuery(\n  $userId: ID!\n) {\n  user(id: $userId) {\n    homeAddress {\n      careOf\n      city\n      id\n      postOfficeBox\n      postalCode\n      street\n    }\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "d24fcd562066f4b2ad15f74c7f412d55";
export default node;
