import React from 'react';
import { useRoutes } from 'react-router-dom';
import AcceptInvite from './AcceptInvite';
import ErrorBoundary from '~/shared/components/ErrorBoundary';
import ImpersonatePage from './ImpersonatePage';
import Login from './Login';
import ResetPassword from './ResetPassword';
import UpdatePassword from './UpdatePassword';
import SelectUser from './SelectUser';
export const AccountPages = () => {
    const routes = useRoutes([
        {
            path: 'impersonate',
            element: <ImpersonatePage />,
        },
        {
            path: 'login',
            element: <Login />,
        },
        {
            path: 'reset-password',
            element: <ResetPassword />,
        },
        {
            path: 'update-password',
            element: <UpdatePassword />,
        },
        {
            path: 'accept-invite',
            element: <AcceptInvite />,
        },
        {
            path: 'select-user',
            element: <SelectUser />,
        },
    ]);
    return <ErrorBoundary>{routes}</ErrorBoundary>;
};
export default AccountPages;
