import React, { Suspense, useEffect, useState } from 'react';
import { faExclamationTriangle, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { Button, Icon, ModalToSheet, ModalToSheetContent, Text, H5, Flexbox } from '@sonika-se/ui-kit/components';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'relay-runtime';
import { useLazyLoadQuery, useMutation, useRefetchableFragment } from 'react-relay/hooks';
import usePolling from '~/hooks/usePolling';
import QRCode from '~/components/QRCode';
import { useDidMountEffect } from '@sonika-se/ui-kit/hooks';
import { setAccessTokens } from '@sonika-se/ui-kit/utils/auth';
const BankIdGetStatus = (props) => {
    const { id, setStatus } = props;
    const queryRef = useLazyLoadQuery(graphql `
      query BankIdFormQuery($model: CollectBankIdAuthInput!) {
        ...BankIdForm_CollectBankIdAuth @arguments(model: $model)
      }
    `, { model: { id } });
    const [data, refetch] = useRefetchableFragment(graphql `
      fragment BankIdForm_CollectBankIdAuth on Query
      @argumentDefinitions(model: { type: "CollectBankIdAuthInput!" })
      @refetchable(queryName: "BankIdForm_CollectBankIdAuthRefetchQuery") {
        collectBankIdAuth(model: $model) {
          message
          status
          qrData
          id
        }
      }
    `, queryRef);
    const { status, qrData } = data.collectBankIdAuth;
    usePolling(() => {
        refetch({
            model: { id },
        }, { fetchPolicy: 'network-only' });
    }, {
        interval: 2000,
    });
    useEffect(() => {
        console.log(data);
        setStatus(Object.assign({}, data.collectBankIdAuth));
    }, [status, qrData]);
    return null;
};
const BankIdForm = (props) => {
    const { disabled, redirectUri } = props;
    const [bankId, setBankId] = useState();
    const [bankIdError, setBankIdError] = useState('');
    const [bankIdAuthInProgress, setBankIdAuthInProgress] = useState(false);
    const [differentDevice, setDifferentDevice] = useState(false);
    const [initiatteBankIdAuth, initiatteBankIdAuthLoading] = useMutation(graphql `
      mutation BankIdForm_InitiateBankIdAuthMutation($model: InitiateBankIdAuthInput!) {
        initiateBankIdAuth(model: $model) {
          autostartToken
          bankIdLaunchUrl
          qrData
          id
        }
      }
    `);
    const [bankIdLogin, bankIdLoginLoading] = useMutation(graphql `
      mutation BankIdForm_BankIdLoginMutation($model: BankIdLoginInput!) {
        bankIdLogin(model: $model) {
          token
          refreshToken
          hasMultipleUsers
        }
      }
    `);
    const beginBankIdLogin = (useDifferentDevice) => {
        initiatteBankIdAuth({
            variables: {
                model: {},
            },
            onCompleted: (values) => {
                setBankId(Object.assign({}, values.initiateBankIdAuth));
                setDifferentDevice(useDifferentDevice);
                setBankIdAuthInProgress(true);
            },
        });
    };
    useDidMountEffect(() => {
        var _a;
        if (bankIdAuthInProgress && !differentDevice)
            (_a = document.getElementById('openBankId')) === null || _a === void 0 ? void 0 : _a.click();
    }, [bankIdAuthInProgress]);
    useEffect(() => {
        if ((bankId === null || bankId === void 0 ? void 0 : bankId.status) === 'FAILED') {
            setBankIdAuthInProgress(false);
            setBankIdError(bankId === null || bankId === void 0 ? void 0 : bankId.message);
            setBankId(undefined);
        }
        if ((bankId === null || bankId === void 0 ? void 0 : bankId.status) === 'COMPLETED') {
            bankIdLogin({
                variables: {
                    model: {
                        id: bankId.id,
                    },
                },
                onCompleted: (values) => {
                    var _a, _b;
                    setBankIdAuthInProgress(false);
                    setAccessTokens((_a = values === null || values === void 0 ? void 0 : values.bankIdLogin) === null || _a === void 0 ? void 0 : _a.token, (_b = values === null || values === void 0 ? void 0 : values.bankIdLogin) === null || _b === void 0 ? void 0 : _b.refreshToken);
                    setBankId(undefined);
                    if (values.bankIdLogin.hasMultipleUsers) {
                        window.location.replace(`/account/select-user?redirectUri=${redirectUri}`);
                    }
                    else if (redirectUri && redirectUri !== '') {
                        window.location.replace(redirectUri);
                    }
                    else {
                        window.location.replace('/');
                    }
                },
                onError: (errors) => {
                    setBankIdAuthInProgress(false);
                    setBankIdError(errors.message);
                    setBankId(undefined);
                },
            });
        }
    }, [bankId]);
    return (<Flexbox direction="column" gap="medium">
      {!bankIdAuthInProgress && (<>
          <H5>Logga in med</H5>
          <Button disabled={disabled || bankIdLoginLoading || initiatteBankIdAuthLoading} display="block" size="large" onClick={() => beginBankIdLogin(false)}>
            BankID på denna enhet
          </Button>
          <Button disabled={disabled || bankIdLoginLoading || initiatteBankIdAuthLoading} display="block" size="large" onClick={() => beginBankIdLogin(true)} appearance="outlined">
            BankID på annan enhet
          </Button>
        </>)}
      {!!bankIdAuthInProgress && (<>
          {differentDevice && (<Text>Öppna BankID-appen. Klicka på QR-ikonen inne i BankID-appen och scanna QR-koden som visas här</Text>)}
          <Suspense fallback={null}>
            <BankIdGetStatus id={bankId.id} setStatus={(values) => {
                setBankId(Object.assign(Object.assign({}, bankId), values));
            }}/>
          </Suspense>
          {!!(bankId === null || bankId === void 0 ? void 0 : bankId.qrData) && differentDevice && (<div style={{ margin: 'auto' }}>
              <QRCode qrData={bankId === null || bankId === void 0 ? void 0 : bankId.qrData}/>
            </div>)}
          {!(bankId === null || bankId === void 0 ? void 0 : bankId.qrData) && differentDevice && (<Flexbox direction="column" gap="xlarge" align="center">
              <Icon icon={faSpinner} color="#0C2AF3" spin style={{ fontSize: '2rem' }}/>
              <H5>Skriv in din säkertskod i BankID-appen</H5>
            </Flexbox>)}
          {!differentDevice && (<Flexbox direction="column" gap="xlarge" align="center">
              <a href={bankId === null || bankId === void 0 ? void 0 : bankId.bankIdLaunchUrl} id="openBankId" style={{ visibility: 'hidden' }}/>
              <Icon icon={faSpinner} color="#0C2AF3" spin style={{ fontSize: '2rem' }}/>
              <H5>{!!(bankId === null || bankId === void 0 ? void 0 : bankId.qrData) ? 'Startar din BankId-app...' : 'Skriv in din säkertskod i BankID-appen'}</H5>
            </Flexbox>)}
          <Button disabled={disabled || bankIdLoginLoading || initiatteBankIdAuthLoading} display="block" size="large" onClick={() => {
                setBankId(undefined);
                setBankIdAuthInProgress(false);
            }} appearance="outlined">
            Avbryt inloggningen
          </Button>
        </>)}
      {!!bankIdError && (<ModalToSheet size="small">
          <ModalToSheetContent>
            <Flexbox direction="column" gap="xlarge" align="center">
              <Icon icon={faExclamationTriangle} color="#0C2AF3" style={{ fontSize: '2rem' }}/>
              <Text>{bankIdError}</Text>
              <Button onClick={() => setBankIdError('')} appearance="outlined" display="block">
                <FormattedMessage id="general.actions.cancel" defaultMessage="Avbryt"/>
              </Button>
            </Flexbox>
          </ModalToSheetContent>
        </ModalToSheet>)}
    </Flexbox>);
};
export default BankIdForm;
