/**
 * @generated SignedSource<<75f759f3c92411fefb0868404d7a0181>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "answer",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "question",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "EditFaqPostModal_updateFaqPostMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateFaqPostPayload",
                    "kind": "LinkedField",
                    "name": "updateFaqPost",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfFaqPost",
                            "kind": "LinkedField",
                            "name": "faqPostEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FaqPost",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        {
                                            "args": null,
                                            "kind": "FragmentSpread",
                                            "name": "useFaqPostAttachments_faqPost"
                                        },
                                        {
                                            "args": null,
                                            "kind": "FragmentSpread",
                                            "name": "useFaqPostTags_faqPost"
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "EditFaqPostModal_updateFaqPostMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateFaqPostPayload",
                    "kind": "LinkedField",
                    "name": "updateFaqPost",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfFaqPost",
                            "kind": "LinkedField",
                            "name": "faqPostEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "FaqPost",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "IFileConnection",
                                            "kind": "LinkedField",
                                            "name": "attachments",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "IFileEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": null,
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                (v6 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "contentType",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "directory",
                                                                    "storageKey": null
                                                                },
                                                                (v2 /*: any*/),
                                                                (v7 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "size",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "storageId",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "url",
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v8 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v9 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "filters": null,
                                            "handle": "connection",
                                            "key": "useFaqPostAttachments_attachments",
                                            "kind": "LinkedHandle",
                                            "name": "attachments"
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ITaggedItemConnection",
                                            "kind": "LinkedField",
                                            "name": "tags",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "ITaggedItemEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": null,
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                (v6 /*: any*/),
                                                                (v2 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "tagId",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "Tag",
                                                                    "kind": "LinkedField",
                                                                    "name": "tag",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v7 /*: any*/),
                                                                        (v2 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v8 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v9 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "filters": null,
                                            "handle": "connection",
                                            "key": "useFaqPostTags_tags",
                                            "kind": "LinkedHandle",
                                            "name": "tags"
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "4c2d38fc4375bfb39b1861f2bee0b09a",
            "id": null,
            "metadata": {},
            "name": "EditFaqPostModal_updateFaqPostMutation",
            "operationKind": "mutation",
            "text": "mutation EditFaqPostModal_updateFaqPostMutation(\n  $model: UpdateFaqPostRequestInput!\n) {\n  updateFaqPost(model: $model) {\n    faqPostEdge {\n      node {\n        id\n        answer\n        question\n        status\n        ...useFaqPostAttachments_faqPost\n        ...useFaqPostTags_faqPost\n      }\n    }\n  }\n}\n\nfragment useFaqPostAttachments_faqPost on FaqPost {\n  id\n  attachments {\n    edges {\n      node {\n        __typename\n        contentType\n        directory\n        id\n        name\n        size\n        storageId\n        url\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment useFaqPostTags_faqPost on FaqPost {\n  id\n  tags {\n    edges {\n      node {\n        __typename\n        id\n        tagId\n        tag {\n          name\n          id\n        }\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "623c81da5f5b84f34b328fbc2d3dcdc9";
export default node;
