import React from 'react';
import { useParams } from 'react-router';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import UserRolesPanel from '~/panels/UserRolesPanel';
export const UserRolesPage = () => {
    const { userId } = useParams();
    const data = useLazyLoadQuery(graphql `
      query UserRolesPageQuery($userId: ID!) {
        user(id: $userId) {
          ...UserRolesPanel_user
        }
      }
    `, {
        userId,
    });
    return <UserRolesPanel userRef={data.user}/>;
};
export default UserRolesPage;
