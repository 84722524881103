import React, { Suspense } from 'react';
import { Flexbox, KeyValue, ModalSkeleton, Panel, PanelButton } from '@sonika-se/ui-kit/components';
import { formatPersonalNumber, isPersonalNumber } from '@sonika-se/ui-kit/utils/text';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import UserPersonalInfoUpdateModal, { userPersonalInfoUpdateModalQuery } from '~/modals/UserPersonalInfoUpdateModal';
import { formatDate, toDate } from '@sonika-se/ui-kit/utils/time';
export const UserPersonalInfoPanel = (props) => {
    const { editable = false, userRef } = props;
    const user = useFragment(graphql `
      fragment UserPersonalInfoPanel_user on User {
        employeeId
        firstname
        id
        lastname
        personnummer
        employmentDate
        employmentEndDate
      }
    `, userRef);
    const [userPersonalInfoUpdateModalRef, loadUserPersonalInfoUpdateModal, disposeUserPersonalInfoUpdateModal] = useQueryLoader(userPersonalInfoUpdateModalQuery);
    return (<>
      <Panel title="Personligt" actions={editable ? (<PanelButton onClick={() => loadUserPersonalInfoUpdateModal({ userId: user.id })}>Ändra</PanelButton>) : undefined}>
        <Flexbox direction="row" gap="xlarge">
          <KeyValue label="Förnamn" value={user.firstname}/>
          <KeyValue label="Efternamn" value={user.lastname}/>
          <KeyValue label={isPersonalNumber(user.personnummer) ? 'Personnummer' : 'Födelsedatum'} value={isPersonalNumber(user.personnummer) ? formatPersonalNumber(user.personnummer) : user.personnummer}/>
          <KeyValue label="Anställningsnr" value={user.employeeId}/>
          <KeyValue label="Anställd sedan" value={user.employmentDate && formatDate(toDate(user.employmentDate), 'date')}/>
          {user.employmentEndDate && (<KeyValue label="Anställning avslutad" value={formatDate(toDate(user.employmentEndDate), 'date')}/>)}
        </Flexbox>
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {userPersonalInfoUpdateModalRef && (<UserPersonalInfoUpdateModal onClose={() => {
                disposeUserPersonalInfoUpdateModal();
            }} preloadedQuery={userPersonalInfoUpdateModalRef}/>)}
      </Suspense>
    </>);
};
export default UserPersonalInfoPanel;
