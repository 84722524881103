import React, { useState } from 'react';
import { Field, FileList as SonikaFileList, Flexbox, Form, FormFooter, Input, Label, MarkdownEditor, Select, } from '@sonika-se/ui-kit/components';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import { useIntl } from 'react-intl';
import enums from '~/enums.json';
const validationSchema = object({
    answer: string().required('Du måste ange ett svar'),
    question: string().required('Du måste ange en fråga'),
    status: string().required('Du måste ange en status'),
});
export const FaqPostForm = (props) => {
    var _a, _b, _c, _d;
    const { children, defaultValues, disabled, fileListProps, onCancel, onSubmit } = props;
    const [selectedState, setSelectedState] = useState(defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.status);
    const { control, formState: { errors }, handleSubmit, register, } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });
    const intl = useIntl();
    const faqPostStates = enums.FaqPostState;
    const statusOptions = Object.keys(faqPostStates).map((type) => {
        var _a;
        return ({
            label: intl.formatMessage({
                defaultMessage: (_a = faqPostStates[type]) === null || _a === void 0 ? void 0 : _a.description,
                id: `faqPostStates_${type}`,
            }),
            value: type,
        });
    });
    return (<Form onSubmit={handleSubmit((formData) => {
            onSubmit(formData);
        })}>
      <Field error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.question) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} label={intl.formatMessage({
            defaultMessage: 'Fråga',
            id: 'general.misc.faq.fields.question.label',
        })}>
        <Controller control={control} name="question" render={({ field }) => {
            var _a, _b;
            return (<Input disabled={disabled} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.question) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} onBlur={field.onBlur} onChange={field.onChange} placeholder={intl.formatMessage({
                    defaultMessage: 'Skriv in en fråga',
                    id: 'general.misc.faq.fields.question.placeholder',
                })} value={field.value}/>);
        }}/>
      </Field>
      <Flexbox direction="column" gap="xsmall">
        <Label text="Svar"/>
        <Controller control={control} name="answer" render={({ field }) => (<MarkdownEditor initialValue={defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.answer} onBlur={field.onBlur} onChange={field.onChange}/>)}/>
      </Flexbox>
      <Field error={(_d = (_c = errors === null || errors === void 0 ? void 0 : errors.status) === null || _c === void 0 ? void 0 : _c.message) === null || _d === void 0 ? void 0 : _d.toString()} label={intl.formatMessage({
            defaultMessage: 'Status',
            id: 'general.misc.faq.fields.status.label',
        })}>
        <Controller control={control} name="status" render={({ field }) => {
            var _a, _b;
            return (<Select disabled={disabled} placeholder={intl.formatMessage({
                    defaultMessage: 'Välj status',
                    id: 'general.misc.faq.fields.status.placeholder',
                })} selectedItems={statusOptions.filter(({ value }) => value === selectedState)} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.status) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} onBlur={field.onBlur} onChange={(value) => {
                    var _a, _b;
                    setSelectedState((value === null || value === void 0 ? void 0 : value.length) ? (_a = value[0]) === null || _a === void 0 ? void 0 : _a.value : '');
                    field.onChange((value === null || value === void 0 ? void 0 : value.length) ? (_b = value[0]) === null || _b === void 0 ? void 0 : _b.value : '');
                }} options={statusOptions} value={field.value}/>);
        }}/>
      </Field>
      <Label text="Filer"/>
      <SonikaFileList headerText={intl.formatMessage({
            defaultMessage: 'Släpp filer här eller klicka för att välja',
            id: 'ScheduleShiftModal.FileList.header_text',
        })} editable {...fileListProps}/>
      {children}
      <FormFooter disabled={disabled} onCancel={() => onCancel()} submitText={!(defaultValues === null || defaultValues === void 0 ? void 0 : defaultValues.id)
            ? intl.formatMessage({ defaultMessage: 'Lägg till', id: 'general.actions.create' })
            : intl.formatMessage({ defaultMessage: 'Spara', id: 'general.actions.save' })}/>
    </Form>);
};
export default FaqPostForm;
