import React from 'react';
import { Field, Form, ModalToSheet, ModalToSheetContent, ModalToSheetHeader, Switch, } from '@sonika-se/ui-kit/components';
import { graphql, useMutation, usePreloadedQuery } from 'react-relay/hooks';
export const assetAttributesUpdateModalQuery = graphql `
  query AssetAttributesUpdateModalQuery($assetId: ID!) {
    asset(id: $assetId) {
      id
      schedulableEntity {
        id
        schedulable
      }
    }
  }
`;
export const AssetAttributesUpdateModal = (props) => {
    var _a;
    const { onClose, preloadedQuery } = props;
    const { asset } = usePreloadedQuery(assetAttributesUpdateModalQuery, preloadedQuery);
    const [createSchedulableAsset, createSchedulableAssetLoading] = useMutation(graphql `
        mutation AssetAttributesUpdateModal_createSchedulableAssetMutation(
          $model: CreateSchedulableAssetRequestInput!
        ) {
          createSchedulableAsset(model: $model) {
            entityEdge {
              node {
                ... on SchedulableAsset {
                  asset {
                    id
                    schedulableEntity {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      `);
    const [updateSchedulableEntity, updateSchedulableEntityLoading] = useMutation(graphql `
        mutation AssetAttributesUpdateModal_updateSchedulableEntityMutation(
          $model: UpdateSchedulableEntityRequestInput!
        ) {
          updateSchedulableEntity(model: $model) {
            entityEdge {
              node {
                schedulable
              }
            }
          }
        }
      `);
    return (<ModalToSheet onClose={() => onClose()} size="small">
      <ModalToSheetHeader onClose={() => onClose()}>Ändra egenskaper</ModalToSheetHeader>
      <ModalToSheetContent>
        <Form>
          <Field direction="row-reverse" label="Schemaläggningsbar">
            <Switch defaultChecked={(_a = asset.schedulableEntity) === null || _a === void 0 ? void 0 : _a.schedulable} disabled={createSchedulableAssetLoading || updateSchedulableEntityLoading} onChange={(event) => {
            if (!asset.schedulableEntity && event.target.checked) {
                createSchedulableAsset({
                    variables: {
                        model: {
                            assetId: asset.id,
                        },
                    },
                });
            }
            else {
                updateSchedulableEntity({
                    variables: {
                        model: {
                            iSchedulableEntityId: asset.schedulableEntity.id,
                            schedulable: event.target.checked,
                        },
                    },
                });
            }
        }}/>
          </Field>
        </Form>
      </ModalToSheetContent>
    </ModalToSheet>);
};
export default AssetAttributesUpdateModal;
