/**
 * @generated SignedSource<<9f30ac73a65978554950d7a69a31c8e5>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employeeId",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "personnummer",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "personnummerLastFour",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentDate",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentEndDate",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserPersonalInfoUpdateModal_updateUserMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateUserPayload",
                    "kind": "LinkedField",
                    "name": "updateUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfUser",
                            "kind": "LinkedField",
                            "name": "userEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v8 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserPersonalInfoUpdateModal_updateUserMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateUserPayload",
                    "kind": "LinkedField",
                    "name": "updateUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfUser",
                            "kind": "LinkedField",
                            "name": "userEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v8 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "e3b2c368e46f936b8c2e5f04135ffc57",
            "id": null,
            "metadata": {},
            "name": "UserPersonalInfoUpdateModal_updateUserMutation",
            "operationKind": "mutation",
            "text": "mutation UserPersonalInfoUpdateModal_updateUserMutation(\n  $model: UpdateUserRequestInput!\n) {\n  updateUser(model: $model) {\n    userEdge {\n      node {\n        employeeId\n        firstname\n        lastname\n        personnummer\n        personnummerLastFour\n        employmentDate\n        employmentEndDate\n        id\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "6d7b8895f2b32b4fe0ad72a01fac5e64";
export default node;
