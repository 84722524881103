/**
 * @generated SignedSource<<8b3962ba0a68149e4ca9c319667ae4db>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "where"
        }
    ], v1 = {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
    }, v2 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 50
        },
        (v1 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RoleSelectModalQuery",
            "selections": [
                {
                    "args": [
                        (v1 /*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "RoleSelectModal_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RoleSelectModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "RoleConnection",
                    "kind": "LinkedField",
                    "name": "roles",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "RoleEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Role",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "filters": [
                        "where"
                    ],
                    "handle": "connection",
                    "key": "RoleSelectModal_query_roles",
                    "kind": "LinkedHandle",
                    "name": "roles"
                }
            ]
        },
        "params": {
            "cacheID": "0d90ea66f5d5083f1e7eb427b9f8f9e0",
            "id": null,
            "metadata": {},
            "name": "RoleSelectModalQuery",
            "operationKind": "query",
            "text": "query RoleSelectModalQuery(\n  $where: RoleFilterInput\n) {\n  ...RoleSelectModal_query_3FC4Qo\n}\n\nfragment RoleSelectModal_query_3FC4Qo on Query {\n  roles(first: 50, where: $where) {\n    edges {\n      node {\n        id\n        name\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "b0a8f2d9afd3e5b2bbaa57340ebc78a3";
export default node;
