import React, { useCallback, useRef } from 'react';
import { graphql, useMutation, usePreloadedQuery } from 'react-relay/hooks';
import { formatPersonalNumber, isPersonalNumber } from '@sonika-se/ui-kit/utils/text';
import { FormModal } from '@sonika-se/ui-kit/components';
import UserPersonalInfoForm from '~/forms/UserPersonalInfoForm';
import { toDate } from '@sonika-se/ui-kit/utils/time';
import { formatISO } from 'date-fns';
export const userPersonalInfoUpdateModalQuery = graphql `
  query UserPersonalInfoUpdateModalQuery($userId: ID!) {
    user(id: $userId) {
      employeeId
      firstname
      id
      lastname
      personnummer
      personnummerLastFour
      employmentDate
      employmentEndDate
    }
  }
`;
export const UserPersonalInfoUpdateModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const { user } = usePreloadedQuery(userPersonalInfoUpdateModalQuery, preloadedQuery);
    const [updateUser, updateUserLoading] = useMutation(graphql `
      mutation UserPersonalInfoUpdateModal_updateUserMutation($model: UpdateUserRequestInput!) {
        updateUser(model: $model) {
          userEdge {
            node {
              employeeId
              firstname
              lastname
              personnummer
              personnummerLastFour
              employmentDate
              employmentEndDate
            }
          }
        }
      }
    `);
    const formRef = useRef(null);
    const handleSubmit = useCallback((form) => {
        updateUser({
            onCompleted: () => onClose(),
            variables: {
                model: {
                    id: user.id,
                    employeeId: form.employeeId,
                    firstname: form.firstname,
                    lastname: form.lastname,
                    personnummer: form.personnummer,
                    employmentDate: form.employmentDate ? formatISO(form.employmentDate) : null,
                    employmentEndDate: form.employmentEndDate ? formatISO(form.employmentEndDate) : null,
                },
            },
        });
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Spara" disabled={updateUserLoading} title="Ändra personligt">
      <UserPersonalInfoForm defaultValues={{
            employeeId: user.employeeId,
            firstname: user.firstname,
            lastname: user.lastname,
            personnummer: isPersonalNumber(user.personnummer)
                ? formatPersonalNumber(user.personnummer)
                : user.personnummer,
            employmentDate: user.employmentDate != null ? toDate(user.employmentDate) : null,
            employmentEndDate: user.employmentEndDate != null ? toDate(user.employmentEndDate) : null,
        }} disabled={updateUserLoading} onSubmit={handleSubmit} ref={formRef}/>
    </FormModal>);
};
export default UserPersonalInfoUpdateModal;
