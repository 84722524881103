/**
 * @generated SignedSource<<bfc5cb482247252159647e5db61c75ee>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "iHaveAvatarId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "iHaveAvatarId"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "kind": "InlineFragment",
        "selections": [
            {
                "alias": "avatarImage",
                "args": null,
                "concreteType": "Avatar",
                "kind": "LinkedField",
                "name": "avatar",
                "plural": false,
                "selections": [
                    (v2 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "storageId",
                        "storageKey": null
                    },
                    {
                        "alias": "avatarUrl",
                        "args": [
                            {
                                "kind": "Literal",
                                "name": "queryTransform",
                                "value": "width=400&height=400&mode=crop&scale=both"
                            }
                        ],
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": "url(queryTransform:\"width=400&height=400&mode=crop&scale=both\")"
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "IHaveAvatar",
        "abstractKey": "__isIHaveAvatar"
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "IHaveAvatarUpdateModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "IHaveAvatarUpdateModalQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        },
                        (v3 /*: any*/),
                        {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isNode"
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "5c3e0e6862c10e9e7abc67f0447e3ecb",
            "id": null,
            "metadata": {},
            "name": "IHaveAvatarUpdateModalQuery",
            "operationKind": "query",
            "text": "query IHaveAvatarUpdateModalQuery(\n  $iHaveAvatarId: ID!\n) {\n  node(id: $iHaveAvatarId) {\n    __typename\n    ... on IHaveAvatar {\n      __isIHaveAvatar: __typename\n      avatarImage: avatar {\n        id\n        storageId\n        avatarUrl: url(queryTransform: \"width=400&height=400&mode=crop&scale=both\")\n      }\n    }\n    __isNode: __typename\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "ae792aa9e87d328a5596957c34eb2b7c";
export default node;
