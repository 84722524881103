import React from 'react';
import { Chip, Divider, Flexbox, H5, Placeholder, Text } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { format } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import { FormattedMessage, useIntl } from 'react-intl';
import { useEnvironment } from '@sonika-se/ui-kit/hooks';
import { endOfDay, startOfDay } from 'date-fns/esm';
export const AbsencePanel = () => {
    const { frontUrls } = useEnvironment();
    const intl = useIntl();
    const data = useLazyLoadQuery(graphql `
      query AbsencePanelQuery($model: GetRunningScheduleRequestInput!) {
        plannedAbsences(model: $model, first: 5) {
          edges {
            node {
              id
              assignedEntity {
                ... on ISchedulableEntity {
                  color
                  displayName
                  id
                  name
                }
              }
              reasonEvent {
                id
                color
                name
              }
              start
              end
            }
          }
          totalCount
        }
      }
    `, {
        model: {
            fromDate: startOfDay(new Date()),
            toDate: endOfDay(new Date()),
        },
    });
    return (<>
      <Flexbox direction="column" gap="medium">
        <Flexbox direction="column" gap="xsmall">
          <Flexbox justify="space">
            <H5>
              <FormattedMessage id="DashboardPage.AbsencePanel.header" defaultMessage="Frånvaro idag"/>
            </H5>
            <a href={`${frontUrls['SCHEDULE']}absences`}>
              <Text size="medium" bold>
                All frånvaro
              </Text>
            </a>
          </Flexbox>
          <Divider />
        </Flexbox>
        {(data === null || data === void 0 ? void 0 : data.plannedAbsences.totalCount) === 0 && (<Placeholder title={intl.formatMessage({
                defaultMessage: 'Ingen frånvaro idag',
                id: 'DashboardPage.AbsencePanel.no_data',
            })}/>)}
        {(data === null || data === void 0 ? void 0 : data.plannedAbsences.totalCount) !== 0 && (<div style={{
                display: 'grid',
                gap: '.5rem',
                gridTemplateColumns: 'auto 1fr',
            }}>
            {data === null || data === void 0 ? void 0 : data.plannedAbsences.edges.map(({ node }) => {
                var _a;
                return (<React.Fragment key={node.id}>
                <div style={{ gridColumn: 2 }}>
                  <Flexbox direction="column" gap="xsmall">
                    <Text size="small">
                      {format(new Date(node.start), 'dd/MM HH:mm', { locale })} -{' '}
                      {format(new Date(node.end), 'dd/MM HH:mm', { locale })}
                    </Text>
                    <Chip type="view" appearance="danger" text={`${node.assignedEntity.displayName} (${node.reasonEvent.name})`} color={(_a = node.reasonEvent) === null || _a === void 0 ? void 0 : _a.color}/>
                  </Flexbox>
                </div>
              </React.Fragment>);
            })}
          </div>)}
      </Flexbox>
    </>);
};
export default AbsencePanel;
