import React from 'react';
import { ErrorBox } from '@sonika-se/ui-kit/components';
export const ErrorBoundary = (props) => {
    const { children, onError = () => <ErrorBox style={{ flex: 1 }} type="page"/> } = props;
    // TODO: Enable AppInsightsErrorBoundary when this issue is resolved https://github.com/microsoft/ApplicationInsights-JS/issues/1804
    /*return (
      <AppInsightsErrorBoundary appInsights={reactPlugin} onError={onError}>
        {children}
      </AppInsightsErrorBoundary>
    )*/
    return <>{children}</>;
};
export default ErrorBoundary;
