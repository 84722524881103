import React from 'react';
import { Flexbox, H6, Text } from '@sonika-se/ui-kit/components';
import { format, isWeekend } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
export const Weekday = (props) => {
    const { date } = props;
    const weekend = isWeekend(date);
    return (<Flexbox direction="column" gap="xsmall">
      <Text size="small" style={{ color: '#6A6A6A', textTransform: 'capitalize' }}>
        {format(date, 'E', { locale })}
      </Text>
      <H6 style={{ color: weekend ? '#FE544F' : null }}>{format(date, 'dd/MM', { locale })}</H6>
    </Flexbox>);
};
export default Weekday;
