/**
 * @generated SignedSource<<b6a8d6c9d35a3d10054755e512a8bf43>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "AssetAssignmentTypesPanel_asset",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentTypeConnection",
                "kind": "LinkedField",
                "name": "assignmentTypes",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssignmentType",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            (v0 /*: any*/)
        ],
        "type": "Asset",
        "abstractKey": null
    };
})();
node.hash = "0b39159ce38efaf848762baf9fb4069c";
export default node;
