import React, { Suspense } from 'react';
import { faCheck, faTimes, faImagePolaroid } from '@fortawesome/pro-regular-svg-icons';
import { ModalToSheetSkeleton, Sidebar, SidebarAction, SidebarMenu, SidebarMenuItem, SidebarSection, } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import AssetAttributesUpdateModal, { assetAttributesUpdateModalQuery } from '~/modals/AssetAttributesUpdateModal';
import IHaveAvatarUpdateModal, { changeAvatarModalQuery } from '~/modals/IHaveAvatarUpdateModal';
export const AssetSidebar = (props) => {
    var _a, _b;
    const { assetRef } = props;
    const asset = useFragment(graphql `
      fragment AssetSidebar_asset on Asset {
        id
        schedulableEntity {
          schedulable
        }
      }
    `, assetRef);
    const [changeAvatarModalQueryRef, loadIHaveAvatarUpdateModalQuery, disposeIHaveAvatarUpdateModalQuery] = useQueryLoader(changeAvatarModalQuery);
    const [assetAttributesUpdateModalQueryRef, loadAssetAttributesUpdateModalQuery, disposeAssetAttributesUpdateModalQuery,] = useQueryLoader(assetAttributesUpdateModalQuery);
    return (<>
      <Sidebar>
        <SidebarSection title="Verktyg">
          <SidebarMenu>
            <SidebarMenuItem icon={faImagePolaroid} onClick={() => {
            loadIHaveAvatarUpdateModalQuery({ iHaveAvatarId: asset.id });
        }}>
              Byt profilbild
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarSection>
        <SidebarSection title="Egenskaper" actions={<SidebarAction onClick={() => {
                loadAssetAttributesUpdateModalQuery({ assetId: asset.id });
            }}>
              Ändra
            </SidebarAction>}>
          <SidebarMenu>
            <SidebarMenuItem appearance={((_a = asset.schedulableEntity) === null || _a === void 0 ? void 0 : _a.schedulable) ? 'go' : 'stop'} icon={((_b = asset.schedulableEntity) === null || _b === void 0 ? void 0 : _b.schedulable) ? faCheck : faTimes}>
              Schemaläggningsbar
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarSection>
      </Sidebar>
      <Suspense fallback={<ModalToSheetSkeleton />}>
        {changeAvatarModalQueryRef && (<IHaveAvatarUpdateModal onClose={() => {
                disposeIHaveAvatarUpdateModalQuery();
            }} preloadedQuery={changeAvatarModalQueryRef}/>)}
        {assetAttributesUpdateModalQueryRef && (<AssetAttributesUpdateModal onClose={() => {
                disposeAssetAttributesUpdateModalQuery();
            }} preloadedQuery={assetAttributesUpdateModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default AssetSidebar;
