import React, { useCallback, useState } from 'react';
import { faPaperPlane } from '@fortawesome/pro-light-svg-icons';
import { createUseStyles } from 'react-jss';
import { useMutation, graphql, usePreloadedQuery } from 'react-relay/hooks';
import { Field, Form, FormModal, Input, H6, Icon, Text } from '@sonika-se/ui-kit/components';
import { useEnvironment } from '@sonika-se/ui-kit/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
export const userInvitationModalQuery = graphql `
  query UserInvitationModalQuery($userId: ID!) {
    user(id: $userId) {
      id
      email
      firstname
      lastname
    }
  }
`;
const useStyles = createUseStyles((theme) => ({
    sentInvitationContainer: {
        textAlign: 'center',
        padding: `${theme.helpers.pxToRem(22)} 0 ${theme.helpers.pxToRem(46)}`,
    },
    icon: {
        color: theme.colors.primary,
        fontSize: '4rem',
        marginBottom: '1rem',
    },
}));
const UserInvitationModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const classes = useStyles();
    const intl = useIntl();
    const [invitationSent, setInvitationSent] = useState(false);
    const { user: { id, firstname, lastname, email }, } = usePreloadedQuery(userInvitationModalQuery, preloadedQuery);
    const [createUserInvitation, createUserInvitationLoading] = useMutation(graphql `
      mutation UserInvitationModal_createUserInvitationMutation($model: InviteUserRequestInput!) {
        createUserInvitation(model: $model) {
          invitationEdge {
            node {
              ticket
            }
          }
        }
      }
    `);
    const { frontUrls } = useEnvironment();
    const handleSubmit = useCallback(() => {
        createUserInvitation({
            variables: {
                model: {
                    userId: id,
                    frontendClient: 'BOAT',
                    frontendUrl: `${frontUrls['CORE']}account/accept-invite`,
                },
            },
            onCompleted: () => setInvitationSent(true),
        });
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={handleSubmit} submitButtonLabel="Skicka" disabled={createUserInvitationLoading || invitationSent} title="Skicka inbjudan">
      {invitationSent ? (<div className={classes.sentInvitationContainer}>
          <Icon icon={faPaperPlane} className={classes.icon}/>
          <H6>
            <FormattedMessage defaultMessage="Inbjudan skickad till {name}" values={{ name: `${firstname} ${lastname}` }} id="UserPages.UserInvitation.invitation_sent_to"/>
          </H6>
        </div>) : (<Form onSubmit={handleSubmit}>
          <Text>
            <FormattedMessage defaultMessage="När du skickar en inbjudan får användaren ett email med en länk för att aktivera sitt konto." id="UserPages.UserInvitation.description"/>
          </Text>
          <Field label={intl.formatMessage({ id: 'general.objects.user.fields.email.label', defaultMessage: 'E-post' })}>
            <Input display="block" inputName="email" readOnly placeholder={intl.formatMessage({
                id: 'general.objects.user.fields.email.label',
                defaultMessage: 'E-post',
            })} value={email} disabled={createUserInvitationLoading}/>
          </Field>
        </Form>)}
    </FormModal>);
};
export default UserInvitationModal;
