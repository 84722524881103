/**
 * @generated SignedSource<<53d8adffee85a00b79656e439ad4b752>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "RoleSchedulePermissionsPanel_role",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "SchedulePermission",
                "kind": "LinkedField",
                "name": "schedulePermissions",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "Schedule",
                        "kind": "LinkedField",
                        "name": "schedule",
                        "plural": false,
                        "selections": [
                            (v0 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "permissions",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Role",
        "abstractKey": null
    };
})();
node.hash = "7e6075eda6391755498d4b456f4aefa5";
export default node;
