/**
 * @generated SignedSource<<4989633f50612fe357e14b6257ed568e>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "InitializeImpersonatePayload",
            "kind": "LinkedField",
            "name": "initializeImpersonate",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "token",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ImpersonateUserModal_initializeImpersonateMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ImpersonateUserModal_initializeImpersonateMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "ccf9a7c40bedb36ae699dabefc992615",
            "id": null,
            "metadata": {},
            "name": "ImpersonateUserModal_initializeImpersonateMutation",
            "operationKind": "mutation",
            "text": "mutation ImpersonateUserModal_initializeImpersonateMutation(\n  $model: InitializeImpersonateRequestInput!\n) {\n  initializeImpersonate(model: $model) {\n    token\n  }\n}\n"
        }
    };
})();
node.hash = "9093a4be27dc171a942bf34d5f83cb4c";
export default node;
