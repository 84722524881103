import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { string, object } from 'yup';
import { Form, Input, Field } from '@sonika-se/ui-kit/components';
export const roleCreateFormValidationSchema = object({
    name: string().required(),
});
export const RoleCreateForm = forwardRef((props, forwardedRef) => {
    const { defaultValues, onSubmit, disabled } = props;
    const { control, formState: { errors, isValid }, handleSubmit, } = useForm({
        defaultValues,
        resolver: yupResolver(roleCreateFormValidationSchema),
    });
    useImperativeHandle(forwardedRef, () => ({
        isValid,
        submit: (onValid, onInvalid) => handleSubmit(onValid, onInvalid)(),
    }), [handleSubmit, isValid]);
    return (<Form onSubmit={handleSubmit((data) => {
            onSubmit(data);
        })}>
        <Field label="Namn">
          <Controller control={control} name="name" render={({ field }) => {
            var _a, _b;
            return (<Input defaultValue={field.value} error={(_b = (_a = errors === null || errors === void 0 ? void 0 : errors.name) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()} disabled={disabled} onBlur={field.onBlur} onChange={field.onChange}/>);
        }}/>
        </Field>
      </Form>);
});
export default RoleCreateForm;
