import React, { Fragment } from 'react';
import { Card, CardContent, Flexbox, H5, Text, H6, Chip, Button } from '@sonika-se/ui-kit/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { format } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import { graphql, useFragment } from 'react-relay/hooks';
import { useEnvironment } from '@sonika-se/ui-kit/hooks';
import Duration from './Duration';
export const timeReportStatus = {
    NONE: {
        text: 'Pågående',
        status: 'NONE',
        appearance: 'primary',
    },
    SUBMITTED: {
        text: 'Inskickad',
        status: 'SUBMITTED',
        appearance: 'warning',
    },
    REJECTED: {
        text: 'Ej godkänd',
        status: 'REJECTED',
        appearance: 'danger',
    },
    APPROVED: {
        text: 'Godkänd',
        status: 'APPROVED',
        appearance: 'success',
    },
};
const TimeReportCard = (props) => {
    var _a;
    const { timeReport } = props;
    const { frontUrls } = useEnvironment();
    const status = timeReportStatus[timeReport === null || timeReport === void 0 ? void 0 : timeReport.status];
    return (<Card background="white" shadow="medium" style={{ minWidth: '250px' }}>
      <CardContent style={{ height: '100%', boxSizing: 'border-box' }}>
        <Flexbox direction="column" gap="medium" style={{ height: '100%' }}>
          <Flexbox direction="column" gap="xxsmall" style={{ textTransform: 'capitalize' }}>
            <Flexbox justify="space">
              <H5>{format(new Date((timeReport === null || timeReport === void 0 ? void 0 : timeReport.month) || undefined), 'MMMM yyyy', { locale })}</H5>
              <Chip appearance={status === null || status === void 0 ? void 0 : status.appearance} text={status === null || status === void 0 ? void 0 : status.text} size="xsmall"/>
            </Flexbox>
            <Text size="xsmall">{`${timeReport.timeTracks.length} tider, ${timeReport.additions.length} tillägg`}</Text>
          </Flexbox>
          <Card shadow="none" background="gray" style={{ height: '100%' }}>
            <CardContent style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxSizing: 'border-box',
            textAlign: 'center',
        }}>
              {(status === null || status === void 0 ? void 0 : status.status) === 'SUBMITTED' && (<FormattedMessage id="TimeReportCard.submitted" defaultMessage="Väntar på administratör"/>)}
              {(status === null || status === void 0 ? void 0 : status.status) === 'APPROVED' && (<FormattedMessage id="TimeReportCard.approved" defaultMessage="Tidrapporten är godkänd"/>)}
              {(status === null || status === void 0 ? void 0 : status.status) === 'REJECTED' &&
            (((_a = timeReport === null || timeReport === void 0 ? void 0 : timeReport.history[0]) === null || _a === void 0 ? void 0 : _a.message) || (<FormattedMessage id="TimeReportCard.rejected" defaultMessage="Tidrapporten ej godkänd"/>))}
            </CardContent>
          </Card>
          <a href={`${frontUrls['TIME']}time-reports/my-time-reports/${timeReport.id}`}>
            <Button display="block" size="small" appearance="outlined">
              Visa tidrapport
            </Button>
          </a>
        </Flexbox>
      </CardContent>
    </Card>);
};
const OngoingTimeReportCard = (props) => {
    var _a, _b;
    const { timeReport } = props;
    const intl = useIntl();
    const { frontUrls } = useEnvironment();
    return (<Card background="white" shadow="medium" style={{ minWidth: '250px' }}>
      <CardContent style={{ height: '100%', boxSizing: 'border-box' }}>
        <Flexbox direction="column" gap="medium" style={{ height: '100%' }}>
          <Flexbox direction="column" gap="xxsmall" style={{ textTransform: 'capitalize' }}>
            <Flexbox justify="space">
              <H5>{format(new Date((timeReport === null || timeReport === void 0 ? void 0 : timeReport.month) || undefined), 'MMMM yyyy', { locale })}</H5>
              <Chip appearance="primary" text="Pågående" size="xsmall"/>
            </Flexbox>
            <Text size="xsmall">{`${timeReport.timeTracks.length} tider, ${timeReport.additions.length} tillägg`}</Text>
          </Flexbox>
          <Flexbox direction="column" gap="medium" style={{ marginBottom: 'auto' }}>
            {!timeReport && (<Card shadow="none" background="gray" style={{ height: '100%' }}>
                <CardContent style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxSizing: 'border-box',
                textAlign: 'center',
            }}>
                  {intl.formatMessage({
                id: 'MyTimeReportsPanel.noTimeReport',
                defaultMessage: 'Det finns ingen aktiv tidrapport den här månaden',
            })}
                </CardContent>
              </Card>)}
            {((_a = timeReport === null || timeReport === void 0 ? void 0 : timeReport.employmentSummaries) === null || _a === void 0 ? void 0 : _a.length) === 0 && (<Card shadow="none" background="gray" style={{ height: '100%' }}>
                <CardContent style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                boxSizing: 'border-box',
            }}>
                  {intl.formatMessage({
                id: 'MyTimeReportsPanel.noHours',
                defaultMessage: 'Det finns inga registrerade tider den här månaden',
            })}
                </CardContent>
              </Card>)}
            {(_b = timeReport === null || timeReport === void 0 ? void 0 : timeReport.employmentSummaries) === null || _b === void 0 ? void 0 : _b.map((summary, index) => {
            var _a, _b;
            return (<Flexbox direction="column" gap="xxsmall" key={`summary-${index}`}>
                <H6>
                  {((_b = (_a = summary.employment) === null || _a === void 0 ? void 0 : _a.employmentSetting) === null || _b === void 0 ? void 0 : _b.title) ||
                    intl.formatMessage({ id: 'TimeTracksSummary.summaryTitle', defaultMessage: 'Övrigt' })}
                </H6>
                {summary.eventSummaries
                    .flat()
                    .sort((time) => (time.isWork ? -1 : 1))
                    .map((time) => (<Fragment key={time.event.id}>
                      <Flexbox justify="space">
                        <Text size="small">{time.event.name}</Text>
                        <Text size="small">
                          {time.unit === 'DAY' && <Duration days={time.value}/>}
                          {time.unit === 'HOUR' && <Duration hours={time.value}/>}
                          {time.unit === 'MINUTE' && <Duration minutes={time.value}/>}
                        </Text>
                      </Flexbox>
                      {time.nonRegularPartsSummary.map((part) => (<Flexbox justify="space" key={part.event.id} style={{ marginLeft: '1rem' }}>
                          <Text size="small">{part.event.name}</Text>
                          <Text size="small">
                            <Duration seconds={part.duration.value}/>
                          </Text>
                        </Flexbox>))}
                    </Fragment>))}
              </Flexbox>);
        })}
          </Flexbox>
          <a href={`${frontUrls['TIME']}time-reports/my-time-reports/${timeReport.id}`}>
            <Button display="block" size="small" appearance="outlined">
              Visa tidrapport
            </Button>
          </a>
        </Flexbox>
      </CardContent>
    </Card>);
};
export const MyTimeReportsPanel = (props) => {
    var _a, _b, _c, _d;
    const data = useFragment(graphql `
      fragment MyTimeReportsPanel_timeReportingUser on TimeReportingUser {
        id
        timeReports(order: { month: DESC }, first: 4) {
          edges {
            node {
              id
              status
              month
              history(order: [{ timestampId: DESC }]) {
                message
              }
              employmentSummaries {
                employment {
                  employmentSetting {
                    title
                  }
                }
                eventSummaries {
                  event {
                    id
                    name
                  }
                  isWork
                  nonRegularPartsSummary {
                    event {
                      id
                      name
                    }
                    duration(unit: SECOND) {
                      value
                    }
                  }
                  isWork
                  unit
                  value
                }
              }
              timeTracks {
                id
              }
              additions {
                id
              }
            }
          }
        }
      }
    `, props.timeReportingUserRef);
    const intl = useIntl();
    return (<Flexbox gap="medium" justify="equal" style={{
            overflowX: 'auto',
            alignItems: 'stretch',
            padding: '0 1rem 1rem 1rem',
            marginRight: '-1rem',
            marginLeft: '-1rem',
        }}>
      {((_b = (_a = data === null || data === void 0 ? void 0 : data.timeReports) === null || _a === void 0 ? void 0 : _a.edges) === null || _b === void 0 ? void 0 : _b.length) === 0 && (<Card shadow="none" background="gray" style={{ height: '100%' }}>
          <CardContent style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                boxSizing: 'border-box',
            }}>
            {intl.formatMessage({
                id: 'MyTimeReportsPanel.noHours',
                defaultMessage: 'Det finns inga tidrapporter',
            })}
          </CardContent>
        </Card>)}
      {(_d = (_c = data === null || data === void 0 ? void 0 : data.timeReports) === null || _c === void 0 ? void 0 : _c.edges) === null || _d === void 0 ? void 0 : _d.map(({ node }) => {
            if ((node === null || node === void 0 ? void 0 : node.status) === 'NONE') {
                return <OngoingTimeReportCard key={node === null || node === void 0 ? void 0 : node.id} timeReport={node}/>;
            }
            return <TimeReportCard timeReport={node} key={node === null || node === void 0 ? void 0 : node.id}/>;
        })}
    </Flexbox>);
};
export default MyTimeReportsPanel;
