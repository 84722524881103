import { graphql, useLazyLoadQuery, useMutation, usePaginationFragment } from 'react-relay/hooks';
export const useTags = (options) => {
    const { setTags, defaultTags = [] } = options;
    const queryRef = useLazyLoadQuery(graphql `
      query useTagsQuery {
        ...useTags_query
      }
    `, {});
    const { data, hasNext, isLoadingNext, loadNext, refetch } = usePaginationFragment(graphql `
      fragment useTags_query on Query
      @argumentDefinitions(
        first: { type: "Int", defaultValue: 10 }
        after: { type: "String" }
        where: { type: "TagFilterInput" }
      )
      @refetchable(queryName: "useTagsPaginationQuery") {
        tags(first: $first, after: $after, where: $where) @connection(key: "useTags_tags") {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    `, queryRef);
    const [createTag, createTagLoading] = useMutation(graphql `
    mutation useTags_createTagMutation($model: CreateTagRequestInput!) {
      createTag(model: $model) {
        tagEdge {
          node {
            id
            name
          }
        }
      }
    }
  `);
    return {
        tags: data === null || data === void 0 ? void 0 : data.tags,
        setTags,
        hasNext,
        loadNext,
        refetch,
        isLoadingNext,
        createTagLoading,
        createTag: ({ name }) => {
            const promise = new Promise((resolve, reject) => {
                createTag({
                    configs: [
                        {
                            connectionInfo: [
                                {
                                    key: 'useTags_tags',
                                    rangeBehavior: 'prepend',
                                },
                            ],
                            parentID: 'client:root',
                            edgeName: 'tagEdge',
                            type: 'RANGE_ADD',
                        },
                    ],
                    onCompleted: (response) => {
                        setTags({ tagIds: [...defaultTags, response.createTag.tagEdge.node.id] });
                        resolve(response);
                    },
                    onError: (error) => {
                        reject(error);
                    },
                    variables: {
                        model: {
                            name,
                        },
                    },
                });
            });
            return promise;
        },
    };
};
export default useTags;
