/**
 * @generated SignedSource<<f2eb6df3977b2305ef3024d517fd363d>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "userId"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserSystemPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserInvitationPanel_user"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserPushDevicesPanel_user"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserSystemInfoPanel_user"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "UserUsernamePanel_user"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserSystemPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserInvitation",
                            "kind": "LinkedField",
                            "name": "invitation",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "accepted",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "created",
                                    "storageKey": null
                                },
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PushDeviceConnection",
                            "kind": "LinkedField",
                            "name": "pushDevices",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PushDevice",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "createdUtc",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "deviceId",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "deviceName",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "inactivatedUtc",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "updatedUtc",
                                            "storageKey": null
                                        },
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "color",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "e257675c0a146ce38724681ee314faa0",
            "id": null,
            "metadata": {},
            "name": "UserSystemPageQuery",
            "operationKind": "query",
            "text": "query UserSystemPageQuery(\n  $userId: ID!\n) {\n  user(id: $userId) {\n    ...UserInvitationPanel_user\n    ...UserPushDevicesPanel_user\n    ...UserSystemInfoPanel_user\n    ...UserUsernamePanel_user\n    id\n  }\n}\n\nfragment UserInvitationPanel_user on User {\n  id\n  invitation {\n    accepted\n    created\n    id\n  }\n}\n\nfragment UserPushDevicesPanel_user on User {\n  pushDevices {\n    nodes {\n      createdUtc\n      deviceId\n      deviceName\n      inactivatedUtc\n      updatedUtc\n      id\n    }\n  }\n}\n\nfragment UserSystemInfoPanel_user on User {\n  color\n  id\n  invitation {\n    accepted\n    created\n    id\n  }\n  status\n}\n\nfragment UserUsernamePanel_user on User {\n  color\n  id\n  username\n}\n"
        }
    };
})();
node.hash = "3a87306a5200b7015c4dba4d341014ea";
export default node;
