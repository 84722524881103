import { Flexbox, KeyValue, ModalSkeleton, Panel, PanelButton } from '@sonika-se/ui-kit/components';
import React, { Suspense } from 'react';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import AssetAddressUpdateModal, { assetAssetUpdateModalQuery } from '~/modals/AssetAddressUpdateModal';
export const AssetAddressPanel = (props) => {
    const { assetRef } = props;
    const asset = useFragment(graphql `
      fragment AssetAddressPanel_asset on Asset {
        city
        id
        postalCode
        street
      }
    `, assetRef);
    const [assetAddressUpdateModalRef, loadAssetAddressUpdateModal, disposeAssetAddressUpdateModal] = useQueryLoader(assetAssetUpdateModalQuery);
    return (<>
      <Panel title="Adress" actions={<PanelButton onClick={() => loadAssetAddressUpdateModal({ assetId: asset.id })}>Ändra</PanelButton>}>
        <Flexbox direction="column" gap="medium">
          <KeyValue label="Gata" value={asset.street}/>
          <KeyValue label="Postnummer" value={asset.postalCode}/>
          <KeyValue label="Stad" value={asset.city}/>
        </Flexbox>
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {assetAddressUpdateModalRef && (<AssetAddressUpdateModal onClose={() => {
                disposeAssetAddressUpdateModal();
            }} preloadedQuery={assetAddressUpdateModalRef}/>)}
      </Suspense>
    </>);
};
export default AssetAddressPanel;
