/**
 * @generated SignedSource<<14e68b8d393d070bd3a3499ddb30e229>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "DeleteFaqPostPayload",
            "kind": "LinkedField",
            "name": "deleteFaqPost",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "EdgeOfFaqPost",
                    "kind": "LinkedField",
                    "name": "faqPostEdge",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "FaqPost",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "FaqPostListItem_deleteFaqPostMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "FaqPostListItem_deleteFaqPostMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "2f2d4fe59ad2df1213a6e9efea4d54d8",
            "id": null,
            "metadata": {},
            "name": "FaqPostListItem_deleteFaqPostMutation",
            "operationKind": "mutation",
            "text": "mutation FaqPostListItem_deleteFaqPostMutation(\n  $model: DeleteFaqPostRequestInput!\n) {\n  deleteFaqPost(model: $model) {\n    faqPostEdge {\n      node {\n        id\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "897356c6b2c1af6e84c3efa90504dbd0";
export default node;
