/**
 * @generated SignedSource<<f0f46c1a4eed567af4f1e6b07e115803>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "kind": "ScalarField",
            "name": "acceptInvitation",
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AcceptInviteMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AcceptInviteMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "fc423b5b9ce6892568e89d884d84121c",
            "id": null,
            "metadata": {},
            "name": "AcceptInviteMutation",
            "operationKind": "mutation",
            "text": "mutation AcceptInviteMutation(\n  $model: AcceptUserInvitationRequestInput!\n) {\n  acceptInvitation(model: $model)\n}\n"
        }
    };
})();
node.hash = "8a3dd838c3ed9532d02d2f325f8c4ceb";
export default node;
