import React from 'react';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { Card, CardContent, Flexbox, Icon, Text, Button } from '@sonika-se/ui-kit/components';
const LoginLoader = (props) => {
    const { abortController } = props;
    return (<Flexbox gap="large" direction="column">
      <Card background="gray" shadow="none">
        <CardContent padding="large">
          <Flexbox align="center" direction="column" justify="center" gap="medium">
            <Icon icon={faSpinner} color="#0C2AF3" pulse style={{ fontSize: '1.5rem' }}/>
            <Text size="small">Loggar in på ditt konto</Text>
          </Flexbox>
        </CardContent>
      </Card>
      <Button size="large" onClick={() => abortController.abort()} appearance="outlined">
        Avbryt inloggningen
      </Button>
    </Flexbox>);
};
export default LoginLoader;
