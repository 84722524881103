/**
 * @generated SignedSource<<e475842c4f0a54375953ce242a547dfe>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v2 = [
        (v1 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "AccountIndexPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CurrentUser",
                    "kind": "LinkedField",
                    "name": "currentUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "me",
                            "plural": false,
                            "selections": [
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "AccountSidebar_user"
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "Avatar_iHaveAvatar"
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "UserContactPanel_user"
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "UserEmploymentPanel_user"
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "UserPersonalInfoPanel_user"
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "UserUsernamePanel_user"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "AccountIndexPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CurrentUser",
                    "kind": "LinkedField",
                    "name": "currentUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "me",
                            "plural": false,
                            "selections": [
                                (v0 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "email",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "phone",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Company",
                                    "kind": "LinkedField",
                                    "name": "company",
                                    "plural": false,
                                    "selections": [
                                        (v0 /*: any*/),
                                        (v1 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "employeeId",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "firstname",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "lastname",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "personnummer",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "employmentDate",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "employmentEndDate",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "color",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "username",
                                    "storageKey": null
                                },
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Avatar",
                                            "kind": "LinkedField",
                                            "name": "avatar",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": [
                                                        {
                                                            "kind": "Literal",
                                                            "name": "queryTransform",
                                                            "value": "width=300&height=300&mode=crop&scale=both"
                                                        }
                                                    ],
                                                    "kind": "ScalarField",
                                                    "name": "url",
                                                    "storageKey": "url(queryTransform:\"width=300&height=300&mode=crop&scale=both\")"
                                                },
                                                (v0 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": (v2 /*: any*/),
                                            "type": "Asset",
                                            "abstractKey": null
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": (v2 /*: any*/),
                                            "type": "Company",
                                            "abstractKey": null
                                        }
                                    ],
                                    "type": "IHaveAvatar",
                                    "abstractKey": "__isIHaveAvatar"
                                }
                            ],
                            "storageKey": null
                        },
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "57942d2df208b06d3e466be3159d4250",
            "id": null,
            "metadata": {},
            "name": "AccountIndexPageQuery",
            "operationKind": "query",
            "text": "query AccountIndexPageQuery {\n  currentUser {\n    me {\n      ...AccountSidebar_user\n      ...Avatar_iHaveAvatar\n      ...UserContactPanel_user\n      ...UserEmploymentPanel_user\n      ...UserPersonalInfoPanel_user\n      ...UserUsernamePanel_user\n      id\n    }\n    id\n  }\n}\n\nfragment AccountSidebar_user on User {\n  id\n}\n\nfragment Avatar_iHaveAvatar on IHaveAvatar {\n  __isIHaveAvatar: __typename\n  avatar {\n    url(queryTransform: \"width=300&height=300&mode=crop&scale=both\")\n    id\n  }\n  ... on Asset {\n    color\n    name\n  }\n  ... on Company {\n    name\n  }\n  ... on User {\n    color\n    firstname\n    lastname\n  }\n}\n\nfragment UserContactPanel_user on User {\n  email\n  id\n  phone\n}\n\nfragment UserEmploymentPanel_user on User {\n  id\n  company {\n    id\n    name\n  }\n}\n\nfragment UserPersonalInfoPanel_user on User {\n  employeeId\n  firstname\n  id\n  lastname\n  personnummer\n  employmentDate\n  employmentEndDate\n}\n\nfragment UserUsernamePanel_user on User {\n  color\n  id\n  username\n}\n"
        }
    };
})();
node.hash = "a447ce864dfc3edd8db426d738544b08";
export default node;
