import React, { useEffect, useRef, useState, useTransition } from 'react';
import { graphql, usePaginationFragment, usePreloadedQuery } from 'react-relay/hooks';
import { SelectModal, Text } from '@sonika-se/ui-kit/components';
export const assignmentTypeSelectModalQuery = graphql `
  query AssignmentTypeSelectModalQuery($where: AssignmentTypeFilterInput) {
    ...AssignmentTypeSelectModal_query @arguments(where: $where)
  }
`;
export const AssignmentTypeSelectModal = (props) => {
    const { defaultWhere, onClose, onSelect, preloadedQuery, selectButtonLabel, title = 'Välj roll' } = props;
    const query = usePreloadedQuery(assignmentTypeSelectModalQuery, preloadedQuery);
    const [where, setWhere] = useState(defaultWhere);
    const { data: { assignmentTypes }, hasNext, isLoadingNext, loadNext, refetch, } = usePaginationFragment(graphql `
      fragment AssignmentTypeSelectModal_query on Query
      @argumentDefinitions(
        after: { type: "String" }
        first: { type: "Int", defaultValue: 50 }
        where: { type: "AssignmentTypeFilterInput" }
      )
      @refetchable(queryName: "AssignmentTypeSelectModal_queryQuery") {
        assignmentTypes(after: $after, first: $first, where: $where)
          @connection(key: "AssignmentTypeSelectModal_query_assignmentTypes") {
          edges {
            node {
              id
              title
            }
          }
        }
      }
    `, query);
    const [isTransitioning, startTransition] = useTransition();
    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        startTransition(() => {
            refetch({
                where,
            }, {
                fetchPolicy: 'network-only',
            });
        });
    }, [where]);
    return (<SelectModal hasNext={hasNext} isLoadingNext={isLoadingNext} isSearching={isTransitioning} items={assignmentTypes.edges.map(({ node }) => node)} loadNext={() => {
            loadNext(50);
        }} onClose={() => {
            onClose === null || onClose === void 0 ? void 0 : onClose();
        }} onSelect={(assignmentTypes) => {
            onSelect === null || onSelect === void 0 ? void 0 : onSelect(assignmentTypes.map(({ id }) => id));
        }} renderItem={(assignmentType) => <Text>{assignmentType.title}</Text>} selectButtonLabel={selectButtonLabel} title={title}/>);
};
export default AssignmentTypeSelectModal;
