/**
 * @generated SignedSource<<3b80a18de70b78eec471e07dceec44cd>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserServicesUpdateModal_setUserServicesMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SetUserServicesPayload",
                    "kind": "LinkedField",
                    "name": "setUserServices",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "UserServicesPanel_user"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserServicesUpdateModal_setUserServicesMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SetUserServicesPayload",
                    "kind": "LinkedField",
                    "name": "setUserServices",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "services",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Company",
                                    "kind": "LinkedField",
                                    "name": "company",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "activeServices",
                                            "storageKey": null
                                        },
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "7f882703271b77818ef2768da8273696",
            "id": null,
            "metadata": {},
            "name": "UserServicesUpdateModal_setUserServicesMutation",
            "operationKind": "mutation",
            "text": "mutation UserServicesUpdateModal_setUserServicesMutation(\n  $model: SetUserServicesInput!\n) {\n  setUserServices(model: $model) {\n    user {\n      ...UserServicesPanel_user\n      id\n    }\n  }\n}\n\nfragment UserServicesPanel_user on User {\n  services\n  company {\n    activeServices\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "252511923ed282035aae446932014f0d";
export default node;
