import React from 'react';
import { useIntl } from 'react-intl';
import { useFragment, graphql, useMutation } from 'react-relay/hooks';
import { Flexbox, InlineSelect, ReadViewContainer } from '@sonika-se/ui-kit/components';
import enums from '~/enums.json';
const { AbsenceTypeEnum } = enums;
export const EditEventAbsenceType = (props) => {
    const { eventRef } = props;
    const { id, absenceType } = useFragment(graphql `
      fragment EditEventAbsenceType_event on Event {
        id
        absenceType
      }
    `, eventRef);
    const [updateEvent, updateEventLoading] = useMutation(graphql `
    mutation EditEventAbsenceTypeMutation($model: UpdateEventRequestInput!) {
      updateEvent(model: $model) {
        eventEdge {
          node {
            id
            absenceType
          }
        }
      }
    }
  `);
    const intl = useIntl();
    return (<Flexbox align="center">
      <InlineSelect disabled={updateEventLoading} isLoading={updateEventLoading} isCreatable={false} onChange={(value) => {
            updateEvent({
                variables: {
                    model: {
                        id,
                        absenceType: value,
                    },
                },
            });
        }} defaultValue={absenceType} placeholder={intl.formatMessage({
            defaultMessage: 'Välj frånvarotyp',
            id: 'EditEventAbsenceType.selectPlaceholder',
        })} options={[
            { label: 'Ej angett', value: '' },
            ...Object.keys(AbsenceTypeEnum).map((value) => {
                var _a;
                return ({
                    label: (_a = AbsenceTypeEnum[value]) === null || _a === void 0 ? void 0 : _a.description,
                    value,
                });
            }),
        ]} view={() => {
            var _a;
            return (<ReadViewContainer style={{ width: '100%' }}>
            {absenceType ? (_a = AbsenceTypeEnum[absenceType]) === null || _a === void 0 ? void 0 : _a.description : 'Ej valt'}
          </ReadViewContainer>);
        }}/>
    </Flexbox>);
};
export default EditEventAbsenceType;
