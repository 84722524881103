import React, { useCallback, useRef } from 'react';
import { graphql, useMutation, usePreloadedQuery } from 'react-relay/hooks';
import { FormModal } from '@sonika-se/ui-kit/components';
import UserExternalContactsForm from '~/forms/UserExternalContactsForm';
export const userExternalContactsUpdateModalQuery = graphql `
  query UserExternalContactsUpdateModalQuery($userId: ID!) {
    user(id: $userId) {
      externalContacts
      id
    }
  }
`;
export const UserExternalContactsUpdateModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const { user } = usePreloadedQuery(userExternalContactsUpdateModalQuery, preloadedQuery);
    const [updateUser, updateUserLoading] = useMutation(graphql `
      mutation UserExternalContactsUpdateModal_updateUserMutation($model: UpdateUserRequestInput!) {
        updateUser(model: $model) {
          userEdge {
            node {
              externalContacts
            }
          }
        }
      }
    `);
    const formRef = useRef(null);
    const handleSubmit = useCallback((form) => {
        updateUser({
            onCompleted: () => onClose(),
            variables: {
                model: {
                    id: user.id,
                    externalContacts: form.externalContacts,
                },
            },
        });
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Spara" disabled={updateUserLoading} title="Ändra externa kontakter">
      <UserExternalContactsForm defaultValues={{
            externalContacts: user.externalContacts,
        }} disabled={updateUserLoading} ref={formRef} onSubmit={handleSubmit}/>
    </FormModal>);
};
export default UserExternalContactsUpdateModal;
