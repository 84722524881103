/**
 * @generated SignedSource<<9c778988a5e13750320de429d1a3bcef>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditEventIsUnsocial_event",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isUnsocial",
            "storageKey": null
        }
    ],
    "type": "Event",
    "abstractKey": null
};
node.hash = "c82c25f612f765feea4e65f6262c765e";
export default node;
