/**
 * @generated SignedSource<<513964f11ba89174f0aeaf955fef0a1f>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserSystemInfoUpdateModal_updateUserStatusMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateUserPayload",
                    "kind": "LinkedField",
                    "name": "updateUserStatus",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfUser",
                            "kind": "LinkedField",
                            "name": "userEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserSystemInfoUpdateModal_updateUserStatusMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateUserPayload",
                    "kind": "LinkedField",
                    "name": "updateUserStatus",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfUser",
                            "kind": "LinkedField",
                            "name": "userEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "2a018d7f04f0daca29e51a909f58247d",
            "id": null,
            "metadata": {},
            "name": "UserSystemInfoUpdateModal_updateUserStatusMutation",
            "operationKind": "mutation",
            "text": "mutation UserSystemInfoUpdateModal_updateUserStatusMutation(\n  $model: UpdateUserStatusRequestInput!\n) {\n  updateUserStatus(model: $model) {\n    userEdge {\n      node {\n        status\n        id\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "3e71922f499d053504461070273f7944";
export default node;
