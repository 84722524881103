/**
 * @generated SignedSource<<1939f573ea41533941aab4c59082235c>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "BankIdFormQuery",
            "selections": [
                {
                    "args": (v1 /*: any*/),
                    "kind": "FragmentSpread",
                    "name": "BankIdForm_CollectBankIdAuth"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "BankIdFormQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CollectBankIdAuthPayload",
                    "kind": "LinkedField",
                    "name": "collectBankIdAuth",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "message",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "qrData",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "420f3b5ffbba4006b0c2074429983ef5",
            "id": null,
            "metadata": {},
            "name": "BankIdFormQuery",
            "operationKind": "query",
            "text": "query BankIdFormQuery(\n  $model: CollectBankIdAuthInput!\n) {\n  ...BankIdForm_CollectBankIdAuth_47b4ea\n}\n\nfragment BankIdForm_CollectBankIdAuth_47b4ea on Query {\n  collectBankIdAuth(model: $model) {\n    message\n    status\n    qrData\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "37cfc0ce5be6a92717e69d200e50cc93";
export default node;
