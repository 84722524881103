import React from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import { useParams } from 'react-router';
import { format } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import { Chip, Flexbox, Text } from '@sonika-se/ui-kit/components';
import { roundTimes } from '@sonika-se/ui-kit/utils/time';
import TimeCardContainer, { useTimeCardContainerStyles } from './TimeCardContainer';
import { useEnvironment } from '@sonika-se/ui-kit/hooks';
export const timeReportStatus = {
    NONE: {
        text: 'Pågående',
        status: 'NONE',
        appearance: 'primary',
    },
    SUBMITTED: {
        text: 'Inskickad',
        status: 'SUBMITTED',
        appearance: 'warning',
    },
    REJECTED: {
        text: 'Ej godkänd',
        status: 'REJECTED',
        appearance: 'danger',
    },
    APPROVED: {
        text: 'Godkänd',
        status: 'APPROVED',
        appearance: 'success',
    },
};
export const getTimeReportStatus = (status) => {
    return timeReportStatus[status];
};
export const TimeReportCard = (props) => {
    var _a, _b, _c;
    const { showName } = props;
    const data = useFragment(graphql `
      fragment TimeReportCard_timeReport on TimeReport {
        id
        status
        month
        timeReportingUser {
          name
          id
        }
        timeTracksSummary {
          duration(unit: HOUR) {
            value
          }
        }
      }
    `, props.timeReportRef);
    const totalHours = ((_b = (_a = data === null || data === void 0 ? void 0 : data.timeTracksSummary) === null || _a === void 0 ? void 0 : _a.duration) === null || _b === void 0 ? void 0 : _b.value) || 0;
    const { frontUrls } = useEnvironment();
    const classes = useTimeCardContainerStyles({});
    const params = useParams();
    const status = getTimeReportStatus(data === null || data === void 0 ? void 0 : data.status);
    return (<TimeCardContainer appearance={status === null || status === void 0 ? void 0 : status.appearance} active={params['*'] === `/${data === null || data === void 0 ? void 0 : data.id}`} onClick={() => {
            window.location.href = `${frontUrls['TIME']}time-reports/all/${data.id}`;
        }}>
      <Flexbox justify="space" direction="column">
        <Text style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
          {showName
            ? `${(_c = data === null || data === void 0 ? void 0 : data.timeReportingUser) === null || _c === void 0 ? void 0 : _c.name}`
            : format(new Date(data === null || data === void 0 ? void 0 : data.month), 'MMMM yyyy', { locale })}
        </Text>
        <div className={classes.childList}>
          {showName && (<Text style={{ textTransform: 'capitalize' }}>
              {format(new Date(data === null || data === void 0 ? void 0 : data.month), 'MMM yyyy  ', { locale }).replace('.', '')}
            </Text>)}
          <Text>{`${roundTimes(totalHours)} tim`}</Text>
          {!showName && <div />}
          <div>
            <Chip size="xsmall" text={status === null || status === void 0 ? void 0 : status.text} appearance={status === null || status === void 0 ? void 0 : status.appearance} type="view" className={classes === null || classes === void 0 ? void 0 : classes.chip}/>
          </div>
        </div>
      </Flexbox>
    </TimeCardContainer>);
};
export default TimeReportCard;
