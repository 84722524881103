/**
 * @generated SignedSource<<8ab74e398b6c8d40626569e1d620083c>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "SetEventAssignmentTypesPayload",
            "kind": "LinkedField",
            "name": "setEventAssignmentTypes",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "EdgeOfEvent",
                    "kind": "LinkedField",
                    "name": "eventEdge",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Event",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                (v1 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssignmentType",
                                    "kind": "LinkedField",
                                    "name": "assignmentTypes",
                                    "plural": true,
                                    "selections": [
                                        (v1 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "EditEventAssignmentTypesMutation",
            "selections": (v2 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "EditEventAssignmentTypesMutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "c9d9044bc87b4d6b66591db74af7b321",
            "id": null,
            "metadata": {},
            "name": "EditEventAssignmentTypesMutation",
            "operationKind": "mutation",
            "text": "mutation EditEventAssignmentTypesMutation(\n  $model: SetEventAssignmentTypesInput!\n) {\n  setEventAssignmentTypes(model: $model) {\n    eventEdge {\n      node {\n        id\n        assignmentTypes {\n          id\n          title\n        }\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "9812e8c3eefe24d37b61c55a195fe0ee";
export default node;
