import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createUseStyles } from 'react-jss';
import * as yup from 'yup';
import { Button, Field, Form, Input, Text } from '@sonika-se/ui-kit/components';
const useStyles = createUseStyles((theme) => ({
    errorText: {
        color: theme.colors.system.stop,
    },
}));
const validationSchema = yup.object().shape({
    username: yup.string().required('Obligatoriskt fält'),
    password: yup.string().required('Obligatoriskt fält'),
});
const LoginForm = (props) => {
    var _a, _b, _c, _d;
    const { disabled, error = '', onSubmit, defaultPassword, defaultUsername } = props;
    const navigate = useNavigate();
    const { control, handleSubmit, formState: { errors }, } = useForm({
        defaultValues: {
            username: defaultUsername,
            password: defaultPassword,
        },
        resolver: yupResolver(validationSchema),
    });
    const classes = useStyles();
    return (<Form onSubmit={handleSubmit(onSubmit)}>
      <Field label="Användarnamn" error={error === 'Felaktigt användarnamn eller lösenord' ? error : (_b = (_a = errors === null || errors === void 0 ? void 0 : errors.username) === null || _a === void 0 ? void 0 : _a.message) === null || _b === void 0 ? void 0 : _b.toString()}>
        <Controller control={control} name="username" render={({ field }) => (<Input display="block" onBlur={field.onBlur} onChange={field.onChange} placeholder="exempel@domanen.se" disabled={disabled} value={field.value}/>)}/>
      </Field>
      <Field label="Lösenord" error={error === 'Felaktigt användarnamn eller lösenord' ? error : (_d = (_c = errors === null || errors === void 0 ? void 0 : errors.password) === null || _c === void 0 ? void 0 : _c.message) === null || _d === void 0 ? void 0 : _d.toString()}>
        <Controller control={control} name="password" render={({ field }) => (<Input display="block" onBlur={field.onBlur} onChange={field.onChange} placeholder="*****" disabled={disabled} type="password" value={field.value}/>)}/>
      </Field>
      <Button disabled={disabled} display="block" size="large" type="submit" style={{ marginTop: '.5rem' }}>
        Logga in
      </Button>
      {error && (<Text size="small" className={classes.errorText}>
          {error}
        </Text>)}
      <div>
        <Button appearance="text" style={{ padding: 0, marginTop: '.25rem' }} onClick={() => {
            navigate('/account/reset-password');
        }}>
          Glömt lösenord?
        </Button>
      </div>
    </Form>);
};
export default LoginForm;
