import React from 'react';
import { useParams } from 'react-router';
import { Flexbox } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import UserSidebar from '~/sidebars/UserSidebar';
import UserContactPanel from '~/panels/UserContactPanel';
import UserExternalContactsPanel from '~/panels/UserExternalContactsPanel';
import UserHomeAddressPanel from '~/panels/UserHomeAddressPanel';
import UserPersonalInfoPanel from '~/panels/UserPersonalInfoPanel';
import UserAssignmentTypesPanel from '~/panels/UserAssignmentTypesPanel';
export const UserDetailsPage = () => {
    const { userId } = useParams();
    const { user } = useLazyLoadQuery(graphql `
      query UserDetailsPageQuery($userId: ID!) {
        user(id: $userId) {
          ...UserActionsPanel_user
          ...UserSidebar_user
          ...UserAssignmentTypesPanel_user
          ...UserContactPanel_user
          ...UserExternalContactsPanel_user
          ...UserHomeAddressPanel_user
          ...UserPersonalInfoPanel_user
          ...UserServicesPanel_user
        }
      }
    `, {
        userId,
    });
    return (<Flexbox direction="row" gap="large">
      <div style={{ flex: 3 }}>
        <Flexbox direction="column" gap="large">
          <UserPersonalInfoPanel editable userRef={user}/>
          <UserContactPanel userRef={user}/>
          <UserExternalContactsPanel userRef={user}/>
          <UserAssignmentTypesPanel userRef={user}/>
          <Flexbox direction="row" gap="large" justify="equal">
            <UserHomeAddressPanel userRef={user}/>
            <span></span>
          </Flexbox>
        </Flexbox>
      </div>
      <div style={{ flex: 1 }}>
        <UserSidebar userRef={user}/>
      </div>
    </Flexbox>);
};
export default UserDetailsPage;
