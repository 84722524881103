import { SONIKA_REST_API_URL } from '../environment';
const requestImpersonationTokens = (token) => {
    const body = JSON.stringify({
        token,
    });
    return fetch(`${SONIKA_REST_API_URL}/tenants/v1/Authentication/impersonate`, {
        method: 'POST',
        cache: 'no-cache',
        headers: { 'Content-Type': 'application/json' },
        body,
    }).then((response) => response.json());
};
export default requestImpersonationTokens;
