/**
 * @generated SignedSource<<6a385388df3862e0dbf56679d04197e2>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Routes_query",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PrivateRoutes_query"
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "4ad86396859f5bd6bf0355f955851699";
export default node;
