import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { Form, Input, Field } from '@sonika-se/ui-kit/components';
export const assetAddressFormValidationSchema = object().shape({
    city: string().notRequired(),
    postalCode: string().notRequired(),
    street: string().notRequired(),
});
export const AssetAddressForm = forwardRef((props, forwardedRef) => {
    const { defaultValues, disabled, onSubmit } = props;
    const { control, formState: { errors, isValid }, handleSubmit, } = useForm({
        defaultValues,
        resolver: yupResolver(assetAddressFormValidationSchema),
    });
    useImperativeHandle(forwardedRef, () => ({
        isValid,
        submit: (onValid, onInvalid) => handleSubmit(onValid, onInvalid)(),
    }), [handleSubmit, isValid]);
    return (<Form columns="1fr 3fr" onSubmit={handleSubmit((data) => {
            onSubmit(data);
        })}>
        <Field columnSpan={2} label="Gatuadress">
          <Controller control={control} name="street" render={({ field }) => {
            var _a;
            return (<Input defaultValue={field.value} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.street) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange}/>);
        }}/>
        </Field>
        <Field label="Postnummer">
          <Controller control={control} name="postalCode" render={({ field }) => {
            var _a;
            return (<Input defaultValue={field.value} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.postalCode) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange}/>);
        }}/>
        </Field>
        <Field label="Stad">
          <Controller control={control} name="city" render={({ field }) => {
            var _a;
            return (<Input defaultValue={field.value} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.city) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange}/>);
        }}/>
        </Field>
      </Form>);
});
export default AssetAddressForm;
