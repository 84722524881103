/**
 * @generated SignedSource<<5d4379f8b9f3edc6009e561beff24965>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "SetCompanyAdminPayload",
            "kind": "LinkedField",
            "name": "setCompanyAdmin",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "isCompanyAdmin",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserPermissionsUpdateModal_setCompanyAdminMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserPermissionsUpdateModal_setCompanyAdminMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "ac2eabba3b83ad8b5dd2c68b44eefe66",
            "id": null,
            "metadata": {},
            "name": "UserPermissionsUpdateModal_setCompanyAdminMutation",
            "operationKind": "mutation",
            "text": "mutation UserPermissionsUpdateModal_setCompanyAdminMutation(\n  $model: SetCompanyAdminInput!\n) {\n  setCompanyAdmin(model: $model) {\n    user {\n      id\n      isCompanyAdmin\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "fb7b961830859058d7178c492c879b18";
export default node;
