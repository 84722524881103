import React, { useState } from 'react';
import { ModalToSheet, ModalToSheetContent, ModalToSheetHeader } from '@sonika-se/ui-kit/components';
import { graphql, useMutation } from 'react-relay/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import FaqPostForm from './FaqPostForm';
import useFaqPostAttachments from '~/hooks/attachments/useFaqPostAttachments';
export const NewFaqPostModal = (props) => {
    const { onClose } = props;
    const [createFaqPostMutation, createFaqPostMutationLoading] = useMutation(graphql `
      mutation NewFaqPostModal_createFaqPostMutation($model: CreateFaqPostRequestInput!) {
        createFaqPost(model: $model) {
          faqPostEdge {
            node {
              id
              ...FaqPostListItem_faqPost
              ...useFaqPostAttachments_faqPost
            }
          }
        }
      }
    `);
    const { addAttachments, setId, uploading } = useFaqPostAttachments(null, {
        onQueueComplete: () => onClose(),
    });
    const intl = useIntl();
    const [tempAttachments, setTempAttachments] = useState();
    return (<ModalToSheet>
      <ModalToSheetHeader onClose={onClose} closeButtonLabel="Avbryt">
        <FormattedMessage id="general.misc.faq.actions.create" defaultMessage="Ny fråga"/>
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <FaqPostForm disabled={createFaqPostMutationLoading || uploading} fileListProps={{
            defaultFiles: tempAttachments,
            onChange: (files) => {
                setTempAttachments(Array.from(files));
            },
            onDelete: (file) => {
                const newTempAttachments = [...tempAttachments].filter((storedFile) => storedFile !== file);
                setTempAttachments(newTempAttachments);
            },
        }} onCancel={() => onClose()} onSubmit={(formData) => {
            console.log(formData);
            createFaqPostMutation({
                configs: [
                    {
                        connectionInfo: [
                            {
                                key: 'FaqPostList_faqPosts',
                                rangeBehavior: 'append',
                            },
                        ],
                        parentID: 'client:root',
                        edgeName: 'faqPostEdge',
                        type: 'RANGE_ADD',
                    },
                ],
                onCompleted: (response) => {
                    if ((tempAttachments === null || tempAttachments === void 0 ? void 0 : tempAttachments.length) > 0) {
                        setId(response.createFaqPost.faqPostEdge.node.id);
                        addAttachments(tempAttachments);
                    }
                    else {
                        onClose();
                    }
                },
                onError: (error) => {
                    console.log({ error });
                },
                variables: {
                    model: {
                        answer: formData.answer,
                        question: formData.question,
                        status: formData.status,
                    },
                },
            });
        }}/>
      </ModalToSheetContent>
    </ModalToSheet>);
};
export default NewFaqPostModal;
