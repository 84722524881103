import React, { useCallback, useRef } from 'react';
import { graphql, useMutation, usePreloadedQuery } from 'react-relay/hooks';
import { FormModal } from '@sonika-se/ui-kit/components';
import UserUsernameForm from '~/forms/UserUsernameForm';
export const userSystemInfoUpdateModalQuery = graphql `
  query UserUsernameUpdateModalQuery($userId: ID!) {
    user(id: $userId) {
      color
      id
      username
    }
  }
`;
export const UserUsernameUpdateModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const { user } = usePreloadedQuery(userSystemInfoUpdateModalQuery, preloadedQuery);
    const [setPasswordLoginUsername, setPasswordLoginUsernameLoading] = useMutation(graphql `
        mutation UserUsernameUpdateModal_setPasswordLoginUsernameMutation($model: SetPasswordLoginUsernameInput!) {
          setPasswordLoginUsername(model: $model) {
            newUsername
          }
        }
      `);
    const formRef = useRef(null);
    const handleSubmit = useCallback((form) => {
        setPasswordLoginUsername({
            onCompleted: () => {
                onClose();
            },
            updater: (store) => {
                var _a;
                (_a = store.get(user.id)) === null || _a === void 0 ? void 0 : _a.setValue(form.username, 'username');
            },
            variables: {
                model: {
                    userId: user.id,
                    username: form.username,
                },
            },
        });
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Spara" disabled={setPasswordLoginUsernameLoading} title="Ändra användarnamn">
      <UserUsernameForm defaultValues={{
            username: user.username,
        }} disabled={setPasswordLoginUsernameLoading} onSubmit={handleSubmit} ref={formRef}/>
    </FormModal>);
};
export default UserUsernameUpdateModal;
