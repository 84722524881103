import React from 'react';
import { useParams } from 'react-router';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import UserPushDevicesPanel from '~/panels/UserPushDevicesPanel';
import UserSystemInfoPanel from '~/panels/UserSystemInfoPanel';
import { Flexbox } from '@sonika-se/ui-kit/components';
import UserUsernamePanel from '~/panels/UserUsernamePanel';
import UserInvitationPanel from '~/panels/UserInvitationPanel';
export const UserSystemPage = () => {
    const { userId } = useParams();
    const { user } = useLazyLoadQuery(graphql `
      query UserSystemPageQuery($userId: ID!) {
        user(id: $userId) {
          ...UserInvitationPanel_user
          ...UserPushDevicesPanel_user
          ...UserSystemInfoPanel_user
          ...UserUsernamePanel_user
        }
      }
    `, {
        userId,
    });
    return (<Flexbox direction="column" gap="large">
      <Flexbox direction="row" gap="large" justify="equal">
        <UserUsernamePanel userRef={user}/>
        <UserSystemInfoPanel userRef={user}/>
      </Flexbox>
      <Flexbox direction="row" gap="large" justify="equal">
        <UserPushDevicesPanel userRef={user}/>
        <UserInvitationPanel userRef={user}/>
      </Flexbox>
    </Flexbox>);
};
export default UserSystemPage;
