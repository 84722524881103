import React, { useState } from 'react';
import { BasicPage, BasicPageHeader, Button } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { ConnectionHandler } from 'relay-runtime';
import RoleCreateModal from '../modals/RoleCreateModal';
import RoleTablePanel from '~/panels/RoleTablePanel';
export const RoleIndexPage = () => {
    const data = useLazyLoadQuery(graphql `
      query RoleIndexPageQuery {
        ...RoleTablePanel_query
      }
    `, {}, {
        fetchPolicy: 'network-only',
    });
    const [roleCreateModalVisible, setRoleCreateModalVisible] = useState(false);
    return (<>
      <BasicPage width="large">
        <BasicPageHeader title="Grupper" action={<Button onClick={() => setRoleCreateModalVisible(true)}>Lägg till grupp</Button>}/>
        <RoleTablePanel queryRef={data}/>
      </BasicPage>
      {roleCreateModalVisible && (<RoleCreateModal connectionsToPrependEdge={[ConnectionHandler.getConnectionID('root', 'RoleTable_query_roles')]} onClose={() => {
                setRoleCreateModalVisible(false);
            }}/>)}
    </>);
};
export default RoleIndexPage;
