import React from 'react';
import { createUseStyles } from 'react-jss';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { useNavigate } from 'react-router';
import { Button } from '@sonika-se/ui-kit/components';
const useStyles = createUseStyles(() => {
    return {
        backLink: {
            fontWeight: 400,
        },
    };
});
export const BackLink = (props) => {
    const { label, link } = props;
    const classes = useStyles();
    const navigate = useNavigate();
    return (<div>
      <Button icon={faArrowLeft} appearance="text" padding={false} className={classes.backLink} onClick={() => navigate(link)}>
        {label}
      </Button>
    </div>);
};
export default BackLink;
