import React, { useState } from 'react';
import { faCheckCircle, faSpinner, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { Flexbox, Icon, Text } from '@sonika-se/ui-kit/components';
import { createUseStyles } from 'react-jss';
import { graphql, useMutation } from 'react-relay/hooks';
import AccountPage from '../AccountPages/components/AccountPage';
import SignupWizard from './components/SignupWizard';
import { useEnvironment } from '@sonika-se/ui-kit/hooks';
const useStyles = createUseStyles((theme) => ({
    container: {
        padding: '2rem 0',
    },
    iconCreate: {
        color: theme.colors.primary,
        fontSize: '1.5rem',
    },
    iconError: {
        color: theme.colors.system.stop,
        fontSize: '1.5rem',
    },
    iconSuccess: {
        color: theme.colors.system.go,
        fontSize: '1.5rem',
    },
    text: {
        textAlign: 'center',
    },
}));
export const SignupPage = () => {
    const [state, setState] = useState('form');
    const classes = useStyles();
    const [createCompany] = useMutation(graphql `
      mutation SignupPage_createCompanyMutation($model: CreateCompanyRequestInput!) {
        createCompany(model: $model) {
          companyEdge {
            node {
              id
            }
          }
        }
      }
    `);
    const { frontUrls } = useEnvironment();
    return (<AccountPage>
      {state === 'form' && (<SignupWizard onSubmit={(data) => {
                setState('create');
                createCompany({
                    onCompleted: () => {
                        setState('done');
                    },
                    onError: () => {
                        setState('error');
                    },
                    variables: {
                        model: {
                            confirmAccountUrl: `${frontUrls['CORE']}account/accept-invite`,
                            email: data.admin.email,
                            firstname: data.admin.firstname,
                            lastname: data.admin.lastname,
                            name: data.company.name,
                            services: data.services.services,
                        },
                    },
                });
            }}/>)}
      {state === 'create' && (<Flexbox align="center" className={classes.container} direction="column" gap="xsmall">
          <Icon className={classes.iconCreate} icon={faSpinner} spin/>
          <Text bold className={classes.text}>
            Skapar ditt konto...
          </Text>
        </Flexbox>)}
      {state === 'done' && (<Flexbox align="center" className={classes.container} direction="column" gap="xsmall">
          <Icon className={classes.iconSuccess} icon={faCheckCircle}/>
          <div>
            <Text bold className={classes.text}>
              Konto skapat!
            </Text>
            <Text className={classes.text} size="medium">
              Kolla din e-post för vidare information om hur du loggar in.
            </Text>
          </div>
        </Flexbox>)}
      {state === 'error' && (<Flexbox align="center" className={classes.container} direction="column" gap="xsmall">
          <Icon className={classes.iconError} icon={faTimesCircle}/>
          <div>
            <Text bold className={classes.text}>
              Kunde inte skapa kontot
            </Text>
            <Text className={classes.text} size="medium">
              Ett fel inträffade. Kontakta <a href="mailto:info@sonika.se">support@sonika.se</a> för hjälp.
            </Text>
          </div>
        </Flexbox>)}
    </AccountPage>);
};
export default SignupPage;
