import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { Field, Form, Input } from '@sonika-se/ui-kit/components';
const validationSchema = object({
    email: string().required(),
    firstname: string().required(),
    lastname: string().required(),
});
export const SignupWizardAdminForm = (props) => {
    const { defaultValues, formId, onSubmit } = props;
    const { control, formState: { errors }, handleSubmit, } = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
    });
    return (<Form id={formId} onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Controller control={control} name="firstname" render={({ field }) => {
            var _a, _b;
            return (<Field error={(_a = errors === null || errors === void 0 ? void 0 : errors.firstname) === null || _a === void 0 ? void 0 : _a.message} label="Förnamn">
            <Input error={(_b = errors === null || errors === void 0 ? void 0 : errors.firstname) === null || _b === void 0 ? void 0 : _b.message} onChange={field.onChange} onBlur={field.onBlur} defaultValue={defaultValues.firstname}/>
          </Field>);
        }}/>
      <Controller control={control} name="lastname" render={({ field }) => {
            var _a, _b;
            return (<Field error={(_a = errors === null || errors === void 0 ? void 0 : errors.lastname) === null || _a === void 0 ? void 0 : _a.message} label="Efternamn">
            <Input error={(_b = errors === null || errors === void 0 ? void 0 : errors.lastname) === null || _b === void 0 ? void 0 : _b.message} onChange={field.onChange} onBlur={field.onBlur} defaultValue={defaultValues.lastname}/>
          </Field>);
        }}/>
      <Controller control={control} name="email" render={({ field }) => {
            var _a, _b;
            return (<Field error={(_a = errors === null || errors === void 0 ? void 0 : errors.email) === null || _a === void 0 ? void 0 : _a.message} label="E-post">
            <Input error={(_b = errors === null || errors === void 0 ? void 0 : errors.email) === null || _b === void 0 ? void 0 : _b.message} onChange={field.onChange} onBlur={field.onBlur} defaultValue={defaultValues.email}/>
          </Field>);
        }}/>
    </Form>);
};
export default SignupWizardAdminForm;
