/**
 * @generated SignedSource<<1afc05ebb46cc614b64173eafce07168>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v1 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "PermissionGroup",
            "kind": "LinkedField",
            "name": "permissionGroups",
            "plural": true,
            "selections": [
                (v0 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Permission",
                    "kind": "LinkedField",
                    "name": "permissions",
                    "plural": true,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "PermissionsFormQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "PermissionsFormQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "ef6ad2a736c4876764f1a39ef217ba37",
            "id": null,
            "metadata": {},
            "name": "PermissionsFormQuery",
            "operationKind": "query",
            "text": "query PermissionsFormQuery {\n  permissionGroups {\n    name\n    permissions {\n      name\n      value\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "9f7759b529a6d99dc1186178668cf28d";
export default node;
