import React from 'react';
import { BasicPage, BasicPageHeader, Grid, Panel, Placeholder } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import ServiceCard from '~/cards/ServiceCard';
import { serviceNames } from '@sonika-se/ui-kit';
export const ServiceIndexPage = () => {
    const { currentCompany: { activeServices }, services, } = useLazyLoadQuery(graphql `
      query ServiceIndexPageQuery {
        currentCompany {
          activeServices
        }
        services {
          ...ServiceCard_serviceInfo
          value
        }
      }
    `, {});
    return (<BasicPage width="large">
      <BasicPageHeader title="Tjänster"/>
      {services.length === 0 ? (<Panel>
          <Placeholder text="Inga tjänster."/>
        </Panel>) : (<Grid columns="1fr 1fr 1fr 1fr" gap="medium">
          {services
                .flat()
                .sort((a, b) => (serviceNames[a.value] > serviceNames[b.value] ? 1 : -1))
                .map((service) => (<ServiceCard active={activeServices.includes(service.value)} key={service.value} serviceRef={service}/>))}
        </Grid>)}
    </BasicPage>);
};
export default ServiceIndexPage;
