/**
 * @generated SignedSource<<ece82d3f605aa44c873a09c6181a6822>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": "avatarImage",
        "args": null,
        "concreteType": "Avatar",
        "kind": "LinkedField",
        "name": "avatar",
        "plural": false,
        "selections": [
            {
                "alias": "avatarUrl",
                "args": [
                    {
                        "kind": "Literal",
                        "name": "queryTransform",
                        "value": "width=400&height=400&mode=crop&scale=both"
                    }
                ],
                "kind": "ScalarField",
                "name": "url",
                "storageKey": "url(queryTransform:\"width=400&height=400&mode=crop&scale=both\")"
            },
            (v2 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "storageId",
                "storageKey": null
            }
        ],
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "IHaveAvatarUpdateModal_setAvatarMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "setAvatar",
                    "plural": false,
                    "selections": [
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "Avatar_iHaveAvatar"
                                },
                                (v3 /*: any*/)
                            ],
                            "type": "IHaveAvatar",
                            "abstractKey": "__isIHaveAvatar"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "IHaveAvatarUpdateModal_setAvatarMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "setAvatar",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        },
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Avatar",
                                    "kind": "LinkedField",
                                    "name": "avatar",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": [
                                                {
                                                    "kind": "Literal",
                                                    "name": "queryTransform",
                                                    "value": "width=300&height=300&mode=crop&scale=both"
                                                }
                                            ],
                                            "kind": "ScalarField",
                                            "name": "url",
                                            "storageKey": "url(queryTransform:\"width=300&height=300&mode=crop&scale=both\")"
                                        },
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        (v4 /*: any*/),
                                        (v5 /*: any*/)
                                    ],
                                    "type": "Asset",
                                    "abstractKey": null
                                },
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        (v5 /*: any*/)
                                    ],
                                    "type": "Company",
                                    "abstractKey": null
                                },
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        (v4 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "firstname",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "lastname",
                                            "storageKey": null
                                        }
                                    ],
                                    "type": "User",
                                    "abstractKey": null
                                },
                                (v3 /*: any*/)
                            ],
                            "type": "IHaveAvatar",
                            "abstractKey": "__isIHaveAvatar"
                        },
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                (v2 /*: any*/)
                            ],
                            "type": "Node",
                            "abstractKey": "__isNode"
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "7f02b853f786757f5d967fb23bd63f43",
            "id": null,
            "metadata": {},
            "name": "IHaveAvatarUpdateModal_setAvatarMutation",
            "operationKind": "mutation",
            "text": "mutation IHaveAvatarUpdateModal_setAvatarMutation(\n  $model: SetAvatarRequestInput!\n) {\n  setAvatar(model: $model) {\n    __typename\n    ... on IHaveAvatar {\n      __isIHaveAvatar: __typename\n      ...Avatar_iHaveAvatar\n      avatarImage: avatar {\n        avatarUrl: url(queryTransform: \"width=400&height=400&mode=crop&scale=both\")\n        id\n        storageId\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment Avatar_iHaveAvatar on IHaveAvatar {\n  __isIHaveAvatar: __typename\n  avatar {\n    url(queryTransform: \"width=300&height=300&mode=crop&scale=both\")\n    id\n  }\n  ... on Asset {\n    color\n    name\n  }\n  ... on Company {\n    name\n  }\n  ... on User {\n    color\n    firstname\n    lastname\n  }\n}\n"
        }
    };
})();
node.hash = "b93908dfb8ac3127e889572bbfbfdea9";
export default node;
