/**
 * @generated SignedSource<<f12407b3b8aafe57cd046d6d75a167ab>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "RolePermissionsPanel_role",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rolePermissions",
            "storageKey": null
        }
    ],
    "type": "Role",
    "abstractKey": null
};
node.hash = "3d82c3f9ae2fc14761da3ffcdd916bb8";
export default node;
