/**
 * @generated SignedSource<<9fed3e92ed018ce79e9329c2cd5f4b8b>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v1 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "PermissionGroup",
            "kind": "LinkedField",
            "name": "permissionGroups",
            "plural": true,
            "selections": [
                (v0 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Permission",
                    "kind": "LinkedField",
                    "name": "permissions",
                    "plural": true,
                    "selections": [
                        (v0 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "RolePermissionsPanelQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "RolePermissionsPanelQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "a33885c75f7809b37375a971d62b9166",
            "id": null,
            "metadata": {},
            "name": "RolePermissionsPanelQuery",
            "operationKind": "query",
            "text": "query RolePermissionsPanelQuery {\n  permissionGroups {\n    name\n    permissions {\n      name\n      value\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "a993958b29bc2d8283e10252bc57731a";
export default node;
