import React, { useCallback, useRef } from 'react';
import { graphql, useMutation } from 'react-relay/hooks';
import { FormModal } from '@sonika-se/ui-kit/components';
import RoleCreateForm from '~/forms/RoleCreateForm';
export const RoleCreateModal = (props) => {
    const { connectionsToPrependEdge, onClose } = props;
    const [createRole, createRoleLoading] = useMutation(graphql `
      mutation RoleCreateModal_createRoleMutation($connectionsToPrependEdge: [ID!]!, $model: CreateRoleInput!) {
        createRole(model: $model) {
          roleEdge @prependEdge(connections: $connectionsToPrependEdge) {
            node {
              id
              name
              rolePermissions
              usersInRole {
                totalCount
              }
            }
          }
        }
      }
    `);
    const formRef = useRef(null);
    const handleSubmit = useCallback((form) => {
        createRole({
            variables: {
                connectionsToPrependEdge,
                model: {
                    name: form.name,
                },
            },
            onCompleted: (response) => {
                onClose(response.createRole.roleEdge.node);
            },
        });
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Spara" disabled={createRoleLoading} title="Lägg till grupp">
      <RoleCreateForm defaultValues={{
            name: '',
        }} disabled={createRoleLoading} onSubmit={handleSubmit} ref={formRef}/>
    </FormModal>);
};
export default RoleCreateModal;
