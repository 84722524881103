/**
 * @generated SignedSource<<bcadaac6e1d702a0a9a54f6c89657be7>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "availableVacanciesModel"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "myScheduleFilterModel"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "myScheduleModel"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SchedulePanel_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "SchedulableUser",
            "kind": "LinkedField",
            "name": "schedulableEntity",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "schedulable",
                    "storageKey": null
                },
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "filter",
                            "variableName": "myScheduleFilterModel"
                        },
                        {
                            "kind": "Variable",
                            "name": "model",
                            "variableName": "myScheduleModel"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "MySchedulePanel_schedulableUser"
                },
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "model",
                            "variableName": "availableVacanciesModel"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "AvailableVacanciesPanel_schedulableUser"
                }
            ],
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
};
node.hash = "0764d1d8ff9d6b1b724dd4937b67674b";
export default node;
