/**
 * @generated SignedSource<<39521fe771712c2ce529f87e5b44f70a>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FaqPanel_query",
    "selections": [
        {
            "args": [
                {
                    "kind": "Literal",
                    "name": "first",
                    "value": 5
                },
                {
                    "kind": "Literal",
                    "name": "order",
                    "value": {
                        "createdDate": "DESC"
                    }
                },
                {
                    "kind": "Literal",
                    "name": "where",
                    "value": {
                        "status": {
                            "eq": "PUBLISHED"
                        }
                    }
                }
            ],
            "kind": "FragmentSpread",
            "name": "FaqPostList_query"
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "e1fcb0f0bd6bb2545eca786941427069";
export default node;
