/**
 * @generated SignedSource<<7045c03fc538f2f28c3a21cce9311094>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "Avatar_iHaveAvatar",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "Avatar",
                "kind": "LinkedField",
                "name": "avatar",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Literal",
                                "name": "queryTransform",
                                "value": "width=300&height=300&mode=crop&scale=both"
                            }
                        ],
                        "kind": "ScalarField",
                        "name": "url",
                        "storageKey": "url(queryTransform:\"width=300&height=300&mode=crop&scale=both\")"
                    }
                ],
                "storageKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    (v0 /*: any*/),
                    (v1 /*: any*/)
                ],
                "type": "Asset",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    (v1 /*: any*/)
                ],
                "type": "Company",
                "abstractKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstname",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastname",
                        "storageKey": null
                    }
                ],
                "type": "User",
                "abstractKey": null
            }
        ],
        "type": "IHaveAvatar",
        "abstractKey": "__isIHaveAvatar"
    };
})();
node.hash = "c0d80fb36d361408f0127ada2547e17e";
export default node;
