import React, { Suspense } from 'react';
import { Outlet, useParams } from 'react-router';
import { BasicPage, Flexbox, H1, Menu, MenuItem, Skeleton } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import Avatar from '~/shared/Avatar';
export const AssetLayout = () => {
    const { assetId } = useParams();
    const { asset } = useLazyLoadQuery(graphql `
      query AssetLayoutQuery($assetId: ID!) {
        asset(id: $assetId) {
          ...Avatar_iHaveAvatar
          name
        }
      }
    `, {
        assetId,
    });
    return (<BasicPage width="large">
      <Flexbox direction="row" gap="medium" style={{ paddingTop: '3rem' }}>
        <Avatar iHaveAvatarRef={asset} size="large"/>
        <H1>{asset.name}</H1>
      </Flexbox>
      <Flexbox direction="column" gap="xlarge">
        <Menu>
          <MenuItem index path="." text="Allmänt"/>
          <MenuItem path="attachments" text="Filer"/>
          <MenuItem path="system" text="System"/>
          <MenuItem path="proficiencies" text="Behörigheter"/>
        </Menu>
        <Suspense fallback={<Skeleton height="40vh"/>}>
          <Outlet />
        </Suspense>
      </Flexbox>
    </BasicPage>);
};
export default AssetLayout;
