import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { Form, Field, Switch } from '@sonika-se/ui-kit/components';
import { boolean } from 'yup';
export const scheduleGeneralInfoFormValidationSchema = object().shape({
    active: boolean().nullable().notRequired(),
});
export const AssignmentTypeStatusForm = forwardRef((props, forwardedRef) => {
    const { defaultValues, disabled, onSubmit } = props;
    const { control, formState: { errors, isValid }, handleSubmit, } = useForm({
        defaultValues,
        resolver: yupResolver(scheduleGeneralInfoFormValidationSchema),
    });
    useImperativeHandle(forwardedRef, () => ({
        isValid,
        submit: (onValid, onInvalid) => handleSubmit(onValid, onInvalid)(),
    }), [handleSubmit, isValid]);
    return (<Form onSubmit={handleSubmit((data) => {
            onSubmit(data);
        })}>
      <Field label="Aktiv">
        <Controller control={control} name="active" render={({ field }) => {
            var _a;
            return (<Switch defaultChecked={field.value} error={(_a = errors === null || errors === void 0 ? void 0 : errors.active) === null || _a === void 0 ? void 0 : _a.message} disabled={disabled} onBlur={field.onBlur} onChange={(event) => field.onChange(event.target.checked)}/>);
        }}/>
      </Field>
    </Form>);
});
export default AssignmentTypeStatusForm;
