import React, { useMemo } from 'react';
import { Card, CardContent, Divider, Flexbox, Text, H4, H5, ColorItem, Placeholder } from '@sonika-se/ui-kit/components';
import { FormattedMessage, useIntl } from 'react-intl';
import { endOfMonth, format } from 'date-fns';
import { sv as locale } from 'date-fns/locale';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import TimeReportCard from './TimeReportCard';
import startOfMonth from 'date-fns/startOfMonth';
import { useViewport } from '@sonika-se/ui-kit/hooks';
import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { addMonths } from 'date-fns/esm';
export const AllTimeReportsPanel = () => {
    var _a, _b, _c, _d, _e, _f, _g;
    const intl = useIntl();
    const monthRange = useMemo(() => {
        const lastMonth = addMonths(startOfMonth(new Date()), -1);
        return {
            lastMonth,
            minMonth: lastMonth,
            maxMonth: endOfMonth(lastMonth),
        };
    }, []);
    const data = useLazyLoadQuery(graphql `
      query AllTimeReportsPanelQuery($where: TimeReportFilterInput, $minMonth: DateTime, $maxMonth: DateTime) {
        timeReports(where: $where, first: 5, after: null) {
          edges {
            node {
              id
              ...TimeReportCard_timeReport
            }
          }
        }
        submittedTimeReports: timeReports(
          where: { status: { eq: SUBMITTED }, month: { gte: $minMonth, lte: $maxMonth } }
        ) {
          totalCount
        }
        approvedTimeReports: timeReports(
          where: { status: { eq: APPROVED }, month: { gte: $minMonth, lte: $maxMonth } }
        ) {
          totalCount
        }
        rejectedTimeReports: timeReports(
          where: { status: { eq: REJECTED }, month: { gte: $minMonth, lte: $maxMonth } }
        ) {
          totalCount
        }
        openTimeReports: timeReports(where: { status: { eq: NONE }, month: { gte: $minMonth, lte: $maxMonth } }) {
          totalCount
        }
      }
    `, {
        where: {
            status: {
                eq: 'SUBMITTED',
            },
            month: { gte: monthRange.minMonth, lte: monthRange.maxMonth },
        },
        minMonth: monthRange.minMonth,
        maxMonth: monthRange.maxMonth,
    });
    const { size } = useViewport();
    return (<Flexbox gap="medium" justify="equal" direction={size !== 'small' ? 'row' : 'column'}>
      <Card background="white" shadow="medium" style={{ maxWidth: size !== 'small' ? '350px' : 'unset' }}>
        <CardContent style={{ height: '100%', boxSizing: 'border-box' }}>
          <Flexbox direction="column" gap="small">
            <Flexbox gap="none" direction="column">
              <Text size="small">
                <FormattedMessage id="AllTimeReportsPanel.header" defaultMessage="Summering av tidrapporter"/>
              </Text>
              <Flexbox style={{ textTransform: 'capitalize' }}>
                <H4 style={{ textTransform: 'capitalize' }}>{format(monthRange.lastMonth, 'MMMM yyyy', { locale })}</H4>
              </Flexbox>
            </Flexbox>
            <Divider />
            <Flexbox align="center">
              <ColorItem color={{
            hex: 'rgb(87, 190, 86)',
            name: '',
        }}/>
              &nbsp;
              <Text>{`${((_a = data === null || data === void 0 ? void 0 : data.approvedTimeReports) === null || _a === void 0 ? void 0 : _a.totalCount) || 0} godkända`}</Text>
            </Flexbox>
            <Flexbox align="center">
              <ColorItem color={{
            hex: 'rgb(253, 143, 39)',
            name: '',
        }}/>
              &nbsp;
              <Text>{`${((_b = data === null || data === void 0 ? void 0 : data.submittedTimeReports) === null || _b === void 0 ? void 0 : _b.totalCount) || 0} inskickade`}</Text>
            </Flexbox>
            <Flexbox align="center">
              <ColorItem color={{
            hex: '#FD544F',
            name: '',
        }}/>
              &nbsp;
              <Text>{`${((_c = data === null || data === void 0 ? void 0 : data.rejectedTimeReports) === null || _c === void 0 ? void 0 : _c.totalCount) || 0} avslagna`}</Text>
            </Flexbox>
            <Flexbox align="center">
              <ColorItem color={{
            hex: '#0C2AF3',
            name: '',
        }}/>
              &nbsp;
              <Text>{`${((_d = data === null || data === void 0 ? void 0 : data.openTimeReports) === null || _d === void 0 ? void 0 : _d.totalCount) || 0} pågående`}</Text>
            </Flexbox>
          </Flexbox>
        </CardContent>
      </Card>
      <Flexbox direction="column" gap="medium">
        <H5>
          <FormattedMessage id="AllTimeReportsPanel.time_reports.header" defaultMessage="Tidrapporter att administrera"/>
        </H5>
        {!((_f = (_e = data === null || data === void 0 ? void 0 : data.timeReports) === null || _e === void 0 ? void 0 : _e.edges) === null || _f === void 0 ? void 0 : _f.length) && (<Placeholder icon={faClock} height="full" title={intl.formatMessage({
                id: 'AllTimeReportsPanel.no_data',
                defaultMessage: 'Det finns inga tidrapporter att administrera',
            })}/>)}
        <Flexbox direction="column" gap="small">
          {(_g = data === null || data === void 0 ? void 0 : data.timeReports.edges) === null || _g === void 0 ? void 0 : _g.map(({ node }) => (<TimeReportCard timeReportRef={node} key={node === null || node === void 0 ? void 0 : node.id} showName/>))}
        </Flexbox>
      </Flexbox>
    </Flexbox>);
};
export default AllTimeReportsPanel;
