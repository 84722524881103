import React, { useState } from 'react';
import { BasicPage, BasicPageHeader, Button } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { ConnectionHandler } from 'relay-runtime';
import AssignmentTypeCreateModal from '~/modals/AssignmentTypeCreateModal';
import AssignmentTypeTablePanel from '~/panels/AssignmentTypeTablePanel';
export const AssignmentTypeIndexPage = () => {
    const data = useLazyLoadQuery(graphql `
      query AssignmentTypeIndexPageQuery {
        ...AssignmentTypeTablePanel_query
      }
    `, {}, {
        fetchPolicy: 'network-only',
    });
    const [assignmentTypeCreateModalVisible, setAssignmentTypeCreateModalVisible] = useState(false);
    return (<>
      <BasicPage width="large">
        <BasicPageHeader title="Roller" action={<Button onClick={() => {
                setAssignmentTypeCreateModalVisible(true);
            }}>
              Lägg till roll
            </Button>}/>
        <AssignmentTypeTablePanel queryRef={data}/>
      </BasicPage>
      {assignmentTypeCreateModalVisible && (<AssignmentTypeCreateModal connectionsToPrependEdge={[
                ConnectionHandler.getConnectionID('root', 'AssignmentTypeTable_query_assignmentTypes'),
            ]} onClose={() => {
                setAssignmentTypeCreateModalVisible(false);
            }}/>)}
    </>);
};
export default AssignmentTypeIndexPage;
