import React, { Suspense } from 'react';
import { Flexbox, KeyValue, ModalSkeleton, Panel, PanelButton } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import { formatDate, toDate } from '@sonika-se/ui-kit/utils/time';
import UserInvitationModal, { userInvitationModalQuery } from '~/modals/UserInvitationModal';
export const UserInvitationPanel = (props) => {
    var _a, _b, _c;
    const { userRef } = props;
    const user = useFragment(graphql `
      fragment UserInvitationPanel_user on User {
        id
        invitation {
          accepted
          created
        }
      }
    `, userRef);
    const [userInvitationModalQueryRef, loadUserInvitationModalQuery, disposeUserInvitationModalQuery] = useQueryLoader(userInvitationModalQuery);
    return (<>
      <Panel title="Inbjudan" actions={<PanelButton disabled={!!((_a = user.invitation) === null || _a === void 0 ? void 0 : _a.accepted)} onClick={() => {
                loadUserInvitationModalQuery({ userId: user.id });
            }}>
            Skicka
          </PanelButton>}>
        <Flexbox direction="row" gap="xlarge">
          <KeyValue label="Skapad" value={((_b = user.invitation) === null || _b === void 0 ? void 0 : _b.created) ? formatDate(toDate(user.invitation.created), 'dateTime') : '-'}/>
          <KeyValue label="Accepterad" value={((_c = user.invitation) === null || _c === void 0 ? void 0 : _c.accepted) ? formatDate(toDate(user.invitation.accepted), 'dateTime') : '-'}/>
        </Flexbox>
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {userInvitationModalQueryRef && (<UserInvitationModal onClose={() => {
                disposeUserInvitationModalQuery();
            }} preloadedQuery={userInvitationModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default UserInvitationPanel;
