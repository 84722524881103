/**
 * @generated SignedSource<<7f478b28f11f97cfaae667f732a8a117>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TimePanel_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "TimeReportingUser",
            "kind": "LinkedField",
            "name": "timeReportingUser",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "active",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "MyTimeReportsPanel_timeReportingUser"
                }
            ],
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
};
node.hash = "8f98b1ac0c5e798bedd63713ee5189f9";
export default node;
