import React, { Suspense } from 'react';
import { Chip, KeyValue, ModalToSheetSkeleton, Panel, PanelButton } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import AssignmentTypeStatusUpdateModal, { assignmentTypeStatusUpdateModalQuery, } from '~/modals/AssignmentTypeStatusUpdateModal';
export const AssignmentTypeStatusPanel = (props) => {
    const { assignmentTypeRef } = props;
    const assignmentType = useFragment(graphql `
      fragment AssignmentTypeStatusPanel_assignmentType on AssignmentType {
        disabledDate
        id
      }
    `, assignmentTypeRef);
    const [assigmnetTypeStatusUpdateModalRef, loadAssigmentTypeStatusUpdateModal, disposeAssigmentTypeStatusUpdateModal] = useQueryLoader(assignmentTypeStatusUpdateModalQuery);
    const canEditAssigmentTypes = usePermissions()(['EDIT_ASSIGNMENT_TYPES']);
    return (<>
      <Panel actions={canEditAssigmentTypes && (<PanelButton onClick={() => loadAssigmentTypeStatusUpdateModal({ assignmentTypeId: assignmentType.id })}>
              Ändra
            </PanelButton>)} title="Status">
        <KeyValue label="Status">
          <div>
            {assignmentType.disabledDate && (<Chip color={sonikaTheme.colors.system.stop} size="xsmall" text="Inaktiv"/>)}
            {!assignmentType.disabledDate && <Chip color={sonikaTheme.colors.system.go} size="xsmall" text="Aktiv"/>}
          </div>
        </KeyValue>
      </Panel>
      <Suspense fallback={<ModalToSheetSkeleton />}>
        {assigmnetTypeStatusUpdateModalRef && (<AssignmentTypeStatusUpdateModal onClose={() => {
                disposeAssigmentTypeStatusUpdateModal();
            }} preloadedQuery={assigmnetTypeStatusUpdateModalRef}/>)}
      </Suspense>
    </>);
};
export default AssignmentTypeStatusPanel;
