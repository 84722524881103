import React, { useEffect, useState } from 'react';
import { serviceNames } from '@sonika-se/ui-kit';
import { Button, Flexbox, Text, Wizard } from '@sonika-se/ui-kit/components';
import SignupWizardAdminForm from './SignupWizardAdminForm';
import SignupWizardCompanyForm from './SignupWizardCompanyForm';
import SignupWizardServicesForm from './SignupWizardServicesForm';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
export const SignupWizard = (props) => {
    const { onSubmit } = props;
    const [currentStep, setCurrentStep] = useState(0);
    const [data, setData] = useState({
        admin: {
            email: '',
            firstname: '',
            lastname: '',
        },
        company: {
            name: '',
        },
        services: {
            services: [],
        },
    });
    const [servicesDefaultValue, setServicesDefaultValue] = useState();
    const query = useLazyLoadQuery(graphql `
      query SignupWizardServicesQuery {
        ...SignupWizardServicesFormServices_query
        services {
          value
        }
      }
    `, {});
    useEffect(() => {
        setServicesDefaultValue({
            services: query.services.map((x) => x.value),
        });
    }, []);
    if (servicesDefaultValue === undefined) {
        return null;
    }
    return (<Flexbox direction="column" gap="medium">
      <Wizard currentStep={currentStep} onStepChange={(step) => {
            setCurrentStep(step);
        }} steps={[
            {
                render: ({ id, state }) => state === 'WRITE' ? (<SignupWizardServicesForm queryRef={query} defaultValues={data.services.services.length > 0 ? data.services : servicesDefaultValue} formId={id} onSubmit={(services) => {
                        setData((data) => (Object.assign(Object.assign({}, data), { services })));
                        setCurrentStep(currentStep + 1);
                    }}/>) : (<Text bold>
                  {data.services.services.length > 0
                        ? data.services.services.map((service) => serviceNames[service]).join(', ')
                        : 'Inga tjänster valda'}
                </Text>),
                title: 'Valda tjänster',
            },
            {
                render: ({ id, state }) => state === 'WRITE' ? (<SignupWizardCompanyForm defaultValues={data.company} formId={id} onSubmit={(company) => {
                        setData((data) => (Object.assign(Object.assign({}, data), { company })));
                        setCurrentStep(currentStep + 1);
                    }}/>) : (<Text bold>{data.company.name}</Text>),
                title: 'Fyll i företagsinformation',
            },
            {
                render: ({ id, state }) => state === 'WRITE' ? (<SignupWizardAdminForm defaultValues={data.admin} formId={id} onSubmit={(admin) => {
                        setData((data) => (Object.assign(Object.assign({}, data), { admin })));
                        setCurrentStep(currentStep + 1);
                    }}/>) : (<Text bold>
                  {`${data.admin.firstname} ${data.admin.lastname}`}
                  <br />
                  {data.admin.email}
                </Text>),
                title: 'Ange administratör',
            },
            {
                render: () => (<Text>
                Kontrollera att uppgifterna ovan stämmer, klicka sen knappen nedan för att börja använda Sonika.
              </Text>),
                title: 'Bekräfta',
            },
        ]} loading={false}/>
      <Button display="block" disabled={currentStep !== 3} onClick={() => {
            onSubmit(data);
        }}>
        Kom igång
      </Button>
    </Flexbox>);
};
export default SignupWizard;
