import React, { useRef } from 'react';
import { Button, Flexbox, ModalToSheet, ModalToSheetContent, ModalToSheetFooter, ModalToSheetHeader, } from '@sonika-se/ui-kit/components';
import { graphql, useMutation, usePreloadedQuery } from 'react-relay/hooks';
import UserHomeAddressForm from '../forms/UserHomeAddressForm';
export const editUserHomeAddressModalQuery = graphql `
  query UserHomeAddressUpdateModalQuery($userId: ID!) {
    user(id: $userId) {
      homeAddress {
        careOf
        city
        id
        postOfficeBox
        postalCode
        street
      }
      id
    }
  }
`;
export const UserHomeAddressUpdateModal = (props) => {
    var _a, _b, _c, _d, _e;
    const { onClose, preloadedQuery } = props;
    const data = usePreloadedQuery(editUserHomeAddressModalQuery, preloadedQuery);
    const [setUserHomeAddress, setUserHomeAddressLoading] = useMutation(graphql `
        mutation UserHomeAddressUpdateModal_setUserHomeAddressMutation($model: SetUserHomeAddressInput!) {
          setUserHomeAddress(model: $model) {
            user {
              homeAddress {
                careOf
                city
                id
                postOfficeBox
                postalCode
                street
              }
            }
          }
        }
      `);
    const [unsetUserHomeAddress, unsetUserHomeAddressLoading] = useMutation(graphql `
        mutation UserHomeAddressUpdateModal_unsetUserHomeAddressMutation($model: UnsetUserHomeAddressInput!) {
          unsetUserHomeAddress(model: $model) {
            user {
              homeAddress {
                careOf
                city
                id
                postOfficeBox
                postalCode
                street
              }
            }
          }
        }
      `);
    const formRef = useRef(null);
    return (<ModalToSheet onClose={() => onClose()}>
      <ModalToSheetHeader onClose={() => onClose()}>Ändra hemadress</ModalToSheetHeader>
      <ModalToSheetContent>
        <UserHomeAddressForm defaultValues={{
            careOf: (_a = data.user.homeAddress) === null || _a === void 0 ? void 0 : _a.careOf,
            city: (_b = data.user.homeAddress) === null || _b === void 0 ? void 0 : _b.city,
            postOfficeBox: (_c = data.user.homeAddress) === null || _c === void 0 ? void 0 : _c.postOfficeBox,
            postalCode: (_d = data.user.homeAddress) === null || _d === void 0 ? void 0 : _d.postalCode,
            street: (_e = data.user.homeAddress) === null || _e === void 0 ? void 0 : _e.street,
        }} disabled={setUserHomeAddressLoading} ref={formRef}/>
      </ModalToSheetContent>
      <ModalToSheetFooter>
        <Flexbox gap="medium" justify="space" style={{ flex: 1 }}>
          {data.user.homeAddress ? (<Button color="danger" disabled={setUserHomeAddressLoading || unsetUserHomeAddressLoading} onClick={() => {
                unsetUserHomeAddress({
                    onCompleted: () => {
                        onClose();
                    },
                    variables: {
                        model: {
                            userId: data.user.id,
                        },
                    },
                });
            }}>
              Ta bort
            </Button>) : (<div />)}
          <Button color="primary" disabled={setUserHomeAddressLoading || unsetUserHomeAddressLoading} onClick={() => {
            formRef.current.submit((form) => {
                setUserHomeAddress({
                    onCompleted: () => {
                        onClose();
                    },
                    variables: {
                        model: {
                            userId: data.user.id,
                            careOf: form.careOf,
                            city: form.city,
                            postOfficeBox: form.postOfficeBox,
                            postalCode: form.postalCode,
                            street: form.street,
                        },
                    },
                });
            });
        }}>
            Spara
          </Button>
        </Flexbox>
      </ModalToSheetFooter>
    </ModalToSheet>);
};
export default UserHomeAddressUpdateModal;
