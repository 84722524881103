import React from 'react';
import { useParams } from 'react-router';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { Flexbox } from '@sonika-se/ui-kit/components';
import UserBankAccountPanel from '~/panels/UserBankAccountPanel';
export const UserEconomyPage = () => {
    const { userId } = useParams();
    const { user } = useLazyLoadQuery(graphql `
      query UserEconomyPageQuery($userId: ID!) {
        user(id: $userId) {
          ...UserBankAccountPanel_user
        }
      }
    `, {
        userId,
    });
    return (<Flexbox direction="column" gap="large">
      <Flexbox direction="row" gap="large" justify="equal">
        <UserBankAccountPanel userRef={user}/>
      </Flexbox>
    </Flexbox>);
};
export default UserEconomyPage;
