import { useState } from 'react';
import { graphql, useFragment, useMutation } from 'react-relay/hooks';
import useAttachments from './useAttachments';
export const useFaqPostAttachments = (faqPostRef, options) => {
    const data = useFragment(graphql `
      fragment useFaqPostAttachments_faqPost on FaqPost {
        id
        attachments(first: null) @connection(key: "useFaqPostAttachments_attachments") {
          edges {
            node {
              contentType
              directory
              id
              name
              size
              storageId
              url
            }
          }
        }
      }
    `, faqPostRef);
    const [id, setId] = useState(data === null || data === void 0 ? void 0 : data.id);
    const [createFaqPostAttachment] = useMutation(graphql `
      mutation useFaqPostAttachments_createFileMutation($model: CreateFaqPostAttachmentRequestInput!) {
        createFaqPostAttachment(model: $model) {
          faqPostEdge {
            node {
              ...useFaqPostAttachments_faqPost
            }
          }
        }
      }
    `);
    const [deleteFaqPostAttachment] = useMutation(graphql `
      mutation useFaqPostAttachments_deleteFileMutation($model: DeleteFaqPostAttachmentRequestInput!) {
        deleteFaqPostAttachment(model: $model) {
          faqPostAttachmentEdge {
            node {
              id
            }
          }
        }
      }
    `);
    const attachments = useAttachments(Object.assign(Object.assign({}, options), { createAttachment: (uploadedAttachment) => {
            const promise = new Promise((resolve, reject) => {
                createFaqPostAttachment({
                    onCompleted: (response) => {
                        resolve(response);
                    },
                    onError: (error) => {
                        reject(error);
                    },
                    variables: {
                        model: Object.assign(Object.assign({}, uploadedAttachment), { faqPostId: id }),
                    },
                });
            });
            return promise;
        }, deleteAttachment: ({ id }) => {
            const promise = new Promise((resolve, reject) => {
                deleteFaqPostAttachment({
                    configs: [
                        {
                            connectionKeys: [
                                {
                                    key: 'useFaqPostAttachments_attachments',
                                },
                            ],
                            parentID: data.id,
                            type: 'RANGE_DELETE',
                            deletedIDFieldName: ['faqPostAttachmentEdge', 'node'],
                            pathToConnection: ['faqPost', 'attachments'],
                        },
                    ],
                    onCompleted: (response) => {
                        resolve(response);
                    },
                    onError: (error) => {
                        reject(error);
                    },
                    variables: {
                        model: {
                            id,
                        },
                    },
                });
            });
            return promise;
        }, uploadEndpoint: 'utilities/v1/FaqPostAttachment/upload' }));
    return Object.assign(Object.assign({}, attachments), { data, setId });
};
export default useFaqPostAttachments;
