/**
 * @generated SignedSource<<b023739035cbb41aa7cd849ed4f3fc49>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EditEventColor",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
        }
    ],
    "type": "Event",
    "abstractKey": null
};
node.hash = "88ccda2f5ccbfabdd9f4810c1cc18a95";
export default node;
