import React from 'react';
import { createUseStyles } from 'react-jss';
export const useTimeCardContainerStyles = createUseStyles((theme) => {
    const colors = {
        primary: theme.colors.primary,
        warning: theme.colors.system.wait,
        danger: theme.colors.system.stop,
        success: theme.colors.system.go,
    };
    const { pxToRem } = theme.helpers;
    return {
        rootContainer: {
            borderRadius: pxToRem(4),
            background: 'white',
        },
        root: ({ appearance, active, color }) => ({
            background: active ? color || colors[appearance] : color ? `${color}26` : `${colors[appearance]}26`,
            padding: `${pxToRem(8)}`,
            borderRadius: pxToRem(4),
            cursor: 'pointer',
            color: active ? '#FFF' : 'inherit',
            '& $chip': {
                marginBottom: 0,
                background: active ? '#FFF' : color || colors[appearance],
                color: active ? color || colors[appearance] : '#FFF',
            },
            '&:hover': {
                background: `${color || colors[appearance]}40`,
                color: 'inherit',
                '& $chip': {
                    background: color || colors[appearance],
                    color: '#FFF',
                },
            },
        }),
        childList: {
            display: 'grid',
            alignItems: 'end',
            gridTemplateColumns: '1fr 1fr 1fr',
            '& *:last-child': {
                align: 'right',
                marginLeft: 'auto',
            },
        },
        chip: {},
    };
});
export const TimeCardContainer = (props) => {
    const { appearance = 'primary', children, className, style, onClick, active = false, color } = props;
    const classes = useTimeCardContainerStyles({ appearance, active, color });
    return (<div className={classes.rootContainer}>
      <div className={[classes.root, className].join(' ')} style={style} onClick={onClick}>
        {children}
      </div>
    </div>);
};
export default TimeCardContainer;
