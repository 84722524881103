import React, { useMemo } from 'react';
import { DataTable, Panel, Placeholder } from '@sonika-se/ui-kit/components';
import { formatDate, toDate } from '@sonika-se/ui-kit/utils/time';
import { graphql, useFragment } from 'react-relay/hooks';
export const UserPushDevicesPanel = (props) => {
    const { userRef } = props;
    const user = useFragment(graphql `
      fragment UserPushDevicesPanel_user on User {
        pushDevices {
          nodes {
            createdUtc
            deviceId
            deviceName
            inactivatedUtc
            updatedUtc
          }
        }
      }
    `, userRef);
    const pushDevices = useMemo(() => user.pushDevices.nodes.map((pushDevice) => {
        const createdAt = toDate(pushDevice.createdUtc);
        const updatedAt = toDate(pushDevice.updatedUtc);
        const inactivatedAt = toDate(pushDevice.inactivatedUtc);
        return {
            deviceName: pushDevice.deviceName,
            createdAt: createdAt ? formatDate(createdAt, 'short') : undefined,
            updatedAt: updatedAt ? formatDate(updatedAt, 'short') : undefined,
            inactivatedAt: inactivatedAt ? formatDate(inactivatedAt, 'short') : undefined,
        };
    }), [user]);
    return (<Panel title="Mobila enheter">
      {pushDevices.length > 0 ? (<DataTable columns={{
                deviceName: {
                    text: 'Namn',
                },
                createdAt: {
                    text: 'Skapad',
                },
                updatedAt: {
                    text: 'Uppdaterad',
                },
                inactivatedAt: {
                    text: 'Inaktiverad',
                },
            }} rows={pushDevices}/>) : (<Placeholder text="Inga mobila enheter."/>)}
    </Panel>);
};
export default UserPushDevicesPanel;
