import React, { Suspense } from 'react';
import { Outlet, useParams } from 'react-router';
import { BasicPage, Flexbox, H1, Menu, MenuItem, Skeleton } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import enums from '~/enums.json';
const { PermissionEnum } = enums;
import Avatar from '~/shared/Avatar';
export const UserLayout = () => {
    const { userId } = useParams();
    const { user } = useLazyLoadQuery(graphql `
      query UserLayoutQuery($userId: ID!) {
        user(id: $userId) {
          ...Avatar_iHaveAvatar
          firstname
          invitation {
            created
            accepted
          }
          lastname
        }
      }
    `, {
        userId,
    });
    const hasPermissions = usePermissions();
    return (<BasicPage width="large">
      <Flexbox direction="row" gap="medium" style={{ paddingTop: '3rem' }}>
        <Avatar iHaveAvatarRef={user} size="large"/>
        <H1>{`${user.firstname} ${user.lastname}`}</H1>
      </Flexbox>
      <Flexbox direction="column" gap="xlarge">
        <Menu>
          <MenuItem index path="" text="Allmänt"/>
          <MenuItem path="groups" text="Grupper"/>
          {hasPermissions([PermissionEnum.EDIT_USER_PERMISSIONS.key]) && (<MenuItem path="permissions" text="Rättigheter"/>)}
          <MenuItem path="attachments" text="Filer"/>
          <MenuItem path="economy" text="Ekonomi"/>
          <MenuItem path="system" text="System"/>
        </Menu>
        <Suspense fallback={<Skeleton height="40vh" type="table"/>}>
          <Outlet />
        </Suspense>
      </Flexbox>
    </BasicPage>);
};
export default UserLayout;
