import React, { useCallback, useRef } from 'react';
import { graphql, useMutation } from 'react-relay/hooks';
import { FormModal } from '@sonika-se/ui-kit/components';
import UserPasswordForm from '~/forms/UserPasswordForm';
export const UserPasswordUpdateModal = (props) => {
    const { updateCurrentUser, onClose, userId } = props;
    const [updateUserPassword, updateUserPasswordLoading] = useMutation(graphql `
        mutation UserPasswordUpdateModal_updateUserPasswordMutation($model: UpdateUserPasswordInput!) {
          updateUserPassword(model: $model) {
            success
          }
        }
      `);
    const [updateCurrentUserPassword, updateCurrentUserPasswordLoading] = useMutation(graphql `
        mutation UserPasswordUpdateModal_updateCurrentUserPasswordMutation(
          $model: UpdateCurrentUserPasswordRequestInput!
        ) {
          updateCurrentUserPassword(model: $model) {
            success
          }
        }
      `);
    const formRef = useRef(null);
    const handleSubmit = useCallback((form) => {
        if (updateCurrentUser) {
            updateCurrentUserPassword({
                onCompleted: () => {
                    onClose();
                },
                variables: {
                    model: {
                        newPassword: form.newPassword,
                        oldPassword: form.oldPassword,
                    },
                },
            });
        }
        else {
            updateUserPassword({
                onCompleted: () => {
                    onClose();
                },
                variables: {
                    model: {
                        newPassword: form.newPassword,
                        userId,
                    },
                },
            });
        }
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Spara" disabled={updateCurrentUserPasswordLoading} size="small" title="Ändra lösenord">
      <UserPasswordForm defaultValues={{
            newPassword: '',
        }} disabled={updateCurrentUserPasswordLoading || updateUserPasswordLoading} onSubmit={handleSubmit} promptOldPassword={updateCurrentUser} ref={formRef}/>
    </FormModal>);
};
export default UserPasswordUpdateModal;
