/**
 * @generated SignedSource<<96c92c4c01c74cbbd8390fb4e50bfb33>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "InitiateBankIdAuthPayload",
            "kind": "LinkedField",
            "name": "initiateBankIdAuth",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "autostartToken",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "bankIdLaunchUrl",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "qrData",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "BankIdForm_InitiateBankIdAuthMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "BankIdForm_InitiateBankIdAuthMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "f23b8bdf649d3223eb5599f290ca3841",
            "id": null,
            "metadata": {},
            "name": "BankIdForm_InitiateBankIdAuthMutation",
            "operationKind": "mutation",
            "text": "mutation BankIdForm_InitiateBankIdAuthMutation(\n  $model: InitiateBankIdAuthInput!\n) {\n  initiateBankIdAuth(model: $model) {\n    autostartToken\n    bankIdLaunchUrl\n    qrData\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "d5015d58a9ee0fbd934e33dd620fd6f1";
export default node;
