import React, { forwardRef, useImperativeHandle } from 'react';
import { Field, Form, Input } from '@sonika-se/ui-kit/components';
import { object, string } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ref } from 'yup';
export const updateUserPasswordFormValidationSchema = (promptOldPassword) => object({
    newPassword: string().required().min(8),
    oldPassword: promptOldPassword ? string().required().min(8) : string().notRequired(),
    repeatPassword: string()
        .required()
        .min(8)
        .oneOf([ref('newPassword')]),
});
export const UserPasswordForm = forwardRef((props, forwardedRef) => {
    const { defaultValues, disabled, onSubmit, promptOldPassword } = props;
    const { control, formState: { errors, isValid }, handleSubmit, } = useForm({
        defaultValues,
        resolver: yupResolver(updateUserPasswordFormValidationSchema(promptOldPassword)),
    });
    useImperativeHandle(forwardedRef, () => ({
        isValid,
        submit: (onValid, onInvalid) => handleSubmit(onValid, onInvalid)(),
    }), [handleSubmit, isValid]);
    return (<Form onSubmit={handleSubmit((data) => {
            onSubmit(data);
        })}>
        {promptOldPassword && (<Field label="Gammalt lösenord">
            <Controller control={control} name="oldPassword" render={({ field }) => {
                var _a;
                return (<Input defaultValue={field.value} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.oldPassword) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} type="password"/>);
            }}/>
          </Field>)}
        <Field label="Nytt lösenord">
          <Controller control={control} name="newPassword" render={({ field }) => {
            var _a;
            return (<Input defaultValue={field.value} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.newPassword) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} type="password"/>);
        }}/>
        </Field>
        <Field label="Upprepa lösenord">
          <Controller control={control} name="repeatPassword" render={({ field }) => {
            var _a;
            return (<Input defaultValue={field.value} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.repeatPassword) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} type="password"/>);
        }}/>
        </Field>
      </Form>);
});
export default UserPasswordForm;
