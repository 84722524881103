import React, { Suspense, useState } from 'react';
import { faImagePolaroid, faKey } from '@fortawesome/pro-regular-svg-icons';
import { ModalToSheetSkeleton, Sidebar, SidebarMenu, SidebarMenuItem, SidebarSection, } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import IHaveAvatarUpdateModal, { changeAvatarModalQuery } from '~/modals/IHaveAvatarUpdateModal';
import UserPasswordUpdateModal from '~/modals/UserPasswordUpdateModal';
export const AccountSidebar = (props) => {
    const { userRef } = props;
    const user = useFragment(graphql `
      fragment AccountSidebar_user on User {
        id
      }
    `, userRef);
    const [changeAvatarModalQueryRef, loadIHaveAvatarUpdateModalQuery, disposeIHaveAvatarUpdateModalQuery] = useQueryLoader(changeAvatarModalQuery);
    const [userPasswordUpdateModalVisible, setUserPasswordUpdateModalVisible] = useState(false);
    return (<>
      <Sidebar>
        <SidebarSection title="Verktyg">
          <SidebarMenu>
            <SidebarMenuItem icon={faKey} onClick={() => {
            setUserPasswordUpdateModalVisible(true);
        }}>
              Ändra lösenord
            </SidebarMenuItem>
            <SidebarMenuItem icon={faImagePolaroid} onClick={() => {
            loadIHaveAvatarUpdateModalQuery({ iHaveAvatarId: user.id });
        }}>
              Byt profilbild
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarSection>
      </Sidebar>
      <Suspense fallback={<ModalToSheetSkeleton />}>
        {changeAvatarModalQueryRef && (<IHaveAvatarUpdateModal onClose={() => {
                disposeIHaveAvatarUpdateModalQuery();
            }} preloadedQuery={changeAvatarModalQueryRef}/>)}
        {userPasswordUpdateModalVisible && (<UserPasswordUpdateModal onClose={() => {
                setUserPasswordUpdateModalVisible(false);
            }} updateCurrentUser userId={user.id}/>)}
      </Suspense>
    </>);
};
export default AccountSidebar;
