import React, { Suspense } from 'react';
import { Flexbox, KeyValue, ModalSkeleton, Panel, PanelButton } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import RoleGeneralInfoUpdateModal, { roleRoleUpdateModalQuery } from '~/modals/RoleGeneralInfoUpdateModal';
export const RoleGeneralInfoPanel = (props) => {
    const { roleRef } = props;
    const role = useFragment(graphql `
      fragment RoleGeneralInfoPanel_role on Role {
        id
        description
        name
      }
    `, roleRef);
    const [roleGeneralInfoUpdateModalRef, loadRoleGeneralInfoUpdateModal, disposeRoleGeneralInfoUpdateModal] = useQueryLoader(roleRoleUpdateModalQuery);
    return (<>
      <Panel title="Info" actions={<PanelButton onClick={() => loadRoleGeneralInfoUpdateModal({ roleId: role.id })}>Ändra</PanelButton>}>
        <Flexbox direction="column" gap="xlarge">
          <KeyValue label="Namn" value={role.name}/>
          <KeyValue label="Beskrivning" value={role.description}/>
        </Flexbox>
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {roleGeneralInfoUpdateModalRef && (<RoleGeneralInfoUpdateModal onClose={() => {
                disposeRoleGeneralInfoUpdateModal();
            }} preloadedQuery={roleGeneralInfoUpdateModalRef}/>)}
      </Suspense>
    </>);
};
export default RoleGeneralInfoPanel;
