/**
 * @generated SignedSource<<db8ec65fc881aaf22b2661323883fd8f>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 5
        },
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v5 = {
        "kind": "InlineFragment",
        "selections": [
            (v3 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
            },
            (v2 /*: any*/),
            (v4 /*: any*/)
        ],
        "type": "ISchedulableEntity",
        "abstractKey": "__isISchedulableEntity"
    }, v6 = {
        "alias": null,
        "args": null,
        "concreteType": "Event",
        "kind": "LinkedField",
        "name": "reasonEvent",
        "plural": false,
        "selections": [
            (v2 /*: any*/),
            (v3 /*: any*/),
            (v4 /*: any*/)
        ],
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "start",
        "storageKey": null
    }, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "end",
        "storageKey": null
    }, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AbsencePanelQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "PlannedAbsenceConnection",
                    "kind": "LinkedField",
                    "name": "plannedAbsences",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PlannedAbsenceEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PlannedAbsence",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "assignedEntity",
                                            "plural": false,
                                            "selections": [
                                                (v5 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v8 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v9 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AbsencePanelQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "PlannedAbsenceConnection",
                    "kind": "LinkedField",
                    "name": "plannedAbsences",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PlannedAbsenceEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PlannedAbsence",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "assignedEntity",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "__typename",
                                                    "storageKey": null
                                                },
                                                (v5 /*: any*/),
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v2 /*: any*/)
                                                    ],
                                                    "type": "Node",
                                                    "abstractKey": "__isNode"
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v8 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v9 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "a0fff21633a1adfa63145c97dc09c886",
            "id": null,
            "metadata": {},
            "name": "AbsencePanelQuery",
            "operationKind": "query",
            "text": "query AbsencePanelQuery(\n  $model: GetRunningScheduleRequestInput!\n) {\n  plannedAbsences(model: $model, first: 5) {\n    edges {\n      node {\n        id\n        assignedEntity {\n          __typename\n          ... on ISchedulableEntity {\n            __isISchedulableEntity: __typename\n            color\n            displayName\n            id\n            name\n          }\n          ... on Node {\n            __isNode: __typename\n            id\n          }\n        }\n        reasonEvent {\n          id\n          color\n          name\n        }\n        start\n        end\n      }\n    }\n    totalCount\n  }\n}\n"
        }
    };
})();
node.hash = "b5553330ea33603c143eec395b601f1a";
export default node;
