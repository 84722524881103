import React, { Suspense, useState } from 'react';
import { Button, Card, CardContent, Flexbox, ModalSkeleton, Panel, Skeleton } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import UserTableQueryForm from '~/forms/UserTableQueryForm';
import UserTable from '~/tables/UserTable';
import { UserExportModal, userExportModalQuery } from '~/modals/UserExportModal';
export const UserTablePanel = (props) => {
    const { actions, extended, filter, queryRef, tableProps, tableRef, title } = props;
    const data = useFragment(graphql `
      fragment UserTablePanel_query on Query
      @argumentDefinitions(extended: { type: "Boolean!" }, filter: { type: "UsersFilterInput" }) {
        ...UserTable_query @arguments(extended: $extended, filter: $filter)
      }
    `, queryRef);
    const [userTableProps, setUserTableProps] = useState(Object.assign(Object.assign({}, tableProps), { extended,
        filter }));
    const [userExportModalQueryRef, loadUserExportModalQuery, disposeUserExportModalQuery] = useQueryLoader(userExportModalQuery);
    return (<>
      <Panel actions={actions} title={title}>
        <Flexbox direction="column" gap="small">
          <Card background="gray" shadow="none">
            <CardContent>
              <Flexbox direction="row" gap="small" justify="space">
                <UserTableQueryForm onSubmit={(query) => {
            const { search, status } = query;
            setUserTableProps({
                filter: search
                    ? Object.assign(Object.assign({}, filter), { search }) : filter,
                where: status
                    ? {
                        status: {
                            eq: status,
                        },
                    }
                    : undefined,
                extended,
            });
        }}/>
                <Button appearance="outlined" onClick={() => {
            loadUserExportModalQuery({
                filter: userTableProps.filter,
                where: userTableProps.where,
            }, {
                fetchPolicy: 'network-only',
            });
        }}>
                  Exportera
                </Button>
              </Flexbox>
            </CardContent>
          </Card>
          <Suspense fallback={<Skeleton type="table"/>}>
            <UserTable queryRef={data} ref={tableRef} {...userTableProps}/>
          </Suspense>
        </Flexbox>
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {userExportModalQueryRef && (<UserExportModal onClose={() => {
                disposeUserExportModalQuery();
            }} preloadedQuery={userExportModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default UserTablePanel;
