/**
 * @generated SignedSource<<22505baa386375220a73d2586e2c2b7a>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 30
        }
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    }, v7 = {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "EventSettingsPageQuery",
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EventTable"
                },
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "EventAssignmentTypeTable"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "EventSettingsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v0 /*: any*/),
                    "concreteType": "EventConnection",
                    "kind": "LinkedField",
                    "name": "events",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EventEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Event",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "additionUnit",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "AssignmentType",
                                            "kind": "LinkedField",
                                            "name": "assignmentTypes",
                                            "plural": true,
                                            "selections": [
                                                (v1 /*: any*/),
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "disabled",
                                            "storageKey": null
                                        },
                                        (v4 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "isAbsence",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "isAddition",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "isAssigneeReplacementReason",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "isWork",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "isUnsocial",
                                            "storageKey": null
                                        },
                                        (v5 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v6 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v7 /*: any*/)
                    ],
                    "storageKey": "events(first:30)"
                },
                {
                    "alias": null,
                    "args": (v0 /*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "EventTableRefetch_events",
                    "kind": "LinkedHandle",
                    "name": "events"
                },
                {
                    "alias": null,
                    "args": (v0 /*: any*/),
                    "concreteType": "AssignmentTypeConnection",
                    "kind": "LinkedField",
                    "name": "assignmentTypes",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssignmentTypeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssignmentType",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "EventConnection",
                                            "kind": "LinkedField",
                                            "name": "events",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "EventEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Event",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                (v1 /*: any*/),
                                                                (v4 /*: any*/),
                                                                (v3 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        (v5 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v6 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v7 /*: any*/)
                    ],
                    "storageKey": "assignmentTypes(first:30)"
                },
                {
                    "alias": null,
                    "args": (v0 /*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "EventAssignmentTypeTableRefetch_assignmentTypes",
                    "kind": "LinkedHandle",
                    "name": "assignmentTypes"
                }
            ]
        },
        "params": {
            "cacheID": "ba13680507b61e374843f960fe00b126",
            "id": null,
            "metadata": {},
            "name": "EventSettingsPageQuery",
            "operationKind": "query",
            "text": "query EventSettingsPageQuery {\n  ...EventTable\n  ...EventAssignmentTypeTable\n}\n\nfragment EventAssignmentTypeTable on Query {\n  assignmentTypes(first: 30) {\n    edges {\n      node {\n        id\n        title\n        events {\n          edges {\n            node {\n              id\n              name\n              color\n            }\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment EventTable on Query {\n  events(first: 30) {\n    edges {\n      node {\n        id\n        additionUnit\n        assignmentTypes {\n          id\n          title\n        }\n        color\n        disabled\n        name\n        isAbsence\n        isAddition\n        isAssigneeReplacementReason\n        isWork\n        isUnsocial\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "da3ef5bd0a1d9fe45e15ab0bc960d91f";
export default node;
