import React, { forwardRef, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { Form, Input, Field, Textarea } from '@sonika-se/ui-kit/components';
export const roleGeneralInfoFormValidationSchema = object().shape({
    description: string().nullable().notRequired(),
    name: string().required(),
});
export const RoleGeneralInfoForm = forwardRef((props, forwardedRef) => {
    const { defaultValues, disabled, onSubmit } = props;
    const { control, formState: { errors, isValid }, handleSubmit, } = useForm({
        defaultValues,
        resolver: yupResolver(roleGeneralInfoFormValidationSchema),
    });
    useImperativeHandle(forwardedRef, () => ({
        isValid,
        submit: (onValid, onInvalid) => handleSubmit(onValid, onInvalid)(),
    }), [handleSubmit, isValid]);
    return (<Form onSubmit={handleSubmit((data) => {
            onSubmit(data);
        })}>
        <Field label="Namn">
          <Controller control={control} name="name" render={({ field }) => {
            var _a;
            return (<Input defaultValue={field.value} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.name) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange}/>);
        }}/>
        </Field>
        <Field label="Beskrivning">
          <Controller control={control} name="description" render={({ field }) => {
            var _a;
            return (<Textarea defaultValue={field.value} error={(_a = errors === null || errors === void 0 ? void 0 : errors.description) === null || _a === void 0 ? void 0 : _a.message} disabled={disabled} onBlur={field.onBlur} onChange={field.onChange}/>);
        }}/>
        </Field>
      </Form>);
});
export default RoleGeneralInfoForm;
