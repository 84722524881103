var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { H3, Flexbox, Notification, Text } from '@sonika-se/ui-kit/components';
import { resetPassword } from '~/utils/auth';
import buildFrontendUrl from '~/utils/buildFrontendUrl';
import ResetPasswordForm from '~/components/Forms/ResetPasswordForm/ResetPasswordForm';
import AccountPage from '../components/AccountPage';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import BackLink from '../components/BackLink';
const ResetPassword = () => {
    const frontendUrl = buildFrontendUrl('/account/update-password');
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const onSubmit = (data, e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        setDisabled(true);
        setError('');
        const success = yield resetPassword(data);
        if (!success) {
            setError('Något gick fel! Vi kunde tyvärr inte begära en återställning av ditt lösenord just nu.');
            setDisabled(false);
            return;
        }
        setSuccess(true);
    });
    return (<AccountPage>
      <Flexbox direction="column" gap="large">
        <BackLink label="Tillbaka till inloggning" link="/account/login"/>
        <Flexbox direction="column" gap="medium">
          <H3>
            <FormattedMessage id="ResetPassword.header" defaultMessage="Återställ lösenord"/>
          </H3>
          <Text>
            <FormattedMessage id="ResetPassword.description" defaultMessage="När du fyllt i din e-postadress och återställt lösenordet får du ett mail från vilket du får möjlighet att skapa ett nytt lösenord."/>
          </Text>
        </Flexbox>
        <ResetPasswordForm error={error} onSubmit={onSubmit} frontendUrl={frontendUrl} disabled={disabled}/>
        {success && (<Notification shadow={false} title="Mejl har skickats till den angivna e-postadressen med instruktioner för hur du återställer lösenordet."/>)}
      </Flexbox>
    </AccountPage>);
};
export default ResetPassword;
