import React, { useCallback, useRef } from 'react';
import { useMutation, graphql } from 'react-relay/hooks';
import { FormModal } from '@sonika-se/ui-kit/components';
import AssignmentTypeCreateForm from '../forms/AssignmentTypeCreateForm';
export const AssignmentTypeCreateModal = (props) => {
    const { connectionsToPrependEdge, onClose } = props;
    const [createAssignmentType, createAssignmentTypeLoading] = useMutation(graphql `
      mutation AssignmentTypeCreateModalCreateMutation(
        $connectionsToPrependEdge: [ID!]!
        $model: CreateAssignmentTypeRequestInput!
      ) {
        createAssignmentType(model: $model) {
          assignmentTypeEdge @prependEdge(connections: $connectionsToPrependEdge) {
            node {
              id
              title
              disabledDate
            }
          }
        }
      }
    `);
    const formRef = useRef(null);
    const handleSubmit = useCallback((form) => {
        createAssignmentType({
            variables: {
                connectionsToPrependEdge,
                model: {
                    title: form.title,
                },
            },
            onCompleted: (response) => {
                onClose(response.createAssignmentType.assignmentTypeEdge.node);
            },
        });
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Spara" disabled={createAssignmentTypeLoading} title="Lägg till roll">
      <AssignmentTypeCreateForm disabled={createAssignmentTypeLoading} onSubmit={handleSubmit} ref={formRef}/>
    </FormModal>);
};
export default AssignmentTypeCreateModal;
