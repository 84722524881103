/**
 * @generated SignedSource<<1ade8868d0dc135022e53316465099bd>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
            (v0 /*: any*/)
        ],
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "SelectUserQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CurrentUser",
                    "kind": "LinkedField",
                    "name": "currentUser",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "availableUsers",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Company",
                                    "kind": "LinkedField",
                                    "name": "company",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v0 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "SelectUserQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CurrentUser",
                    "kind": "LinkedField",
                    "name": "currentUser",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "availableUsers",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Company",
                                    "kind": "LinkedField",
                                    "name": "company",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v0 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v0 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v0 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "2e158fbdcbf859a7f3f02d67c265b1d0",
            "id": null,
            "metadata": {},
            "name": "SelectUserQuery",
            "operationKind": "query",
            "text": "query SelectUserQuery {\n  currentUser {\n    me {\n      id\n    }\n    availableUsers {\n      company {\n        name\n        id\n      }\n      id\n      firstname\n      lastname\n    }\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "f6102e4385feb6ecc593f23747563d83";
export default node;
