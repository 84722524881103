/**
 * @generated SignedSource<<79a38f4f542ff414dacf6d007cc06165>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    }, v2 = [
        (v1 /*: any*/)
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "ScheduleShiftCard_scheduleShift",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
            },
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "assignedEntity",
                "plural": false,
                "selections": [
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Asset",
                                "kind": "LinkedField",
                                "name": "asset",
                                "plural": false,
                                "selections": (v2 /*: any*/),
                                "storageKey": null
                            }
                        ],
                        "type": "SchedulableAsset",
                        "abstractKey": null
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": (v2 /*: any*/),
                                "storageKey": null
                            }
                        ],
                        "type": "SchedulableUser",
                        "abstractKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "AssignmentType",
                "kind": "LinkedField",
                "name": "assignmentType",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "Schedule",
                "kind": "LinkedField",
                "name": "schedule",
                "plural": false,
                "selections": [
                    (v3 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "end",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "ScheduleShiftConnection",
                "kind": "LinkedField",
                "name": "simultaneousScheduleShift",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "ScheduleShiftEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "ScheduleShift",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": null,
                                        "kind": "LinkedField",
                                        "name": "assignedEntity",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "kind": "InlineFragment",
                                                "selections": [
                                                    (v0 /*: any*/),
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "Asset",
                                                        "kind": "LinkedField",
                                                        "name": "asset",
                                                        "plural": false,
                                                        "selections": [
                                                            (v3 /*: any*/),
                                                            (v1 /*: any*/)
                                                        ],
                                                        "storageKey": null
                                                    }
                                                ],
                                                "type": "SchedulableAsset",
                                                "abstractKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "start",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isVacant",
                "storageKey": null
            }
        ],
        "type": "ScheduleShift",
        "abstractKey": null
    };
})();
node.hash = "7162fe73cd16c64c3772bcf95631a9e9";
export default node;
