/**
 * @generated SignedSource<<ba8da5406a55c7be831ddadc65afd448>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "filter"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ColleagueTablePanel_query",
    "selections": [
        {
            "args": [
                {
                    "kind": "Variable",
                    "name": "filter",
                    "variableName": "filter"
                }
            ],
            "kind": "FragmentSpread",
            "name": "ColleagueTable_query"
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "0c18d78435e4d25e4d9acb23c1205752";
export default node;
