import React, { Suspense, useMemo } from 'react';
import { graphql, useLazyLoadQuery, useMutation, useQueryLoader } from 'react-relay/hooks';
import { useParams } from 'react-router';
import { Button, ModalSkeleton, PanelButton } from '@sonika-se/ui-kit/components';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import { ConnectionHandler } from 'relay-runtime';
import UserTablePanel from './panels/UserTablePanel';
import UserSelectModal, { userSelectModalQuery } from './shared/modals/UserSelectModal';
export const ServiceUsersPage = () => {
    const params = useParams();
    const service = params.service;
    const userTableFilter = useMemo(() => ({
        servicesIn: [service],
    }), []);
    const hasPermissions = usePermissions();
    const userTableExtended = hasPermissions(['EDIT_USERS']);
    const data = useLazyLoadQuery(graphql `
      query ServiceUsersPageQuery($userTableExtended: Boolean!, $userTableFilter: UsersFilterInput!) {
        ...UserTablePanel_query @arguments(extended: $userTableExtended, filter: $userTableFilter)
      }
    `, {
        userTableExtended,
        userTableFilter,
    }, {
        fetchPolicy: 'network-only',
    });
    const [userSelectModalQueryRef, loadUserSelectModalQuery, disposeUserSelectModalQuery] = useQueryLoader(userSelectModalQuery);
    const [addUsersToService, addUsersToServiceLoading] = useMutation(graphql `
      mutation ServiceUsersPage_addUsersToServiceMutation(
        $connectionsToPrependEdgeTo: [ID!]!
        $model: AddUsersToServiceInput!
      ) {
        addUsersToService(model: $model) {
          userEdges @prependEdge(connections: $connectionsToPrependEdgeTo) {
            node {
              firstname
              id
              lastname
            }
          }
        }
      }
    `);
    const [removeUsersFromService, removeUsersFromServiceLoading] = useMutation(graphql `
        mutation ServiceUsersPage_removeUsersToServiceMutation(
          $connectionsToDeleteEdgeFrom: [ID!]!
          $model: RemoveUsersFromServiceInput!
        ) {
          removeUsersFromService(model: $model) {
            userIds @deleteEdge(connections: $connectionsToDeleteEdgeFrom)
          }
        }
      `);
    const userTableConnectionId = useMemo(() => ConnectionHandler.getConnectionID('root', 'UserTable_query_users'), []);
    return (<>
      <UserTablePanel actions={hasPermissions(['EDIT_USERS']) && (<PanelButton disabled={addUsersToServiceLoading} onClick={() => {
                loadUserSelectModalQuery({
                    filter: {
                        servicesNotIn: [service],
                    },
                    where: {
                        status: {
                            eq: 'ACTIVE',
                        },
                    },
                }, {
                    fetchPolicy: 'network-only',
                });
            }}>
              Lägg till
            </PanelButton>)} extended={userTableExtended} filter={userTableFilter} queryRef={data} tableProps={{
            actions: (user) => (<Button appearance="outlined" color="danger" disabled={removeUsersFromServiceLoading} onClick={() => {
                    removeUsersFromService({
                        variables: {
                            connectionsToDeleteEdgeFrom: [userTableConnectionId],
                            model: {
                                service,
                                userIds: [user.id],
                            },
                        },
                    });
                }} size="xsmall">
              Ta bort
            </Button>),
        }} title="Användare"/>
      <Suspense fallback={<ModalSkeleton />}>
        {userSelectModalQueryRef && (<UserSelectModal defaultFilter={{
                servicesNotIn: [service],
            }} defaultWhere={{
                status: {
                    eq: 'ACTIVE',
                },
            }} onClose={() => {
                disposeUserSelectModalQuery();
            }} onSelect={(userIds) => {
                addUsersToService({
                    variables: {
                        connectionsToPrependEdgeTo: [userTableConnectionId],
                        model: {
                            service,
                            userIds,
                        },
                    },
                });
                disposeUserSelectModalQuery();
            }} preloadedQuery={userSelectModalQueryRef}/>)}
      </Suspense>
    </>);
};
export default ServiceUsersPage;
