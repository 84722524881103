import { Flexbox, H5, Icon, Text } from '@sonika-se/ui-kit/components';
import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles } from 'react-jss';
const useStyles = createUseStyles((theme) => {
    const { colors, helpers } = theme;
    return {
        container: {
            border: `${helpers.pxToRem(1)} solid ${colors.divider}`,
            borderRadius: helpers.pxToRem(4),
            cursor: 'pointer',
            padding: helpers.pxToRem(12),
            '&:hover': {
                borderColor: colors.primary,
            },
            '&:hover $icon': {
                borderColor: helpers.pxToRem(1),
            },
        },
        icon: {
            backgroundColor: '#DEEFFF',
            borderRadius: '0.25rem',
            border: `${helpers.pxToRem(1)} solid transparent`,
            color: '#0C2AF3',
            padding: '0.75rem',
        },
        selected: {
            backgroundColor: colors.primaryLight,
            borderColor: colors.primary,
            '& $icon': {
                backgroundColor: colors.primary,
                color: '#FFFFFF',
            },
        },
    };
});
export const ServiceToggleButton = (props) => {
    const { defaultValue, icon, onChange, text, title, disabled } = props;
    const classes = useStyles();
    const [value, setValue] = useState(defaultValue);
    const firstRender = useRef(true);
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        onChange(value);
    }, [value]);
    return (<div className={[classes.container, value && classes.selected].join(' ')} onClick={() => {
            if (disabled) {
                return;
            }
            setValue((value) => !value);
        }}>
      <Flexbox align="center" gap="small">
        <Icon className={classes.icon} icon={icon}/>
        <Flexbox direction="column" gap="none">
          <H5>{title}</H5>
          {text && <Text size="small">{text}</Text>}
        </Flexbox>
      </Flexbox>
    </div>);
};
export default ServiceToggleButton;
