/**
 * @generated SignedSource<<32f87defd220ed3bded49abe78f1e908>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userTableExtended"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "userTableFilter"
        }
    ], v1 = {
        "kind": "Variable",
        "name": "filter",
        "variableName": "userTableFilter"
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = [
        (v1 /*: any*/),
        {
            "kind": "Literal",
            "name": "first",
            "value": 50
        }
    ], v4 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
        }
    ], v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AssignmentTypeUsersPageQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "extended",
                            "variableName": "userTableExtended"
                        },
                        (v1 /*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "UserTablePanel_query"
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AssignmentTypeUsersPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CurrentUser",
                    "kind": "LinkedField",
                    "name": "currentUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "me",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "isCompanyAdmin",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "UserConnection",
                    "kind": "LinkedField",
                    "name": "users",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "AssignmentTypeConnection",
                                            "kind": "LinkedField",
                                            "name": "assignmentTypes",
                                            "plural": false,
                                            "selections": (v4 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "firstname",
                                            "storageKey": null
                                        },
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "UserInvitation",
                                            "kind": "LinkedField",
                                            "name": "invitation",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "created",
                                                    "storageKey": null
                                                },
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "lastname",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "SchedulableUser",
                                            "kind": "LinkedField",
                                            "name": "schedulableEntity",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "schedulable",
                                                    "storageKey": null
                                                },
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "TimeReportingUser",
                                            "kind": "LinkedField",
                                            "name": "timeReportingUser",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "active",
                                                    "storageKey": null
                                                },
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "status",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Avatar",
                                                    "kind": "LinkedField",
                                                    "name": "avatar",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": [
                                                                {
                                                                    "kind": "Literal",
                                                                    "name": "queryTransform",
                                                                    "value": "width=300&height=300&mode=crop&scale=both"
                                                                }
                                                            ],
                                                            "kind": "ScalarField",
                                                            "name": "url",
                                                            "storageKey": "url(queryTransform:\"width=300&height=300&mode=crop&scale=both\")"
                                                        },
                                                        (v2 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v5 /*: any*/),
                                                        (v6 /*: any*/)
                                                    ],
                                                    "type": "Asset",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v6 /*: any*/)
                                                    ],
                                                    "type": "Company",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v5 /*: any*/)
                                                    ],
                                                    "type": "User",
                                                    "abstractKey": null
                                                }
                                            ],
                                            "type": "IHaveAvatar",
                                            "abstractKey": "__isIHaveAvatar"
                                        },
                                        {
                                            "condition": "userTableExtended",
                                            "kind": "Condition",
                                            "passingValue": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "RoleConnection",
                                                    "kind": "LinkedField",
                                                    "name": "roles",
                                                    "plural": false,
                                                    "selections": (v4 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "services",
                                                    "storageKey": null
                                                }
                                            ]
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "filters": [],
                    "handle": "connection",
                    "key": "UserTable_query_users",
                    "kind": "LinkedHandle",
                    "name": "users"
                }
            ]
        },
        "params": {
            "cacheID": "c0fd5e9540e181fe48455e14d257387e",
            "id": null,
            "metadata": {},
            "name": "AssignmentTypeUsersPageQuery",
            "operationKind": "query",
            "text": "query AssignmentTypeUsersPageQuery(\n  $userTableExtended: Boolean!\n  $userTableFilter: UsersFilterInput!\n) {\n  ...UserTablePanel_query_2KSX7Q\n}\n\nfragment Avatar_iHaveAvatar on IHaveAvatar {\n  __isIHaveAvatar: __typename\n  avatar {\n    url(queryTransform: \"width=300&height=300&mode=crop&scale=both\")\n    id\n  }\n  ... on Asset {\n    color\n    name\n  }\n  ... on Company {\n    name\n  }\n  ... on User {\n    color\n    firstname\n    lastname\n  }\n}\n\nfragment UserTablePanel_query_2KSX7Q on Query {\n  ...UserTable_query_2KSX7Q\n}\n\nfragment UserTable_query_2KSX7Q on Query {\n  currentUser {\n    me {\n      id\n      isCompanyAdmin\n    }\n    id\n  }\n  users(filter: $userTableFilter, first: 50) {\n    edges {\n      node {\n        ...Avatar_iHaveAvatar\n        assignmentTypes {\n          totalCount\n        }\n        firstname\n        id\n        invitation {\n          created\n          id\n        }\n        lastname\n        roles @include(if: $userTableExtended) {\n          totalCount\n        }\n        services @include(if: $userTableExtended)\n        schedulableEntity {\n          schedulable\n          id\n        }\n        timeReportingUser {\n          active\n          id\n        }\n        status\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "e545a36c1ecdc1c69504e74926c2bc68";
export default node;
