import React from 'react';
import { Flexbox, KeyValue, Panel } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
export const ServiceGeneralInfoPanel = (props) => {
    const { queryRef } = props;
    const { users } = useFragment(graphql `
      fragment ServiceGeneralInfoPanel_query on Query @argumentDefinitions(service: { type: "Service!" }) {
        users(filter: { servicesIn: [$service] }) {
          totalCount
        }
      }
    `, queryRef);
    return (<>
      <Panel title="Info">
        <Flexbox direction="column" gap="xlarge">
          <KeyValue label="Antal användare" value={users.totalCount}/>
        </Flexbox>
      </Panel>
    </>);
};
export default ServiceGeneralInfoPanel;
