import React, { useCallback, useRef } from 'react';
import { graphql, useMutation, usePreloadedQuery } from 'react-relay/hooks';
import { FormModal } from '@sonika-se/ui-kit/components';
import UserSystemInfoForm from '~/forms/UserSystemInfoForm';
export const userSystemInfoUpdateModalQuery = graphql `
  query UserSystemInfoUpdateModalQuery($userId: ID!) {
    user(id: $userId) {
      color
      id
      status
    }
  }
`;
export const UserSystemInfoUpdateModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const { user } = usePreloadedQuery(userSystemInfoUpdateModalQuery, preloadedQuery);
    const [updateUser, updateUserLoading] = useMutation(graphql `
      mutation UserSystemInfoUpdateModal_updateUserMutation($model: UpdateUserRequestInput!) {
        updateUser(model: $model) {
          userEdge {
            node {
              color
            }
          }
        }
      }
    `);
    const [updateUserStatus, updateUserStatusLoading] = useMutation(graphql `
      mutation UserSystemInfoUpdateModal_updateUserStatusMutation($model: UpdateUserStatusRequestInput!) {
        updateUserStatus(model: $model) {
          userEdge {
            node {
              status
            }
          }
        }
      }
    `);
    const formRef = useRef(null);
    const handleSubmit = useCallback((form) => {
        updateUser({
            onCompleted: () => {
                updateUserStatus({
                    onCompleted: () => {
                        onClose();
                    },
                    variables: {
                        model: {
                            id: user.id,
                            status: form.status ? 'ACTIVE' : 'DISABLED',
                        },
                    },
                });
            },
            variables: {
                model: {
                    color: form.color,
                    id: user.id,
                },
            },
        });
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Spara" disabled={updateUserLoading || updateUserStatusLoading} title="Ändra systeminformation">
      <UserSystemInfoForm defaultValues={{
            color: user.color,
            status: user.status === 'ACTIVE',
        }} disabled={updateUserLoading || updateUserStatusLoading} onSubmit={handleSubmit} ref={formRef}/>
    </FormModal>);
};
export default UserSystemInfoUpdateModal;
