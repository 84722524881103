/**
 * @generated SignedSource<<ac88926a8060293d7363e6d8951aaad1>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SignupWizardServicesFormServices_query",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "ServiceInfo",
            "kind": "LinkedField",
            "name": "services",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "24b2df8c24e0e1e8d32be413f74af2c6";
export default node;
