var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { H3, Text, Flexbox, Card, CardContent, Icon, Button } from '@sonika-se/ui-kit/components';
import { removeAccessTokens } from '@sonika-se/ui-kit/utils/auth';
import { getStatusUpdatePassword, updatePassword } from '~/utils/auth';
import AccountPage from '../components/AccountPage';
import { FormattedMessage } from 'react-intl';
import UpdatePasswordForm from '~/components/Forms/UpdatePasswordForm/UpdatePasswordForm';
import { faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import { graphql, useMutation } from 'react-relay/hooks';
const UpdatePassword = () => {
    const [urlSearchParams] = useSearchParams();
    const ticket = urlSearchParams.get('ticket');
    const [ticketData, setTicketData] = useState();
    const [disabled, setDisabled] = useState(false);
    const [checkPasswordPolicyMutation, checkPasswordPolicyMutationLoading] = useMutation(graphql `
        mutation UpdatePassword_checkPasswordPolicyMutation($model: CheckPasswordPolicyInput!) {
          checkPasswordPolicy(model: $model) {
            success
            failureReason
          }
        }
      `);
    // Clear any previous login
    removeAccessTokens();
    useEffect(() => {
        const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
            const responseData = yield getStatusUpdatePassword(ticket);
            setTicketData(responseData || { hasExpired: true });
        });
        fetchData();
    }, []);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const navigate = useNavigate();
    const onSubmit = (data, e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        setError('');
        setDisabled(true);
        checkPasswordPolicyMutation({
            variables: {
                model: {
                    password: data.password,
                },
            },
            onCompleted: (checkPolicyResponse) => __awaiter(void 0, void 0, void 0, function* () {
                if (checkPolicyResponse.checkPasswordPolicy.success) {
                    const success = yield updatePassword(Object.assign(Object.assign({}, data), { username: ticketData && ticketData.username }), ticketData && ticketData.ticket);
                    if (!success) {
                        setDisabled(false);
                        setError('Något gick fel! Vi kunde tyvärr inte begära en återställning av ditt lösenord just nu.');
                        return;
                    }
                    setSuccess(true);
                }
                else {
                    setDisabled(false);
                    setError(checkPolicyResponse.checkPasswordPolicy.failureReason);
                }
            }),
        });
    });
    if (!ticketData) {
        return null;
    }
    return (<AccountPage>
      <Flexbox direction="column" gap="large">
        <Flexbox direction="column" gap="medium">
          <H3>
            <FormattedMessage id="UpdatePassword.header" defaultMessage="Uppdatera ditt lösenord"/>
          </H3>
          <Text>
            <FormattedMessage id="UpdatePassword.description" defaultMessage="Välj ett nytt lösenord som är minst åtta  tecken långt"/>
          </Text>
        </Flexbox>
        {!success && !ticketData.hasExpired && (<UpdatePasswordForm error={error} onSubmit={onSubmit} disabled={disabled}/>)}
        {success && !ticketData.hasExpired && (<div>
            <Card style={{ marginBottom: '2rem' }}>
              <CardContent style={{ textAlign: 'center' }}>
                <H3>
                  <Icon icon={faThumbsUp} color="#0C2AF3"/>
                </H3>
                <Text size="small">
                  Ditt lösenord har uppdateras, gå vidare till inloggningssidan för att logga in med det nya lösenordet.
                </Text>
              </CardContent>
            </Card>
            <Button size="large" onClick={() => navigate('/account/login')} display="block">
              Till inloggning
            </Button>
          </div>)}
        {ticketData.hasExpired && (<div>
            <Card style={{ marginBottom: '2rem' }}>
              <CardContent>
                <Text size="small">
                  Länken du har använt är inte giltig längre.
                  <br />
                  Klicka på återställ lösenord för att beställa en ny.
                </Text>
              </CardContent>
            </Card>
            <Button size="large" onClick={() => navigate('/account/reset-password')} display="block">
              Återställ lösenord
            </Button>
          </div>)}
      </Flexbox>
    </AccountPage>);
};
export default UpdatePassword;
