import React, { Suspense, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Checkbox, Confirm, DataTable, Flexbox, Icon, Label, ModalSkeleton, Panel, PanelButton, Placeholder, Tooltip, } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useMutation, useQueryLoader } from 'react-relay/hooks';
import { usePermissions } from '@sonika-se/ui-kit/hooks';
import enums from '~/enums.json';
const { PermissionEnum } = enums;
import UserAssignmentTypeSelectModal, { userAssignmentTypeSelectModalQuery, } from '~/shared/modals/UserAssignmentTypeSelectModal';
import { faExclamationTriangle, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
export const AssetProficienciesPanel = (props) => {
    const { assetRef } = props;
    const asset = useFragment(graphql `
      fragment AssetProficienciesPanel_asset on Asset {
        proficientUsers {
          nodes {
            id
            assignmentType {
              id
              title
            }
            user {
              id
              fullName
            }
          }
        }
        id
        validateProficiency
      }
    `, assetRef);
    const typesAndUsers = useMemo(() => asset.proficientUsers.nodes.map((proficiency) => ({
        assignmentType: proficiency.assignmentType,
        user: proficiency.user,
        actions: proficiency,
    })), [asset]);
    const [assetProficienciesSelectModalRef, loadAssetProficienciesSelectModal, disposeAssetProficienciesSelectModal] = useQueryLoader(userAssignmentTypeSelectModalQuery);
    const [addProficienciesToAsset, addProficienciesToAssetLoading] = useMutation(graphql `
        mutation AssetProficienciesPanel_addProficienciesToAssetMutation($model: AddAssetUserProficienciesInput!) {
          addAssetUserProficiencies(model: $model) {
            asset {
              ...AssetProficienciesPanel_asset
            }
          }
        }
      `);
    const [removeProficienciesFromAsset, removeProficienciesFromAssetLoading] = useMutation(graphql `
        mutation AssetProficienciesPanel_removeProficienciesFromAssetMutation(
          $model: DeleteAssetUserProficienciesInput!
        ) {
          deleteAssetUserProficiencies(model: $model) {
            asset {
              ...AssetProficienciesPanel_asset
            }
          }
        }
      `);
    const [updateAsset, updateAssetLoading] = useMutation(graphql `
      mutation AssetProficienciesPanel_updateAssetMutation($model: UpdateAssetRequestInput!) {
        updateAsset(model: $model) {
          asset {
            id
            validateProficiency
          }
        }
      }
    `);
    const hasPermissions = usePermissions();
    const hasEditRolesPermission = hasPermissions([PermissionEnum.EDIT_ASSETS.key]);
    const [confirmDeleteProficiency, setConfirmDeleteProficiency] = useState(null);
    // const where = useMemo(
    //   () => ({
    //     id: {
    //       nin: user.roles.nodes.map(({ id }) => id),
    //     },
    //   }),
    //   [user],
    // )
    return (<>
      <Panel title="Behörigheter" actions={<>
            {hasEditRolesPermission && (<Flexbox style={{ gap: '1rem' }}>
                <span>
                  <Label style={{ cursor: 'pointer' }}>
                    <Checkbox disabled={updateAssetLoading} checked={asset.validateProficiency} onChange={(event) => {
                    updateAsset({
                        variables: { model: { id: asset.id, validateProficiency: event.target.checked } },
                    });
                }}/>
                    Validera behörigheter
                  </Label>
                  <Tooltip content={`Visar varningar på användare som inte har behörighet att jobba på enheten`}>
                    <Icon icon={faInfoCircle} color={sonikaTheme.colors.primary}/>
                  </Tooltip>
                </span>
                <PanelButton disabled={addProficienciesToAssetLoading} onClick={() => {
                    loadAssetProficienciesSelectModal({
                    // where,
                    });
                }}>
                  Lägg till
                </PanelButton>
              </Flexbox>)}
          </>}>
        {typesAndUsers.length > 0 ? (<DataTable columns={{
                user: {
                    text: 'Namn',
                    render: ({ id, fullName }) => <Link to={`/users/${id}`}>{fullName}</Link>,
                },
                assignmentType: {
                    text: 'Roll',
                    render: (assignmentType) => (<Link to={`/assignment-types/${assignmentType.id}`}>{assignmentType.title}</Link>),
                },
                actions: {
                    align: 'right',
                    text: '',
                    render: (proficiency) => (<Button appearance="outlined" color="danger" disabled={removeProficienciesFromAssetLoading} onClick={() => {
                            setConfirmDeleteProficiency({
                                id: proficiency.id,
                                userFullName: proficiency.user.fullName,
                                assignmentTypeTitle: proficiency.assignmentType.title,
                            });
                        }} size="xsmall">
                    Ta bort
                  </Button>),
                },
            }} rows={typesAndUsers}/>) : (<Placeholder text="Inga behörigheter."/>)}
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {assetProficienciesSelectModalRef && (<UserAssignmentTypeSelectModal onClose={() => {
                disposeAssetProficienciesSelectModal();
            }} onSelect={(userAssignmentTypes) => {
                disposeAssetProficienciesSelectModal();
                addProficienciesToAsset({
                    variables: {
                        model: {
                            assetId: asset.id,
                            userAssignmentTypesIds: userAssignmentTypes,
                        },
                    },
                });
            }} 
        // defaultWhere={{ status: { eq: 'ACTIVE' } }}
        preloadedQuery={assetProficienciesSelectModalRef}/>)}
      </Suspense>
      {confirmDeleteProficiency && (<Confirm confirmButton={{
                onConfirm: () => {
                    removeProficienciesFromAsset({
                        variables: {
                            model: {
                                assetId: asset.id,
                                assetUserProficienciesIds: [confirmDeleteProficiency.id],
                            },
                        },
                        onCompleted: () => {
                            setConfirmDeleteProficiency(null);
                        },
                    });
                },
                text: 'Ta bort',
            }} headerText="Ta bort behörighet" icon={faExclamationTriangle} onCancel={() => {
                setConfirmDeleteProficiency(null);
            }} text={`Är du säker på att du vill ta bort behörigheten för ${confirmDeleteProficiency.userFullName} med rollen ${confirmDeleteProficiency.assignmentTypeTitle}?`}/>)}
    </>);
};
export default AssetProficienciesPanel;
