import React, { useMemo } from 'react';
import { Flexbox, Panel, Placeholder } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import ScheduleShiftCard from '~/cards/ScheduleShiftCard';
import Weekday from '~/components/Widgets/SchedulePanel/Weekday';
import { toDate } from '@sonika-se/ui-kit/utils/time';
export const UserUpcomingScheduleShiftsPanel = (props) => {
    const { schedulableEntityRef } = props;
    const { scheduleItems } = useFragment(graphql `
      fragment UserUpcomingScheduleShiftsPanel_schedulableEntity on SchedulableUser
      @argumentDefinitions(model: { type: "GetRunningScheduleRequestInput!" }) {
        scheduleItems(first: 5, model: $model) {
          nodes {
            ... on Node {
              id
            }
            __typename
            ... on ICanBeUsedToCalculateScheduleTimes {
              start
            }
            ...ScheduleShiftCard_scheduleShift
          }
        }
      }
    `, schedulableEntityRef);
    const scheduleShifts = useMemo(() => {
        return scheduleItems.nodes.filter((x) => x.__typename === 'ScheduleShift');
    }, [scheduleItems]);
    return (<Panel title="Kommande pass">
      {scheduleShifts.length === 0 ? (<Placeholder text="Inga kommande pass."/>) : (<Flexbox direction="column" gap="medium">
          <div style={{
                display: 'grid',
                gap: '.5rem',
                gridTemplateColumns: 'auto 1fr',
            }}>
            {scheduleShifts.map((scheduleItem) => (<React.Fragment key={scheduleItem.id}>
                <Weekday date={toDate(scheduleItem.start)}/>
                <div style={{ gridColumn: 2 }}>
                  <ScheduleShiftCard key={scheduleItem.id} scheduleShiftRef={scheduleItem}/>
                </div>
              </React.Fragment>))}
          </div>
        </Flexbox>)}
    </Panel>);
};
export default UserUpcomingScheduleShiftsPanel;
