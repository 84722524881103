import React from 'react';
import { KeyValue, Panel } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
export const UserEmploymentPanel = (props) => {
    const { userRef } = props;
    const user = useFragment(graphql `
      fragment UserEmploymentPanel_user on User {
        id
        company {
          id
          name
        }
      }
    `, userRef);
    return (<Panel title="Anställning">
      <KeyValue label="Företag" value={user.company.name}/>
    </Panel>);
};
export default UserEmploymentPanel;
