import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';
import { graphql, usePaginationFragment } from 'react-relay/hooks';
import { Chip, DataTable, Flexbox, Icon, Placeholder, Text, Tooltip } from '@sonika-se/ui-kit/components';
import { Link } from 'react-router-dom';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { serviceIcons, serviceNames } from '@sonika-se/ui-kit';
import { pluralize } from '@sonika-se/ui-kit/utils/text';
import enums from '~/enums.json';
const { Service } = enums;
import Avatar from '~/shared/Avatar';
export const UserTable = forwardRef((props, forwardedRef) => {
    const { actions, extended, filter, queryRef, where } = props;
    const { data, hasNext, isLoadingNext, loadNext, refetch } = usePaginationFragment(graphql `
      fragment UserTable_query on Query
      @argumentDefinitions(
        after: { type: "String" }
        extended: { type: "Boolean!" }
        filter: { type: "UsersFilterInput" }
        first: { type: "Int", defaultValue: 50 }
        where: { type: "UserFilterInput" }
      )
      @refetchable(queryName: "UserTablePaginationQuery") {
        currentUser {
          me {
            id
            isCompanyAdmin
          }
        }
        users(after: $after, filter: $filter, first: $first, where: $where)
          @connection(key: "UserTable_query_users", filters: []) {
          edges {
            node {
              ...Avatar_iHaveAvatar
              assignmentTypes {
                totalCount
              }
              firstname
              id
              invitation {
                created
              }
              lastname
              roles @include(if: $extended) {
                totalCount
              }
              schedulableEntity {
                schedulable
              }
              timeReportingUser {
                active
              }
              services @include(if: $extended)
              status
            }
          }
        }
      }
    `, queryRef);
    useImperativeHandle(forwardedRef, () => ({
        refetch: () => {
            refetch({ extended, filter, where }, { fetchPolicy: 'network-only' });
        },
    }), [extended, filter, refetch, where]);
    const firstRender = useRef(true);
    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
        refetch({ extended, filter, where }, { fetchPolicy: 'network-only' });
    }, [extended, filter, where]);
    const users = useMemo(() => data.users.edges.map(({ node: user }) => {
        var _a, _b;
        return ({
            avatar: user,
            name: user,
            services: extended ? user.services : undefined,
            isSchedulable: extended ? (_a = user.schedulableEntity) === null || _a === void 0 ? void 0 : _a.schedulable : undefined,
            isTimeReporting: extended ? (_b = user.timeReportingUser) === null || _b === void 0 ? void 0 : _b.active : undefined,
            isInvited: extended ? user.invitation !== null : undefined,
            status: user.status === 'ACTIVE',
            actions: actions ? user : undefined,
        });
    }), [data, actions]);
    return users.length === 0 ? (<Placeholder text="Inga användare."/>) : (<DataTable columns={{
            avatar: {
                text: '',
                render: (user) => <Avatar iHaveAvatarRef={user} size="small"/>,
                width: 1,
            },
            name: {
                text: 'Namn',
                render: (user) => (<Flexbox direction="column" gap="none">
              <Text bold>
                {extended ? (<Link to={`/users/${user.id}`}>
                    {user.firstname} {user.lastname}
                  </Link>) : (<>
                    {user.firstname} {user.lastname}
                  </>)}
              </Text>
              {extended && (user.assignmentTypes || user.roles) && (<Text appearance="light" size="xsmall">
                  {[
                            user.assignmentTypes ? pluralize `${user.assignmentTypes.totalCount} roll[|er]` : undefined,
                            user.roles ? pluralize `${user.roles.totalCount} grupp[|er]` : undefined,
                        ]
                            .filter((value) => !!value)
                            .join(' • ')}
                </Text>)}
            </Flexbox>),
            },
            services: extended
                ? {
                    text: 'Tjänster',
                    render: (services) => (<Flexbox direction="row" gap="xsmall">
                  {Object.keys(Service)
                            .filter((service) => service !== 'SURVEY')
                            .sort()
                            .map((serviceName) => (<Tooltip content={serviceNames[serviceName]} key={serviceName}>
                        <Icon color={(services === null || services === void 0 ? void 0 : services.includes(serviceName))
                                ? sonikaTheme.colors.primary
                                : sonikaTheme.colors.foreground.placeholder} icon={serviceIcons[serviceName]}/>
                      </Tooltip>))}
                </Flexbox>),
                }
                : undefined,
            isSchedulable: extended
                ? {
                    align: 'center',
                    text: 'Schemaläggningsbar',
                    render: (isSchedulable) => isSchedulable ? (<Icon color={sonikaTheme.colors.system.go} icon={faCheck}/>) : (<Icon color={sonikaTheme.colors.system.stop} icon={faTimes}/>),
                }
                : undefined,
            isTimeReporting: extended
                ? {
                    align: 'center',
                    text: 'Tidrapporterar',
                    render: (isTimeReporting) => isTimeReporting ? (<Icon color={sonikaTheme.colors.system.go} icon={faCheck}/>) : (<Icon color={sonikaTheme.colors.system.stop} icon={faTimes}/>),
                }
                : undefined,
            isInvited: extended
                ? {
                    align: 'center',
                    text: 'Inbjuden',
                    render: (isInvited) => isInvited ? (<Icon color={sonikaTheme.colors.system.go} icon={faCheck}/>) : (<Icon color={sonikaTheme.colors.system.stop} icon={faTimes}/>),
                }
                : undefined,
            status: {
                text: 'Status',
                render: (status) => (<div>
              {status ? (<Chip color={sonikaTheme.colors.system.go} size="xsmall" text="Aktiv" type="view"/>) : (<Chip color={sonikaTheme.colors.system.stop} size="xsmall" text="Inaktiv" type="view"/>)}
            </div>),
                width: 1,
            },
            actions: actions
                ? {
                    align: 'right',
                    render: (user) => {
                        return actions(user);
                    },
                    text: '',
                }
                : undefined,
        }} loading={isLoadingNext} onPaginate={() => {
            loadNext(50);
        }} pagination={{
            buttonText: 'Ladda fler',
            hasNextPage: hasNext,
        }} rows={users}/>);
});
export default UserTable;
