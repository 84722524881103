var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { SONIKA_REST_API_URL } from '../environment';
export const getStatusUpdatePassword = function (ticket) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch(`${SONIKA_REST_API_URL}/tenants/v1/Password/reset/${ticket}/status`, {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (!response.ok) {
            return false;
        }
        return response.json();
    });
};
export const resetPassword = function (authData) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch(`${SONIKA_REST_API_URL}/tenants/v1/Password/reset`, {
            method: 'PUT',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(authData),
        });
        if (!response.ok) {
            return false;
        }
        return true;
    });
};
export const updatePassword = function (requestBody, ticket) {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield fetch(`${SONIKA_REST_API_URL}/tenants/v1/Password/reset/${ticket}`, {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });
        if (!response.ok) {
            return false;
        }
        return true;
    });
};
