import React, { useCallback, useRef } from 'react';
import { graphql, useMutation, usePreloadedQuery } from 'react-relay/hooks';
import { FormModal } from '@sonika-se/ui-kit/components';
import AssignmentTypeGeneralInfoForm from '~/forms/AssignmentTypeGeneralInfoForm';
export const assignmentTypeAssignmentTypeUpdateModalQuery = graphql `
  query AssignmentTypeGeneralInfoUpdateModalQuery($assignmentTypeId: ID!) {
    assignmentType(id: $assignmentTypeId) {
      id
      title
    }
  }
`;
export const AssignmentTypeGeneralInfoUpdateModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const { assignmentType } = usePreloadedQuery(assignmentTypeAssignmentTypeUpdateModalQuery, preloadedQuery);
    const [updateAssignmentType, updateAssignmentTypeLoading] = useMutation(graphql `
        mutation AssignmentTypeGeneralInfoUpdateModal_updateAssignmentTypeMutation(
          $model: UpdateAssignmentTypeRequestInput!
        ) {
          updateAssignmentType(model: $model) {
            assignmentTypeEdge {
              node {
                title
              }
            }
          }
        }
      `);
    const formRef = useRef(null);
    const handleSubmit = useCallback((form) => {
        updateAssignmentType({
            onCompleted: () => {
                onClose();
            },
            variables: {
                model: {
                    assignmentTypeId: assignmentType.id,
                    title: form.title,
                },
            },
        });
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Spara" disabled={updateAssignmentTypeLoading} title="Ändra allmän info">
      <AssignmentTypeGeneralInfoForm defaultValues={{
            title: assignmentType.title,
        }} disabled={updateAssignmentTypeLoading} onSubmit={handleSubmit} ref={formRef}/>
    </FormModal>);
};
export default AssignmentTypeGeneralInfoUpdateModal;
