import React from 'react';
import { useParams } from 'react-router';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import RoleGeneralInfoPanel from '~/panels/RoleGeneralInfoPanel';
export const RoleDetailsPage = () => {
    const { roleId } = useParams();
    const { role } = useLazyLoadQuery(graphql `
      query RoleDetailsPageQuery($roleId: ID!) {
        role(id: $roleId) {
          ...RoleGeneralInfoPanel_role
        }
      }
    `, {
        roleId,
    });
    return <RoleGeneralInfoPanel roleRef={role}/>;
};
export default RoleDetailsPage;
