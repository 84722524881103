import { useEffect, useRef } from 'react';
export const usePolling = (pollingFn, options) => {
    const { interval } = options;
    const intervalHandleRef = useRef();
    useEffect(() => {
        intervalHandleRef.current = setInterval(() => {
            pollingFn();
        }, interval);
        return () => {
            clearInterval(intervalHandleRef.current);
        };
    }, []);
};
export default usePolling;
