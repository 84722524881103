import React from 'react';
import { Route, Routes } from 'react-router';
import ColleagueDetailsPage from '~/pages/ColleagueDetailsPage';
import ColleagueIndexPage from '~/pages/ColleagueIndexPage';
import ColleagueLayout from '~/layouts/ColleagueLayout';
export const ColleagueRoutes = () => (<Routes>
    <Route path="" element={<ColleagueIndexPage />}/>
    <Route element={<ColleagueLayout />} path=":userId/*">
      <Route path="" element={<ColleagueDetailsPage />}/>
    </Route>
  </Routes>);
export default ColleagueRoutes;
