import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { Field, Form, Icon, Input, Select2 } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import { object, string } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
export const userBankAccountFormValidationSchema = (clearingNumbers) => object({
    accountNumber: string()
        .required()
        .test('isValidAccountNumber', 'Kontonummer är ogiltigt', (value, { parent }) => {
        const clearingNumber = findClearingNumber(parent.clearingNumber, clearingNumbers);
        return (clearingNumber !== undefined &&
            (value === null || value === void 0 ? void 0 : value.length) >= clearingNumber.accountNumberLengthStart &&
            (value === null || value === void 0 ? void 0 : value.length) <= clearingNumber.accountNumberLengthEnd);
    }),
    bankId: string().required(),
    clearingNumber: string()
        .required()
        .test('isValidClearingNumber', 'Clearingnummer är ogiltigt', (value) => findClearingNumber(parseInt(value), clearingNumbers) !== undefined),
});
const isClearingNumberInRange = (clearingNumber, rangeStart, rangeEnd) => clearingNumber >= rangeStart && clearingNumber <= rangeEnd;
const findClearingNumber = (searchClearingNumber, clearingNumbers) => clearingNumbers.find((clearingNumber) => isClearingNumberInRange(searchClearingNumber, clearingNumber.rangeStart, clearingNumber.rangeEnd));
export const UserBankAccountForm = forwardRef((props, forwardedRef) => {
    const { defaultValues, disabled, queryRef } = props;
    const data = useFragment(graphql `
        fragment UserBankAccountForm_query on Query {
          bankDetails {
            nodes {
              clearingNumbers {
                accountNumberLengthEnd
                accountNumberLengthStart
                clearingLength
                rangeEnd
                rangeStart
              }
              id
              name
            }
          }
        }
      `, queryRef);
    const [clearingNumbers, setClearingNumbers] = useState([]);
    const validationSchema = useMemo(() => userBankAccountFormValidationSchema(clearingNumbers), [clearingNumbers]);
    const { control, formState: { errors, isValid }, handleSubmit, watch, } = useForm({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
    });
    const bankIdFieldValue = watch('bankId');
    const clearingNumberFieldValue = watch('clearingNumber');
    const [clearingNumberPlaceholder, setClearingNumberPlaceholder] = useState();
    useImperativeHandle(forwardedRef, () => ({
        isValid,
        submit: (onValid, onInvalid) => handleSubmit(onValid, onInvalid)(),
    }), [handleSubmit, isValid]);
    useEffect(() => {
        const selectedBank = data.bankDetails.nodes.find((bank) => bank.id === bankIdFieldValue);
        const clearingNumbers = selectedBank ? selectedBank.clearingNumbers : [];
        setClearingNumbers(clearingNumbers);
        if (clearingNumbers.length > 0) {
            const clearingNumberLengths = clearingNumbers.map((clearingNumber) => clearingNumber.clearingLength);
            const clearingNumberRule = {
                max: Math.max(...clearingNumberLengths),
                min: Math.min(...clearingNumberLengths),
            };
            setClearingNumberPlaceholder(`${clearingNumberRule.min !== clearingNumberRule.max
                ? `${clearingNumberRule.min} - ${clearingNumberRule.max}`
                : clearingNumberRule.min} siffror`);
        }
    }, [bankIdFieldValue]);
    const [accountNumberPlaceholder, setAccountNumberPlaceholder] = useState();
    useEffect(() => {
        const clearingNumber = findClearingNumber(parseInt(clearingNumberFieldValue), clearingNumbers);
        setAccountNumberPlaceholder(clearingNumber !== undefined
            ? `${clearingNumber.accountNumberLengthStart !== clearingNumber.accountNumberLengthEnd
                ? `${clearingNumber.accountNumberLengthStart} - ${clearingNumber.accountNumberLengthEnd}`
                : clearingNumber.accountNumberLengthStart} siffror`
            : undefined);
    }, [clearingNumberFieldValue]);
    return (<Form columns="1fr 1fr">
        <Field columnSpan={2} label="Bank">
          <Controller control={control} name="bankId" render={({ field }) => {
            var _a;
            return (<Select2 defaultValue={field.value} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.bankId) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={({ single }) => field.onChange(single)} options={data.bankDetails.nodes.map((bank) => ({
                    label: bank.name,
                    value: bank.id,
                }))}/>);
        }}/>
        </Field>
        <Field label="Clearingnummer">
          <Controller control={control} name="clearingNumber" render={({ field }) => {
            var _a;
            return (<Input defaultValue={field.value} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.clearingNumber) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} placeholder={clearingNumberPlaceholder}/>);
        }}/>
        </Field>
        {!!bankIdFieldValue && (<Field label="Nummerserier">
            {clearingNumbers === null || clearingNumbers === void 0 ? void 0 : clearingNumbers.map((clearingNumber) => (<p key={`${clearingNumber.rangeStart}-${clearingNumber.rangeEnd}`}>
                {`${clearingNumber.rangeStart} - ${clearingNumber.rangeEnd}`}
                {isClearingNumberInRange(parseInt(clearingNumberFieldValue), clearingNumber.rangeStart, clearingNumber.rangeEnd) && (<Icon color={sonikaTheme.colors.system.go} icon={faCheck} style={{ fontSize: '0.8rem', marginLeft: '0.5rem' }}/>)}
              </p>))}
          </Field>)}
        <Field columnSpan={2} label="Kontonummer">
          <Controller control={control} name="accountNumber" render={({ field }) => {
            var _a;
            return (<Input defaultValue={field.value} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.accountNumber) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} placeholder={accountNumberPlaceholder}/>);
        }}/>
        </Field>
      </Form>);
});
export default UserBankAccountForm;
