import React, { Suspense, useState } from 'react';
import { Card, CardContent, Flexbox, Panel, Skeleton } from '@sonika-se/ui-kit/components';
import { graphql, useFragment } from 'react-relay/hooks';
import RoleTableQueryForm from '~/forms/RoleTableQueryForm';
import RoleTable from '~/tables/RoleTable';
export const RoleTablePanel = (props) => {
    const { actions, queryRef } = props;
    const data = useFragment(graphql `
      fragment RoleTablePanel_query on Query {
        ...RoleTable_query
      }
    `, queryRef);
    const [roleTableProps, setRoleTableProps] = useState();
    return (<Panel actions={actions}>
      <Flexbox direction="column" gap="small">
        <Card background="gray" shadow="none">
          <CardContent>
            <RoleTableQueryForm onSubmit={(query) => {
            const { search } = query;
            setRoleTableProps({
                where: search
                    ? {
                        or: [
                            {
                                description: {
                                    contains: search,
                                },
                            },
                            {
                                name: {
                                    contains: search,
                                },
                            },
                        ],
                    }
                    : undefined,
            });
        }}/>
          </CardContent>
        </Card>
        <Suspense fallback={<Skeleton type="table"/>}>
          <RoleTable queryRef={data} {...roleTableProps}/>
        </Suspense>
      </Flexbox>
    </Panel>);
};
export default RoleTablePanel;
