/**
 * @generated SignedSource<<a03466ebe226f7a3620c15bfb294d120>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "TimeReportCard_timeReport",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "month",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "TimeReportingUser",
                "kind": "LinkedField",
                "name": "timeReportingUser",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                    },
                    (v0 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "TimeTracksSummary",
                "kind": "LinkedField",
                "name": "timeTracksSummary",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": [
                            {
                                "kind": "Literal",
                                "name": "unit",
                                "value": "HOUR"
                            }
                        ],
                        "concreteType": "TimeSpanUnitValue",
                        "kind": "LinkedField",
                        "name": "duration",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "value",
                                "storageKey": null
                            }
                        ],
                        "storageKey": "duration(unit:\"HOUR\")"
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "TimeReport",
        "abstractKey": null
    };
})();
node.hash = "677e1809015d71a5e192c391718de2bf";
export default node;
