/**
 * @generated SignedSource<<5db912a23448d548bb32e1204cc128e8>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "service"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ServiceGeneralInfoPanel_query",
    "selections": [
        {
            "alias": null,
            "args": [
                {
                    "fields": [
                        {
                            "items": [
                                {
                                    "kind": "Variable",
                                    "name": "servicesIn.0",
                                    "variableName": "service"
                                }
                            ],
                            "kind": "ListValue",
                            "name": "servicesIn"
                        }
                    ],
                    "kind": "ObjectValue",
                    "name": "filter"
                }
            ],
            "concreteType": "UserConnection",
            "kind": "LinkedField",
            "name": "users",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "343870dbd6a301a34a9a49e9322e5438";
export default node;
