/**
 * @generated SignedSource<<b3cabf877b6ff4cf90c01cdbb4056cdd>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserAttachmentsPanel_user",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useUserAttachments_user"
        }
    ],
    "type": "User",
    "abstractKey": null
};
node.hash = "e52292af937e7282e30e9a05effe0946";
export default node;
