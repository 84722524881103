/**
 * @generated SignedSource<<cc364482132e4c82e4d3da865eece092>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": {
        "refetch": {
            "connection": null,
            "fragmentPathInResult": [],
            "operation": require('./PrivateRoutes_queryQuery.graphql')
        }
    },
    "name": "PrivateRoutes_query",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "CurrentUser",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Avatar",
                            "kind": "LinkedField",
                            "name": "avatar",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Literal",
                                            "name": "queryTransform",
                                            "value": "width=68&height=68&mode=crop&scale=both"
                                        }
                                    ],
                                    "kind": "ScalarField",
                                    "name": "url",
                                    "storageKey": "url(queryTransform:\"width=68&height=68&mode=crop&scale=both\")"
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "color",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstname",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastname",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "permissions",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "services",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Query",
    "abstractKey": null
};
node.hash = "014975e3ef18d6b8ab2d52e8f7bbd145";
export default node;
