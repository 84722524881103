import React, { Suspense } from 'react';
import { Outlet, useParams } from 'react-router';
import { BasicPage, BasicPageHeader, Flexbox, Menu, MenuItem, Skeleton } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
export const RoleLayout = () => {
    const { roleId } = useParams();
    const data = useLazyLoadQuery(graphql `
      query RoleLayoutQuery($roleId: ID!) {
        role(id: $roleId) {
          name
        }
      }
    `, {
        roleId,
    });
    return (<BasicPage width="large">
      <BasicPageHeader title={data.role.name}/>
      <Flexbox direction="column" gap="xlarge">
        <Menu>
          <MenuItem index path="." text="Allmänt"/>
          <MenuItem path="users" text="Användare"/>
          <MenuItem path="permissions" text="Rättigheter"/>
          <MenuItem path="schedulePermissions" text="Schemarättigheter"/>
        </Menu>
        <Suspense fallback={<Skeleton height="40vh" type="table"/>}>
          <Outlet />
        </Suspense>
      </Flexbox>
    </BasicPage>);
};
export default RoleLayout;
