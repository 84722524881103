import React from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { useParams } from 'react-router';
import ServiceGeneralInfoPanel from '~/panels/ServiceGeneralInfoPanel';
export const ServiceDetailsPage = () => {
    const params = useParams();
    const service = params.service;
    const data = useLazyLoadQuery(graphql `
      query ServiceDetailsPageQuery($service: Service!) {
        ...ServiceGeneralInfoPanel_query @arguments(service: $service)
      }
    `, {
        service,
    });
    return <ServiceGeneralInfoPanel queryRef={data}/>;
};
export default ServiceDetailsPage;
