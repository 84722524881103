import React from 'react';
import { Route, Routes } from 'react-router';
import RoleDetailsPage from '~/pages/RoleDetailsPage';
import RoleIndexPage from '~/pages/RoleIndexPage';
import RoleLayout from '~/layouts/RoleLayout';
import RolePermissionsPage from '~/pages/RolePermissionsPage';
import RoleSchedulePermissionsPage from '~/pages/RoleSchedulePermissionsPage';
import RoleUsersPage from '~/pages/RoleUsersPage';
export const RoleRoutes = () => (<Routes>
    <Route path="" element={<RoleIndexPage />}/>
    <Route element={<RoleLayout />} path=":roleId/*">
      <Route path="" element={<RoleDetailsPage />}/>
      <Route path="users" element={<RoleUsersPage />}/>
      <Route path="permissions" element={<RolePermissionsPage />}/>
      <Route path="schedulePermissions" element={<RoleSchedulePermissionsPage />}/>
    </Route>
  </Routes>);
export default RoleRoutes;
