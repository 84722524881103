import React, { useCallback, useRef } from 'react';
import { useMutation, graphql } from 'react-relay/hooks';
import { FormModal } from '@sonika-se/ui-kit/components';
import AssetCreateForm from '../forms/AssetCreateForm';
export const AssetCreateModal = (props) => {
    const { connectionsToPrependEdge, onClose } = props;
    const [createAsset, createAssetLoading] = useMutation(graphql `
    mutation AssetCreateModalCreateMutation($connectionsToPrependEdge: [ID!]!, $model: CreateAssetRequestInput!) {
      createAsset(model: $model) {
        assetEdge @prependEdge(connections: $connectionsToPrependEdge) {
          node {
            disabledDate
            id
            name
          }
        }
      }
    }
  `);
    const formRef = useRef(null);
    const handleSubmit = useCallback((form) => {
        createAsset({
            variables: {
                connectionsToPrependEdge,
                model: {
                    name: form.name,
                },
            },
            onCompleted: (response) => {
                onClose(response.createAsset.assetEdge.node);
            },
        });
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Spara" disabled={createAssetLoading} title="Lägg till enhet">
      <AssetCreateForm defaultValues={{
            name: '',
        }} disabled={createAssetLoading} onSubmit={handleSubmit} ref={formRef}/>
    </FormModal>);
};
export default AssetCreateModal;
