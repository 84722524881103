var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { graphql, useLazyLoadQuery, useMutation } from 'react-relay/hooks';
import { Controller, useForm } from 'react-hook-form';
import { Button, Field, Flexbox, FormSection, Input, ModalToSheet, ModalToSheetContent, ModalToSheetFooter, ModalToSheetHeader, } from '@sonika-se/ui-kit/components';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
const validationSchema = object().shape({
    name: string().required(),
    administrationEmail: string().nullable().notRequired(),
    organizationNumber: string().nullable().notRequired(),
});
export const EditCompanySettingsModal = (props) => {
    const { onClose, onDismiss } = props;
    const { currentCompany } = useLazyLoadQuery(graphql `
      query EditCompanySettingsModalQuery {
        currentCompany {
          id
          name
          administrationEmail
          organizationNumber
        }
      }
    `, {}, { fetchPolicy: 'network-only' });
    const defaultValues = {
        administrationEmail: currentCompany.administrationEmail,
        name: currentCompany.name,
        organizationNumber: currentCompany.organizationNumber,
    };
    const [updateCompanySettings, updateCompanySettingsLoading] = useMutation(graphql `
      mutation EditCompanySettingsModal_updateMutation($model: UpdateCurrentCompanyRequestInput!) {
        updateCurrentCompany(model: $model) {
          companyEdge {
            node {
              id
              name
              administrationEmail
            }
          }
        }
      }
    `);
    const { control, formState: { errors, isValid }, handleSubmit, } = useForm({
        defaultValues: defaultValues,
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
    });
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        updateCompanySettings({
            variables: {
                model: {
                    name: data.name,
                    administrationEmail: data.administrationEmail,
                    organizationNumber: data.organizationNumber,
                },
            },
            onCompleted: () => {
                onClose();
            },
        });
    });
    const onInvalidForm = (errors) => __awaiter(void 0, void 0, void 0, function* () {
        console.log(errors);
    });
    return (<>
      <ModalToSheet onClose={() => onDismiss === null || onDismiss === void 0 ? void 0 : onDismiss()} size={'small'}>
        <form onSubmit={handleSubmit(onSubmit, onInvalidForm)} style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
          <ModalToSheetHeader onClose={() => onDismiss === null || onDismiss === void 0 ? void 0 : onDismiss()}>Uppdatera företagsinställningar</ModalToSheetHeader>
          <ModalToSheetContent>
            <FormSection columns="1fr">
              <Field label="Namn">
                <Controller control={control} name="name" render={({ field }) => {
            var _a;
            return (<Input error={(_a = errors === null || errors === void 0 ? void 0 : errors.name) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange} defaultValue={field.value}/>);
        }}/>
              </Field>
              <Field label="Administratör e-post">
                <Controller control={control} name="administrationEmail" render={({ field }) => {
            var _a;
            return (<Input error={(_a = errors === null || errors === void 0 ? void 0 : errors.administrationEmail) === null || _a === void 0 ? void 0 : _a.message} type="email" onBlur={field.onBlur} onChange={field.onChange} defaultValue={field.value}/>);
        }}/>
              </Field>
              <Field label="Organisationsnummer">
                <Controller control={control} name="organizationNumber" render={({ field }) => {
            var _a;
            return (<Input error={(_a = errors === null || errors === void 0 ? void 0 : errors.organizationNumber) === null || _a === void 0 ? void 0 : _a.message} type="text" onBlur={field.onBlur} onChange={field.onChange} defaultValue={field.value}/>);
        }}/>
              </Field>
            </FormSection>
          </ModalToSheetContent>
          <ModalToSheetFooter>
            <Flexbox direction="row" justify="space" style={{ flex: 1 }}>
              <Button type="button" color="default" onClick={() => onDismiss === null || onDismiss === void 0 ? void 0 : onDismiss()}>
                Stäng
              </Button>
              <Button type="submit" disabled={updateCompanySettingsLoading}>
                Spara
              </Button>
            </Flexbox>
          </ModalToSheetFooter>
        </form>
      </ModalToSheet>
    </>);
};
export default EditCompanySettingsModal;
