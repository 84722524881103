/**
 * @generated SignedSource<<ca8c70c5d9142ceb4d2aad9a4b55a851>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
    }, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
    }, v3 = [
        (v0 /*: any*/),
        (v1 /*: any*/),
        (v2 /*: any*/)
    ], v4 = {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "impersonatedBy",
        "plural": false,
        "selections": (v3 /*: any*/),
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "permissions",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "AppQuery",
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "Routes_query"
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CurrentUser",
                    "kind": "LinkedField",
                    "name": "currentUser",
                    "plural": false,
                    "selections": [
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "me",
                            "plural": false,
                            "selections": (v3 /*: any*/),
                            "storageKey": null
                        },
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "AppQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "CurrentUser",
                    "kind": "LinkedField",
                    "name": "currentUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "me",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Avatar",
                                    "kind": "LinkedField",
                                    "name": "avatar",
                                    "plural": false,
                                    "selections": [
                                        (v0 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": [
                                                {
                                                    "kind": "Literal",
                                                    "name": "queryTransform",
                                                    "value": "width=68&height=68&mode=crop&scale=both"
                                                }
                                            ],
                                            "kind": "ScalarField",
                                            "name": "url",
                                            "storageKey": "url(queryTransform:\"width=68&height=68&mode=crop&scale=both\")"
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "color",
                                    "storageKey": null
                                },
                                (v1 /*: any*/),
                                (v2 /*: any*/),
                                (v0 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v5 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "services",
                            "storageKey": null
                        },
                        (v0 /*: any*/),
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "94b4e6f569e74799c36ff2935ed1d98b",
            "id": null,
            "metadata": {},
            "name": "AppQuery",
            "operationKind": "query",
            "text": "query AppQuery {\n  ...Routes_query\n  currentUser {\n    impersonatedBy {\n      id\n      firstname\n      lastname\n    }\n    me {\n      id\n      firstname\n      lastname\n    }\n    permissions\n    id\n  }\n}\n\nfragment PrivateRoutes_query on Query {\n  currentUser {\n    me {\n      avatar {\n        id\n        url(queryTransform: \"width=68&height=68&mode=crop&scale=both\")\n      }\n      color\n      firstname\n      lastname\n      id\n    }\n    permissions\n    services\n    id\n  }\n}\n\nfragment Routes_query on Query {\n  ...PrivateRoutes_query\n}\n"
        }
    };
})();
node.hash = "171f5cb9e0717baf16a55b2ca257ac00";
export default node;
