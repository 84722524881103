/**
 * @generated SignedSource<<661d975848958e9cc5df1d57362ed6be>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "model",
                    "variableName": "model"
                }
            ],
            "concreteType": "SetRolePermissionsPayload",
            "kind": "LinkedField",
            "name": "setRolePermissions",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Role",
                    "kind": "LinkedField",
                    "name": "role",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "rolePermissions",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RolePermissionsUpdateModal_setRolePermissionsMutation",
            "selections": (v1 /*: any*/),
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RolePermissionsUpdateModal_setRolePermissionsMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "a9657320c0e336282d2bc33cc2fc157d",
            "id": null,
            "metadata": {},
            "name": "RolePermissionsUpdateModal_setRolePermissionsMutation",
            "operationKind": "mutation",
            "text": "mutation RolePermissionsUpdateModal_setRolePermissionsMutation(\n  $model: SetRolePermissionsRequestInput!\n) {\n  setRolePermissions(model: $model) {\n    role {\n      id\n      rolePermissions\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "120e21d8f12a6da44a350dd63a612676";
export default node;
