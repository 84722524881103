/**
 * @generated SignedSource<<9305269763bfc006675261dc222faded>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "concreteType": "UserBankAccount",
        "kind": "LinkedField",
        "name": "bankAccount",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accountNumber",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "BankDetails",
                "kind": "LinkedField",
                "name": "bankDetails",
                "plural": false,
                "selections": [
                    (v2 /*: any*/)
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bankName",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clearingNumber",
                "storageKey": null
            },
            (v2 /*: any*/)
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserBankAccountUpdateModal_setUserBankAccountMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SetUserBankAccountPayload",
                    "kind": "LinkedField",
                    "name": "setUserBankAccount",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserBankAccountUpdateModal_setUserBankAccountMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SetUserBankAccountPayload",
                    "kind": "LinkedField",
                    "name": "setUserBankAccount",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "5f6a68f2e94cb08672a6dff989f80e01",
            "id": null,
            "metadata": {},
            "name": "UserBankAccountUpdateModal_setUserBankAccountMutation",
            "operationKind": "mutation",
            "text": "mutation UserBankAccountUpdateModal_setUserBankAccountMutation(\n  $model: SetUserBankAccountInput!\n) {\n  setUserBankAccount(model: $model) {\n    user {\n      bankAccount {\n        accountNumber\n        bankDetails {\n          id\n        }\n        bankName\n        clearingNumber\n        id\n      }\n      id\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "0869c54c30a7eff4bb37bc8347b357d7";
export default node;
