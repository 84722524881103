import React, { Suspense } from 'react';
import { Flexbox, KeyValue, ModalSkeleton, Panel, PanelButton } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import AssignmentTypeGeneralInfoUpdateModal, { assignmentTypeAssignmentTypeUpdateModalQuery, } from '~/modals/AssignmentTypeGeneralInfoUpdateModal';
export const AssignmentTypeGeneralInfoPanel = (props) => {
    const { assignmentTypeRef } = props;
    const assignmentType = useFragment(graphql `
      fragment AssignmentTypeGeneralInfoPanel_assignmentType on AssignmentType {
        id
        title
      }
    `, assignmentTypeRef);
    const [assignmentTypeGeneralInfoUpdateModalRef, loadAssignmentTypeGeneralInfoUpdateModal, disposeAssignmentTypeGeneralInfoUpdateModal,] = useQueryLoader(assignmentTypeAssignmentTypeUpdateModalQuery);
    return (<>
      <Panel title="Info" actions={<PanelButton onClick={() => loadAssignmentTypeGeneralInfoUpdateModal({ assignmentTypeId: assignmentType.id })}>
            Ändra
          </PanelButton>}>
        <Flexbox direction="column" gap="xlarge">
          <KeyValue label="Titel" value={assignmentType.title}/>
        </Flexbox>
      </Panel>
      <Suspense fallback={<ModalSkeleton />}>
        {assignmentTypeGeneralInfoUpdateModalRef && (<AssignmentTypeGeneralInfoUpdateModal onClose={() => {
                disposeAssignmentTypeGeneralInfoUpdateModal();
            }} preloadedQuery={assignmentTypeGeneralInfoUpdateModalRef}/>)}
      </Suspense>
    </>);
};
export default AssignmentTypeGeneralInfoPanel;
