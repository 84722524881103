import React from 'react';
import { useParams } from 'react-router';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import AssetProficienciesPanel from '~/panels/AssetProficienciesPanel';
export const AssetProficienciesPage = () => {
    const { assetId } = useParams();
    const data = useLazyLoadQuery(graphql `
      query AssetProficienciesPageQuery($assetId: ID!) {
        asset(id: $assetId) {
          ...AssetProficienciesPanel_asset
        }
      }
    `, {
        assetId,
    });
    return <AssetProficienciesPanel assetRef={data.asset}/>;
};
export default AssetProficienciesPage;
