import React, { useCallback, useRef } from 'react';
import { graphql, useMutation, usePreloadedQuery } from 'react-relay/hooks';
import { FormModal } from '@sonika-se/ui-kit/components';
import UserContactForm from '~/forms/UserContactForm';
export const userContactUpdateModalQuery = graphql `
  query UserContactUpdateModalQuery($userId: ID!) {
    user(id: $userId) {
      email
      id
      phone
    }
  }
`;
export const UserContactUpdateModal = (props) => {
    const { onClose, preloadedQuery } = props;
    const { user } = usePreloadedQuery(userContactUpdateModalQuery, preloadedQuery);
    const [updateUser, updateUserLoading] = useMutation(graphql `
      mutation UserContactUpdateModal_updateUserMutation($model: UpdateUserRequestInput!) {
        updateUser(model: $model) {
          userEdge {
            node {
              email
              phone
            }
          }
        }
      }
    `);
    const formRef = useRef(null);
    const handleSubmit = useCallback((form) => {
        updateUser({
            onCompleted: () => onClose(),
            variables: {
                model: {
                    id: user.id,
                    email: form.email,
                    phone: form.phone,
                },
            },
        });
    }, []);
    return (<FormModal onClose={() => onClose()} onSubmit={() => formRef.current.submit(handleSubmit)} submitButtonLabel="Spara" disabled={updateUserLoading} title="Ändra kontakt">
      <UserContactForm defaultValues={{
            email: user.email,
            phone: user.phone,
        }} disabled={updateUserLoading} onSubmit={handleSubmit} ref={formRef}/>
    </FormModal>);
};
export default UserContactUpdateModal;
