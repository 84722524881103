import React, { Suspense, useEffect, useState } from 'react';
import { graphql, usePreloadedQuery, useMutation } from 'react-relay/hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { ModalToSheetContent, Label, H6, Flexbox, ModalToSheet, ModalToSheetHeader, SelectDropdown, NewInlineSelect, } from '@sonika-se/ui-kit/components';
import EventList from './EventList';
import { useDebounce } from '@sonika-se/ui-kit/hooks';
export const updateEventByAssignmentTypeModalQuery = graphql `
  query UpdateEventByAssignmentTypeModalQuery($id: ID!) {
    assignmentType(id: $id) {
      id
      title
      events {
        edges {
          node {
            id
            name
          }
        }
      }
    }
    ...EventList_query
  }
`;
export const UpdateEventByAssignmentTypeModal = (props) => {
    var _a;
    const { onCancel, preloadedQuery } = props;
    const data = usePreloadedQuery(updateEventByAssignmentTypeModalQuery, preloadedQuery);
    const [updateEventAssignmentType, updateEventAssignmentTypeLoading] = useMutation(graphql `
      mutation UpdateEventByAssignmentTypeModalMutation($model: SetAssignmentTypeEventsInput!) {
        setAssignmentTypeEvents(model: $model) {
          assignmentTypeEdge {
            node {
              id
              title
              events {
                edges {
                  node {
                    name
                    id
                  }
                }
              }
            }
          }
        }
      }
    `);
    const [selected, setSelected] = useState([]);
    const intl = useIntl();
    const [search, setSearch] = useState('');
    const delayedSearch = useDebounce(search, 300);
    useEffect(() => {
        var _a, _b, _c;
        setSelected((_c = (_b = (_a = data === null || data === void 0 ? void 0 : data.assignmentType) === null || _a === void 0 ? void 0 : _a.events) === null || _b === void 0 ? void 0 : _b.edges) === null || _c === void 0 ? void 0 : _c.map(({ node }) => ({
            value: node === null || node === void 0 ? void 0 : node.id,
            label: node === null || node === void 0 ? void 0 : node.name,
        })));
    }, [data]);
    return (<ModalToSheet size="small" onClose={onCancel}>
      <ModalToSheetHeader onClose={onCancel} closeButtonLabel={intl.formatMessage({ id: 'general.actions.close', defaultMessage: 'Stäng' })}>
        <FormattedMessage id="general.actions.edit_assignment_type" defaultMessage="Redigera roll"/>
      </ModalToSheetHeader>
      <ModalToSheetContent>
        <Flexbox direction="column" gap="medium">
          <H6>{(_a = data === null || data === void 0 ? void 0 : data.assignmentType) === null || _a === void 0 ? void 0 : _a.title}</H6>
          <Label text="Händelser">
            <NewInlineSelect disabled={updateEventAssignmentTypeLoading} isLoading={updateEventAssignmentTypeLoading} onChange={(values) => {
            var _a;
            updateEventAssignmentType({
                variables: {
                    model: {
                        id: (_a = data === null || data === void 0 ? void 0 : data.assignmentType) === null || _a === void 0 ? void 0 : _a.id,
                        eventIds: (values === null || values === void 0 ? void 0 : values.length) ? values === null || values === void 0 ? void 0 : values.map(({ value }) => value) : [],
                    },
                },
            });
        }} onSearch={(searchString) => {
            setSearch(searchString);
        }} searchString={search} multiple stayOpenOnSelect selectedItems={selected} placeholder={intl.formatMessage({
            defaultMessage: 'Välj händelser',
            id: 'UpdateEventByAssignmentTypeModal.events.placeholder',
        })} optionsList={<Suspense fallback={<SelectDropdown isLoading setSelected={() => console.log('selected')} selectedItems={[]} searchString=""/>}>
                  <EventList selected={selected} eventsRef={data} search={delayedSearch} onSelected={(eventIds) => {
                var _a;
                updateEventAssignmentType({
                    variables: {
                        model: {
                            id: (_a = data === null || data === void 0 ? void 0 : data.assignmentType) === null || _a === void 0 ? void 0 : _a.id,
                            eventIds,
                        },
                    },
                });
            }}/>
                </Suspense>}/>
          </Label>
        </Flexbox>
      </ModalToSheetContent>
    </ModalToSheet>);
};
export default UpdateEventByAssignmentTypeModal;
