/**
 * @generated SignedSource<<ada766e73c71d8e68f6572ddfa48bbd1>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserExternalContactsPanel_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "externalContacts",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
};
node.hash = "0b2458e9bbd179d24a47a93d7e8942ab";
export default node;
