/**
 * @generated SignedSource<<195049c1102583bb7c8b05a9f47a131f>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "maxMonth"
    }, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "minMonth"
    }, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "where"
    }, v3 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 5
        },
        {
            "kind": "Variable",
            "name": "where",
            "variableName": "where"
        }
    ], v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v5 = {
        "fields": [
            {
                "kind": "Variable",
                "name": "gte",
                "variableName": "minMonth"
            },
            {
                "kind": "Variable",
                "name": "lte",
                "variableName": "maxMonth"
            }
        ],
        "kind": "ObjectValue",
        "name": "month"
    }, v6 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
        }
    ], v7 = {
        "alias": "submittedTimeReports",
        "args": [
            {
                "fields": [
                    (v5 /*: any*/),
                    {
                        "kind": "Literal",
                        "name": "status",
                        "value": {
                            "eq": "SUBMITTED"
                        }
                    }
                ],
                "kind": "ObjectValue",
                "name": "where"
            }
        ],
        "concreteType": "TimeReportConnection",
        "kind": "LinkedField",
        "name": "timeReports",
        "plural": false,
        "selections": (v6 /*: any*/),
        "storageKey": null
    }, v8 = {
        "alias": "approvedTimeReports",
        "args": [
            {
                "fields": [
                    (v5 /*: any*/),
                    {
                        "kind": "Literal",
                        "name": "status",
                        "value": {
                            "eq": "APPROVED"
                        }
                    }
                ],
                "kind": "ObjectValue",
                "name": "where"
            }
        ],
        "concreteType": "TimeReportConnection",
        "kind": "LinkedField",
        "name": "timeReports",
        "plural": false,
        "selections": (v6 /*: any*/),
        "storageKey": null
    }, v9 = {
        "alias": "rejectedTimeReports",
        "args": [
            {
                "fields": [
                    (v5 /*: any*/),
                    {
                        "kind": "Literal",
                        "name": "status",
                        "value": {
                            "eq": "REJECTED"
                        }
                    }
                ],
                "kind": "ObjectValue",
                "name": "where"
            }
        ],
        "concreteType": "TimeReportConnection",
        "kind": "LinkedField",
        "name": "timeReports",
        "plural": false,
        "selections": (v6 /*: any*/),
        "storageKey": null
    }, v10 = {
        "alias": "openTimeReports",
        "args": [
            {
                "fields": [
                    (v5 /*: any*/),
                    {
                        "kind": "Literal",
                        "name": "status",
                        "value": {
                            "eq": "NONE"
                        }
                    }
                ],
                "kind": "ObjectValue",
                "name": "where"
            }
        ],
        "concreteType": "TimeReportConnection",
        "kind": "LinkedField",
        "name": "timeReports",
        "plural": false,
        "selections": (v6 /*: any*/),
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "AllTimeReportsPanelQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "TimeReportConnection",
                    "kind": "LinkedField",
                    "name": "timeReports",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "TimeReportEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TimeReport",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v4 /*: any*/),
                                        {
                                            "args": null,
                                            "kind": "FragmentSpread",
                                            "name": "TimeReportCard_timeReport"
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                (v7 /*: any*/),
                (v8 /*: any*/),
                (v9 /*: any*/),
                (v10 /*: any*/)
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v2 /*: any*/),
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "AllTimeReportsPanelQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v3 /*: any*/),
                    "concreteType": "TimeReportConnection",
                    "kind": "LinkedField",
                    "name": "timeReports",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "TimeReportEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TimeReport",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v4 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "status",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "month",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "TimeReportingUser",
                                            "kind": "LinkedField",
                                            "name": "timeReportingUser",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "name",
                                                    "storageKey": null
                                                },
                                                (v4 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "TimeTracksSummary",
                                            "kind": "LinkedField",
                                            "name": "timeTracksSummary",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": [
                                                        {
                                                            "kind": "Literal",
                                                            "name": "unit",
                                                            "value": "HOUR"
                                                        }
                                                    ],
                                                    "concreteType": "TimeSpanUnitValue",
                                                    "kind": "LinkedField",
                                                    "name": "duration",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "value",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": "duration(unit:\"HOUR\")"
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                (v7 /*: any*/),
                (v8 /*: any*/),
                (v9 /*: any*/),
                (v10 /*: any*/)
            ]
        },
        "params": {
            "cacheID": "5c1e601fd210eac9d201e7d7f0443201",
            "id": null,
            "metadata": {},
            "name": "AllTimeReportsPanelQuery",
            "operationKind": "query",
            "text": "query AllTimeReportsPanelQuery(\n  $where: TimeReportFilterInput\n  $minMonth: DateTime\n  $maxMonth: DateTime\n) {\n  timeReports(where: $where, first: 5) {\n    edges {\n      node {\n        id\n        ...TimeReportCard_timeReport\n      }\n    }\n  }\n  submittedTimeReports: timeReports(where: {status: {eq: SUBMITTED}, month: {gte: $minMonth, lte: $maxMonth}}) {\n    totalCount\n  }\n  approvedTimeReports: timeReports(where: {status: {eq: APPROVED}, month: {gte: $minMonth, lte: $maxMonth}}) {\n    totalCount\n  }\n  rejectedTimeReports: timeReports(where: {status: {eq: REJECTED}, month: {gte: $minMonth, lte: $maxMonth}}) {\n    totalCount\n  }\n  openTimeReports: timeReports(where: {status: {eq: NONE}, month: {gte: $minMonth, lte: $maxMonth}}) {\n    totalCount\n  }\n}\n\nfragment TimeReportCard_timeReport on TimeReport {\n  id\n  status\n  month\n  timeReportingUser {\n    name\n    id\n  }\n  timeTracksSummary {\n    duration(unit: HOUR) {\n      value\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "90d74c6fb06a6d6b9c731ff4e2ccc06f";
export default node;
