import React, { forwardRef, useImperativeHandle } from 'react';
import { Field, Form, Input } from '@sonika-se/ui-kit/components';
import { object, string } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
export const userHomeAddressFormValidationSchema = object({
    careOf: string().nullable().notRequired(),
    city: string().nullable().notRequired(),
    postOfficeBox: string().nullable().notRequired(),
    postalCode: string().nullable().notRequired(),
    street: string().nullable().notRequired(),
});
export const UserHomeAddressForm = forwardRef((props, forwardedRef) => {
    const { defaultValues, disabled } = props;
    const { control, formState: { errors, isValid }, handleSubmit, } = useForm({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(userHomeAddressFormValidationSchema),
    });
    useImperativeHandle(forwardedRef, () => ({
        isValid,
        submit: (onValid, onInvalid) => handleSubmit(onValid, onInvalid)(),
    }), [handleSubmit, isValid]);
    return (<Form columns="1fr 2fr">
        <Field columnSpan={2} label="C/O">
          <Controller control={control} name="careOf" render={({ field }) => {
            var _a;
            return (<Input defaultValue={field.value} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.careOf) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange}/>);
        }}/>
        </Field>
        <Field columnSpan={2} label="Gatuadress">
          <Controller control={control} name="street" render={({ field }) => {
            var _a;
            return (<Input defaultValue={field.value} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.street) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange}/>);
        }}/>
        </Field>
        <Field columnSpan={2} label="Postbox">
          <Controller control={control} name="postOfficeBox" render={({ field }) => {
            var _a;
            return (<Input defaultValue={field.value} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.postOfficeBox) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange}/>);
        }}/>
        </Field>
        <Field label="Postnummer">
          <Controller control={control} name="postalCode" render={({ field }) => {
            var _a;
            return (<Input defaultValue={field.value} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.postalCode) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange}/>);
        }}/>
        </Field>
        <Field label="Stad">
          <Controller control={control} name="city" render={({ field }) => {
            var _a;
            return (<Input defaultValue={field.value} disabled={disabled} error={(_a = errors === null || errors === void 0 ? void 0 : errors.city) === null || _a === void 0 ? void 0 : _a.message} onBlur={field.onBlur} onChange={field.onChange}/>);
        }}/>
        </Field>
      </Form>);
});
export default UserHomeAddressForm;
