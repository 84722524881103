var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { setAccessTokens } from '@sonika-se/ui-kit/utils/auth';
import { SONIKA_REST_API_URL } from '../environment';
const executeLoginRequest = function (urlLoginPart, method, body, header, signal) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield fetch(`${SONIKA_REST_API_URL}${urlLoginPart}`, {
                method,
                signal,
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    header: header,
                },
                body: JSON.stringify(body),
            });
            return response;
        }
        catch (error) {
            return error;
        }
    });
};
const login = (authData, signal) => __awaiter(void 0, void 0, void 0, function* () {
    const urlLoginPart = '/tenants/v1/Authentication/login';
    const method = 'POST';
    const extraHeader = null;
    const body = authData;
    const response = yield executeLoginRequest(urlLoginPart, method, body, extraHeader, signal);
    if (!response.ok) {
        if ((response === null || response === void 0 ? void 0 : response.name) === 'AbortError') {
            return 'abort';
        }
        switch (response.status) {
            case 400:
                return 'error-user';
            default:
                return 'error';
        }
    }
    const { token, refreshToken } = yield response.json();
    setAccessTokens(token, refreshToken);
    return 'success';
});
export default login;
