/**
 * @generated SignedSource<<470ea94fb20330aae5fd0590736845f8>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "roleId"
        }
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "roleId"
                }
            ],
            "concreteType": "Role",
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rolePermissions",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "RolePermissionsUpdateModalQuery",
            "selections": (v1 /*: any*/),
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "RolePermissionsUpdateModalQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "fbc13adf6bb75d756426c6b29d1f69f9",
            "id": null,
            "metadata": {},
            "name": "RolePermissionsUpdateModalQuery",
            "operationKind": "query",
            "text": "query RolePermissionsUpdateModalQuery(\n  $roleId: ID!\n) {\n  role(id: $roleId) {\n    id\n    rolePermissions\n  }\n}\n"
        }
    };
})();
node.hash = "f864d5b872de5ba2205dcd5960f3d449";
export default node;
