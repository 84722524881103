import { useState } from 'react';
import { graphql, useFragment, useMutation } from 'react-relay/hooks';
import useAttachments from './useAttachments';
export const useAssetAttachments = (assetRef, options) => {
    const data = useFragment(graphql `
      fragment useAssetAttachments_asset on Asset {
        id
        attachments(first: null) @connection(key: "useAssetAttachments_attachments") {
          edges {
            node {
              contentType
              directory
              id
              name
              size
              storageId
              url
            }
          }
        }
      }
    `, assetRef);
    const [id, setId] = useState(data === null || data === void 0 ? void 0 : data.id);
    const [createAssetAttachment] = useMutation(graphql `
      mutation useAssetAttachments_createFileMutation($model: CreateAssetAttachmentRequestInput!) {
        createAssetAttachment(model: $model) {
          assetEdge {
            node {
              ...useAssetAttachments_asset
            }
          }
        }
      }
    `);
    const [deleteAssetAttachment] = useMutation(graphql `
      mutation useAssetAttachments_deleteFileMutation($model: DeleteAssetAttachmentRequestInput!) {
        deleteAssetAttachment(model: $model) {
          assetAttachmentEdge {
            node {
              id
            }
          }
        }
      }
    `);
    const attachments = useAttachments(Object.assign(Object.assign({}, options), { createAttachment: (uploadedAttachment) => {
            const promise = new Promise((resolve, reject) => {
                createAssetAttachment({
                    onCompleted: (response) => {
                        resolve(response);
                    },
                    onError: (error) => {
                        reject(error);
                    },
                    variables: {
                        model: Object.assign(Object.assign({}, uploadedAttachment), { assetId: id }),
                    },
                });
            });
            return promise;
        }, deleteAttachment: ({ id }) => {
            const promise = new Promise((resolve, reject) => {
                deleteAssetAttachment({
                    configs: [
                        {
                            connectionKeys: [
                                {
                                    key: 'useAssetAttachments_attachments',
                                },
                            ],
                            parentID: data.id,
                            type: 'RANGE_DELETE',
                            deletedIDFieldName: ['assetAttachmentEdge', 'node'],
                            pathToConnection: ['asset', 'attachments'],
                        },
                    ],
                    onCompleted: (response) => {
                        resolve(response);
                    },
                    onError: (error) => {
                        reject(error);
                    },
                    variables: {
                        model: {
                            id,
                        },
                    },
                });
            });
            return promise;
        }, uploadEndpoint: 'tenants/v1/AssetAttachment/upload' }));
    return Object.assign(Object.assign({}, attachments), { data, setId });
};
export default useAssetAttachments;
