/**
 * @generated SignedSource<<8c67e501e0f68b9790f53c4b729871a0>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "connectionsToPrependEdge"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstname",
        "storageKey": null
    }, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    }, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "created",
        "storageKey": null
    }, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastname",
        "storageKey": null
    }, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserCreateModalCreateMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateUserPayload",
                    "kind": "LinkedField",
                    "name": "createUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfUser",
                            "kind": "LinkedField",
                            "name": "userEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "UserInvitation",
                                            "kind": "LinkedField",
                                            "name": "invitation",
                                            "plural": false,
                                            "selections": [
                                                (v4 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v5 /*: any*/),
                                        (v6 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserCreateModalCreateMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "CreateUserPayload",
                    "kind": "LinkedField",
                    "name": "createUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfUser",
                            "kind": "LinkedField",
                            "name": "userEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "UserInvitation",
                                            "kind": "LinkedField",
                                            "name": "invitation",
                                            "plural": false,
                                            "selections": [
                                                (v4 /*: any*/),
                                                (v3 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v5 /*: any*/),
                                        (v6 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "filters": null,
                            "handle": "prependEdge",
                            "key": "",
                            "kind": "LinkedHandle",
                            "name": "userEdge",
                            "handleArgs": [
                                {
                                    "kind": "Variable",
                                    "name": "connections",
                                    "variableName": "connectionsToPrependEdge"
                                }
                            ]
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "a4d8db8e0b6e6b032592093f73031f59",
            "id": null,
            "metadata": {},
            "name": "UserCreateModalCreateMutation",
            "operationKind": "mutation",
            "text": "mutation UserCreateModalCreateMutation(\n  $model: CreateUserRequestInput!\n) {\n  createUser(model: $model) {\n    userEdge {\n      node {\n        firstname\n        id\n        invitation {\n          created\n          id\n        }\n        lastname\n        status\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "bd18bedb062b87973dee52feac9b77a4";
export default node;
