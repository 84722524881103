/**
 * @generated SignedSource<<2077bfe6c74b1ce0dacb591058c84716>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AssignmentTypeGeneralInfoUpdateModal_updateAssignmentTypeMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateAssignmentTypePayload",
                    "kind": "LinkedField",
                    "name": "updateAssignmentType",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfAssignmentType",
                            "kind": "LinkedField",
                            "name": "assignmentTypeEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssignmentType",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AssignmentTypeGeneralInfoUpdateModal_updateAssignmentTypeMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateAssignmentTypePayload",
                    "kind": "LinkedField",
                    "name": "updateAssignmentType",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "EdgeOfAssignmentType",
                            "kind": "LinkedField",
                            "name": "assignmentTypeEdge",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssignmentType",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "70f230f95d06fbd3cd222ac3fc1b41ef",
            "id": null,
            "metadata": {},
            "name": "AssignmentTypeGeneralInfoUpdateModal_updateAssignmentTypeMutation",
            "operationKind": "mutation",
            "text": "mutation AssignmentTypeGeneralInfoUpdateModal_updateAssignmentTypeMutation(\n  $model: UpdateAssignmentTypeRequestInput!\n) {\n  updateAssignmentType(model: $model) {\n    assignmentTypeEdge {\n      node {\n        title\n        id\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "27f533bd0f215cc2bd501e04e3d1d9b6";
export default node;
