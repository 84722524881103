/**
 * @generated SignedSource<<6ecea534f5737d77e47e4eeb80cc5f4f>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssetSystemInfoPanel_asset",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "disabledDate",
            "storageKey": null
        }
    ],
    "type": "Asset",
    "abstractKey": null
};
node.hash = "f3659debef1e4018b192ca59be3360e8";
export default node;
