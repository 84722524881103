import React, { Suspense } from 'react';
import { Outlet, useParams } from 'react-router';
import { BasicPage, BasicPageHeader, Flexbox, Menu, MenuItem, Skeleton } from '@sonika-se/ui-kit/components';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
export const AssignmentTypeLayout = () => {
    const { assignmentTypeId } = useParams();
    const data = useLazyLoadQuery(graphql `
      query AssignmentTypeLayoutQuery($assignmentTypeId: ID!) {
        assignmentType(id: $assignmentTypeId) {
          title
        }
      }
    `, {
        assignmentTypeId,
    });
    return (<BasicPage width="large">
      <BasicPageHeader title={data.assignmentType.title}/>
      <Flexbox direction="column" gap="xlarge">
        <Menu>
          <MenuItem index path="." text="Allmänt"/>
          <MenuItem path="users" text="Användare"/>
          <MenuItem path="assets" text="Enheter"/>
        </Menu>
        <Suspense fallback={<Skeleton height="40vh"/>}>
          <Outlet />
        </Suspense>
      </Flexbox>
    </BasicPage>);
};
export default AssignmentTypeLayout;
