/**
 * @generated SignedSource<<ea72eefae47f07444e0e4b60d687d822>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserContactPanel_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phone",
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
};
node.hash = "4ca4a941bf2acf29b0aa778d21821a65";
export default node;
