import React, { Suspense, useState } from 'react';
import { faCheck, faTimes, faGhost, faImagePolaroid, faPaperPlane, faKey, faAddressBook, faClock, faCalendarAlt, faUsers, } from '@fortawesome/pro-regular-svg-icons';
import { ModalToSheetSkeleton, Sidebar, SidebarAction, SidebarMenu, SidebarMenuItem, SidebarSection, } from '@sonika-se/ui-kit/components';
import { graphql, useFragment, useQueryLoader } from 'react-relay/hooks';
import { serviceIcons, serviceNames } from '@sonika-se/ui-kit';
import { useCurrentUser, useEnvironment, usePermissions } from '@sonika-se/ui-kit/hooks';
import enums from '~/enums.json';
const { PermissionEnum, Service } = enums;
import IHaveAvatarUpdateModal, { changeAvatarModalQuery } from '~/modals/IHaveAvatarUpdateModal';
import ImpersonateUserModal, { impersonateUserModalQuery } from '~/modals/ImpersonateUserModal';
import UserAttributesUpdateModal, { userAttributesUpdateModalQuery } from '~/modals/UserAttributesUpdateModal';
import UserInvitationModal, { userInvitationModalQuery } from '~/modals/UserInvitationModal';
import UserPasswordUpdateModal from '~/modals/UserPasswordUpdateModal';
import UserServicesUpdateModal, { userServicesUpdateModalQuery } from '~/modals/UserServicesUpdateModal';
import { Link } from 'react-router-dom';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
export const UserSidebar = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { userRef } = props;
    const user = useFragment(graphql `
      fragment UserSidebar_user on User {
        hidden
        id
        invitation {
          created
          accepted
        }
        isCompanyAdmin
        schedulableEntity {
          schedulable
        }
        services
        timeReportingUser {
          active
        }
      }
    `, userRef);
    const { frontUrls } = useEnvironment();
    const [impersonateUserModalQueryRef, loadImpersonateUserModalQuery, disposeImpersonateUserModalQuery] = useQueryLoader(impersonateUserModalQuery);
    const [changeAvatarModalQueryRef, loadIHaveAvatarUpdateModalQuery, disposeIHaveAvatarUpdateModalQuery] = useQueryLoader(changeAvatarModalQuery);
    const [userInvitationModalQueryRef, loadUserInvitationModalQuery, disposeUserInvitationModalQuery] = useQueryLoader(userInvitationModalQuery);
    const [userAttributesUpdateModalQueryRef, loadUserAttributesUpdateModalQuery, disposeUserAttributesUpdateModalQuery] = useQueryLoader(userAttributesUpdateModalQuery);
    const [userServicesUpdateModalQueryRef, loadUserServicesUpdateModalQuery, disposeUserServicesUpdateModalQuery] = useQueryLoader(userServicesUpdateModalQuery);
    const [userPasswordUpdateModalVisible, setUserPasswordUpdateModalVisible] = useState(false);
    const currentUser = useCurrentUser();
    const hasPermissions = usePermissions();
    return (<>
      <Sidebar>
        <SidebarSection title="Genvägar">
          <SidebarMenu>
            {!user.hidden && (<SidebarMenuItem icon={faUsers}>
                <Link to={`/colleagues/${user.id}`}>Användarens sida i kollegor</Link>
              </SidebarMenuItem>)}
            <SidebarMenuItem icon={faAddressBook}>
              <a href={`${frontUrls['EMPLOYMENT']}users/${user.id}`}>Användarens anställningar</a>
            </SidebarMenuItem>
            {((_a = user.schedulableEntity) === null || _a === void 0 ? void 0 : _a.schedulable) && (<SidebarMenuItem icon={faCalendarAlt}>
                <a href={`${frontUrls['SCHEDULE']}my-schedule?selectedUserId=${user.id}`}>Användarens schema</a>
              </SidebarMenuItem>)}
            {((_b = user.timeReportingUser) === null || _b === void 0 ? void 0 : _b.active) && (<SidebarMenuItem icon={faClock}>
                <a href={`${frontUrls['TIME']}time-reports/all?selectedUserId=${user.id}`}>Användarens tidrapporter</a>
              </SidebarMenuItem>)}
          </SidebarMenu>
        </SidebarSection>
        <SidebarSection title="Verktyg">
          <SidebarMenu>
            {hasPermissions([PermissionEnum.IMPERSONATE_USERS.key]) && (<SidebarMenuItem disabled={user.isCompanyAdmin || user.id === currentUser.id || !!currentUser.impersonatedBy} icon={faGhost} onClick={() => {
                loadImpersonateUserModalQuery({ userId: user.id });
            }}>
                Logga in som användare
              </SidebarMenuItem>)}
            <SidebarMenuItem icon={faKey} onClick={() => {
            setUserPasswordUpdateModalVisible(true);
        }}>
              Ändra lösenord
            </SidebarMenuItem>
            <SidebarMenuItem icon={faImagePolaroid} onClick={() => {
            loadIHaveAvatarUpdateModalQuery({ iHaveAvatarId: user.id });
        }}>
              Byt profilbild
            </SidebarMenuItem>
            {(!user.invitation === null || !((_c = user.invitation) === null || _c === void 0 ? void 0 : _c.accepted)) && (<SidebarMenuItem icon={faPaperPlane} onClick={() => {
                loadUserInvitationModalQuery({
                    userId: user.id,
                });
            }}>
                {((_d = user.invitation) === null || _d === void 0 ? void 0 : _d.created) ? 'Bjud in användare igen' : 'Bjud in användare'}
              </SidebarMenuItem>)}
          </SidebarMenu>
        </SidebarSection>
        <SidebarSection title="Tjänster" actions={<SidebarAction onClick={() => {
                loadUserServicesUpdateModalQuery({
                    userId: user.id,
                });
            }}>
              Ändra
            </SidebarAction>}>
          <SidebarMenu>
            {Object.keys(Service)
            .filter((service) => service !== 'SURVEY')
            .sort()
            .map((serviceName) => (<SidebarMenuItem icon={serviceIcons[serviceName]} iconProps={{
                color: user.services.includes(serviceName)
                    ? sonikaTheme.colors.primary
                    : sonikaTheme.colors.foreground.placeholder,
            }} key={serviceName}>
                  {serviceNames[serviceName]}
                </SidebarMenuItem>))}
          </SidebarMenu>
        </SidebarSection>
        <SidebarSection title="Egenskaper" actions={<SidebarAction onClick={() => {
                loadUserAttributesUpdateModalQuery({ userId: user.id });
            }}>
              Ändra
            </SidebarAction>}>
          <SidebarMenu>
            <SidebarMenuItem appearance={((_e = user.schedulableEntity) === null || _e === void 0 ? void 0 : _e.schedulable) ? 'go' : 'stop'} icon={((_f = user.schedulableEntity) === null || _f === void 0 ? void 0 : _f.schedulable) ? faCheck : faTimes}>
              Schemaläggningsbar
            </SidebarMenuItem>
            <SidebarMenuItem appearance={((_g = user.timeReportingUser) === null || _g === void 0 ? void 0 : _g.active) ? 'go' : 'stop'} icon={((_h = user.timeReportingUser) === null || _h === void 0 ? void 0 : _h.active) ? faCheck : faTimes}>
              Tidrapporterar
            </SidebarMenuItem>
            <SidebarMenuItem appearance={!user.hidden ? 'go' : 'stop'} icon={!user.hidden ? faCheck : faTimes}>
              Visa i kollegor
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarSection>
      </Sidebar>
      <Suspense fallback={<ModalToSheetSkeleton />}>
        {changeAvatarModalQueryRef && (<IHaveAvatarUpdateModal onClose={() => {
                disposeIHaveAvatarUpdateModalQuery();
            }} preloadedQuery={changeAvatarModalQueryRef}/>)}
        {impersonateUserModalQueryRef && (<ImpersonateUserModal onClose={() => {
                disposeImpersonateUserModalQuery();
            }} preloadedQuery={impersonateUserModalQueryRef}/>)}
        {userAttributesUpdateModalQueryRef && (<UserAttributesUpdateModal onClose={() => {
                disposeUserAttributesUpdateModalQuery();
            }} preloadedQuery={userAttributesUpdateModalQueryRef}/>)}
        {userInvitationModalQueryRef && (<UserInvitationModal onClose={() => {
                disposeUserInvitationModalQuery();
            }} preloadedQuery={userInvitationModalQueryRef}/>)}
        {userServicesUpdateModalQueryRef && (<UserServicesUpdateModal onClose={() => {
                disposeUserServicesUpdateModalQuery();
            }} preloadedQuery={userServicesUpdateModalQueryRef}/>)}
        {userPasswordUpdateModalVisible && (<UserPasswordUpdateModal onClose={() => {
                setUserPasswordUpdateModalVisible(false);
            }} userId={user.id}/>)}
      </Suspense>
    </>);
};
export default UserSidebar;
