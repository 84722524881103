/**
 * @generated SignedSource<<319960aadedf41b049810629819564bf>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "assetId"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "assetId"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AssetDetailsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Asset",
                    "kind": "LinkedField",
                    "name": "asset",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "AssetAddressPanel_asset"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "AssetAssignmentTypesPanel_asset"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "AssetSidebar_asset"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "AssetGeneralInfoPanel_asset"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AssetDetailsPageQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Asset",
                    "kind": "LinkedField",
                    "name": "asset",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "city",
                            "storageKey": null
                        },
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "postalCode",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "street",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssignmentTypeConnection",
                            "kind": "LinkedField",
                            "name": "assignmentTypes",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssignmentType",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SchedulableAsset",
                            "kind": "LinkedField",
                            "name": "schedulableEntity",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "schedulable",
                                    "storageKey": null
                                },
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "assetNumber",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "f5dcee5571c36b306bc95de3f1b2ca0b",
            "id": null,
            "metadata": {},
            "name": "AssetDetailsPageQuery",
            "operationKind": "query",
            "text": "query AssetDetailsPageQuery(\n  $assetId: ID!\n) {\n  asset(id: $assetId) {\n    ...AssetAddressPanel_asset\n    ...AssetAssignmentTypesPanel_asset\n    ...AssetSidebar_asset\n    ...AssetGeneralInfoPanel_asset\n    id\n  }\n}\n\nfragment AssetAddressPanel_asset on Asset {\n  city\n  id\n  postalCode\n  street\n}\n\nfragment AssetAssignmentTypesPanel_asset on Asset {\n  assignmentTypes {\n    nodes {\n      id\n      title\n    }\n  }\n  id\n}\n\nfragment AssetGeneralInfoPanel_asset on Asset {\n  assetNumber\n  description\n  id\n  name\n}\n\nfragment AssetSidebar_asset on Asset {\n  id\n  schedulableEntity {\n    schedulable\n    id\n  }\n}\n"
        }
    };
})();
node.hash = "be802fe76e4b74c8dbd2f730a39495b6";
export default node;
