import React, { useEffect } from 'react';
import QRCodeRenderer from 'qrcode';
import { createRandomId } from '@sonika-se/ui-kit/utils/misc';
const QRCode = (props) => {
    const id = createRandomId();
    useEffect(() => {
        const canvas = document.getElementById(id);
        QRCodeRenderer.toCanvas(canvas, props.qrData, { type: 'svg', width: '200' }, (err) => {
            if (err)
                throw err;
        });
    }, [props.qrData]);
    return <canvas id={id}/>;
};
export default QRCode;
