import React from 'react';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { graphql, useLazyLoadQuery } from 'react-relay/hooks';
import { AppContextProvider } from '@sonika-se/ui-kit/components';
import { mapTextsToIntlFormat } from '@sonika-se/ui-kit/utils/misc';
import '@toast-ui/editor/dist/toastui-editor.css';
import '@sonika-se/ui-kit/bundle.css';
import Routes from './Routes';
import SystemTexts from './SystemTexts';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';
const App = () => {
    var _a;
    const [texts, setTexts] = React.useState([]);
    const data = useLazyLoadQuery(graphql `
      query AppQuery {
        ...Routes_query
        currentUser {
          impersonatedBy {
            id
            firstname
            lastname
          }
          me {
            id
            firstname
            lastname
          }
          permissions
        }
      }
    `, {});
    const { currentUser } = data;
    const [breadcrumbs, setBreadcrumbs] = React.useState();
    return (<>
      <Suspense fallback={<div />}>
        <SystemTexts onChange={(texts) => setTexts(texts)}/>
      </Suspense>
      <AppContextProvider value={{
            breadcrumbs,
            currentUser: (currentUser === null || currentUser === void 0 ? void 0 : currentUser.me) && {
                id: currentUser.me.id,
                name: `${currentUser.me.firstname} ${currentUser.me.lastname}`,
                impersonatedBy: currentUser.impersonatedBy && {
                    id: (_a = currentUser.impersonatedBy) === null || _a === void 0 ? void 0 : _a.id,
                    name: `${currentUser.impersonatedBy.firstname} ${currentUser.impersonatedBy.lastname}`,
                },
                permissions: currentUser.permissions,
            },
            setBreadcrumbs,
        }}>
        <IntlProvider onError={() => {
            // console.warn(error)}
        }} locale="sv" messages={mapTextsToIntlFormat(texts)}>
          <BrowserRouter>
            <Routes queryRef={data}/>
          </BrowserRouter>
        </IntlProvider>
      </AppContextProvider>
    </>);
};
export default withAITracking(reactPlugin, App, null, 'flex1');
