/**
 * @generated SignedSource<<f94e036d19da22d0edfd1e51b8c0a516>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "connectionsToPrependEdgeTo"
        },
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "model"
        }
    ], v1 = [
        {
            "kind": "Variable",
            "name": "model",
            "variableName": "model"
        }
    ], v2 = {
        "alias": null,
        "args": null,
        "concreteType": "EdgeOfUser",
        "kind": "LinkedField",
        "name": "userEdges",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "firstname",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastname",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": null
    };
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ServiceUsersPage_addUsersToServiceMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "AddUsersToServicePayload",
                    "kind": "LinkedField",
                    "name": "addUsersToService",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "Mutation",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ServiceUsersPage_addUsersToServiceMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "AddUsersToServicePayload",
                    "kind": "LinkedField",
                    "name": "addUsersToService",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "filters": null,
                            "handle": "prependEdge",
                            "key": "",
                            "kind": "LinkedHandle",
                            "name": "userEdges",
                            "handleArgs": [
                                {
                                    "kind": "Variable",
                                    "name": "connections",
                                    "variableName": "connectionsToPrependEdgeTo"
                                }
                            ]
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "315e14b45122324ab4730adbc2a67710",
            "id": null,
            "metadata": {},
            "name": "ServiceUsersPage_addUsersToServiceMutation",
            "operationKind": "mutation",
            "text": "mutation ServiceUsersPage_addUsersToServiceMutation(\n  $model: AddUsersToServiceInput!\n) {\n  addUsersToService(model: $model) {\n    userEdges {\n      node {\n        firstname\n        id\n        lastname\n      }\n    }\n  }\n}\n"
        }
    };
})();
node.hash = "6fc11f6dbd8933f35ed5fa2be31f29ce";
export default node;
