import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { RelayEnvironmentProvider } from 'react-relay/hooks';
import { Button, ErrorBox, Flexbox, Skeleton, Text, ThemeProvider } from '@sonika-se/ui-kit/components';
import { sonikaTheme } from '@sonika-se/ui-kit/themes';
import { ApiContextProvider, EnvironmentContextProvider, useLogout, useRedirectToLogin, useToasts, } from '@sonika-se/ui-kit/hooks';
import { removeAccessTokens } from '@sonika-se/ui-kit/utils/auth';
import App from './App';
import { APP_NAME, APP_VERSION, BUILD_ID, SONIKA_FRONT_URLS, SONIKA_GRAPHQL_API_URL, SONIKA_REST_API_URL, } from './environment';
import useProvideRelayEnvironment from '~/hooks/useProvideRelayEnvironment';
import ErrorBoundary from './shared/components/ErrorBoundary';
console.info(`Loading ${APP_NAME} v${APP_VERSION}${BUILD_ID ? ` (${BUILD_ID})` : ''}`);
// SET --vh property for css so we can use it to calculate the cirrect viewheight on eg. Mobile screens
const vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
window.addEventListener('resize', () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});
window.addEventListener('orientationchange', () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
});
const RelayProvider = (props) => {
    const { children } = props;
    const environment = useProvideRelayEnvironment();
    return <RelayEnvironmentProvider environment={environment}>{children}</RelayEnvironmentProvider>;
};
const Index = () => (<EnvironmentContextProvider value={{
        frontUrls: SONIKA_FRONT_URLS,
        graphQLApiUrl: SONIKA_GRAPHQL_API_URL,
        restApiUrl: SONIKA_REST_API_URL,
    }}>
    <ThemeProvider theme={sonikaTheme}>
      <IndexInner />
    </ThemeProvider>
  </EnvironmentContextProvider>);
const IndexInner = () => {
    const redirectToLogin = useRedirectToLogin();
    const logout = useLogout();
    const { addToast } = useToasts();
    return (<Suspense fallback={<Skeleton type="page"/>}>
      <ErrorBoundary onError={() => (<ErrorBox height="100%">
            <Flexbox gap="large" direction="column" align="center">
              <Text>Något gick fel och vi kan inte ladda sidan just nu, försök igen!</Text>
              <Flexbox gap="small">
                <Button onClick={() => {
                window.location.reload();
            }}>
                  Försök igen
                </Button>
                <Button onClick={() => logout()} color="secondary">
                  Logga ut
                </Button>
              </Flexbox>
            </Flexbox>
          </ErrorBox>)}>
        <ApiContextProvider value={{
            onGraphQLApiError: (error) => {
                console.log('A GraphQL API error occured', error);
                addToast(error === null || error === void 0 ? void 0 : error.message, {
                    color: 'error',
                });
            },
            onAccessTokenRefreshError: (error) => {
                console.log('Error refreshing access token', error);
                removeAccessTokens();
                redirectToLogin();
            },
            onRestApiError: (error) => {
                console.log('A REST API error occured', error);
            },
        }}>
          <RelayProvider>
            <Suspense fallback={<div />}>
              <App />
            </Suspense>
          </RelayProvider>
        </ApiContextProvider>
      </ErrorBoundary>
    </Suspense>);
};
createRoot(document.getElementById('root')).render(<Index />);
