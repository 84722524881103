/**
 * @generated SignedSource<<d95182b843619f1bcf5154654f3f4a41>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssetAttachmentsPanel_asset",
    "selections": [
        {
            "args": null,
            "kind": "FragmentSpread",
            "name": "useAssetAttachments_asset"
        }
    ],
    "type": "Asset",
    "abstractKey": null
};
node.hash = "68418f85be87d9d1e4bafdefe26c3533";
export default node;
