/**
 * @generated SignedSource<<bb6441e2d532de5ed70bbff76ef59c68>>
 * @lightSyntaxTransform
 * @nogrep
 */
const node = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssetSidebar_asset",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "concreteType": "SchedulableAsset",
            "kind": "LinkedField",
            "name": "schedulableEntity",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "schedulable",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Asset",
    "abstractKey": null
};
node.hash = "5de4807ad0c995e4830805fba3aee0b9";
export default node;
