import React, { Suspense, useState } from 'react';
import { Card, Divider, Flexbox, H2, H4, Skeleton, Tabs, Text } from '@sonika-se/ui-kit/components';
import { useEnvironment, usePermissions, useViewport } from '@sonika-se/ui-kit/hooks';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'relay-runtime';
import { useFragment } from 'react-relay/hooks';
import MyTimeReportsPanel from './MyTimeReportsPanel';
import AllTimeReportsPanel from './AllTimeReportsPanel';
export const TimePanel = (props) => {
    var _a, _b, _c;
    const { frontUrls } = useEnvironment();
    const hasPermissions = usePermissions();
    const data = useFragment(graphql `
      fragment TimePanel_user on User {
        timeReportingUser {
          active
          id
          ...MyTimeReportsPanel_timeReportingUser
        }
      }
    `, props.userRef);
    const [tab, setTab] = useState(((_a = data === null || data === void 0 ? void 0 : data.timeReportingUser) === null || _a === void 0 ? void 0 : _a.active) ? 'my-timereports' : 'all-timereports');
    const { size } = useViewport();
    if (!hasPermissions(['ADMINISTER_TIME_REPORTS']) && !((_b = data === null || data === void 0 ? void 0 : data.timeReportingUser) === null || _b === void 0 ? void 0 : _b.active))
        return null;
    return (<Flexbox direction="column" gap="medium">
      <Flexbox justify="space" align="center">
        <Flexbox gap="large" align="center">
          {size !== 'small' ? (<H2>
              <FormattedMessage defaultMessage="Tid" id="general.services.TIME.name"/>
            </H2>) : (<H4>
              <FormattedMessage defaultMessage="Tid" id="general.services.TIME.name"/>
            </H4>)}
        </Flexbox>
        <a href={`${frontUrls['TIME']}`}>
          <Text size="medium" bold>
            Tidrapporter
          </Text>
        </a>
      </Flexbox>
      <Flexbox direction="column" gap="medium">
        {hasPermissions(['ADMINISTER_TIME_REPORTS']) && ((_c = data === null || data === void 0 ? void 0 : data.timeReportingUser) === null || _c === void 0 ? void 0 : _c.active) && (<div>
            <Tabs initialValue={tab} tabs={[
                {
                    value: 'my-timereports',
                    key: 'my-timereports',
                    label: 'Mina tidrapporter',
                },
                {
                    value: 'all-timereports',
                    key: 'all-timereports',
                    label: 'Administration',
                },
            ]} onChange={(value) => setTab(value)}/>
            <Divider />
          </div>)}
        {tab === 'my-timereports' && <MyTimeReportsPanel timeReportingUserRef={data === null || data === void 0 ? void 0 : data.timeReportingUser}/>}
        {tab === 'all-timereports' && (<Suspense fallback={<Card shadow="none">
                <Skeleton type="table" height="200px"/>
              </Card>}>
            <AllTimeReportsPanel />
          </Suspense>)}
      </Flexbox>
    </Flexbox>);
};
export default TimePanel;
