var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Chip, Flexbox, Text } from '@sonika-se/ui-kit/components';
import { useEnvironment } from '@sonika-se/ui-kit/hooks';
import React, { useMemo } from 'react';
import { graphql, useFragment } from 'react-relay/hooks';
import dateRangeInWords from '~/utils/dateRangeInWords';
export const ScheduleShiftCard = (props) => {
    var _a, _b, _c, _d;
    const { className, scheduleShiftRef, type } = props, rest = __rest(props, ["className", "scheduleShiftRef", "type"]);
    const data = useFragment(graphql `
      fragment ScheduleShiftCard_scheduleShift on ScheduleShift {
        __typename
        id
        assignedEntity {
          ... on SchedulableAsset {
            asset {
              color
            }
          }
          ... on SchedulableUser {
            user {
              color
            }
          }
        }
        assignmentType {
          title
        }
        schedule {
          name
        }
        end
        simultaneousScheduleShift {
          edges {
            node {
              id
              assignedEntity {
                ... on SchedulableAsset {
                  id
                  asset {
                    name
                    color
                  }
                }
              }
            }
          }
        }
        start
        isVacant
      }
    `, scheduleShiftRef);
    if (data.__typename !== 'ScheduleShift') {
        console.warn(`ScheduleShiftCard used for entity of type ${data.__typename}. Will not render anything.`);
        return null;
    }
    const title = useMemo(() => {
        var _a;
        if (type === 'ASSET') {
            return `${data.assignmentType.title} (${data.schedule.name})`;
        }
        const simultaneousAssets = (_a = data.simultaneousScheduleShift) === null || _a === void 0 ? void 0 : _a.edges.filter((asset) => { var _a, _b; return (_b = (_a = asset === null || asset === void 0 ? void 0 : asset.node) === null || _a === void 0 ? void 0 : _a.assignedEntity) === null || _b === void 0 ? void 0 : _b.asset; });
        return `${data.assignmentType.title}${(simultaneousAssets === null || simultaneousAssets === void 0 ? void 0 : simultaneousAssets.length)
            ? ` (${simultaneousAssets.map((asset) => asset.node.assignedEntity.asset.name).join(', ')})`
            : ''}`;
    }, [data]);
    const { frontUrls } = useEnvironment();
    return (<div className={className} {...rest}>
      <Flexbox direction="column" gap="xsmall">
        <Text size="small">{dateRangeInWords(new Date(data === null || data === void 0 ? void 0 : data.start.toString()), new Date(data === null || data === void 0 ? void 0 : data.end.toString()))}</Text>
        <Chip onClick={() => (window.location.href = `${frontUrls['SCHEDULE']}running-schedules/${data === null || data === void 0 ? void 0 : data.id}`)} type="button" color={data.assignedEntity ? ((_b = (_a = data.assignedEntity) === null || _a === void 0 ? void 0 : _a.asset) === null || _b === void 0 ? void 0 : _b.color) || ((_d = (_c = data.assignedEntity) === null || _c === void 0 ? void 0 : _c.user) === null || _d === void 0 ? void 0 : _d.color) : '#F44336'} text={title} specialColor={data.isVacant ? 'red-striped' : undefined}/>
      </Flexbox>
    </div>);
};
export default ScheduleShiftCard;
